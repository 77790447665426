import React, { memo } from 'react';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';

type Props = {
  readonly message?: string;
  readonly basic?: boolean;
  readonly loading?: boolean;
};

const defaultProps: Partial<Props> = {
  message: 'No Data'
};

export const NoDataPlaceholder: React.FC<Props> = memo(
  ({ basic, message, loading }) =>
    loading ? (
      <Segment basic={basic}>
        <Dimmer active inverted>
          <Loader />
        </Dimmer>
      </Segment>
    ) : (
      <Segment basic={basic}>{message}</Segment>
    )
);

NoDataPlaceholder.defaultProps = defaultProps;
