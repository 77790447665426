import React, { memo } from 'react';
import { Label, StrictLabelProps } from 'semantic-ui-react';
import { TopologyStatus } from 'utils';

import './styles.less';

type Props = {
  readonly state: string;
};

export const StateLabel: React.FC<Props> = memo(({ state }) => {
  const props: StrictLabelProps = {
    size: 'tiny',
    className: 'cl-state-label'
  };

  if (state === TopologyStatus.UP) {
    props.className = 'active';
  } else if (
    [
      TopologyStatus.DEPLOY_IN_PROGRESS,
      TopologyStatus.PASSWORD_CHANGE,
      TopologyStatus.BASTION_CONFIGURATION,
      TopologyStatus.SHUTDOWN_IN_PROGRESS
    ].includes(state as TopologyStatus)
  ) {
    props.className = 'in-progress';
  } else if (
    [
      TopologyStatus.DOWN,
      TopologyStatus.UNKNOWN,
      TopologyStatus.ERROR
    ].includes(state as TopologyStatus)
  ) {
    props.className = 'inactive';
  }

  return <Label {...props}>{state}</Label>;
});
