/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */

/**
 * Checks if two arrays are equal.
 *
 * @template T - The type of elements in the arrays.
 * @param {T[]} a - The first array.
 * @param {T[]} b - The second array.
 * @return {boolean} - true if the arrays are equal, false otherwise.
 */
export function equalArrays<T>(a: T[], b: T[]): boolean {
  return a.length === b.length && a.every((value, index) => value === b[index]);
}
