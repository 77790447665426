import React, { memo, useState } from 'react';
import Chart from 'react-apexcharts';
import { Card, Divider, Icon } from 'semantic-ui-react';

import { getTimeRanges } from 'utils';

import { MetricDataName } from 'enums';
import { useTimeSeriesData } from 'hooks/reportHooks/useTimeSeriesData';

import {
  activeFailingAndActivelyDeployedTopologyOptions,
  activeTopologyPerRegionsOptions,
  ramUtilizationOptions,
  topologyPerRegionOptions
} from '../ChartOptions/TopologyChartOptions';
import {
  StartEndMixin,
  TimelinePicker
} from '../TimelinePicker/TimelinePicker';
import {
  getActiveFailingAndActivelyDeployedTopologySeries,
  getActiveTopologyPerRegionSeries,
  getRamUtilizationSeries,
  getTopologyPerRegionSeries,
  getTotalTopologies
} from './TopologyChartHelpers';

import './styles.less';

export const TopologyCharts: React.FC = memo(() => {
  const [dateTime, setDateTime] = useState(getTimeRanges('1w'));

  // Adding comments for readability: original responses by api
  const originalRamUtilization = useTimeSeriesData(
    MetricDataName.RAMUtilization,
    dateTime.start,
    dateTime.end
  );
  const ramUtilization = originalRamUtilization.data;
  const originalNumberOfTopologies = useTimeSeriesData(
    MetricDataName.NumberOfTopologies,
    dateTime.start,
    dateTime.end
  );
  const originalFailingNumberOfTopologies = useTimeSeriesData(
    MetricDataName.CloudlabsTopologyFailedCount,
    dateTime.start,
    dateTime.end
  );
  const originalActivelyDeployedNumberOfTopologies = useTimeSeriesData(
    MetricDataName.CloudlabsTopologyActivelyDeployedCount,
    dateTime.start,
    dateTime.end
  );
  const numberOfTopologies = originalNumberOfTopologies.data;
  const topologyTimeseries =
    numberOfTopologies && numberOfTopologies.timeseries
      ? numberOfTopologies.timeseries
      : [];
  const failingNumberOfTopologies = originalFailingNumberOfTopologies.data;
  const failingTopologyTimeseries =
    failingNumberOfTopologies && failingNumberOfTopologies.timeseries
      ? failingNumberOfTopologies.timeseries
      : [];
  const activelyDeployedNumberOfTopologies =
    originalActivelyDeployedNumberOfTopologies.data;
  const activelyDeployedTopologyTimeseries =
    activelyDeployedNumberOfTopologies &&
    activelyDeployedNumberOfTopologies.timeseries
      ? activelyDeployedNumberOfTopologies.timeseries
      : [];
  // process data for charts
  const topologyPerRegion = getTopologyPerRegionSeries(topologyTimeseries);
  const totalTopologies = getTotalTopologies(topologyPerRegion);
  const topologyPerRegionSeries = [
    {
      name: 'Number of Topologies',
      data: topologyPerRegion
    }
  ];
  const ramUtilizationSeries = [
    {
      name: '% Utilized',
      data: getRamUtilizationSeries(
        ramUtilization && ramUtilization.timeseries
          ? ramUtilization.timeseries
          : []
      )
    }
  ];
  const activeTopologyPerRegionSeries = getActiveTopologyPerRegionSeries(
    topologyTimeseries
  );
  const activeFailingAndActivelyDeployedTopologySeries = getActiveFailingAndActivelyDeployedTopologySeries(
    topologyTimeseries,
    failingTopologyTimeseries,
    activelyDeployedTopologyTimeseries
  );

  return (
    <div>
      <h2 className='caption'>Topologies</h2>
      <TimelinePicker
        setDateTime={(value: StartEndMixin) => setDateTime(value)}
      />
      <Divider />
      <Card
        raised
        header={
          <h3>
            <Icon name='globe' />
            Global number of topologies {totalTopologies}
          </h3>
        }
      />
      <div className='charts-div'>
        <Chart
          className='chart'
          options={topologyPerRegionOptions(
            originalNumberOfTopologies.isLoading
          )}
          series={topologyPerRegionSeries}
          type='bar'
          width='700'
          height='300'
        />
        <Chart
          className='chart'
          options={ramUtilizationOptions(originalRamUtilization.isLoading)}
          series={ramUtilizationSeries}
          type='bar'
          width='700'
          height='300'
        />
        <Chart
          className='chart'
          options={activeFailingAndActivelyDeployedTopologyOptions(
            originalNumberOfTopologies.isLoading &&
              originalFailingNumberOfTopologies.isLoading &&
              originalActivelyDeployedNumberOfTopologies.isLoading
          )}
          series={activeFailingAndActivelyDeployedTopologySeries}
          type='line'
          width='700'
          height='300'
        />
        <Chart
          className='chart'
          options={activeTopologyPerRegionsOptions(
            originalNumberOfTopologies.isLoading
          )}
          series={activeTopologyPerRegionSeries}
          type='line'
          width='700'
          height='300'
        />
      </div>
    </div>
  );
});
