/*
 * Copyright 2024-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */
import {
  getBaseUrl,
  stripEmptyLines,
  stripIndentation,
  topologyName,
  topologyUtils
} from 'utils';

import {
  getApstraVmWebAccess,
  getFlowVmWebAccess,
  TopologyDetails,
  VM,
  VMAccess
} from 'types';

export const topologyDetailsHtml = (t: TopologyDetails): string => {
  const vmInfoTemplate = (
    vmName: string,
    vm: VM | undefined,
    vmAccess: VMAccess | undefined
  ): string => {
    if (vmAccess && vm) {
      const url = topologyUtils.getAccessLink(vmAccess, vm);
      return `
        <p>
          ${vmName} link:&nbsp;
          <a href=${url} target='_blank' rel='noreferrer'>
            ${url}
          </a>
        </p>
        <p>
          Username:&nbsp;${vmAccess.username}
        </p>
        <p>
          Password:&nbsp;${vmAccess.password}
        </p>`;
    }
    return '';
  };

  const apstraVmInfo: string = vmInfoTemplate(
    'Apstra VM',
    ...getApstraVmWebAccess(t)
  );

  const flowVmInfo: string = vmInfoTemplate(
    'Flow VM',
    ...getFlowVmWebAccess(t)
  );
  const topologyUrl = `${getBaseUrl()}/topology/${t.uuid}`;

  return stripIndentation(
    stripEmptyLines(`
        <html lang="en">
          <body>
            <h2>Access details for Juniper Apstra Cloudlabs topology</h2>
            <p>Topology name: <b>${topologyName(t)}</b></p>
            <p>Cloudlabs topology URL: <a href='${topologyUrl}' target='_blank' rel='noreferrer'>${topologyUrl}</a></p>
            ${apstraVmInfo}
            ${flowVmInfo}
          </body>
        </html>`)
  );
};
