import { AxiosRequestConfig } from 'axios';

import { interpolateRoute } from 'utils';

import { TaskCreatedResponse } from 'interfaces';
import {
  DepartmentListResponse,
  TagListResponse
} from 'interfaces/response/tag-list-response';
import { Tag } from 'types/Tags/tags';

import { apiPrefix, apiRoutes } from 'constants/api-routes';

import { API } from './api';

const getAll = (config?: AxiosRequestConfig): Promise<TagListResponse> => {
  return API.get<TagListResponse>(apiPrefix(apiRoutes.tags), config);
};

const getAllDepartments = (
  config?: AxiosRequestConfig
): Promise<DepartmentListResponse> => {
  return API.get<DepartmentListResponse>(
    apiPrefix(apiRoutes.departments),
    config
  );
};

const getOne = (
  id: string,
  config?: AxiosRequestConfig
): Promise<Tag | null> => {
  return API.get<Tag>(
    apiPrefix(interpolateRoute(apiRoutes.tagsById, { id })),
    config
  );
};

const update = (
  id: number,
  data: Partial<Tag>,
  config?: AxiosRequestConfig
): Promise<Tag | null> => {
  return API.put<Tag>(
    apiPrefix(interpolateRoute(apiRoutes.tagsById, { id })),
    data,
    config
  );
};

const updateTopologyTag = (
  uuid: string,
  data: TagListResponse,
  config?: AxiosRequestConfig
): Promise<TagListResponse> => {
  return API.put<TagListResponse>(
    apiPrefix(interpolateRoute(apiRoutes.topologyTagUpdate, { uuid })),
    data,
    config
  );
};

const remove = (
  id: number,
  config?: AxiosRequestConfig
): Promise<Tag | null> => {
  return API.delete<Tag>(
    apiPrefix(interpolateRoute(apiRoutes.tagsById, { id })),
    config
  );
};

const create = (
  data: Tag,
  config?: AxiosRequestConfig
): Promise<TaskCreatedResponse | null> => {
  return API.post<TaskCreatedResponse>(
    apiPrefix(interpolateRoute(apiRoutes.tags)),
    data,
    config
  );
};

export const tagsApi = {
  getAll,
  getAllDepartments,
  getOne,
  update,
  updateTopologyTag,
  remove,
  create
};
