/*
 * Copyright 2024-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */
import {
  getBaseUrl,
  stripEmptyLines,
  stripIndentation,
  topologyName,
  topologyUtils
} from 'utils';

import {
  getApstraVmWebAccess,
  getFlowVmWebAccess,
  TopologyDetails,
  VM,
  VMAccess
} from 'types';

export const topologyDetailsText = (t: TopologyDetails): string => {
  const vmInfoTemplate = (
    vmName: string,
    vm: VM | undefined,
    vmAccess: VMAccess | undefined
  ): string => {
    if (vmAccess && vm) {
      const url = topologyUtils.getAccessLink(vmAccess, vm);
      return stripIndentation(
        stripEmptyLines(`
        ${vmName} link: ${url}
        Username: ${vmAccess.username}
        Password: ${vmAccess.password}
      `)
      );
    }
    return '';
  };

  const apstraVmInfo: string = vmInfoTemplate(
    'Apstra VM',
    ...getApstraVmWebAccess(t)
  );

  const flowVmInfo: string = vmInfoTemplate(
    'ApstraFlow VM',
    ...getFlowVmWebAccess(t)
  );
  const topologyUrl = `${getBaseUrl()}/topology/${t.uuid}`;

  return stripIndentation(
    stripEmptyLines(`
    Access details for Juniper Apstra Cloudlabs topology
    ----
    Topology name: ${topologyName(t)}
    Cloudlabs topology URL: ${topologyUrl}
    ${apstraVmInfo}
    ${flowVmInfo}
  `)
  );
};
