/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */
import React, { memo, useEffect } from 'react';
import { Label } from 'semantic-ui-react';

import { Validator } from 'components/ValidatedInput/Validators/Base';

import { Tag } from './Tag';

import './styles.less';

interface TagInputProps {
  validators: Validator[];
  placeholder?: string;
  onChange: (tags: string[]) => void;
  allowDuplicates?: boolean;
}

export const TagInput: React.FC<TagInputProps> = memo(
  ({ validators, onChange, allowDuplicates, placeholder }) => {
    const separators = [' ', 'Enter'];
    const [tags, setTags] = React.useState<string[]>([]);
    const [error, setError] = React.useState<string>('');

    const containerClassName = error
      ? 'cltaginput-container error'
      : 'cltaginput-container';

    useEffect(() => {
      onChange(tags);
    }, [tags, onChange]);

    const checkErrors = (
      text: string,
      setInputValue: (value: string) => void
    ) => {
      const errors = validators
        .map(v => v.error(text))
        .filter(err => err !== null)
        .join('; ');
      setError(errors);
      if (!errors && text !== '') {
        setTags([...tags, text]);
        setInputValue('');
      }
    };

    return (
      <>
        <div className={containerClassName}>
          {tags.map((tag, index) => (
            <Tag
              key={`${tag}_${index}`}
              aria-label={tag}
              label={tag}
              onRemove={() => {
                setTags(tags.filter(t => t !== tag));
              }}
            />
          ))}
          <input
            placeholder={placeholder}
            type={'text'}
            className={'cltaginput-input'}
            onKeyUp={e => {
              e.stopPropagation();

              const text =
                e.key === ' '
                  ? e.currentTarget.value.slice(0, -1)
                  : e.currentTarget.value;
              e.currentTarget.value = text;

              if (e.key === 'Backspace' && e.currentTarget.value === '') {
                setTags(tags.slice(0, -1));
              }

              if (!allowDuplicates && tags.includes(text)) {
                setError(`"${text}" already exists`);
              } else if (separators.includes(e.key)) {
                checkErrors(text, value => {
                  e.currentTarget.value = value;
                });
                e.preventDefault();
              }
            }}
            onBlur={e => {
              const text = e.currentTarget.value;

              if (!allowDuplicates && tags.includes(text)) {
                setError(`"${text}" already exists`);
              } else if (text !== '') {
                checkErrors(text, value => {
                  e.currentTarget.value = value;
                });
                e.preventDefault();
              }
            }}
          />
        </div>
        {error && (
          <Label basic color={'red'} pointing={'above'}>
            {error}
          </Label>
        )}
      </>
    );
  }
);
