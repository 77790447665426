import { isEmpty } from 'lodash';
import React, { memo } from 'react';
import { Grid } from 'semantic-ui-react';

import { topologyUtils } from 'utils';

import { NoDataPlaceholder } from 'components';
import { VM } from 'types';
import { VMsTable } from 'views/Topology/VMsTable';

import { BastionUsageMessage } from '../BastionUsageMessage';

type Props = {
  readonly vms: VM[];
};

export const VMs: React.FC<Props> = memo(({ vms }) => {
  const bastionUsageMessage = () => {
    const bastion: VM | undefined = vms.find(vm => vm.role === 'bastion');

    if (bastion) {
      const bastionSsh = bastion.access.find(
        access => access.protocol === 'ssh'
      );
      if (bastionSsh) {
        const bastionFqdn = topologyUtils.getAccessLink(bastionSsh, bastion);
        return <BastionUsageMessage bastionFqdn={bastionFqdn} />;
      }
    }
  };

  return (
    <div className='cl-vms'>
      <h2 className='cl-caption-padded'>{'VMs'}</h2>
      {isEmpty(vms) ? (
        <NoDataPlaceholder />
      ) : (
        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>{bastionUsageMessage()}</Grid.Column>
            <Grid.Column width={12}>
              <VMsTable vms={vms} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </div>
  );
});
