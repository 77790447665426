import { find } from 'lodash';
import React, { memo } from 'react';
import { Grid } from 'semantic-ui-react';

import { isTopologyInactive, isTopologyUp } from 'utils';

import { TopologyDetails } from 'types';

import { AOSFlowUICard } from '../AOSFlowUICard';
import { AOSUICard } from '../AOSUICard';
import { MilestoneActionsCard } from '../MilestoneActionsCard';
import { PredefinedActionsCard } from '../PredefinedActionsCard';
import { TelemetryStreamingCard } from '../TelemetryStreamingCard';

type Props = {
  topology: TopologyDetails;
  refetchData: () => void;
};

export const TopologyCards: React.FC<Props> = memo(
  ({ topology, refetchData }) => {
    const supportedActionsAvailable =
      topology.templateData &&
      topology.templateData.params &&
      topology.templateData.params.hasOwnProperty('supportedActions');
    const supportedActions: string[] = supportedActionsAvailable
      ? (topology.templateData?.params?.supportedActions as string[])
      : [];
    const aosVM = find(topology.vms, { role: 'aos' });
    const telemetryStreamingVM = find(topology.vms, { role: 'bastion' });
    const flowDataCollectorVM = find(topology.vms, { role: 'flowData' });
    const name = topology.name.toLowerCase();
    const topologyIsDown = isTopologyInactive(topology);
    const topologyIsUp = isTopologyUp(topology);
    const isFlowCollector = () => {
      return (
        topology.templateData?.params?.topologyType &&
        topology.templateData.params.topologyType === 'ApstraFlow'
      );
    };
    return (
      <div className='cl-topology-cards'>
        <h2 className='cl-caption-padded'>Predefined & Quick Actions</h2>
        <Grid centered>
          <Grid.Row>
            <Grid.Column computer='8' largeScreen='4' widescreen='4'>
              <AOSUICard vm={aosVM} enabled={topology.state === 'up'} />
            </Grid.Column>
            <Grid.Column computer='8' largeScreen='4' widescreen='4'>
              <TelemetryStreamingCard
                vm={telemetryStreamingVM}
                enabled={topologyIsUp}
              />
            </Grid.Column>
            {name.includes('customer_lab') && !name.includes('freeform') && (
              <Grid.Column computer='8' largeScreen='4' widescreen='4'>
                <MilestoneActionsCard
                  topologyUuid={topology.uuid}
                  isUp={topologyIsUp}
                  topologyIsDown={topologyIsDown}
                />
              </Grid.Column>
            )}
            {isFlowCollector() && (
              <Grid.Column computer='8' largeScreen='4' widescreen='4'>
                <AOSFlowUICard
                  vm={flowDataCollectorVM}
                  enabled={topologyIsUp}
                />
              </Grid.Column>
            )}
          </Grid.Row>
          <Grid.Row>
            <PredefinedActionsCard
              topology={topology}
              refetchData={refetchData}
              supportedActions={supportedActions}
            />
          </Grid.Row>
        </Grid>
      </div>
    );
  }
);
