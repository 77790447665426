/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */

import { Validator } from './Base';

export class Unique extends Validator {
  private readonly values: string[];
  private value?: string;

  constructor(values: string[]) {
    super();
    this.values = values;
  }

  validate(value: string): boolean {
    this.value = value;
    return !this.values.includes(value);
  }

  protected errorMessage(): string {
    return this.value ? `"${this.value}" already exists` : 'Tag already exists';
  }
}
