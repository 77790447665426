import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Table } from 'semantic-ui-react';

import { Template, TopologyFieldNames } from 'types';

import { setTopologyFieldName } from 'actions/actions';
import { RootState } from 'Reducers/contactReducer';

import './styles.less';

type Props = {
  readonly templates: Template[];
  readonly selectedTemplateId: number | null;
  readonly onChange: (templateId: number) => void;
};

export const TemplateSelectionTable: React.FC<Props> = memo(
  ({ templates, selectedTemplateId, onChange }) => {
    const dispatch = useDispatch();
    const fieldValueNames: TopologyFieldNames = useSelector(
      (state: RootState) => state.fieldValues
    );
    return (
      <Table celled striped selectable className='cl-template-selection-table'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>{'Template Name'}</Table.HeaderCell>
            <Table.HeaderCell>{'Apstra Version'}</Table.HeaderCell>
            <Table.HeaderCell>{'Description'}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {templates.map(template => (
            <Table.Row
              key={template.id}
              active={selectedTemplateId === template.id}
              onClick={() => {
                onChange(template.id);
                dispatch(
                  setTopologyFieldName({
                    ...fieldValueNames,
                    template: template.name,
                    templateId: [template.id],
                    templateExpiration: template.defaultExpiration
                      ? template.defaultExpiration
                      : 0
                  })
                );
              }}
            >
              <Table.Cell collapsing className='selection-cell'>
                <Checkbox radio checked={selectedTemplateId === template.id} />
              </Table.Cell>
              <Table.Cell className='template-name-cell'>
                {template.label ? template.label : template.name}
              </Table.Cell>
              <Table.Cell>{template.aosVersion}</Table.Cell>
              <Table.Cell>{template.description}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
);
