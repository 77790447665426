import React, { memo } from 'react';

import { StepItem } from 'types';

import './styles.less';

type Props = {
  readonly stepIndex: number;
  readonly status: string;
  readonly stepItem: StepItem;
  readonly disableStepClick: boolean;
  readonly onStepClick: (value: number) => void;
};
export const Step: React.FC<Props> = memo(
  ({ stepIndex, status, stepItem, disableStepClick, onStepClick }) => {
    let style = 'step';
    if (status === 'current') {
      style = 'step is-current';
    }
    if (status === 'completed') {
      style = 'step is-completed';
    }
    if (status === 'incomplete') {
      style = 'step is-incomplete';
    }
    return (
      <div
        className={style}
        onClick={() => {
          if (disableStepClick) {
            return;
          }
          onStepClick(stepIndex);
        }}
      >
        <h2 className='step-title'> {stepItem.title} </h2>
        <p className='step-description'> {stepItem.description} </p>
      </div>
    );
  }
);
