import { AxiosRequestConfig } from 'axios';

import { interpolateRoute } from 'utils';

import { apiPrefix, apiRoutes } from 'constants/api-routes';
import { TaskCreatedResponse } from 'interfaces';
import { Template, TemplateImage } from 'types';

import { API } from './api';

const getOne = (id: number, config?: AxiosRequestConfig): Promise<Template> => {
  return API.get<Template>(
    apiPrefix(interpolateRoute(apiRoutes.templateById, { id })),
    config
  );
};

const create = (
  data: Template,
  config?: AxiosRequestConfig
): Promise<TaskCreatedResponse | null> => {
  return API.post<TaskCreatedResponse>(
    apiPrefix(interpolateRoute(apiRoutes.templates)),
    data,
    config
  );
};

const remove = (
  id: number,
  config?: AxiosRequestConfig
): Promise<Template | null> => {
  return API.delete<Template>(
    apiPrefix(interpolateRoute(apiRoutes.templateById, { id })),
    config
  );
};

const modify = (
  id: number,
  data: Template,
  config?: AxiosRequestConfig
): Promise<Template | null> => {
  return API.patch<Template>(
    apiPrefix(interpolateRoute(apiRoutes.templateById, { id })),
    data,
    config
  );
};

const addTemplateImage = (
  templateName: string,
  imageName: string,
  data: any,
  config?: AxiosRequestConfig
): Promise<TemplateImage | null> => {
  return API.put<TemplateImage>(
    apiPrefix(
      interpolateRoute(apiRoutes.manageTemplateImage, {
        templateName,
        imageName
      })
    ),
    data,
    config
  );
};

const deleteTemplateImage = (
  templateName: string,
  imageName: string,
  config?: AxiosRequestConfig
): Promise<TemplateImage | null> => {
  return API.delete<TemplateImage>(
    apiPrefix(
      interpolateRoute(apiRoutes.manageTemplateImage, {
        templateName,
        imageName
      })
    ),
    config
  );
};

export const templatesApi = {
  getOne,
  create,
  remove,
  modify,
  addTemplateImage,
  deleteTemplateImage
};
