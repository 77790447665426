import { UserRole } from 'enums';

import { FeatureFlagsConfig } from '../components/FeatureFlags';

export const features: FeatureFlagsConfig[] = [
  {
    name: 'JuniperOktaAuth',
    description: 'Authentication with Juniper Okta OAuth',
    enabled: true
  },
  {
    name: 'ApstraGoogleAuth',
    description: 'Authentication with Apstra Google OAuth',
    enabled: false
  },
  {
    name: UserRole.Admin,
    description: 'feature flag for labs',
    enabled: true
  },
  {
    name: UserRole.SE,
    description: 'feature flag for labs',
    enabled: true
  }
];
