import React, { memo } from 'react';
import { Checkbox, Table } from 'semantic-ui-react';

import { Tag } from 'types/Tags/tags';

import './styles.less';

type Props = {
  readonly tags: Tag[];
  readonly selectedTag: Tag;
  readonly column?: string;
  readonly direction?: 'ascending' | 'descending';
  readonly handleSelect: (tag: Tag) => void;
  readonly onSort: (clickedColumn: string) => void;
};

export const TagsTable: React.FC<Props> = memo(
  ({ tags, selectedTag, handleSelect, column, direction, onSort }) => {
    const isTagSelected = (id: number) => {
      return selectedTag.id === id;
    };

    return (
      <Table
        sortable
        celled
        striped
        unstackable
        className='cl-topologies-table'
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell
              sorted={column === 'name' ? direction : undefined}
              onClick={() => onSort('name')}
            >
              Tag Name
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'value' ? direction : undefined}
              onClick={() => onSort('value')}
            >
              Tag Value
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {tags.map((t: Tag) => {
            return (
              <Table.Row key={t.id}>
                <Table.Cell collapsing>
                  <Checkbox
                    radio
                    checked={isTagSelected(t.id)}
                    onChange={() => {
                      handleSelect(t);
                    }}
                  />
                </Table.Cell>
                <Table.Cell>{t.name}</Table.Cell>
                <Table.Cell>{t.value}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }
);
