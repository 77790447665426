import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Divider, Grid, GridColumn } from 'semantic-ui-react';

import { getBases, getStoredBaseQueryResponse, notifier } from 'utils';

import { ChatActivity, KnowledgeSearch } from 'components';
import {
  MetaData,
  QuerySummary,
  SummaryRef,
  SummaryResponse
} from 'interfaces';

import { RootState } from 'Reducers/contactReducer';

import { Queries } from '../Queries/Queries';

import './styles.less';

export const KnowledgeSearchResults: React.FC = () => {
  const requestQuery = useSelector(
    (state: RootState) => state.chat.requestQuery
  );
  const initialState = {
    corpusName: requestQuery.corpusName ? requestQuery.corpusName : '',
    userQuery: ''
  };
  const [summary, setSummary] = React.useState('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [urlData, setUrlData] = React.useState([{ title: '', url: '' }]);
  const [segmentType, setSegmentType] = React.useState<string>('firstLoad');
  const [request, setRequest] = React.useState(requestQuery);
  const [inputQuery, setInputQuery] = React.useState<QuerySummary>(
    initialState
  );

  const response = getStoredBaseQueryResponse(
    requestQuery.userQuery,
    requestQuery.corpusName
  );
  useEffect(() => {
    const retrieveData = async () => {
      const summaryMessage = (summaryResponse: SummaryResponse) => {
        if (!isEmpty(summaryResponse)) {
          let message = summaryResponse.summaryMsg;
          const documentRef = summaryResponse.documentRef;
          for (const [key, value] of Object.entries(documentRef)) {
            const meta: MetaData[] = summaryResponse.summaryRef.filter(
              res => res.id === key
            )[0].metadata;
            const url = meta.filter((data: MetaData) => data.name === 'url')[0]
              .value;
            for (const i of value.values()) {
              const matcher =
                `[${i}]` ||
                `[${i},` ||
                `,${i},` ||
                `,${i}]` ||
                `[${i}-` ||
                `-${i}]`;
              message = message.replaceAll(
                matcher,
                `<a href=${url}><sup>${i}</sup></a>`
              );
            }
          }
          setSummary(message);
        }
      };
      try {
        summaryMessage(response[0].response);
        const summaryRef = response[0].response.summaryRef as SummaryRef[];
        const urlObj = summaryRef.map((ref: SummaryRef) => {
          return ref.metadata.filter(
            (data: MetaData) => data.name === 'url' || data.name === 'title'
          );
        });
        const urlList = urlObj.flatMap((data: MetaData[]) => {
          return { title: data[1].value, url: data[0].value };
        });
        setUrlData(urlList);
      } catch {
        notifier.error({ message: 'Request Timed out' });
      }
    };
    if (
      !isEmpty(requestQuery.corpusName) &&
      request !== requestQuery &&
      response.length >= 1
    ) {
      if (!isEmpty(getBases())) {
        setLoading(true);
        setRequest(requestQuery);
        setSegmentType('response');
        retrieveData();
        setLoading(false);
      }
    }
  }, [requestQuery, request, response]);

  return (
    <div>
      <h2 className='caption'>Chat Assistant</h2>
      <p className='description'>
        All the resources you need to learn about Apstra by yourself with
        AI-powered explanations and Q&A.
      </p>
      <Divider hidden />
      <Grid columns={2} padded>
        <GridColumn width={13}>
          <KnowledgeSearch
            loading={loading}
            inputQuery={inputQuery}
            setLoading={setLoading}
            setInputQuery={setInputQuery}
          />
          <Queries
            segmentType={segmentType}
            summary={summary}
            linkData={urlData}
            inputQuery={inputQuery}
            setSegmentType={setSegmentType}
            setInputQuery={setInputQuery}
          />
        </GridColumn>
        <GridColumn width={3}>
          <ChatActivity setSegmentType={setSegmentType} />
        </GridColumn>
      </Grid>
    </div>
  );
};
