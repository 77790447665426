import { find } from 'lodash';
import React, { memo } from 'react';

import { VM } from 'types';

import { VMCard } from '../VMCard';

type Props = {
  vm: VM | undefined;
  enabled: boolean;
};

const getAccess = (vm: VM | undefined) => {
  return vm
    ? find(
        vm.access,
        acc =>
          (acc.protocol === 'http' || acc.protocol === 'https') &&
          acc.privatePort === 3000
      )
    : null;
};

export const TelemetryStreamingCard: React.FC<Props> = memo(
  ({ vm, enabled }) => (
    <VMCard
      name='Telemetry Streaming'
      description='Grafana Dashboard pulling telemetry from an external TSDB. Data is streamed out of Apstra to the TSDB.'
      vm={vm}
      access={getAccess(vm)}
      enabled={enabled}
    />
  )
);
