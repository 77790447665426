import { filter, isEmpty } from 'lodash';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { Label, Menu, Tab } from 'semantic-ui-react';

import { permissionsUtils } from 'utils/permissions';

import {
  NoDataPlaceholder,
  RequestData,
  TemplateSelectionCardWizard
} from 'components';
import { apiRoutes } from 'constants/api-routes';
import { permissions } from 'constants/permissions';
import { Template } from 'types';

import './styles.less';

type TemplateFilters = {
  approved: boolean;
  experimental: boolean;
};

const filterTempaltes = (
  templates: Template[],
  filters: TemplateFilters
): Template[] => {
  if (filters.approved === filters.experimental) {
    return templates;
  }
  if (filters.approved) {
    return filter(templates, { approved: true });
  }
  return filter(templates, { approved: false });
};

type Props = {
  readonly stepVisible: boolean;
  readonly selectedTemplateId: number | null;
  readonly onChange: (
    templateId: number | string | null,
    templateName: string
  ) => void;
};

export const TemplateSelectionWizard: React.FC<Props> = memo(
  ({ stepVisible, selectedTemplateId, onChange }) => {
    const [filters, setFilters] = useState<TemplateFilters>({
      approved: true,
      experimental: false
    });
    const params = useMemo(() => ({ limit: 100000 }), []);
    const maySeeExternal = permissionsUtils.checkPermissions(
      permissions.templates.maySeeExternal
    );
    const panesDescription = {
      approved: `What are approved templates? Approved topologies have been
         fully tested and validated to work as expected. These
         topologies are approved for general use and available to customers.`,
      experimental: `What are experimental templates? Not recommended.
       Experimental topologies are considered unstable or not supported
       in CloudLabs. These topologies are usually under active development,
       are considered unstable and thus not suitable for customer use.`
    };
    const getPanes = (
      filteredTemplates: Template[],
      approved: number,
      experimental: number
    ) => [
      {
        menuItem: (
          <Menu.Item key='approved'>
            Approved <Label circular size='mini' content={approved} />
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane as='div'>
            {isEmpty(filteredTemplates) ? (
              <NoDataPlaceholder />
            ) : (
              <TemplateSelectionCardWizard
                paneDescription={panesDescription.approved}
                templates={filteredTemplates}
                selectedTemplateId={selectedTemplateId}
                onChange={onChange}
              />
            )}
          </Tab.Pane>
        )
      },
      {
        menuItem: (
          <Menu.Item key='experimental' disabled={!maySeeExternal}>
            Experimental <Label circular size='mini' content={experimental} />
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane as='div'>
            {isEmpty(filteredTemplates) ? (
              <NoDataPlaceholder />
            ) : (
              <TemplateSelectionCardWizard
                paneDescription={panesDescription.experimental}
                templates={filteredTemplates}
                selectedTemplateId={selectedTemplateId}
                onChange={onChange}
              />
            )}
          </Tab.Pane>
        )
      }
    ];
    return (
      <RequestData
        url={apiRoutes.templates}
        params={params}
        transformResponse={useCallback(
          (data: { templates: Template[] }) => data.templates,
          []
        )}
      >
        {({ data: templates }) => {
          const approved = templates.filter((t: Template) => t.approved).length;
          const experimental = templates.filter((t: Template) => !t.approved)
            .length;
          const filteredTemplates = filterTempaltes(templates, filters);
          return (
            <div className='cl-template-selection' hidden={!stepVisible}>
              <h2 className='section-number'>Select a Template</h2>
              <Tab
                panes={getPanes(filteredTemplates, approved, experimental)}
                onTabChange={(event, data) => {
                  const tabApproved = data.activeIndex === 0;
                  setFilters({
                    approved: tabApproved,
                    experimental: !tabApproved
                  });
                }}
              />
            </div>
          );
        }}
      </RequestData>
    );
  }
);
