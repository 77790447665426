import React, { memo } from 'react';
import { Button, Icon, Popup, Segment } from 'semantic-ui-react';

import { topologyUtils } from 'utils';

import { RouterLink } from 'components';
import { VM, VMAccess } from 'types';

import './styles.less';

type Props = {
  readonly name: string;
  readonly description: string;
  readonly vm: VM | undefined;
  readonly access: VMAccess | undefined | null;
  readonly enabled: boolean;
};

export const VMCard: React.FC<Props> = memo(
  ({ name, description, vm, access, enabled }) => {
    const [isCopied, setCopied] = React.useState<boolean>(false);
    const username = enabled && access ? access.username : null;
    const password = enabled && access ? access.password : null;
    const link =
      enabled && vm && access ? topologyUtils.getAccessLink(access, vm) : null;
    return (
      <Segment className='cl-vm-card'>
        <h3 className='header'>{name}</h3>
        <div className='description'>{description}</div>
        <div>
          <span className='credentials-label'>{'login: '}</span>
          {username}
        </div>
        <div>
          <span className='credentials-label'>{'password: '}</span>
          {password && (
            <span>
              {password}
              <Popup
                content={
                  isCopied ? 'Password copied!' : 'Copy password to clipboard'
                }
                on={['hover', 'click']}
                closeOnTriggerClick={false}
                onClose={() => setCopied(false)}
                trigger={
                  <Button
                    className='copy-btn'
                    basic
                    icon='copy outline'
                    data-testid='copy-to'
                    onClick={() =>
                      navigator.clipboard
                        .writeText(password)
                        .then(() => setCopied(true))
                    }
                  />
                }
              />
            </span>
          )}
        </div>
        <Button
          primary
          disabled={!(enabled && link)}
          className='open-btn'
          size='medium'
          as={link ? RouterLink : null}
          to={link}
        >
          <Icon name='triangle right' className='button' />
          {'Open In New Tab'}
        </Button>
      </Segment>
    );
  }
);
