import React, { memo } from 'react';

import { TemplateSelectionCardWizard } from 'components';

import { useLabTemplates } from 'hooks/useLabTemplate';

import './styles.less';

type Props = {
  readonly stepVisible: boolean;
  readonly selectedTemplateId: string | null;
  readonly onChange: (
    templateId: number | string | null,
    templateName: string
  ) => void;
};

export const LabTemplateSelection: React.FC<Props> = memo(
  ({ stepVisible, selectedTemplateId, onChange }) => {
    const labTemplates = useLabTemplates();

    return (
      <div className='cl-template-selection' hidden={!stepVisible}>
        <h2 className='section-number'>Select a Lab Template</h2>
        <TemplateSelectionCardWizard
          paneDescription='lab'
          templates={labTemplates.data || []}
          selectedTemplateId={selectedTemplateId}
          onChange={onChange}
        />
      </div>
    );
  }
);
