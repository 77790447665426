import { isEmpty } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { Button, Card, Form, Grid, Icon } from 'semantic-ui-react';

import { interpolateRoute, notifier } from 'utils';
import { isNullOrUndefined } from 'utils/utilFunctions';

import { assets } from 'constants/assets';
import { routes } from 'constants/routing';
import { useRouter } from 'hooks/useRouter';

import { templatesApi } from 'api/templates';
import { MainLayout } from 'layouts';
import { Template } from 'types';
import { TemplateCard } from 'views/CreateTopology/CreateTopologyWizard/TemplateCard';

import './styles.less';

export const ManageTemplates: React.FC = memo(() => {
  const router = useRouter();
  const defaultImage = assets.images.noImageAvailable.src;
  const [imageAsURL, setImageAsURL] = useState<string>(defaultImage);
  const [imageAsFile, setImageAsFile] = useState<any>(null);
  const [imageName, setImageName] = useState<string>('');
  const [imageFileSize, setImageFileSize] = useState<number>();

  const nullTemplate = {
    approved: false,
    deployed: 'undeployed'
  } as Template;

  const [template, setTemplate] = useState<Template>(nullTemplate);

  const templateId: number = Number((router.match.params as { id: number }).id);
  const pageHeader = templateId ? 'Edit Template' : 'Create Template';
  const submitBtnText = templateId ? 'Update Template' : 'Create Template';
  const submitBtnIcon = templateId ? 'edit outline' : 'add circle';
  useEffect(() => {
    const getTemplate = async () => {
      try {
        const res = await templatesApi.getOne(templateId);
        setTemplate(res);
        if (!isEmpty(res.templateImages)) {
          setImageAsURL(res.templateImages[0].url);
          setImageName(res.templateImages[0].name);
        }
      } catch (err) {
        notifier.requestFailed(err);
      }
    };
    if (templateId) {
      getTemplate();
    }
  }, [templateId]);

  const handleChange = (field: string, value: string | boolean) => {
    setTemplate({ ...(template || {}), [field]: value });
  };

  const onCreate = async (newTemplate: Template) => {
    if (!newTemplate) {
      return;
    }
    if (!newTemplate.ptestArgs) {
      newTemplate.ptestArgs = '';
    }
    try {
      await templatesApi.create(newTemplate);
      if (imageAsURL !== defaultImage) {
        await templatesApi.addTemplateImage(
          newTemplate.name,
          imageName,
          imageAsFile,
          {
            headers: {
              'Content-Type': imageAsFile.type,
              Accept: 'application/json'
            }
          }
        );
      }
      notifier.success({
        message: `Template created`
      });
      router.push(interpolateRoute(routes.templates.path));
    } catch (err) {
      if (imageFileSize && imageFileSize > 10) {
        notifier.error({
          message: `Selected Image Size exceeds 10MB`
        });
      } else {
        notifier.requestFailed(err);
      }
    }
  };

  const onEdit = async (newTemplate: Template) => {
    if (!newTemplate) {
      return;
    }
    try {
      await templatesApi.modify(templateId, newTemplate);
      if (
        (isEmpty(template.templateImages) && imageAsURL !== defaultImage) ||
        (!isEmpty(template.templateImages) &&
          template.templateImages[0].url !== imageAsURL &&
          imageAsURL !== defaultImage)
      ) {
        await templatesApi.addTemplateImage(
          newTemplate.name,
          imageName,
          imageAsFile,
          {
            headers: {
              'content-type': imageAsFile.type
            }
          }
        );
      } else if (
        imageAsURL === defaultImage &&
        !isEmpty(template.templateImages)
      ) {
        await templatesApi.deleteTemplateImage(
          newTemplate.name,
          newTemplate.templateImages[0].name
        );
      }
      notifier.success({
        message: `Template ${newTemplate.name} successfully updated`
      });
      router.push(interpolateRoute(routes.templates.path));
    } catch (err) {
      notifier.requestFailed(err);
    }
  };

  const onSubmit = () => {
    if (templateId) {
      onEdit(template);
    } else {
      onCreate(template);
    }
  };
  return (
    <MainLayout className='cl-manage-template'>
      <div className='caption'>
        <h2 className='header'>{pageHeader}</h2>
      </div>
      <Form onSubmit={() => onSubmit()}>
        <Grid columns={2} celled>
          <Grid.Column>
            <Form.Input
              required
              label='Name'
              placeholder='Name'
              value={template.name || ''}
              autoFocus
              onChange={event => {
                template.label = event.target.value.split('_').join(' ');
                handleChange('name', event.target.value);
              }}
            />
            <Form.Input
              required
              label='Description'
              placeholder='Description'
              value={template.description || ''}
              onChange={event =>
                handleChange('description', event.target.value)
              }
            />
            <Form.Input
              label='Long Description'
              placeholder='Description with more pertinent details to the end-user'
              value={template.longDescription || ''}
              onChange={event =>
                handleChange('longDescription', event.target.value)
              }
            />
            <Form.Input
              label='Label'
              placeholder='Label'
              value={template.label || ''}
              onChange={event => handleChange('label', event.target.value)}
            />
            <Form.Input
              required
              label='Ptest Suite'
              placeholder='Ptest Suite'
              value={template.ptestSuite || ''}
              onChange={event => handleChange('ptestSuite', event.target.value)}
            />

            <Form.Input
              label='Ptest Args'
              placeholder='Ptest Args'
              value={template.ptestArgs || ''}
              onChange={event => handleChange('ptestArgs', event.target.value)}
            />
            <Form.Input
              label='Apstra version'
              placeholder='AOS_3.2.1_OB'
              value={template.aosVersion || ''}
              onChange={event => handleChange('aosVersion', event.target.value)}
            />
            <Form.Input
              label='Slicer runner ptests Docker image name'
              placeholder='cloudlabs/slicer-runner-with-ptests'
              value={template.ptestImage || ''}
              onChange={event => handleChange('ptestImage', event.target.value)}
            />

            <Form.Input
              required
              label='Working Directory'
              placeholder='Working Directory'
              value={template.workingDirectory || ''}
              onChange={event =>
                handleChange('workingDirectory', event.target.value)
              }
            />
            <Form.Input
              label='Default Expiration (hours)'
              placeholder='Default Expiration (hours)'
              value={template.defaultExpiration || ''}
              onChange={event =>
                handleChange('defaultExpiration', event.target.value)
              }
            />
            <Form.Field label='Template Blueprint Image' control='label'>
              <div className='custom-file-input'>
                <label className='pretty-file'>
                  Choose File
                  <input
                    id='blueprint-image'
                    type='file'
                    accept='image/jpeg,image/jpg,image/png,image/gif'
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (
                        !event ||
                        !event.target ||
                        (event.target && isEmpty(event.target.files))
                      ) {
                        setImageAsURL(defaultImage);
                        setImageAsFile(null);
                        setImageName('');
                        return;
                      } else {
                        const target = event.target as any;
                        const file = target.files[0];
                        if (isNullOrUndefined(file)) {
                          setImageAsURL(defaultImage);
                          setImageAsFile(null);
                          setImageName('');
                          return;
                        }
                        const fileSize = Math.round(
                          file.size / Math.pow(1024, 2)
                        );
                        setImageName(file.name);
                        setImageFileSize(fileSize);
                        setImageAsURL(URL.createObjectURL(file as any));
                        setImageAsFile(file);
                      }
                    }}
                  />
                </label>
                <span>
                  {imageName}
                  {imageAsURL !== defaultImage && (
                    <span
                      onClick={e => {
                        e.preventDefault();
                        (document.getElementById(
                          'blueprint-image'
                        ) as HTMLInputElement).value = '';
                        setImageAsURL(defaultImage);
                        setImageName('');
                        setImageAsFile(null);
                        return;
                      }}
                      className='delete'
                    >
                      Reset <Icon name='redo' />
                    </span>
                  )}
                </span>
              </div>
            </Form.Field>
            <Form.Checkbox
              label='Approved'
              checked={template.approved || false}
              onChange={(event, data) =>
                handleChange('approved', data.checked as boolean)
              }
            />
            <Form.Checkbox
              label='Deployed'
              checked={template.deployed === 'deployed' || false}
              onChange={(event, data) => {
                if (data.checked) {
                  handleChange('deployed', 'deployed');
                } else {
                  handleChange('deployed', 'undeployed');
                }
              }}
            />
          </Grid.Column>
          {nullTemplate !== template && (
            <Grid.Column>
              <Grid.Row centered>
                <Card raised>
                  <TemplateCard
                    template={template}
                    manageTemplatesPreview={true}
                    showDescription={true}
                    showShortDescription={true}
                    showDeployedLabel={true}
                    showTemplateImage={true}
                    showAosVersion={true}
                    displayAnimation='dimmer'
                    imageAsURL={imageAsURL}
                  />
                </Card>
              </Grid.Row>
            </Grid.Column>
          )}
        </Grid>
        <div className='actions'>
          {!isNullOrUndefined(templateId) && (
            <Button
              secondary
              onClick={() => {
                router.push(interpolateRoute(routes.templates.path));
              }}
            >
              Cancel
            </Button>
          )}
          <Form.Button primary>
            <Icon className='button' name={submitBtnIcon} />
            {submitBtnText}
          </Form.Button>
        </div>
      </Form>
    </MainLayout>
  );
});
