/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */
import { Partner } from 'api/usePartners';

import { Command, FunctionWithArgs, SerializedCommand } from './Command';

class DeletePartner implements Command {
  deletedPartner: Partner;

  constructor(deletedPartner: Partner) {
    this.deletedPartner = deletedPartner;
  }

  preview(partners: Partner[]): Partner[] {
    return partners.filter(p => p.uuid !== this.deletedPartner.uuid);
  }

  getApiCall(): FunctionWithArgs {
    return { functionName: 'useDeletePartner', args: [this.deletedPartner] };
  }

  asString(): string {
    return 'Delete partner company ' + this.deletedPartner.name;
  }

  serialize(): SerializedCommand {
    return {
      type: 'DeletePartner',
      data: {
        deletedPartner: this.deletedPartner
      }
    };
  }
}

export { DeletePartner };
