import React, { memo } from 'react';
import { Checkbox, Form } from 'semantic-ui-react';

export type TemplateFilters = {
  approved: boolean;
  experimental: boolean;
};

type Props = {
  readonly filters: TemplateFilters;
  readonly onChange: (filters: TemplateFilters) => void;
};

export const TemplateFiltersForm: React.FC<Props> = memo(
  ({ filters, onChange }) => {
    return (
      <Form className='cl-template-filters-form'>
        <Form.Group inline>
          <Form.Field>
            <Checkbox
              label='Approved'
              checked={filters.approved}
              onChange={() =>
                onChange({ ...filters, approved: !filters.approved })
              }
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label='Experimental'
              checked={filters.experimental}
              onChange={() =>
                onChange({ ...filters, experimental: !filters.experimental })
              }
            />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
);
