import React, { memo } from 'react';

export type FeatureFlagsConfig = {
  readonly enabled: boolean;
  readonly name: string;
  readonly description: string;
};

type FeatureProviderProps = {
  readonly config: FeatureFlagsConfig[];
  readonly children?: React.ReactNode;
};

type FeatureProps = {
  readonly name: string;
  readonly children?: React.ReactNode;
};

const FeatureFlags = React.createContext<FeatureFlagsConfig[]>([]);

export const Feature: React.FC<FeatureProps> = memo(({ children, name }) => {
  return (
    <FeatureFlags.Consumer>
      {value => {
        const matching = value.filter(flag => {
          return flag.name === name && flag.enabled;
        });
        if (matching.length > 0) {
          return children;
        }
      }}
    </FeatureFlags.Consumer>
  );
});

export const FeatureProvider: React.FC<FeatureProviderProps> = memo(
  ({ children, config }) => {
    return (
      <FeatureFlags.Provider value={config}>{children}</FeatureFlags.Provider>
    );
  }
);
