import React, { memo, useState } from 'react';
import { Button, Grid, Icon, Input } from 'semantic-ui-react';

import { authUtils, notifier } from 'utils';

import { tagsApi } from 'api/tag';
import { UserRole } from 'enums';
import { Tag } from 'types/Tags/tags';

import './styles.less';

type Props = {
  readonly tag: Tag;
  readonly selectedTag: Tag;
  readonly addTag: boolean;
  readonly setTag: (t: Tag) => void;
  readonly updateSelectedTag: (t: Tag) => void;
  readonly setRefetch: (value: boolean) => void;
  readonly setAdd: (value: boolean) => void;
};

export const TagActionMenu: React.FC<Props> = memo(
  ({
    tag,
    selectedTag,
    addTag,
    setTag,
    updateSelectedTag,
    setRefetch,
    setAdd
  }) => {
    const [modifyTag, setModify] = useState<boolean>(false);
    const [action, setAction] = useState<string>('');

    const iconName = action === 'Delete' ? 'delete' : 'edit';
    const isAdmin = authUtils.getLoggedInUser().role === UserRole.Admin;
    const onChange = (value: Tag) => setTag(value);
    const handleChange = (field: string, value: string) => {
      onChange({ ...(tag || {}), [field]: value });
    };
    const handleModifyAction = (selectedValue: Tag, actionType: string) => {
      setTag(selectedValue);
      setAdd(false);
      setModify(true);
      setAction(actionType);
    };

    const onAction = () => {
      if (!tag) {
        return;
      } else if (action === 'Create') {
        onCreateTag();
      } else if (action === 'Update') {
        onEditTag();
      } else if (action === 'Delete') {
        onDeleteTag();
      }
    };

    const setToInitial = () => {
      setTag({} as Tag);
      setRefetch(true);
    };

    const onCreateTag = async () => {
      let data: Tag;
      data = {
        name: tag.name,
        value: tag.value
      } as Tag;
      try {
        await tagsApi.create(data);
        notifier.success({
          message: `Tag "${tag.name}:${tag.value}" successfully created`
        });
        setAdd(false);
        setToInitial();
      } catch (err) {
        notifier.requestFailed(err);
      }
    };

    const onEditTag = async () => {
      let data: Tag;
      data = {
        name: tag.name,
        value: tag.value
      } as Tag;
      try {
        await tagsApi.update(selectedTag.id, data);
        notifier.success({
          message: `Tag "${tag.name}:${tag.value}" successfully modified`
        });
        setModify(false);
        setToInitial();
        updateSelectedTag(tag);
      } catch (err) {
        notifier.requestFailed(err);
      }
    };

    const onDeleteTag = async () => {
      try {
        await tagsApi.remove(selectedTag.id);
        notifier.success({
          message: `Tag "${tag.name}:${tag.value}" successfully deleted`
        });
        setModify(false);
        setToInitial();
        updateSelectedTag({} as Tag);
      } catch (err) {
        notifier.requestFailed(err);
      }
    };

    return (
      <Grid>
        <Grid.Row centered>
          <Button
            basic
            content={
              <span>
                <Icon name='add' />
                {'Add Tags'}
              </span>
            }
            onClick={() => {
              setAdd(true);
              setModify(false);
              setTag({} as Tag);
              setAction('Create');
            }}
            disabled={!isAdmin}
          />
          <Button
            basic
            content={
              <span>
                <Icon name='edit' />
                {'Edit Tags'}
              </span>
            }
            onClick={() => handleModifyAction(selectedTag, 'Update')}
            disabled={!isAdmin}
          />
          <Button
            basic
            content={
              <span>
                <Icon name='delete' />
                {'Delete Tags'}
              </span>
            }
            onClick={() => handleModifyAction(selectedTag, 'Delete')}
            disabled={!isAdmin}
          />
        </Grid.Row>
        {(addTag || modifyTag) && (
          <Grid container centered>
            <Grid.Row>
              <Grid.Column width={4}>
                <span className='input-content'>
                  <b>Tag Name</b>
                </span>
              </Grid.Column>
              <Grid.Column width={4}>
                <span className='input-content'>
                  <b>Tag Value</b>
                </span>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}>
                <Input
                  required
                  placeholder='Enter Tag Name'
                  value={tag.name || ''}
                  autoFocus
                  onChange={event => handleChange('name', event.target.value)}
                  disabled={
                    action === 'Delete' ||
                    (modifyTag && Object.keys(selectedTag).length === 0)
                  }
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <Input
                  required
                  placeholder='Enter Tag Value'
                  value={tag.value || ''}
                  onChange={event => handleChange('value', event.target.value)}
                  disabled={
                    action === 'Delete' ||
                    (modifyTag && Object.keys(selectedTag).length === 0)
                  }
                />
              </Grid.Column>
              <Grid.Column width={2}>
                <Button
                  basic
                  content={
                    <span>
                      <Icon name={iconName} />
                      {action}
                    </span>
                  }
                  onClick={() => onAction()}
                  disabled={modifyTag && Object.keys(selectedTag).length === 0}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Grid>
    );
  }
);
