import React, { memo } from 'react';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';

import { Lab } from 'types';

import { LabGuideFrame } from '../LabGuideFrame';

type Props = {
  readonly isLoading: boolean;
  readonly lab: Lab;
  readonly visible: boolean;
  readonly onVisibleChange: (visible: boolean) => void;
  readonly onContentChange: (content: boolean) => void;
};

export const LabDetailView: React.FC<Props> = memo(
  ({ isLoading, lab, visible, onVisibleChange, onContentChange }) => {
    if (isLoading) {
      return (
        <Segment>
          <Dimmer active inverted>
            <Loader active>Loading...</Loader>
          </Dimmer>
        </Segment>
      );
    }
    return (
      <LabGuideFrame
        lab={lab}
        visible={visible}
        onVisibleChange={onVisibleChange}
        onContentChange={onContentChange}
      />
    );
  }
);
