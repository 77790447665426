/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */
import { Partner } from 'api/usePartners';

import { Command, FunctionWithArgs, SerializedCommand } from './Command';

class EditPartner implements Command {
  partner: Partner;
  newDomains: string[] | undefined;
  newName: string | undefined;

  constructor(
    partner: Partner,
    newName: string | undefined,
    newDomains: string[] | undefined
  ) {
    this.partner = partner;
    this.newDomains = newDomains;
    this.newName = newName;
  }

  preview(partners: Partner[]): Partner[] {
    const result = [...partners];
    for (const p of result) {
      if (p.name === this.partner.name) {
        p.domains = this.newDomains === undefined ? p.domains : this.newDomains;
        p.name = this.newName === undefined ? p.name : this.newName;
        break;
      }
    }
    return result;
  }

  getApiCall(): FunctionWithArgs {
    return {
      functionName: 'useUpdatePartner',
      args: [
        {
          partner: this.partner,
          newName: this.newName,
          newDomains: this.newDomains
        }
      ]
    };
  }

  asString(): string {
    if (this.newDomains) {
      return `Update domains for partner '${
        this.partner.name
      }' to ${this.newDomains.map(d => `"${d}"`).join(', ')}`;
    }
    if (this.newName) {
      return `Change name of partner '${this.partner.name}' to '${this.newName}'`;
    }

    return 'Do nothing, new name or domains not provided';
  }

  serialize(): SerializedCommand {
    return {
      type: 'EditPartner',
      data: {
        partner: this.partner,
        newDomains: this.newDomains,
        newName: this.newName
      }
    };
  }
}

export { EditPartner };
