import React from 'react';
import { Grid } from 'semantic-ui-react';

import { BlueprintTestCaseOutputType } from 'types';

import './styles.less';

type Props = {
  readonly output: BlueprintTestCaseOutputType;
};

const BlueprintCard: React.FC<Props> = ({ output }) => {
  return (
    <Grid.Row columns='3'>
      <Grid.Column textAlign='center'>
        <b>Apstra Controller VM</b>
        <Grid.Row columns='2'>
          <Grid.Column>Minimum vCPU Count</Grid.Column>
          <Grid.Column>
            {output.apstra_controller_vm.min_vcpu_count}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns='2'>
          <Grid.Column>Minimum Memory (GB)</Grid.Column>
          <Grid.Column>{output.apstra_controller_vm.min_mem_gb}</Grid.Column>
        </Grid.Row>
        <Grid.Row columns='2'>
          <Grid.Column>Minimum Disk (GB)</Grid.Column>
          <Grid.Column>{output.apstra_controller_vm.min_disk_gb}</Grid.Column>
        </Grid.Row>
      </Grid.Column>
      <Grid.Column textAlign='center'>
        <b>Number of Worker VMs (per fabric)</b>
        <Grid.Row columns='2'>
          <Grid.Column>Number of Devices</Grid.Column>
          <Grid.Column>
            {output.number_of_workers.number_of_devices}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns='2'>
          <Grid.Column>Number of VMs</Grid.Column>
          <Grid.Column>{output.number_of_workers.number_of_vm}</Grid.Column>
        </Grid.Row>
      </Grid.Column>
      <Grid.Column textAlign='center'>
        <b>Apstra Worker VMs</b>
        <Grid.Row columns='2'>
          <Grid.Column>Minimum vCPU Count</Grid.Column>
          <Grid.Column>{output.apstra_worker_vms.min_vcpu_count}</Grid.Column>
        </Grid.Row>
        <Grid.Row columns='2'>
          <Grid.Column>Minimum Memory (GB)</Grid.Column>
          <Grid.Column>{output.apstra_worker_vms.min_mem_gb}</Grid.Column>
        </Grid.Row>
        <Grid.Row columns='2'>
          <Grid.Column>Minimum Disk (GB)</Grid.Column>
          <Grid.Column>{output.apstra_worker_vms.min_disk_gb}</Grid.Column>
        </Grid.Row>
      </Grid.Column>
    </Grid.Row>
  );
};

export default BlueprintCard;
