import React, { Fragment, memo, useState } from 'react';
import { Button, Checkbox, Icon } from 'semantic-ui-react';

import { interpolateRoute } from 'utils';
import { isNullOrUndefined } from 'utils/utilFunctions';

import { CloudlabsBanner } from 'components';
import { routes } from 'constants/routing';
import { useRouter } from 'hooks/useRouter';
import { Contact, CreateTopology } from 'types';

import { CreateTopologyContactSelector } from './ContactSelector';
import { DeploymentRegionSelection } from './DeploymentRegionSelection';
import { TagSelector } from './TagSelector';
import { TemplateSelection } from './TemplateSelection';
import { TopologyExpiration } from './TopologyExpiration';

type Props = {
  readonly contact: Contact;
  readonly topology: CreateTopology;
  readonly selectedTags: string[];
  readonly setSelectedTags: (value: string[]) => void;
  readonly onChange: (value: CreateTopology) => void;
  readonly createTopology: () => void;
};

export const CreateTopologySingleView: React.FC<Props> = memo(
  ({
    contact,
    topology,
    selectedTags,
    setSelectedTags,
    onChange,
    createTopology
  }) => {
    const router = useRouter();
    topology.createdForId = topology.createdForId || contact.id;
    const [sendEmail, setSendEmail] = useState<boolean>(
      !isNullOrUndefined(topology.emailNotificationsEnabled)
        ? topology.emailNotificationsEnabled
        : true
    );
    const handleChange = (field: string, value: any) => {
      onChange({ ...(topology || {}), [field]: value });
    };
    const openCreateTopologyView = (openContactId: string) => {
      if (openContactId) {
        handleChange('owner', openContactId);
        router.push({
          pathname: interpolateRoute(routes.createTopology.path, {
            id: openContactId
          })
        });
      }
    };
    return (
      <Fragment>
        <div className='caption'>
          <CloudlabsBanner />
          <h2>
            {'Create a New Topology For:'}
            <CreateTopologyContactSelector
              contact={contact}
              selectedContact={topology.createdForId}
              onChange={openCreateTopologyView}
            />
          </h2>
        </div>
        <TemplateSelection
          selectedTemplateId={topology.template}
          onChange={value => handleChange('template', value)}
        />
        <DeploymentRegionSelection
          selectedRegionName={topology.region}
          onChange={value => handleChange('region', value)}
        />
        <TopologyExpiration
          selectedDateTime={topology.expirationTime}
          onChange={value => handleChange('expirationTime', value)}
        />
        <TagSelector selectedTags={selectedTags} onChange={setSelectedTags} />
        <div className='actions'>
          <Button
            primary
            disabled={!topology.template || !topology.region}
            onClick={() => {
              topology.emailNotificationsEnabled = sendEmail;
              createTopology();
            }}
          >
            <Icon className='button' name='triangle right' />
            {'Submit'}
          </Button>
          <Checkbox
            label='Send an Email to Contact After Creation'
            checked={topology.emailNotificationsEnabled || sendEmail}
            onChange={(e, { checked }) => {
              setSendEmail(checked as boolean);
              handleChange('emailNotificationsEnabled', checked as boolean);
            }}
          />
        </div>
      </Fragment>
    );
  }
);
