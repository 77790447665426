import React, { memo } from 'react';
import { Redirect, RouteProps } from 'react-router';
import { Route } from 'react-router-dom';

import { authUtils, isNotAuthorizedEulaAccepted } from 'utils';

import { routes } from 'constants/routing';

export const PublicWithEulaRoute: React.FC<RouteProps> = memo(props => {
  const { location: { pathname = null } = {} } = props;

  if (!authUtils.loggedIn() && !isNotAuthorizedEulaAccepted()) {
    return (
      <Redirect
        to={{
          pathname: routes.eulaNotAuthorized.path,
          state: { returnUrl: pathname }
        }}
      />
    );
  }

  return <Route {...props} />;
});
