import React, { memo } from 'react';

import { TopologyExpirationDatePicker } from 'components/TopologyExpirationDatePicker';

type Props = {
  readonly stepVisible: boolean;
  readonly selectedDateTime: string;
  readonly onChange: (date: string) => void;
};

export const TopologyExpirationWizard: React.FC<Props> = memo(
  ({ stepVisible, selectedDateTime, onChange }) => {
    return (
      <div className='cl-deployment-region-selection' hidden={!stepVisible}>
        <h2 className='section-number'>Set Expiration Date</h2>
        <TopologyExpirationDatePicker
          selectedDateTime={selectedDateTime}
          onChange={onChange}
        />
      </div>
    );
  }
);
