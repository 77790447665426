import React, { memo } from 'react';
import { Input, InputOnChangeData } from 'semantic-ui-react';

import './styles.less';

type Props = {
  readonly searchText: string;
  readonly setSearchText: (text: string) => void;
};
export const SearchTags: React.FC<Props> = memo(
  ({ searchText, setSearchText }) => {
    return (
      <Input
        type='search'
        icon='search'
        value={searchText || ''}
        onChange={(
          event: React.ChangeEvent<HTMLInputElement>,
          data: InputOnChangeData
        ) => setSearchText(data.value as string)}
      />
    );
  }
);
