import React, { memo } from 'react';

import { ActionsCard } from '../ActionsCard';

type Props = {
  readonly topologyUuid: string;
  readonly isUp: boolean;
  readonly topologyIsDown: boolean;
};

export const MilestoneActionsCard: React.FC<Props> = memo(
  ({ topologyUuid, isUp, topologyIsDown }) => (
    <ActionsCard
      title={'Milestone Actions'}
      topologyUuid={topologyUuid}
      topologyIsUp={isUp}
      topologyIsDown={topologyIsDown}
    />
  )
);
