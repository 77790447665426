import React, { memo, useState } from 'react';
import {
  Button,
  Divider,
  Dropdown,
  DropdownProps,
  Grid,
  Modal,
  Segment
} from 'semantic-ui-react';

import { authUtils, contactUtils, notifier, permissionsUtils } from 'utils';
import { reloadData } from 'utils/urls';
import { isNullOrUndefined } from 'utils/utilFunctions';

import { contactsApi } from 'api/contacts';
import { permissions } from 'constants/permissions';
import { UserRole } from 'enums';
import { Contact } from 'types';

import './styles.less';

type Props = {
  readonly user: Contact;
};

export const AccountDetailsTab: React.FC<Props> = memo(({ user }) => {
  const isNonPartner = isNullOrUndefined(user.partnerCompany);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [localContact, setLocalContact] = useState<Contact>(user);
  const handleChange = (field: string, value: string) => {
    setLocalContact({ ...(user || {}), [field]: value });
  };
  const changeRole = async () => {
    try {
      await contactsApi.update(user.id, localContact);
      notifier.success({
        message: `Contact "${user.name}" role changed`
      });
      setConfirmDeleteModalOpen(false);
      reloadData();
    } catch (err) {
      notifier.requestFailed(err);
    }
  };
  const currentUser = authUtils.getLoggedInUser();
  const ROLE_OPTIONS = {
    admin: {
      text: 'Admin',
      value: 'admin',
      permissions: null,
      hidden: ['partner', 'partner_admin', 'external'].includes(user.role)
    },
    internal: {
      text: 'Internal',
      value: 'internal',
      permissions: permissions.contacts.mayConvertInternals,
      hidden: ['partner', 'partner_admin', 'external'].includes(user.role)
    },
    support: {
      text: 'Support',
      value: 'support',
      permissions: permissions.contacts.mayConvertInternals,
      hidden:
        ['partner', 'partner_admin', 'external'].includes(user.role) ||
        currentUser.role === UserRole.Internal
    },
    se: {
      text: 'SE',
      value: 'se',
      permissions: permissions.contacts.mayConvertInternals,
      hidden:
        ['partner', 'partner_admin', 'external'].includes(user.role) ||
        currentUser.role === UserRole.Internal
    },
    partner: {
      text: 'Partner',
      value: 'partner',
      permissions: permissions.contacts.maySeePartners.concat(UserRole.Partner),
      hidden: ['admin', 'support', 'internal', 'external'].includes(user.role)
    },
    partnerAdmin: {
      text: 'Partner Admin',
      value: 'partner_admin',
      permissions: permissions.contacts.maySeePartners,
      hidden: ['admin', 'support', 'internal', 'external'].includes(user.role)
    },
    customer: {
      text: 'Customer',
      value: 'external',
      permissions: null,
      hidden: [
        'admin',
        'partner',
        'partner_admin',
        'support',
        'internal'
      ].includes(user.role)
    }
  };
  const options = Object.values(ROLE_OPTIONS)
    .filter(
      ({ permissions: optionPermissions }) =>
        !optionPermissions ||
        permissionsUtils.checkPermissions(optionPermissions, currentUser.role)
    )
    .filter(({ hidden: optionHidden }) => !optionHidden);
  return (
    <Segment basic padded>
      <h3 className='header'>Account Summary</h3>
      <Divider horizontal />
      <Grid columns={2} padded>
        <Grid.Column>
          <Grid.Row>
            <div className='tab-padding-block'>
              <div>
                <b>Name</b>
              </div>
              <div>{user.name}</div>
            </div>
          </Grid.Row>
          <Grid.Row>
            <div className='tab-padding-block'>
              <div>
                <b>Email</b>
              </div>
              <div>{user.email}</div>
            </div>
          </Grid.Row>
          {contactUtils.isInternalContact(user.role) && (
            <Grid.Row>
              <div className='tab-padding-block'>
                <div>
                  <b>Department</b>
                </div>
                <div>{contactUtils.formatField(user.department)}</div>
              </div>
            </Grid.Row>
          )}
          {!isNonPartner && (
            <Grid.Row>
              <div className='tab-padding-block'>
                <div>
                  <b>Role</b>
                </div>
                <Dropdown
                  search
                  options={options}
                  placeholder='Select role'
                  wrapSelection
                  onChange={(event, data: DropdownProps) => {
                    handleChange('role', data.value as string);
                    setConfirmDeleteModalOpen(true);
                  }}
                />
              </div>
            </Grid.Row>
          )}
        </Grid.Column>
        <Grid.Column>
          {isNonPartner && (
            <Grid.Row>
              <div className='tab-padding-block'>
                <div>
                  <b>Role</b>
                </div>
                <Dropdown
                  search
                  options={options}
                  placeholder='Select role'
                  wrapSelection
                  value={user.role}
                  onChange={(event, data: DropdownProps) => {
                    handleChange('role', data.value as string);
                    setConfirmDeleteModalOpen(true);
                  }}
                />
              </div>
            </Grid.Row>
          )}
          <Grid.Row>
            <div className='tab-padding-block'>
              <div>
                <b>Company</b>
              </div>
              <div>{user.company}</div>
            </div>
          </Grid.Row>
          {!isNonPartner && (
            <Grid.Row>
              <div className='tab-padding-block'>
                <div>
                  <b>Partner Company</b>
                </div>
                <div>{user.partnerCompany}</div>
              </div>
            </Grid.Row>
          )}
        </Grid.Column>
      </Grid>
      <Modal
        closeIcon={true}
        size='mini'
        open={confirmDeleteModalOpen}
        onClose={() => setConfirmDeleteModalOpen(false)}
      >
        <Modal.Header>
          Change role for user <i>{user.name}</i>
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>
              Change role for user <b>{user.name}</b> from <b>{user.role}</b> to{' '}
              <b>{localContact.role}</b>?
            </p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setConfirmDeleteModalOpen(false)}>
            Cancel
          </Button>
          <Button primary onClick={() => changeRole()}>
            Change
          </Button>
        </Modal.Actions>
      </Modal>
    </Segment>
  );
});
