import React, { memo } from 'react';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';

type Props = {
  readonly trigger: React.ReactNode;
  readonly options: Partial<DropdownItemProps>[];
  readonly onChange: (value: string) => void;
  readonly enabled: boolean;
  readonly showHeader: boolean;
  readonly item?: boolean;
  readonly icon?: string | null;
  readonly direction?: 'left' | 'right';
  readonly scrolling?: boolean;
};

export const ExtendDuration: React.FC<Props> = memo(
  ({
    options,
    showHeader,
    onChange,
    trigger,
    enabled,
    item,
    icon,
    direction,
    scrolling
  }) => {
    const header = () => {
      if (showHeader) {
        return (
          <>
            <Dropdown.Header icon='stopwatch' content='Select Duration' />
            <Dropdown.Divider />
          </>
        );
      } else {
        return null;
      }
    };
    return (
      <Dropdown
        item={item}
        trigger={trigger}
        disabled={!enabled}
        icon={icon}
        direction={direction}
        scrolling={scrolling}
        data-testid={'extend-duration-dropdown'}
      >
        <Dropdown.Menu>
          {header()}
          {options.map((option, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => onChange(option.value as string)}
              {...option}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
);
