import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Dimmer,
  Label,
  Loader,
  Radio,
  Table
} from 'semantic-ui-react';

import { contactUtils, interpolateRoute } from 'utils';

import { TextLink } from 'components/TextLink';
import { routes } from 'constants/routing';
import { Contact } from 'types';

import { setSelectedContacts } from 'features/contacts';
import { RootState } from 'Reducers/contactReducer';
import { ContactDepartmentPopup } from './ContactDepartmentPopup';
import { SelectionType } from './SelectionType';

function ContactApprovalLabel(props: { contact: Contact }) {
  return (
    <Label
      horizontal
      color={props.contact.approved ? 'green' : 'red'}
      content={props.contact.approved ? 'Approved' : 'Disapproved'}
    />
  );
}

function ContactSelector(props: {
  selectionType: SelectionType;
  selected: boolean;
  onSelect: () => void;
}) {
  if (props.selectionType === 'multi') {
    return (
      <Checkbox
        checked={props.selected}
        type='checkbox'
        onChange={props.onSelect}
      />
    );
  } else {
    return (
      <Radio checked={props.selected} type='radio' onChange={props.onSelect} />
    );
  }
}

const ContactDepartment = (props: {
  allowDepartmentUpdate: boolean;
  contact: Contact;
  setContactForDepartmentModal: (contact: Contact[]) => void;
  setDepartmentModalOpen: (open: boolean) => void;
}) => {
  if (props.allowDepartmentUpdate) {
    return (
      <ContactDepartmentPopup
        contact={props.contact}
        setContactForDepartmentModal={props.setContactForDepartmentModal}
        setDepartmentModalOpen={props.setDepartmentModalOpen}
      />
    );
  } else {
    return <span>{props.contact.department}</span>;
  }
};

export function ContactTableRow(props: {
  showLoader: boolean;
  contact: Contact;
  displayedColumns: string[];
  setContactForDepartmentModal: (contact: Contact[]) => void;
  setDepartmentModalOpen: (open: boolean) => void;
  activeTopologies: number;
  inactiveTopologies: number;
  erroredTopologies: number;
  selectionType: SelectionType;
  allowDepartmentUpdate: boolean;
}) {
  const contactsState = useSelector((state: RootState) => state.contact);
  const dispatch = useDispatch();
  const selectedContacts = contactsState.selected;

  const checked = () => selectedContacts.indexOf(props.contact) !== -1;
  const selectContact = (): void => {
    if (!checked()) {
      if (props.selectionType === 'single') {
        dispatch(setSelectedContacts([props.contact]));
      } else if (props.selectionType === 'multi') {
        dispatch(setSelectedContacts(selectedContacts.concat(props.contact)));
      }
    } else if (props.selectionType === 'multi') {
      dispatch(
        setSelectedContacts(selectedContacts.filter(c => c !== props.contact))
      );
    }
  };

  return (
    <Table.Row onClick={selectContact}>
      <Table.Cell collapsing key={'selector'}>
        <Dimmer.Inner active={props.showLoader} inverted>
          <Loader active={props.showLoader}>Loading...</Loader>
        </Dimmer.Inner>
        <ContactSelector
          selected={checked()}
          onSelect={selectContact}
          selectionType={props.selectionType}
        />
      </Table.Cell>
      {props.displayedColumns.map(col => {
        switch (col) {
          case 'name':
            return (
              <Table.Cell key={col}>
                <TextLink
                  to={interpolateRoute(routes.contactDetails.path, {
                    id: props.contact.id,
                    active: 'details'
                  })}
                >
                  {props.contact.name}
                </TextLink>
              </Table.Cell>
            );
          case 'company':
            return <Table.Cell key={col}>{props.contact.company}</Table.Cell>;
          case 'partner company':
            return (
              <Table.Cell key={col}>
                {contactUtils.formatField(props.contact.partnerCompany)}
              </Table.Cell>
            );
          case 'department':
            return (
              <Table.Cell key={col}>
                <ContactDepartment
                  contact={props.contact}
                  setContactForDepartmentModal={
                    props.setContactForDepartmentModal
                  }
                  setDepartmentModalOpen={props.setDepartmentModalOpen}
                  allowDepartmentUpdate={props.allowDepartmentUpdate}
                />
              </Table.Cell>
            );
          case 'role':
            return (
              <Table.Cell key={col}>
                {contactUtils.formatRole(props.contact.role)}
              </Table.Cell>
            );
          case 'approval':
            return (
              <Table.Cell key={col}>
                <ContactApprovalLabel contact={props.contact} />
              </Table.Cell>
            );
          case 'topologies':
            return (
              <Table.Cell className='label-border' key={col}>
                <Label
                  content={`Active: ${props.activeTopologies}`}
                  basic
                  color='green'
                />
                <Label
                  content={`Inactive: ${props.inactiveTopologies}`}
                  basic
                  color='orange'
                />
                <Label
                  content={`Errored: ${props.erroredTopologies}`}
                  basic
                  color='red'
                />
                <Link
                  to={interpolateRoute(routes.createTopology.path, {
                    id: props.contact.id
                  })}
                >
                  <Button primary>Create Topology</Button>
                </Link>
              </Table.Cell>
            );
        }
        return <></>;
      })}
    </Table.Row>
  );
}
