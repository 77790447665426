import React from 'react';
import { Checkbox, Table } from 'semantic-ui-react';

import { Buildserver } from 'types/buildserver';

import { BuildserverNameColumn } from './BuildserverNameColumn';
import { BuildserverPublicIpDns } from './BuildserverPublicIpDns';
import { BuildserverStateLabel } from './BuildserverStateLabel';
import { BuildserverUtilization } from './BuildserverUtilization';

interface BuildserverRowProps {
  selected: Buildserver[];
  setSelected(buildservers: Buildserver[]): void;
  buildserver: Buildserver;
  buildserverCount: number;
}

export const BuildserverRow: React.FC<BuildserverRowProps> = ({
  selected,
  setSelected,
  buildserverCount,
  buildserver
}) => {
  const found = () => selected.find(bs => buildserver.id === bs.id);

  const onOneSelected = () => {
    if (found()) {
      setSelected(selected.filter(bs => bs.id !== buildserver.id));
    } else {
      setSelected([...selected, buildserver]);
    }
  };

  return (
    <Table.Row>
      <Table.Cell collapsing>
        <Checkbox
          onChange={() => onOneSelected()}
          checked={
            found() !== undefined || selected.length === buildserverCount
          }
        />
      </Table.Cell>
      <Table.Cell>
        <BuildserverNameColumn buildserver={buildserver} />
      </Table.Cell>
      <Table.Cell>{buildserver.region.label}</Table.Cell>
      <Table.Cell>
        <BuildserverStateLabel buildserver={buildserver} />
      </Table.Cell>
      <Table.Cell>
        <BuildserverPublicIpDns buildserver={buildserver} />
      </Table.Cell>
      <Table.Cell>
        <BuildserverUtilization buildserver={buildserver} />
      </Table.Cell>
    </Table.Row>
  );
};
