import jwt from 'jsonwebtoken';

import { LoggedInUser } from 'types';

import { storage } from './storage';

export const authTokenStorageKey = 'cl-token';
export const toggleTokenStorageKey = 'toggle';

const hasToken = (tokenKey: string): boolean => !!storage.get(tokenKey);
const getToken = (tokenKey: string): string => storage.get(tokenKey);
const setToken = (tokenKey: string, token: string) =>
  storage.set(tokenKey, token);
const removeToken = (tokenKey: string) => storage.remove(tokenKey);
const decodeToken: any = (token: string) => jwt.decode(token, { json: true });

const getLoggedInUser = (): LoggedInUser =>
  decodeToken(getToken(authTokenStorageKey));

const logout = () => {
  removeToken(authTokenStorageKey);
};

const loggedIn = () => hasToken(authTokenStorageKey) && !!getLoggedInUser();

export const authUtils = {
  getLoggedInUser,

  getToken,
  setToken,
  decodeToken,

  logout,

  loggedIn
};
