import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Button, Divider, Icon } from 'semantic-ui-react';

import { contactUtils } from 'utils';

import { CloudlabsBanner, RecentTopologies } from 'components';
import { routes } from 'constants/routing';
import { UserRole } from 'enums';

type Props = {
  readonly role: UserRole;
};

export const DashboardOld: React.FC<Props> = memo(({ role }) => {
  return (
    <div>
      <CloudlabsBanner />
      <RecentTopologies />
      {contactUtils.isInternalContact(role) && (
        <div>
          <Divider />
          <h2>{'Tags'}</h2>
          <Link to={routes.manageTags.path}>
            <Button secondary>
              <Icon className='button' name='shekel' />
              {'Manage Tags'}
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
});
