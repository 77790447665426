import cx, { Value } from 'classnames';
import React, { memo } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import './styles.less';

type Props = {
  readonly className?: Value;
} & LinkProps;

export const TextLink: React.FC<Props> = memo(
  ({ children, className, ...props }) => (
    <Link className={cx('cl-text-link', className)} {...props}>
      {children}
    </Link>
  )
);
