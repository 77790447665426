import React, { Fragment, memo } from 'react';
import { Icon } from 'semantic-ui-react';

// import { TextLink } from 'components';
import { Contact } from 'types';

import './styles.less';

type Props = {
  readonly contact: Contact;
  readonly mayChangeContact: boolean;
  readonly openDetailsModal: () => void;
};

export const ContactDetailsCaption: React.FC<Props> = memo(
  ({ contact, mayChangeContact, openDetailsModal }) => (
    <div className='cl-contact-details-caption'>
      <div className='access-info'>
        <h2 className='contact-name'>
          {contact.name}
          {contact.company && <Fragment> &ndash; {contact.company}</Fragment>}
          {mayChangeContact && (
            <Icon
              name='edit'
              className='primary'
              size='small'
              link
              onClick={openDetailsModal}
            />
          )}
        </h2>
        {/* Disabled for Phase I */}
        {/* <div>{'Access Expires in 162 days, 15:11:49'}</div>
        <div>
          <TextLink href='#'>Extend</TextLink>
          {' or '}
          <TextLink href='#'>Revoke</TextLink>
        </div> */}
      </div>
    </div>
  )
);
