/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */

import React from 'react';
import { Icon, Input, Menu } from 'semantic-ui-react';

interface SearchProps {
  onChange: (search: string) => void;
}

const Search: React.FC<SearchProps> = ({ onChange }) => {
  return (
    <Input
      type={'search'}
      icon={'search'}
      placeholder={'Search partners...'}
      onChange={e => onChange(e.currentTarget.value)}
    />
  );
};

interface PartnersMenuBarProps {
  onSearchChanged: (value: string) => void;
  onAddPartner: () => void;
  onUndo: () => void;
  onRedo: () => void;
  onApply: () => void;
  onClear: () => void;
  undoDisabled: boolean;
  redoDisabled: boolean;
}

export const PartnersMenuBar: React.FC<PartnersMenuBarProps> = ({
  onSearchChanged,
  onAddPartner,
  onUndo,
  onRedo,
  onApply,
  onClear,
  undoDisabled,
  redoDisabled
}) => {
  return (
    <Menu>
      <Menu.Item>
        <Search onChange={v => onSearchChanged(v)} />
      </Menu.Item>
      <Menu.Item
        aria-label={'Undo'}
        disabled={undoDisabled}
        onClick={() => onUndo()}
      >
        <Icon name={'undo'} />
        Undo
      </Menu.Item>
      <Menu.Item
        aria-label={'Redo'}
        onClick={() => onRedo()}
        disabled={redoDisabled}
      >
        <Icon name={'redo'} />
        Redo
      </Menu.Item>
      <Menu.Item aria-label={'Add Partner'} onClick={() => onAddPartner()}>
        <Icon name={'plus'} />
        Add Partner
      </Menu.Item>
      <Menu.Item
        aria-label={'Apply changes'}
        onClick={() => onApply()}
        disabled={undoDisabled}
      >
        <Icon name={'check'} />
        Apply changes
      </Menu.Item>
      <Menu.Item
        aria-label={'Clear all changes'}
        onClick={() => onClear()}
        disabled={undoDisabled}
      >
        <Icon name={'trash'} />
        Clear all changes
      </Menu.Item>
    </Menu>
  );
};
