import React, { memo, useEffect } from 'react';

import { notifier } from 'utils';

import { AosLogo } from 'components/AosLogo';
import { useRouter } from 'hooks/useRouter';

import { CredentialLoginForm } from './CredentialsLoginForm';

import './styles.less';

export const EmailLoginView: React.FC = memo(() => {
  const router = useRouter();
  const error = router.location.state
    ? (router.location.state as { error: string }).error
    : null;

  useEffect(() => {
    if (error) {
      notifier.error({ message: error });
    }
  }, [error]);

  return (
    <div className='cl-login'>
      <AosLogo nowrap />
      <h1 className='header'>{'Try Intent-Based Networking in the Cloud'}</h1>
      <div className='forms'>
        <CredentialLoginForm />
      </div>
    </div>
  );
});
