import React, { PureComponent } from 'react';

import { Contacts } from 'components';
import { MainLayout } from 'layouts';

export class ContactsView extends PureComponent {
  render() {
    return (
      <MainLayout>
        <Contacts />
      </MainLayout>
    );
  }
}
