import axios from 'axios';
import { useQuery } from 'react-query';

import { apiPrefix } from 'constants/api-routes';

export type AnnouncementLevel = 'danger' | 'info' | 'warning';

export type Announcement = {
  uuid: string;
  title: string;
  level: AnnouncementLevel;
  contents: string;
  createdAt: string;
  updatedAt: string;
};

// Define the type for the API response
type AnnouncementsResponse = {
  announcements: Announcement[];
  total: number;
  hasMore: boolean;
  offset: number;
};

// Create a function to fetch the announcements data
const fetchAnnouncements = async (): Promise<AnnouncementsResponse> => {
  const response = await axios.get<AnnouncementsResponse>(
    apiPrefix('/v1.3/announcements?limit=10')
  );
  return response.data;
};

export const useAnnouncements = () => {
  return useQuery<AnnouncementsResponse, Error>(
    'announcements',
    fetchAnnouncements,
    {
      refetchInterval: 10000,
      refetchOnWindowFocus: false,
      staleTime: 5000
    }
  );
};
