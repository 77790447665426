import React, { memo } from 'react';

import { RegionSelection } from 'views/CreateTopology/RegionSelector/RegionSelection';

type Props = {
  readonly stepVisible: boolean;
  readonly selectedRegionName: string | null;
  readonly onChange: (region: string) => void;
};

export const DeploymentRegionSelectionWizard: React.FC<Props> = memo(
  ({ stepVisible, selectedRegionName, onChange }) => (
    <div className='cl-deployment-region-selection' hidden={!stepVisible}>
      <h2 className='section-number'>Select a Deployment Region</h2>
      <RegionSelection
        selectedRegionName={selectedRegionName}
        onChange={onChange}
      />
    </div>
  )
);
