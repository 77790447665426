import { isEmpty } from 'lodash';
import React, { memo, useState } from 'react';
import { Label, List } from 'semantic-ui-react';

import { tagUtils } from 'utils';

import { Tag } from 'types/Tags/tags';

import './styles.less';

type Props = {
  readonly tags: Tag[];
};

export const TagCell: React.FC<Props> = memo(({ tags }) => {
  const [showMore, setShowMore] = useState(false);

  const getTopologyTags = () => {
    const filterTag = tags.filter(tag => tagUtils.nonDepartmentTag(tag));
    return filterTag.map(tag => {
      return `${tag.name}:${tag.value}`;
    });
  };
  const topologyTags = getTopologyTags();
  const numberOfItems = showMore ? topologyTags.length : 2;
  const tagList = (items: string[]) => {
    return items.map((tag, key) => (
      <List key={key} horizontal content={<Label content={tag} />} />
    ));
  };
  return (
    <div>
      {isEmpty(topologyTags) ? (
        '-'
      ) : topologyTags.length > 2 ? (
        <div>
          {tagList(topologyTags.slice(0, numberOfItems))}
          <a
            href='#/'
            className='link-color'
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? ' Show less' : '...Show more'}
          </a>
        </div>
      ) : (
        <div>{tagList(topologyTags)}</div>
      )}
    </div>
  );
});
