import React, { memo } from 'react';
import { Menu, MenuItem, MenuMenu } from 'semantic-ui-react';

import './styles.less';

type Props = {
  readonly filter: 'owned' | 'managed' | 'both' | 'none' | string;
  readonly pageLimit: '5' | '10' | string;
  readonly onFilterChange: (filter: string) => void;
  readonly onPageLimitChange: (value: string) => void;
};

export const DashboardTableFilter: React.FC<Props> = memo(
  ({ filter, pageLimit, onFilterChange, onPageLimitChange }) => {
    const allFilters = ['owned', 'managed'];
    const handleFilterSelection = (name: string) => {
      let tableFilter = [filter];
      if (tableFilter.includes(name)) {
        tableFilter = tableFilter.filter(item => item !== name);
      } else if (tableFilter.includes('both')) {
        tableFilter = allFilters.filter(item => item !== name);
      } else if (tableFilter.includes('none')) {
        tableFilter = [name];
      } else {
        tableFilter = [...tableFilter, name];
      }
      if (tableFilter.length === 2 && !tableFilter.includes('both')) {
        onFilterChange('both');
      } else {
        const newFilter = tableFilter.pop();
        onFilterChange(newFilter?.length ? newFilter : 'none');
      }
    };
    return (
      <Menu secondary className='dashboard-table-filter'>
        <MenuMenu position='left'>
          <MenuItem header>Filter By</MenuItem>
          <MenuItem
            name='owned'
            active={['owned', 'both'].includes(filter)}
            onClick={(e, { name }) => {
              if (name) handleFilterSelection(name);
            }}
          >
            Created for me
          </MenuItem>
          <MenuItem
            name='managed'
            active={['managed', 'both'].includes(filter)}
            onClick={(e, { name }) => {
              if (name) handleFilterSelection(name);
            }}
          >
            Created by me
          </MenuItem>
        </MenuMenu>
        <MenuMenu position='right'>
          <MenuItem header>Page Size</MenuItem>
          <MenuItem
            name='5'
            active={pageLimit === '5'}
            onClick={(e, { name }) => {
              if (name) onPageLimitChange(name);
            }}
          />
          <MenuItem
            name='10'
            active={pageLimit === '10'}
            onClick={(e, { name }) => {
              if (name) onPageLimitChange(name);
            }}
          />
        </MenuMenu>
      </Menu>
    );
  }
);
