import React from 'react';
import { Divider, Icon, Segment } from 'semantic-ui-react';

import './styles.less';

export const BotWelcome: React.FC = () => {
  return (
    <Segment raised>
      <h2 className='justify'>
        All the resource and tools you need to learn more about Apstra!
      </h2>
      <Divider />
      <h3>
        <Icon name='search' color='green' /> Query
      </h3>
      <p>
        Now you can search for any resources related to Apstra.{' '}
        <b>Select a Knowledge Base </b>-{'>'}{' '}
        <b>Type in your query or search from the Dropdown</b> -{'>'}{' '}
        <b>Press Enter</b>
      </p>
      <p>
        For example: <b>Apstra 4.2 </b>-{'>'}
        <i>What are resource pools?</i> -{'>'} <b>Press Enter</b>
      </p>
      <Divider />
      <h3>
        <Icon name='feed' color='blue' /> Feedback
      </h3>
      <p>
        Search feedback for every question will help us improve the responses.
        Please give each question a thumbs up/down and if you feel an answer can
        be better, please include your recommendation in the feedback box.
      </p>
      <p>
        If you have general feedback about usage and the UI, please submit it
        via the “feedback” form on the right.
      </p>
      <Divider />
      <h3>
        <Icon name='folder' color='yellow' /> Organize
      </h3>
      <p>
        You can view your previous searches under
        <b> Recent Searches</b>.
      </p>
      <p>
        You can bookmark your favorite queries too. You will see a 'Favorite'
        button. Once favorited, you can view them under <b>Favorites</b>.
      </p>
      <p>
        <b>Note: </b>All the queries can be expanded to view a short hand
        explanation, you can also click the content to view all the relevant
        links associated with it.
      </p>
      <Divider />
      <h3>
        <Icon name='heart' color='red' /> Our Goals
      </h3>
      <p>
        Our goal is to give our users the right tools, content and guidance to
        learn about Apstra by themselves or with first hand support.
      </p>
      <p>
        Found it interesting? Shoot us a compliment from our feedback widget!
      </p>
      <p>Thanks!</p>
    </Segment>
  );
};
