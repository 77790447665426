import React, { memo } from 'react';
import { Modal } from 'semantic-ui-react';

import { contactUtils } from 'utils';

import { Contact } from 'types';

import { ContactDetailsForm } from 'components/ContactDetailsForm';

type Props = {
  readonly open: boolean;
  readonly contact: Contact;
  readonly onClose: (data: null | Contact) => void;
  readonly onChange: (contact: Contact) => void;
  readonly onSubmit: () => void;
};

export const PartnerContactDetailsModal: React.FC<Props> = memo(
  ({ open, contact, onClose, onChange, onSubmit }) => {
    const isPartner = contactUtils.isPartnerOrPartnerAdmin(contact);
    const role = contactUtils.formatRole(contact.role);
    return (
      <Modal
        open={open}
        closeIcon={true}
        closeOnDimmerClick={false}
        onClose={() => onClose(null)}
        size='tiny'
      >
        <Modal.Header>{`Edit ${role}`}</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <ContactDetailsForm
              contact={contact}
              submitBtnText={'Save Changes'}
              submitBtnIcon={'save'}
              passwordOptional={isPartner}
              onSubmit={onSubmit}
              onChange={onChange}
            />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
);
