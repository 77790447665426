import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import { authUtils, interpolateRoute } from 'utils';

import { SearchForm } from 'components/Contacts/SearchForm';
import { routes } from 'constants/routing';

import { UserSelectorTableWizard } from './UserSelectorTableWizard';

import './styles.less';

type Props = {
  readonly stepVisible: boolean;
};

export const UserSelectorWizard: React.FC<Props> = memo(({ stepVisible }) => {
  const id = authUtils.getLoggedInUser().id;
  return (
    <div className='cl-create-topology-user-selector' hidden={!stepVisible}>
      <h2 className='section-number'>{<span>Select a User</span>}</h2>
      <div className='search-section'>
        <SearchForm />
        <div className='align-right create-user'>
          <span>Can't find user?</span>
          <Link to={interpolateRoute(routes.createUserContact.path, { id })}>
            <Button icon='plus circle' content='Create New User' />
          </Link>
        </div>
      </div>
      <UserSelectorTableWizard />
    </div>
  );
});
