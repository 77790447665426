/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */

import { Validator } from './Base';

export class Length extends Validator {
  private minLength: number;
  private maxLength?: number;

  constructor(minLength: number, maxLength?: number) {
    super();
    this.minLength = minLength;
    this.maxLength = maxLength;
  }

  protected validate(text: string): boolean {
    if (this.maxLength) {
      return text.length >= this.minLength && text.length <= this.maxLength;
    } else {
      return text.length >= this.minLength;
    }
  }

  protected errorMessage(): string {
    return `Length should be between ${this.minLength} and ${this.maxLength}`;
  }
}
