import { find, first } from 'lodash';
import React, { useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';

import { useLaunchTemplates } from 'hooks/useLaunchTemplates';
import { useRegions } from 'hooks/useRegions';
import { Region } from 'types';

import { CreateBuildserverModal } from './CreateBuildserverModal';

export const BuildserverCreate: React.FC = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const launchTemplates = useLaunchTemplates();
  const regions = useRegions();

  let defaultRegion: Region | undefined;

  if (regions.isSuccess && regions.data) {
    const found = find(regions.data, { name: 'us-west-2' });
    if (found) {
      defaultRegion = found;
    } else {
      defaultRegion = first(regions.data);
    }
  }

  return (
    <Button.Group positive>
      <CreateBuildserverModal
        regions={regions.data || []}
        launchTemplates={launchTemplates.data || []}
        setOpen={(val: boolean) => setOpenModal(val)}
        isOpen={() => openModal}
      />
      <Button
        onClick={() => setOpenModal(true)}
        loading={regions.isLoading}
        disabled={defaultRegion === undefined}
        icon
        labelPosition={'left'}
      >
        <Icon name={'rocket'} />
        Launch
      </Button>
    </Button.Group>
  );
};
