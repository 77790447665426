import axios from 'axios';
import { isEmpty } from 'lodash';
import { useQuery, UseQueryResult } from 'react-query';

import { notifier } from 'utils';

import { apiPrefix, apiRoutes } from 'constants/api-routes';
import { TimeSeriesRequest, TimeSeriesResponse } from 'types';

export const useTimeSeriesData = (
  values: string,
  startDate?: string,
  endDate?: string
): UseQueryResult<TimeSeriesResponse> => {
  let params: TimeSeriesRequest = { values };
  if (!isEmpty(startDate) && !isEmpty(endDate)) {
    params = { values, start: startDate, end: endDate };
  } else if (!isEmpty(startDate)) {
    params = { values, start: startDate };
  } else if (!isEmpty(endDate)) {
    params = { values, end: endDate };
  }
  return useQuery<TimeSeriesResponse>(
    ['TimeSeriesData', startDate, endDate, values],
    () => {
      return axios
        .get(apiPrefix(apiRoutes.timeseriesData), { params })
        .then(response => (response.data ? response.data : {}))
        .catch(error => {
          notifier.requestFailed(error);
          return [];
        });
    }
  );
};
