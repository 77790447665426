import React, { memo, useState } from 'react';
import { Divider, Form, Input, Message, Modal } from 'semantic-ui-react';

import { notifier, validateEmail } from 'utils';

import { authApi } from 'api/auth';
import { AuthFormContainer, PasswordField, SubmitButton } from 'components';
import { routes } from 'constants/routing';
import { useRouter } from 'hooks/useRouter';

import './styles.less';

export const CredentialLoginForm: React.FC = memo(() => {
  const router = useRouter();
  const [credentials, setCredentials] = useState<{
    email: string;
    password: string;
  }>({
    email: '',
    password: ''
  });

  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [isValid, isEmailValid] = useState(false);

  const modalDissmissed = () => {
    setResetPasswordModalOpen(false);
    setEmail('');
    isEmailValid(false);
  };

  const login = async (e: React.FormEvent<HTMLFormElement>) => {
    if (resetPasswordModalOpen) {
      isEmailValid(!validateEmail(email));
      if (validateEmail(email)) {
        sendPasswordResetEmail();
      }
    } else {
      e.preventDefault();
      try {
        const { token } = await authApi.loginEmail(credentials);
        router.push({ pathname: routes.eulaAutorized.path, state: { token } });
      } catch (err) {
        notifier.requestFailed(err);
      }
    }
  };

  const sendPasswordResetEmail = async () => {
    try {
      await authApi.sendResetPasswordEmail(email);
      setResetPasswordModalOpen(false);
      notifier.success({
        message: 'Please check your email for the password reset link'
      });
      setEmail('');
    } catch (err) {
      notifier.requestFailed(err);
    }
  };

  return (
    <AuthFormContainer
      title='Login using credentials'
      className='cl-credentials-login-form'
    >
      <Form onSubmit={login}>
        <Form.Field>
          <label>{'Email Address:'}</label>
          <Input
            type='email'
            placeholder='Email Address'
            required
            value={credentials.email}
            onChange={e =>
              setCredentials({ ...credentials, email: e.target.value })
            }
          />
        </Form.Field>
        <PasswordField
          inputProps={{
            value: credentials.password,
            required: true,
            onChange: e =>
              setCredentials({ ...credentials, password: e.target.value })
          }}
        />
        <SubmitButton>{'LOGIN'}</SubmitButton>
        <Divider horizontal />
        <button
          className='forgot-password-button'
          onClick={e => {
            setResetPasswordModalOpen(true);
            e.preventDefault();
          }}
        >
          Forgot Password?
        </button>
        <Modal
          open={resetPasswordModalOpen}
          closeIcon={true}
          dimmer='blurring'
          closeOnDimmerClick={false}
          onClose={() => modalDissmissed()}
          size='small'
        >
          <Modal.Header>{'Did you forget your password?'}</Modal.Header>
          <Modal.Content image>
            <Modal.Description>
              <Form onSubmit={() => sendPasswordResetEmail}>
                Enter your email address and we’ll send instructions over on how
                to reset your password.
                <Form.Input
                  required
                  placeholder='Enter your email address'
                  type='email'
                  error={isValid}
                  value={email || ''}
                  autoFocus
                  onChange={event => setEmail(event.target.value)}
                />
                <div className='actions'>
                  <Form.Button primary>{'Reset Password'}</Form.Button>
                </div>
                {isValid && (
                  <Message negative content='Please enter a valid email' />
                )}
              </Form>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </Form>
    </AuthFormContainer>
  );
});
