import { storage } from './storage';

const eulaAccepctedStorageKey = 'cl-eula-accepted';

export const isNotAuthorizedEulaAccepted = (): boolean => {
  return storage.get(eulaAccepctedStorageKey) === 'true';
};

export const setNotAuthorizedEulaAccepted = () => {
  storage.set(eulaAccepctedStorageKey, 'true');
};
