import { find, includes, isEmpty } from 'lodash';

import { permissions } from '../constants/permissions';
import { authUtils } from './auth';

const currentUserRole = (): string | null => {
  const user = authUtils.getLoggedInUser();
  return user ? user.role : null;
};

const checkPermissions = (
  roles: string[],
  userRole: string | null = currentUserRole()
) => {
  return includes(roles, userRole);
};

const checkRoutePermissions = (
  path: string | null,
  userRole: string | null = currentUserRole()
) => {
  if (path === null) {
    return true;
  }
  const route = find(permissions.routes, { path });
  return route && !isEmpty(route.roles)
    ? includes(route.roles, userRole)
    : true;
};

export const permissionsUtils = {
  checkPermissions,
  checkRoutePermissions
};
