import React, { memo } from 'react';
import { Header, Modal } from 'semantic-ui-react';

import { Quota } from 'types/quota';

import { CreateQuotaForm } from './CreateQuotaForm';

type Props = {
  readonly open: boolean;
  readonly selectedQuota: Quota;
  readonly onClose: (value: boolean) => void;
  readonly onQuotaChange: (value: Quota) => void;
};

export const CreateQuotaModal: React.FC<Props> = memo(
  ({ open, onClose, selectedQuota, onQuotaChange }) => {
    return (
      <Modal
        onClose={() => onClose(false)}
        open={open}
        size='small'
        closeIcon={true}
      >
        <Header icon>Create/Edit Quota</Header>
        <Modal.Content>
          <CreateQuotaForm
            currentQuota={selectedQuota}
            onChange={onQuotaChange}
            onClose={onClose}
          />
        </Modal.Content>
      </Modal>
    );
  }
);
