import { AxiosError, AxiosResponse } from 'axios';
import React, { memo } from 'react';
import { Message } from 'semantic-ui-react';

type Props = {
  error: AxiosError;
};

export const message = (response: AxiosResponse | undefined) => {
  if (!response || !response.data || !response.data.errors) {
    return 'Unknown error';
  } else {
    return response.data.errors.map((err: any) => err.message);
  }
};

const header = (response: AxiosResponse | undefined) => {
  if (!response) {
    return 'An unexpected error has occurred';
  } else {
    return response.statusText;
  }
};

export const ErrorMessage: React.FC<Props> = memo(({ error }) => {
  return (
    <Message negative>
      <Message.Header>{header(error.response)}</Message.Header>
      <p>{message(error.response)}</p>
    </Message>
  );
});
