import React, { memo } from 'react';
import { Button, ButtonGroup, Icon } from 'semantic-ui-react';

import { useBuildserverMutations } from 'hooks/useBuildserverMutations';
import { Buildserver } from 'types/buildserver';

import { BuildserverCreate } from './BuildserverCreate';

interface TerminateProps {
  selected: Buildserver[];
}

export const BuildserverTerminate: React.FC<TerminateProps> = memo(
  ({ selected }) => {
    const { useDeleteBuildserver } = useBuildserverMutations();

    const terminate = () => {
      selected.forEach(bs => {
        useDeleteBuildserver.mutate(bs);
      });
    };

    return (
      <Button
        negative
        disabled={selected.length === 0}
        onClick={terminate}
        icon
        labelPosition={'left'}
      >
        <Icon name={'delete'} />
        Terminate
      </Button>
    );
  }
);

export const BuildserverActions: React.FC<TerminateProps> = memo(
  ({ selected }) => {
    return (
      <ButtonGroup>
        <BuildserverTerminate selected={selected} />
        <Button.Or />
        <BuildserverCreate />
      </ButtonGroup>
    );
  }
);
