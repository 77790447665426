import React, { memo, useState } from 'react';
import { Form, Modal } from 'semantic-ui-react';

type Props = {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly onSubmit: (value: string) => void;
};

export const BlueprintNameModal: React.FC<Props> = memo(
  ({ open, onClose, onSubmit }) => {
    const [name, setName] = useState('');

    const onLocalSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onSubmit(name);
    };

    return (
      <Modal
        open={open}
        closeIcon={true}
        closeOnDimmerClick={false}
        onClose={() => {
          setName('');
          onClose();
        }}
        size='tiny'
      >
        <Modal.Header>{'Select Blueprint Name'}</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <Form onSubmit={onLocalSubmit}>
              <Form.Input
                label='Name'
                required
                placeholder='Name'
                value={name || ''}
                autoFocus
                onChange={event => setName(event.target.value)}
              />
              <div className='actions'>
                <Form.Button primary>{'Submit'}</Form.Button>
              </div>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
);
