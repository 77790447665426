import {
  ApiLabGuideDoc,
  FreeformLabGuideDoc,
  FreeformRALabGuideDoc,
  GettingStartedLabGuideDoc,
  JuniperLabGuideDoc,
  MultiLabGuideDoc,
  TerraformJunosLabGuideDoc
} from 'utils/urls';

import { routes } from 'constants/routing';
import { MenuItem } from 'types';

const getPropsFromRoute = (route: { label: string; path: string }) => {
  return {
    label: route.label,
    link: route.path
  };
};

export const menuItems: MenuItem[] = [
  {
    icon: 'th',
    ...getPropsFromRoute(routes.dashboard)
  },
  {
    label: 'Users',
    link: null,
    icon: 'users',
    children: [
      getPropsFromRoute(routes.contacts),
      getPropsFromRoute(routes.createUserContact),
      getPropsFromRoute(routes.partners)
    ]
  },
  {
    label: 'Topology',
    link: null,
    icon: 'sitemap',
    children: [
      getPropsFromRoute(routes.topologies),
      getPropsFromRoute(routes.templates)
    ]
  },
  {
    icon: 'comment',
    ...getPropsFromRoute(routes.chat)
  },
  {
    label: 'Reports',
    link: null,
    icon: 'file alternate',
    children: [
      {
        ...getPropsFromRoute(routes.adminStats)
      },
      {
        ...getPropsFromRoute(routes.contactReports)
      },
      {
        ...getPropsFromRoute(routes.topologyReports)
      }
    ]
  },
  {
    label: 'Lab Guides',
    link: null,
    icon: 'lab',
    children: [
      {
        label: 'Getting Started',
        link: GettingStartedLabGuideDoc()
      },
      {
        label: 'Lab 1 - Intro to Apstra (Juniper)',
        link: JuniperLabGuideDoc()
      },
      {
        label: 'Lab 1 - Intro to Apstra (Multi-vendor)',
        link: MultiLabGuideDoc()
      },
      {
        label: 'Lab 1 - Intro to Apstra (Apstra Freeform)',
        link: FreeformLabGuideDoc()
      },
      {
        label: 'Lab2 - Freeform Resource Allocation',
        link: FreeformRALabGuideDoc()
      },
      {
        label: 'API Lab Guides',
        link: ApiLabGuideDoc()
      },
      {
        label: 'Intro to Terraform and Apstra (Juniper)',
        link: TerraformJunosLabGuideDoc()
      }
    ]
  },
  {
    icon: 'lab',
    ...getPropsFromRoute(routes.labs)
  },
  {
    label: 'SE toolbox',
    link: null,
    icon: 'suitcase',
    children: [
      {
        ...getPropsFromRoute(routes.apstraSizingCalculator)
      }
    ]
  },
  {
    label: 'Admin Console',
    link: null,
    icon: 'terminal',
    children: [
      {
        icon: 'server',
        ...getPropsFromRoute(routes.buildservers)
      },
      {
        icon: 'chart pie',
        ...getPropsFromRoute(routes.quotas)
      }
    ]
  },
  {
    icon: 'tags',
    ...getPropsFromRoute(routes.manageTags)
  }
];
