/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */

export abstract class Validator {
  protected abstract validate(text: string): boolean;
  protected abstract errorMessage(): string;
  error(text: string): string {
    return this.validate(text) ? '' : this.errorMessage();
  }
}
