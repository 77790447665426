import React, { memo } from 'react';

import { assets } from 'constants/assets';

import './styles.less';

const { juniperLogo } = assets.images;
export const JuniperLogo: React.FC = memo(() => (
  <div className='cl-juniper-logo'>
    <img src={juniperLogo.src} alt={juniperLogo.alt} />
  </div>
));
