import React, { memo } from 'react';

import { QuerySummary } from 'interfaces';

import { SearchBar } from './SearchBar';

type Props = {
  readonly loading: boolean;
  readonly setLoading: (value: boolean) => void;
  readonly inputQuery: QuerySummary;
  readonly setInputQuery: (value: QuerySummary) => void;
};

export const KnowledgeSearch: React.FC<Props> = memo(
  ({ loading, inputQuery, setLoading, setInputQuery }) => {
    return (
      <SearchBar
        loading={loading}
        inputQuery={inputQuery}
        setLoading={setLoading}
        setInputQuery={setInputQuery}
      />
    );
  }
);
