import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Popup } from 'semantic-ui-react';

import { authUtils, interpolateRoute } from 'utils';

import { routes } from 'constants/routing';
import { useRouter } from 'hooks/useRouter';

import './styles.less';

export const UserName: React.FC = memo(() => {
  const user = authUtils.getLoggedInUser();
  const router = useRouter();
  const detailsLink = interpolateRoute(routes.contactDetails.path, {
    id: user.id,
    active: 'details'
  });
  const logout = () => {
    authUtils.logout();
    router.push(routes.login.path);
  };
  return (
    <div className='support-wrapper cl-user-name'>
      <Link to={detailsLink}>
        <Icon name='user outline' />
        {user ? user.name : null}
      </Link>
      <Popup
        content='Sign out'
        trigger={
          <Icon
            className='button-wrapper'
            size='large'
            name='sign-out'
            onClick={logout}
          />
        }
        position='top left'
      />
    </div>
  );
});
