import React from 'react';

import { Confirm, List } from 'semantic-ui-react';

import { Topology } from 'types';

import './styles.less';

interface ConfirmTopologyDeleteProps {
  readonly selectedTopologies: Topology[];
  readonly open: boolean;
  setOpen: (open: boolean) => void;
  onDelete: () => void;
}

export const ConfirmTopologyDelete: React.FC<ConfirmTopologyDeleteProps> = ({
  selectedTopologies,
  open,
  setOpen,
  onDelete
}) => {
  const contentText = () => {
    if (selectedTopologies.length === 1) {
      return (
        <p>
          Are you sure you want to delete topology{' '}
          <b>{selectedTopologies[0].name}</b>?
        </p>
      );
    } else if (selectedTopologies.length > 1 && selectedTopologies.length < 5) {
      return (
        <>
          <p>Are you sure you want to delete these topologies?</p>
          <List ordered>
            {selectedTopologies.map((topology: Topology) => (
              <List.Item key={topology.uuid}>{topology.name}</List.Item>
            ))}
          </List>
        </>
      );
    } else if (selectedTopologies.length >= 5) {
      return (
        <p>
          Are you sure you want to delete {selectedTopologies.length}{' '}
          topologies?
        </p>
      );
    } else {
      return <p>Are you sure you want to delete these topologies?</p>;
    }
  };
  const content = <div className='confirmContentText'>{contentText()}</div>;

  return (
    <Confirm
      size='small'
      content={content}
      open={open}
      onCancel={() => setOpen(false)}
      onConfirm={onDelete}
      confirmButton='Delete'
      data-testid='confirm-delete'
    />
  );
};
