import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dimmer, Loader, Table } from 'semantic-ui-react';
import { notifier, topologyStates } from 'utils';

import { topologiesApi } from 'api/topology';
import { CustomTableHeaderRow } from 'components/CustomTableHeaderRow';
import { Pagination } from 'components/TablePagination';
import { Contact, Topology } from 'types';

import { fetchContacts, setSelectedContacts } from 'features/contacts';
import { RootState } from 'Reducers/contactReducer';
import { ChooseDepartmentModal } from './ChooseDepartmentModal';
import { ContactTableRow } from './ContactTableRow';
import { SelectionType } from './SelectionType';

import './styles.less';

export type Option = 'allowDepartmentUpdate';

type Props = {
  readonly displayedColumns: string[];
  readonly selectionType: SelectionType;
  readonly options: Option[];
};

export const ContactsTable: React.FC<Props> = ({
  displayedColumns,
  selectionType,
  options
}) => {
  const contactsState = useSelector((state: RootState) => state.contact);
  const contacts = contactsState.contacts;
  const selectedContacts = contactsState.selected;
  const [contactsLoading, setContactsLoading] = useState<boolean>(
    contactsState.requestState === 'loading'
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (contactsState.requestState === 'idle') {
      dispatch(
        fetchContacts({
          page: contactsState.currentPage,
          limit: contactsState.limit,
          filter: contactsState.filter.text,
          role: contactsState.filter.role
        })
      );
    }
  }, [contactsState, dispatch]);

  useEffect(() => {
    setContactsLoading(contactsState.requestState === 'loading');
  }, [contactsState.requestState]);

  const [confirmModalOpen, setDepartmentModalOpen] = useState(false);

  const [contactForDepartmentModal, setContactForDepartmentModal] = useState<
    Contact[]
  >([]);
  const allOrAnySelected = () =>
    (selectedContacts.length === contacts.length && contacts.length !== 0) ||
    selectedContacts.length > 0;

  const handleSelectAll = async () => {
    const tempContacts =
      selectedContacts.length === contacts.length || selectedContacts.length > 0
        ? []
        : [...contacts];
    dispatch(setSelectedContacts(tempContacts));
  };

  const [topologies, setTopologies] = useState<Topology[]>([]);

  useEffect(() => {
    const getTopologies = async () => {
      try {
        const res = await topologiesApi.getAll({
          params: { paginated: false }
        });
        setTopologies(res.topologies);
      } catch (err) {
        notifier.requestFailed(err);
      }
    };
    getTopologies();
  }, [dispatch]);

  const activeTopologyNumber = (contact: Contact) =>
    topologies.filter(
      topology =>
        topology.createdFor.id === contact.id &&
        topologyStates.topologyActive(topology)
    ).length;
  const inActiveTopologyNumber = (contact: Contact) =>
    topologies.filter(
      topology =>
        topology.createdFor.id === contact.id &&
        topologyStates.topologyInactive(topology)
    ).length;
  const errorTopologyNumber = (contact: Contact) =>
    topologies.filter(
      topology =>
        topology.createdFor.id === contact.id &&
        topologyStates.topologyError(topology)
    ).length;

  const header = (
    <Table.Header>
      <CustomTableHeaderRow
        selectionType={selectionType}
        indeterminate={allOrAnySelected()}
        onChange={() => handleSelectAll()}
        displayedColumns={displayedColumns}
      />
    </Table.Header>
  );

  const footer = (
    <Table.Footer>
      <Table.Row>
        <Table.HeaderCell colSpan={displayedColumns.length + 1}>
          <Pagination
            activePage={contactsState.currentPage}
            totalPages={contactsState.totalPages}
            onPaginationChange={page => dispatch(fetchContacts({ page }))}
          />
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  );

  const tableLoading = (
    <Dimmer.Dimmable as={Table} dimmed>
      {header}
      <Table.Body>
        <Table.Row>
          <Table.Cell colSpan={displayedColumns.length + 1}>
            <Dimmer.Inner active inverted>
              <Loader active>Loading...</Loader>
            </Dimmer.Inner>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
      {footer}
    </Dimmer.Dimmable>
  );

  const tableLoaded = (
    <Table celled striped>
      {header}
      <Dimmer.Dimmable
        as={Table.Body}
        dimmed={contactsLoading}
        attached={'bottom'}
      >
        {contacts.map((contact, idx) => (
          <ContactTableRow
            showLoader={idx === 0 && contactsLoading}
            key={contact.id}
            contact={contact}
            displayedColumns={displayedColumns}
            selectionType={selectionType}
            setDepartmentModalOpen={setDepartmentModalOpen}
            setContactForDepartmentModal={setContactForDepartmentModal}
            activeTopologies={activeTopologyNumber(contact)}
            inactiveTopologies={inActiveTopologyNumber(contact)}
            erroredTopologies={errorTopologyNumber(contact)}
            allowDepartmentUpdate={options.some(
              o => o === 'allowDepartmentUpdate'
            )}
          />
        ))}
      </Dimmer.Dimmable>
      {footer}
    </Table>
  );

  return (
    <>
      {contacts.length === 0 && contactsLoading ? tableLoading : tableLoaded}
      {confirmModalOpen && (
        <ChooseDepartmentModal
          open={confirmModalOpen}
          setOpen={setDepartmentModalOpen}
          contacts={contactForDepartmentModal}
        />
      )}
    </>
  );
};
