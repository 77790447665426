import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Segment, StrictBreadcrumbSectionProps } from 'semantic-ui-react';

import { authUtils, interpolateRoute, notifier } from 'utils';
import { isNullOrUndefined } from 'utils/utilFunctions';

import { useLabMutations } from 'hooks/useLabTemplate';
import { useRouter } from 'hooks/useRouter';

import {
  CloudlabsBanner,
  DeploymentRegionSelectionWizard,
  ProgressStepperNavButtons,
  UserSelectorWizard
} from 'components';
import { ProgressStepper } from 'components/ProgressStepper';
import { routes } from 'constants/routing';
import { CreateLabPage } from 'enums/create-lab';
import { MainLayout } from 'layouts';
import { LabRequest, StepItem, TopologyFieldNames } from 'types';

import { setTopologyFieldName } from 'actions/actions';
import { setSelectedContacts } from 'features/contacts';
import { initialFieldName, RootState } from 'Reducers/contactReducer';
import { LabTemplateSelection } from './LabTemplateSelection';

import './styles.less';

export const CreateLabWizard: React.FC = () => {
  const router = useRouter();
  const contactsState = useSelector((state: RootState) => state.contact);
  const dispatch = useDispatch();
  const selectedContacts = contactsState.selected;
  const [lab, setNewLab] = useState<LabRequest>({} as LabRequest);
  const fieldValueNames: TopologyFieldNames = useSelector(
    (state: RootState) => state.fieldValues
  );
  const { useCreateLab } = useLabMutations();
  const [activeSteps, setActiveSteps] = useState<number>(0);
  const [completedSteps, setCompletedSteps] = useState<number>(0);
  const disablePrevButton = activeSteps === 0;
  const stepItems: StepItem[] = [
    {
      key: CreateLabPage.user,
      title: 'Step 1:',
      description: 'Select User'
    },
    {
      key: CreateLabPage.labTemplate,
      title: 'Step 2:',
      description: 'Select Lab'
    },
    {
      key: CreateLabPage.region,
      title: 'Step 3:',
      description: 'Select Deployment region'
    }
  ];
  const [createTopologySteps, setCreateTopologySteps] = useState<StepItem[]>(
    stepItems
  );

  if (selectedContacts && selectedContacts.length > 0) {
    const selected = selectedContacts[0];
    if (selected) {
      lab.createdForId = selected.id;
    }
  }
  const mapSteps = useMemo(() => {
    return new Map();
  }, []);
  stepItems.forEach((step: StepItem, i: number) => {
    mapSteps.set(i, step.key);
  });

  const checkIfStepIsCompleted = (stepKey: string) => {
    switch (stepKey) {
      case CreateLabPage.user:
        return !isNullOrUndefined(lab.createdForId);
      case CreateLabPage.labTemplate:
        return !isNullOrUndefined(lab.labTemplate);
      case CreateLabPage.region:
        return !isNullOrUndefined(lab.region);
      default:
        return true;
    }
  };

  const updatedStepItems = useMemo(() => {
    if (fieldValueNames !== initialFieldName) {
      const createSteps = createTopologySteps;
      if (
        mapSteps.get(activeSteps) === CreateLabPage.user ||
        !isEmpty(fieldValueNames.owner)
      ) {
        const index = createSteps.findIndex(
          step => step.key === CreateLabPage.user
        );
        if (index > -1)
          createSteps[
            index
          ].description = `Selected user: ${fieldValueNames.owner}`;
      }
      if (
        mapSteps.get(activeSteps) === CreateLabPage.labTemplate ||
        !isEmpty(fieldValueNames.template)
      ) {
        const index = createSteps.findIndex(
          step => step.key === CreateLabPage.labTemplate
        );
        createSteps[
          index
        ].description = `Selected lab template: ${fieldValueNames.template}`;
      }
      if (
        mapSteps.get(activeSteps) === CreateLabPage.region ||
        !isEmpty(fieldValueNames.region)
      ) {
        const index = createSteps.findIndex(
          step => step.key === CreateLabPage.region
        );
        createSteps[
          index
        ].description = `Selected region: ${fieldValueNames.region}`;
      }
      return createSteps;
    }
  }, [createTopologySteps, activeSteps, fieldValueNames, mapSteps]);

  useEffect(() => {
    setCreateTopologySteps(createTopologySteps);
  }, [fieldValueNames, createTopologySteps, updatedStepItems]);

  const handleChange = (field: string, value: any) => {
    setNewLab({ ...(lab || {}), [field]: value });
  };
  const breadcrumbSections: StrictBreadcrumbSectionProps[] = [
    {
      content: routes.createLabs.label,
      active: true
    }
  ];

  const createLab = async () => {
    const selected =
      selectedContacts && selectedContacts.length > 0
        ? selectedContacts[0].name
        : authUtils.getLoggedInUser().name;
    const label = `${selected} ${fieldValueNames.template}`;
    try {
      const resp = await useCreateLab.mutateAsync({
        label,
        createdForId: lab.createdForId,
        region: lab.region,
        labTemplate: lab.labTemplate
      });
      setNewLab({} as any);
      dispatch(setSelectedContacts([]));
      dispatch(setTopologyFieldName(initialFieldName));
      router.push(
        resp.labUuid
          ? interpolateRoute(routes.lab.path, { uuid: resp.labUuid })
          : interpolateRoute(routes.labs.path)
      );
    } catch (err) {
      notifier.requestFailed(err);
    }
  };

  return (
    <MainLayout
      breadcrumbSections={breadcrumbSections}
      className='cl-create-lab-view'
    >
      <CloudlabsBanner />
      <div className='caption'>
        <h2>Create New Lab</h2>
      </div>
      <ProgressStepper
        activeSteps={activeSteps}
        completedSteps={completedSteps}
        stepItems={createTopologySteps}
        onStepClick={setActiveSteps}
      />
      <UserSelectorWizard
        stepVisible={mapSteps.get(activeSteps) === CreateLabPage.user}
      />
      <LabTemplateSelection
        stepVisible={mapSteps.get(activeSteps) === CreateLabPage.labTemplate}
        selectedTemplateId={lab.labTemplate}
        onChange={value => handleChange('labTemplate', value)}
      />
      <DeploymentRegionSelectionWizard
        stepVisible={mapSteps.get(activeSteps) === CreateLabPage.region}
        selectedRegionName={lab.region}
        onChange={value => {
          handleChange('region', value);
        }}
      />
      <div className='nav-buttons-segment'>
        <Segment raised>
          <ProgressStepperNavButtons
            activeSteps={activeSteps}
            stepItemsLength={createTopologySteps.length}
            disablePrevButton={disablePrevButton}
            disableNextButton={
              !checkIfStepIsCompleted(mapSteps.get(activeSteps))
            }
            actionButton='Create Lab'
            setActiveSteps={setActiveSteps}
            setCompletedSteps={value => {
              setCompletedSteps(value);
            }}
            action={() => {
              createLab();
            }}
          />
        </Segment>
      </div>
    </MainLayout>
  );
};
