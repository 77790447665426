/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */
import React, { memo } from 'react';
import { Popup } from 'semantic-ui-react';

import './styles.less';

interface RegionCapacityProps {
  numberOfTopologies?: number;
}

const UnknownCapacityLabel: React.FC = memo(() => (
  <Popup
    header={'Select template to see capacity for this region.'}
    trigger={<div className='small grey'>Select template to see capacity</div>}
    on={['hover', 'focus']}
  />
));

const LowCapacityLabel: React.FC<RegionCapacityProps> = memo(
  ({ numberOfTopologies }) => (
    <Popup
      header={'Region is at low capacity'}
      content={`Has capacity for ${numberOfTopologies} more topologies.`}
      trigger={<div className='small yellow'>Low Capacity</div>}
      on={['hover', 'focus']}
    />
  )
);

const NoCapacityLabel: React.FC = memo(() => (
  <Popup
    header={'No capacity for new topologies'}
    content={
      'Topologies will fail to create with insufficient resources error if this region is selected.'
    }
    trigger={<div className={'small red'}>No capacity</div>}
    on={['hover', 'focus']}
  />
));

const SomeCapacityLabel: React.FC<{
  numberOfTopologies: number;
}> = memo(({ numberOfTopologies }) => (
  <Popup
    header={`Has capacity for ${numberOfTopologies} more topologies`}
    content={`${numberOfTopologies} more topologies can be created in this region.`}
    trigger={
      <div className={'small green'}>
        Has capacity for {numberOfTopologies} topologies
      </div>
    }
    on={['hover', 'focus']}
  />
));

export const RegionCapacityLabel: React.FC<RegionCapacityProps> = memo(
  ({ numberOfTopologies }) => {
    if (numberOfTopologies === undefined) {
      return <UnknownCapacityLabel />;
    } else if (numberOfTopologies <= 0) {
      return <NoCapacityLabel />;
    } else if (numberOfTopologies > 0 && numberOfTopologies < 3) {
      return <LowCapacityLabel numberOfTopologies={numberOfTopologies} />;
    } else {
      return <SomeCapacityLabel numberOfTopologies={numberOfTopologies} />;
    }
  }
);
