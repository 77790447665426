/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */

import React, { createRef, useState } from 'react';
import { Button, Icon, Input, Label } from 'semantic-ui-react';

export type Validator = (
  newValue: string,
  originalValue?: string
) => Error | null;

export interface EditableElementProps {
  text: string;
  onChange: (text: string) => void;
  onDelete: () => void;
  validators: Validator[];
}

export const EditableElement: React.FC<EditableElementProps> = ({
  text,
  onChange,
  onDelete,
  validators
}) => {
  const [value, setValue] = useState(text);
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors] = useState<Error[]>([]);
  const [originalValue] = useState(text);
  const inputRef = createRef<Input>();

  const runValidators = (val: string): Error[] => {
    return validators
      .map(validator => validator(val, originalValue))
      .filter(e => e !== null) as Error[];
  };
  const hasErrors = errors.length > 0;

  return (
    <div>
      {hasErrors && (
        <Label basic color={'red'} pointing={'below'}>
          {errors.map(e => e.message).join(', ')}
        </Label>
      )}
      <div className={'container'}>
        <Input
          ref={inputRef}
          disabled={!isEditing}
          className={'editableElement'}
          value={value}
          error={hasErrors}
          onChange={e => {
            setValue(e.currentTarget.value);
            setErrors(runValidators(e.currentTarget.value) ?? []);
          }}
          onKeyDown={(event: React.KeyboardEvent) => {
            if (event.key === 'Enter' && !hasErrors) {
              setIsEditing(false);
              onChange(value);
            }
            if (event.key === 'Escape') {
              setIsEditing(false);
              setValue(originalValue);
              setErrors([]);
            }
          }}
        />

        {isEditing ? (
          <Button
            icon
            data-testid='apply'
            onClick={() => {
              setIsEditing(false);
              onChange(value);
            }}
          >
            <Icon name='check' />
          </Button>
        ) : (
          <Button.Group>
            <Button
              icon
              aria-label={'Edit ' + value}
              data-testid='editButton'
              onClick={() => {
                setIsEditing(!isEditing);
                inputRef.current?.focus();
              }}
            >
              <Icon name={'edit'} />
            </Button>
            <Button
              icon
              aria-label={'Delete ' + value}
              data-testid='deleteButton'
              onClick={() => onDelete()}
            >
              <Icon name={'delete'} />
            </Button>
          </Button.Group>
        )}
      </div>
    </div>
  );
};
