import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';

import { contactUtils } from 'utils';

import { Contact } from 'types';

export function ContactDepartmentPopup(props: {
  contact: Contact;
  setContactForDepartmentModal: (contact: Contact[]) => void;
  setDepartmentModalOpen: (open: boolean) => void;
}) {
  const popupTriggerContent = (contact: Contact) => {
    const department = contactUtils.formatField(contact.department);
    if (department === '-') {
      return `Click on the icon to add department to user ${contact.name}`;
    } else {
      return `User ${contact.name} is in ${contact.department} department. Click to change`;
    }
  };

  const popupTriggerIcon = (contact: Contact) => {
    const department = contactUtils.formatField(contact.department);
    if (contactUtils.isInternalContact(contact.role)) {
      if (department === '-') {
        return (
          <span
            className={'clickable'}
            onClick={() => {
              props.setContactForDepartmentModal([contact]);
              props.setDepartmentModalOpen(true);
            }}
          >
            <Icon name='warning circle' color='red' />
            {department}
          </span>
        );
      } else {
        return (
          <span
            className={'clickable'}
            onClick={() => {
              props.setContactForDepartmentModal([contact]);
              props.setDepartmentModalOpen(true);
            }}
          >
            <Icon name='tag' />
            {department}
          </span>
        );
      }
    } else {
      return <span>{department}</span>;
    }
  };

  return (
    <Popup
      trigger={popupTriggerIcon(props.contact)}
      content={popupTriggerContent(props.contact)}
      position='top right'
      inverted
      flowing
      style={{ background: '#5a5a5a' }}
      basic
      hoverable
    />
  );
}
