import { isEmpty } from 'lodash';
import React, { memo } from 'react';
import { Button } from 'semantic-ui-react';

import { assets } from 'constants/assets';
import { ImageAssets, Template, TemplateImage } from 'types';

type Props = {
  readonly template: Template;
  readonly onChange: (templateId: number, templateName: string) => void;
  readonly setPreviewTemplateImage: (image: ImageAssets) => void;
  readonly setPreviewTemplateDescription: (description: string) => void;
  readonly setModalVisible: (visible: boolean) => void;
};

export const TemplateCardButtons: React.FC<Props> = memo(
  ({
    onChange,
    template,
    setModalVisible,
    setPreviewTemplateDescription,
    setPreviewTemplateImage
  }) => {
    const getImage = (templateImage: TemplateImage[]): ImageAssets =>
      isEmpty(templateImage)
        ? assets.images.noImageAvailable
        : {
            src: templateImage[0].url,
            alt: templateImage[0].name
          };
    return (
      <div className='btn-container'>
        <Button
          className='btn'
          content='Preview Template'
          onClick={() => {
            setPreviewTemplateImage(getImage(template.templateImages));
            setPreviewTemplateDescription(template.longDescription);
            setModalVisible(true);
          }}
        />
        <Button
          className='btn'
          content='Select Template'
          onClick={() => onChange(template.id, template.name)}
        />
      </div>
    );
  }
);
