import { permissions } from 'constants/permissions';
import { UserRole } from 'enums';
import { Contact, LoggedInUser } from 'types';

import { permissionsUtils } from './permissions';

const mayChangeByRole = (role: string) => {
  if (role === UserRole.Admin) {
    return permissionsUtils.checkPermissions(
      permissions.contacts.mayChangeAdmin
    );
  }
  if (role === UserRole.Support) {
    return permissionsUtils.checkPermissions(
      permissions.contacts.mayChangeSupport
    );
  }
  if (role === UserRole.Internal) {
    return permissionsUtils.checkPermissions(
      permissions.contacts.mayChangeInternal
    );
  }
  if (role === UserRole.PartnerAdmin) {
    return permissionsUtils.checkPermissions(
      permissions.contacts.mayChangePartnerAdmin
    );
  }
  if (role === UserRole.Partner) {
    return permissionsUtils.checkPermissions(
      permissions.contacts.mayChangePartner
    );
  }
  if (role === UserRole.Customer) {
    return permissionsUtils.checkPermissions(
      permissions.contacts.mayChangeCustomer
    );
  }
  return false;
};

const isPartnerAdmin = (contact: Contact) => {
  return contact && UserRole.PartnerAdmin === contact.role;
};

const isPartner = (contact: Contact) => {
  return contact && UserRole.Partner === contact.role;
};

const isPartnerOrPartnerAdmin = (contact: Contact) => {
  return isPartner(contact) || isPartnerAdmin(contact);
};

const isCustomer = (contact: Contact) => {
  return contact && UserRole.Customer === contact.role;
};

const isAdminOrInternal = (user: LoggedInUser) => {
  return (
    user && (UserRole.Admin === user.role || UserRole.Internal === user.role)
  );
};

const isInternalUser = (user: LoggedInUser) => {
  return (
    user &&
    [UserRole.Admin, UserRole.Support, UserRole.Internal].includes(user.role)
  );
};

const isAdmin = (user: Contact) => {
  return user && UserRole.Admin === user.role;
};

const isInternal = (user: Contact) => {
  return user && UserRole.Internal === user.role;
};

const isSupport = (user: Contact) => {
  return user && UserRole.Support === user.role;
};

const isAdminOrSupport = (user: Contact) => {
  return (
    user && (UserRole.Admin === user.role || UserRole.Support === user.role)
  );
};

const isAdminOrSE = (role: UserRole) => {
  return role && (UserRole.Admin === role || UserRole.SE === role);
};

const isInternalContact = (role: UserRole) => {
  return (
    role &&
    [UserRole.Admin, UserRole.Support, UserRole.Internal, UserRole.SE].includes(
      role
    )
  );
};

const formatRole = (role: UserRole) => {
  switch (role) {
    case UserRole.Admin:
      return 'Admin';
    case UserRole.Internal:
      return 'Internal';
    case UserRole.Support:
      return 'Support';
    case UserRole.SE:
      return 'SE';
    case UserRole.PartnerAdmin:
      return 'Partner Admin';
    case UserRole.Partner:
      return 'Partner';
    case UserRole.Customer:
      return 'Customer';
    default:
      return role;
  }
};

const roleFromString = (role: string): UserRole => {
  switch (role) {
    case 'Admin':
      return UserRole.Admin;
    case 'Internal':
      return UserRole.Internal;
    case 'Support':
      return UserRole.Support;
    case 'SE':
      return UserRole.SE;
    case 'Partner Admin':
      return UserRole.PartnerAdmin;
    case 'Partner':
      return UserRole.Partner;
    case 'Customer':
      return UserRole.Customer;
    default:
      return role as UserRole;
  }
};

const ROLE_OPTIONS = {
  customers: {
    label: 'Customer',
    value: UserRole.Customer,
    permissions: null
  },
  internal: {
    label: 'Internal',
    value: UserRole.Internal,
    permissions: permissions.contacts.maySeeInternals
  },
  partners: {
    label: 'Partner',
    value: UserRole.Partner,
    permissions: permissions.contacts.maySeePartners
  },
  support: {
    label: 'Support',
    value: UserRole.Support,
    permissions: permissions.contacts.maySeeSupport
  },
  se: {
    label: 'SE',
    value: UserRole.SE,
    permissions: permissions.contacts.maySeeSupport
  }
};

const formatField = (field: string) => (field ? field : '-');

/**
 * Very rudimentary code targeted to be used in tests to generate contacts
 * @param name
 * @param department
 * @param partnerCompany
 * @param id
 */
function makeContact(
  name: string,
  department: string,
  partnerCompany: string,
  id: number = 0
): Contact {
  const company = 'company';

  return {
    company,
    department,
    email: `${name}@${company}.com`,
    eulaAccepted: false,
    id,
    name,
    partnerCompany,
    role: UserRole.Admin
  };
}

export const contactUtils = {
  makeContact,
  mayChangeByRole,
  isPartnerAdmin,
  isPartner,
  isPartnerOrPartnerAdmin,
  isCustomer,
  formatRole,
  roleFromString,
  isAdminOrInternal,
  ROLE_OPTIONS,
  isAdmin,
  isInternal,
  isSupport,
  isAdminOrSupport,
  isInternalContact,
  formatField,
  isInternalUser,
  isAdminOrSE
};
