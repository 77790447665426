import React, { PureComponent } from 'react';

import { ReportCharts } from 'components';
import { MainLayout } from 'layouts';

export class AdminStatsView extends PureComponent {
  render() {
    return (
      <MainLayout>
        <ReportCharts />
      </MainLayout>
    );
  }
}
