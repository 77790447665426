function getSlicerServerPublicDnsFromEnv(): string {
  if (process.env.REACT_APP_ENV === 'development') {
    // use staging slicer server from Ohio region for local env
    return 'ec2-3-87-71-191.compute-1.amazonaws.com';
  }

  return 'ec2-34-222-201-184.us-west-2.compute.amazonaws.com';
}

export const appConfig = {
  baseURL:
    process.env.NODE_ENV === 'production'
      ? '/api'
      : 'http://localhost:9020/api',
  slicerServerPublicDns: getSlicerServerPublicDnsFromEnv()
};
