import React, { memo, useEffect, useMemo, useState } from 'react';
import { Card } from 'semantic-ui-react';

import { getLinkData } from 'utils';

import './styles.less';

type Props = {
  readonly url: string;
  readonly title: string;
};
export const BaseReferenceLinks: React.FC<Props> = memo(({ url, title }) => {
  const initialState = useMemo(() => {
    return {
      title,
      description: ''
    };
  }, [title]);
  const [linkData, setLinkData] = useState(initialState);
  useEffect(() => {
    let isSubscribed = true;
    const retrieveData = async () => {
      const metaData = await getLinkData(url);
      setLinkData(isSubscribed ? metaData : initialState);
    };
    if (isSubscribed && url) {
      retrieveData();
      isSubscribed = false;
    }
  }, [url, initialState]);

  return (
    <Card
      className='text-wrap-card'
      href={url}
      target='_blank'
      link={true}
      header={linkData.title}
      description={linkData.description}
    />
  );
});
