/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */

import AddPartner from './AddPartner';
import { Command, SerializedCommand } from './Command';
import { DeletePartner } from './DeletePartner';
import { EditPartner } from './EditPartner';

const serializeOne = (command: Command): SerializedCommand => {
  return command.serialize();
};

const deserializeOne = (command: SerializedCommand): Command => {
  switch (command.type) {
    case 'AddPartner':
      return new AddPartner(command.data.partner, command.data.domains);
    case 'DeletePartner':
      return new DeletePartner(command.data.deletedPartner);
    case 'EditPartner':
      return new EditPartner(
        command.data.partner,
        command.data.newName,
        command.data.newDomains
      );
  }
  throw new Error('Unknown command type: ' + command.type);
};

export const serialize = (commands: Command[]): SerializedCommand[] => {
  return commands.map(c => serializeOne(c));
};

export const deserialize = (commands: SerializedCommand[]): Command[] => {
  return commands.map((c: SerializedCommand) => deserializeOne(c));
};
