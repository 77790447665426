import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import 'config/interceptors';

import App from './App';
import { FeatureProvider } from './components/FeatureFlags';
import { features } from './config/features';
import { store } from './store';
import './styles/index.less';

const root = createRoot(document.getElementById('root') as Element);

root.render(
  <Provider store={store}>
    <FeatureProvider config={features}>
      <App />
    </FeatureProvider>
  </Provider>
);
