import { Toolbar } from 'types';

export const toolbarProps = (category: string): Toolbar => {
  return {
    show: true,
    tools: {
      download: false,
      selection: true,
      zoom: true,
      zoomin: true,
      zoomout: true,
      pan: false,
      reset: false,
      customIcons: [
        {
          icon: '<i aria-hidden="true" class="external share icon"></i>',
          index: 0,
          title: 'Export as CSV',
          class: 'icon-padding custom-icon',
          click: (chart?: any, options?: any, e?: any) => {
            const ctx = chart.ctx;
            ctx.exports.exportToCSV({
              series: chart.series.w.config.series,
              columnDelimiter: ','
            });
          }
        }
      ]
    },
    export: {
      csv: {
        columnDelimiter: ',',
        headerCategory: category
      }
    },
    autoSelected: 'zoom'
  };
};

export const commonProps = (loading: boolean) => {
  return {
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 1,
      curve: 'smooth'
    },
    grid: {
      row: {
        colors: ['transparent', 'transparent'],
        opacity: 0.5
      }
    },
    noData: {
      text: loading ? 'Loading...' : 'No Data available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#000000',
        fontSize: '14px',
        fontFamily: 'Helvetica'
      }
    }
  };
};

export const commonMultiSeriesProps = (loading: boolean) => {
  return {
    stroke: {
      width: 2,
      curve: 'smooth'
    },
    colors: legendColors,
    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: false,
        shade: 'dark',
        shadeIntensity: 0.5,
        type: 'horizontal',
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100]
      }
    },
    markers: {
      size: 0
    },
    noData: {
      text: loading ? 'Loading...' : 'No Data available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#000000',
        fontSize: '14px',
        fontFamily: 'Helvetica'
      }
    },
    legend: {
      position: 'top'
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeFormatter: {
          year: 'yyyy',
          month: `MMM 'yy`,
          day: 'dd MMM',
          hour: 'HH:mm'
        }
      },
      title: {
        text: 'Time',
        style: {
          color: 'gray'
        }
      }
    },
    tooltip: {
      shared: true,
      intersect: false
    }
  };
};

export const commonContactProps = (loading: boolean) => {
  const props = JSON.parse(JSON.stringify(commonMultiSeriesProps(loading)));
  const modified = {
    yaxis: {
      title: {
        text: 'Number of Contacts',
        style: {
          color: 'gray'
        }
      }
    }
  };
  return Object.assign(props, modified);
};

export const legendColors = [
  '#1F77B4',
  '#2E93fA',
  '#488c08',
  '#E91E63',
  '#FF9800',
  '#FF00FF',
  '#000000',
  '#9467BD',
  '#8C564B',
  '#7F7F7F',
  '#BCBD22',
  '#FF7F0E',
  '#AEC7E8',
  '#98df8a',
  '#ff9896',
  '#ff9896',
  '#c49c94'
];
