import { isEmpty } from 'lodash';
import React, { memo, useState } from 'react';
import {
  Button,
  ButtonProps,
  Icon,
  Modal,
  TextArea,
  TextAreaProps
} from 'semantic-ui-react';

import { isNullOrUndefined } from 'utils/utilFunctions';

import { QueryStorage } from 'interfaces';

import './styles.less';

type Props = {
  readonly summaryResponse: QueryStorage;
  readonly feedback: string;
  readonly modalVisible: boolean;
  readonly setFeedback: (value: string) => void;
  readonly handleFavorite: () => void;
  readonly handleFeedback: (value: string, message: string) => void;
  readonly setModalVisible: (value: boolean) => void;
};
export const ChatButtonContainer: React.FC<Props> = memo(
  ({
    summaryResponse,
    feedback,
    modalVisible,
    setFeedback,
    handleFavorite,
    handleFeedback,
    setModalVisible
  }) => {
    const [feedbackMessage, setFeedbackMessage] = useState<string>('');
    if (isEmpty(summaryResponse.taskUuid)) {
      return (
        <Button
          data-testid='favorite-btn'
          icon
          floated='right'
          basic
          onClick={() => handleFavorite()}
        >
          <span className={summaryResponse.favorite ? 'favorited' : ''}>
            Favorite{' '}
            <Icon
              name='star'
              className={summaryResponse.favorite ? 'favorite-color' : ''}
            />
          </span>
        </Button>
      );
    }
    return (
      <>
        <Button.Group floated='right'>
          <Button
            data-testid='favorite-group'
            basic
            animated='fade'
            onClick={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
              data: ButtonProps
            ) => {
              handleFavorite();
              event.currentTarget.blur();
            }}
          >
            <Button.Content
              hidden
              className={summaryResponse.favorite ? 'favorited' : ''}
            >
              Favorite
            </Button.Content>
            <Button.Content visible>
              <span
                data-testid='favorite-icon'
                className={summaryResponse.favorite ? 'favorited' : ''}
              >
                <Icon
                  name='star'
                  className={summaryResponse.favorite ? 'favorite-color' : ''}
                />
              </span>
            </Button.Content>
          </Button>
          {['positive', 'neutral'].includes(feedback) && (
            <Button
              data-testid='feedback-positive'
              basic
              animated='fade'
              disabled={
                !isNullOrUndefined(feedback) &&
                ['positive', 'negative'].includes(feedback)
              }
              onClick={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                data: ButtonProps
              ) => {
                setFeedback('positive');
                setModalVisible(true);
                event.currentTarget.blur();
              }}
            >
              <Button.Content
                hidden
                className={feedback === 'positive' ? 'favorited' : ''}
              >
                Like
              </Button.Content>
              <Button.Content visible>
                <span className={feedback === 'positive' ? 'favorited' : ''}>
                  <Icon
                    name='thumbs up'
                    className={feedback === 'positive' ? 'feedback-color' : ''}
                  />
                </span>
              </Button.Content>
            </Button>
          )}
          {['neutral', 'negative'].includes(feedback) && (
            <Button
              data-testid='feedback-negative'
              basic
              animated='fade'
              disabled={
                !isNullOrUndefined(feedback) &&
                ['positive', 'negative'].includes(feedback)
              }
              onClick={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                data: ButtonProps
              ) => {
                setFeedback('negative');
                setModalVisible(true);
                event.currentTarget.blur();
              }}
            >
              <Button.Content
                hidden
                className={feedback === 'negative' ? 'favorited' : ''}
              >
                Dislike
              </Button.Content>
              <Button.Content visible>
                <span className={feedback === 'negative' ? 'favorited' : ''}>
                  <Icon
                    name='thumbs down'
                    className={feedback === 'negative' ? 'feedback-color' : ''}
                  />
                </span>
              </Button.Content>
            </Button>
          )}
        </Button.Group>
        <Modal
          data-testid='feedback-modal'
          open={modalVisible}
          closeIcon={true}
          closeOnDimmerClick={false}
          onClose={() => {
            setFeedback('neutral');
            setModalVisible(false);
          }}
          size='tiny'
        >
          <Modal.Header>Give Feedback</Modal.Header>
          <Modal.Content>
            <TextArea
              data-testid='feedback-input'
              placeholder='Tell us your thoughts'
              onChange={(
                event: React.FormEvent<HTMLTextAreaElement>,
                data: TextAreaProps
              ) => setFeedbackMessage(event.currentTarget.value)}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                setFeedback('neutral');
                setModalVisible(false);
              }}
            >
              Cancel
            </Button>
            <Button
              data-testid='feedback-submit'
              primary
              onClick={() => {
                handleFeedback(feedback, feedbackMessage);
                setFeedbackMessage('');
              }}
            >
              Submit
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
);
