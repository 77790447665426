import axios from 'axios';

import { storage } from 'utils';

import { KnowledgeBase, QueryStorage, SummaryResponse } from 'interfaces';

export const getTitleOfDoc = (htmlDoc: any) => {
  const titleEl = htmlDoc.querySelector('title');
  if (!titleEl) {
    return null;
  }
  return titleEl.innerText;
};

export const currentKnowledgeBase = 'knowledgeBases';
export const botQueries = 'botQueries';

export const getAttrOfDocElement = (
  htmlDoc: any,
  query: string,
  attr: string
) => {
  const el = htmlDoc.querySelector(query);
  if (!el) {
    return null;
  }
  return el.getAttribute(attr);
};

export const getLinkData = async (url: string) => {
  const resp = await axios.get(url);
  const htmlDoc = new DOMParser().parseFromString(resp.data, 'text/html');
  return {
    title: getTitleOfDoc(htmlDoc),
    url,
    description: getAttrOfDocElement(
      htmlDoc,
      `meta[name='description']`,
      'content'
    )
  };
};

export const storeBases = (knowledgeBases: KnowledgeBase[]) =>
  storage.set(currentKnowledgeBase, JSON.stringify(knowledgeBases));

export const getBases = () =>
  JSON.parse(storage.get(currentKnowledgeBase)) || [];

export const getStoredBaseQueryResponse = (
  query?: string,
  corpus?: string
): QueryStorage[] => {
  const corpusTree = JSON.parse(storage.get(botQueries)) || [];
  if (query) {
    return corpusTree.filter(
      (q: QueryStorage) =>
        q.query === query && (corpus ? q.corpusName === corpus : true)
    );
  }
  return corpusTree;
};

export const storeBaseQueryResponse = (
  corpusName: string,
  query: string,
  favorite: boolean = false,
  response: SummaryResponse,
  taskUuid: string = '',
  feedback: string = 'neutral',
  updateFavorites?: boolean,
  replaceResponse?: boolean,
  updateFeedback?: boolean
) => {
  const allResponses = getStoredBaseQueryResponse();
  const responseQueryIndex = getStoredBaseQueryResponse().findIndex(
    (q: QueryStorage) => q.query === query
  );
  if (responseQueryIndex !== -1) {
    if (updateFavorites || replaceResponse || updateFeedback) {
      if (updateFavorites) {
        allResponses[responseQueryIndex].favorite = favorite;
      } else if (replaceResponse) {
        allResponses[responseQueryIndex].corpusName = corpusName;
        allResponses[responseQueryIndex].response = response;
        allResponses[responseQueryIndex].taskUuid = taskUuid;
        allResponses[responseQueryIndex].feedback = feedback;
      } else if (updateFeedback) {
        allResponses[responseQueryIndex].feedback = feedback;
      }
      storage.set(botQueries, JSON.stringify(allResponses));
    }
  } else {
    storage.set(
      botQueries,
      JSON.stringify([
        ...getStoredBaseQueryResponse(),
        {
          favorite,
          corpusName,
          query,
          response,
          taskUuid,
          feedback
        }
      ])
    );
  }
};
