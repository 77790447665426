import React, { memo } from 'react';

import { StepItem } from 'types';

import { Steps } from './Steps';

type Props = {
  readonly activeSteps: number;
  readonly completedSteps: number;
  readonly stepItems: StepItem[];
  readonly onStepClick: (value: number) => void;
};
export const ProgressStepper: React.FC<Props> = memo(
  ({ activeSteps, stepItems, completedSteps, onStepClick }) => {
    return (
      <div>
        <div>
          <Steps
            stepsItems={stepItems}
            activeSteps={activeSteps}
            completedSteps={completedSteps}
            onStepClick={onStepClick}
          />
        </div>
      </div>
    );
  }
);
