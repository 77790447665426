/*
 * Copyright 2024-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */

import React, { useEffect } from 'react';

import NotificationBanner from 'components/NotificationBanner/NotificationBanner';
import { AnnouncementLevel, useAnnouncements } from 'hooks/useAnnouncements';
import { usePersistentState } from 'hooks/usePersistentState';

const Announcements = () => {
  const announcements = useAnnouncements();
  const isInitialRender = React.useRef(true);
  const { state, setState } = usePersistentState<string[], string>(
    'closedAnnouncements',
    [],
    JSON.stringify,
    JSON.parse
  );

  useEffect(() => {
    if (announcements.isSuccess && isInitialRender.current) {
      const newAnnouncementUuids = new Set(
        announcements.data.announcements.map(a => a.uuid)
      );
      isInitialRender.current = false;
      setState(state.filter(uuid => newAnnouncementUuids.has(uuid)));
    }
  }, [
    announcements.isSuccess,
    announcements.data,
    isInitialRender,
    setState,
    state
  ]);

  const announcementLevelToNotificationLevel = (level: AnnouncementLevel) =>
    level === 'danger' ? 'error' : level;

  const onCloseAnnouncement = (uuid: string) => {
    // append the uuid to the end if not already present in state
    if (
      state.find(announcementUuid => announcementUuid === uuid) === undefined
    ) {
      setState([...state, uuid]);
    }
  };

  if (!announcements.isSuccess) return null;

  return (
    <div>
      {announcements.isSuccess &&
        announcements.data.announcements
          .filter(a => state.find(uuid => uuid === a.uuid) === undefined)
          .map(announcement => (
            <NotificationBanner
              key={announcement.uuid}
              header={announcement.title}
              message={announcement.contents}
              type={announcementLevelToNotificationLevel(announcement.level)}
              onDismiss={() => onCloseAnnouncement(announcement.uuid)}
            />
          ))}
    </div>
  );
};

export default Announcements;
