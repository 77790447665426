import React, { memo } from 'react';

import { assets } from 'constants/assets';

import './styles.less';

const { apstraLogo } = assets.images;
export const ApstraLogo: React.FC = memo(() => (
  <div className='cl-apstra-logo'>
    <img src={apstraLogo.src} alt={apstraLogo.alt} />
  </div>
));
