import { paginationUtils } from 'utils/pagination';

import { ContactUsage, PaginationFilters } from 'types';

export const filterContacts = (
  contacts: ContactUsage[],
  searchText: string,
  pagination: PaginationFilters
): {
  total: number;
  items: ContactUsage[];
} => {
  const items = contacts.filter(contact => {
    let valid = true;
    if (searchText) {
      const text = searchText.toLowerCase();
      valid =
        valid &&
        (contact.name.toLowerCase().includes(text) ||
          contact.email.toLowerCase().includes(text) ||
          contact.company.toLowerCase().includes(text) ||
          contact.createdBy.toLowerCase().includes(text));
    }
    return valid;
  });

  const activeItems = paginationUtils.getActiveItems({
    ...pagination,
    items
  });
  return {
    total: items.length,
    items: activeItems
  };
};
