import React, { memo } from 'react';
import { Divider, Segment } from 'semantic-ui-react';

import { AosLogo } from 'components';

import { EulaAcceptForm } from './EulaAcceptForm';
import { EulaContent } from './EulaContent';

import './styles.less';

type Props = {
  onAccept: () => void;
  onCancel?: () => void;
};

export const Eula: React.FC<Props> = memo(({ onAccept, onCancel }) => {
  return (
    <div className='cl-eula'>
      <div className='wrapper'>
        <AosLogo nowrap />
        <Segment className='eula-content-wrapper'>
          <EulaContent />
          <Divider />
          <EulaAcceptForm onAccept={onAccept} onCancel={onCancel} />
        </Segment>
      </div>
    </div>
  );
});
