import { AxiosRequestConfig } from 'axios';

import { interpolateRoute } from 'utils';

import { apiPrefix, apiRoutes } from 'constants/api-routes';

import { MilestoneListResponse } from '../interfaces/response/milestone-list-response';
import { MilestoneStatus } from '../interfaces/response/milestone-status';
import { TopologyMilestoneApplied } from '../interfaces/response/topology-milestone-applied';
import { API } from './api';

const getAll = (
  topologyUuid: string,
  config?: AxiosRequestConfig
): Promise<MilestoneListResponse> => {
  return API.get<MilestoneListResponse>(
    apiPrefix(
      interpolateRoute(apiRoutes.milestones, {
        uuid: topologyUuid
      })
    ),
    config
  );
};

const getStatus = (
  topologyUuid: string,
  config?: AxiosRequestConfig
): Promise<MilestoneStatus> => {
  return API.get<MilestoneStatus>(
    apiPrefix(
      interpolateRoute(apiRoutes.milestoneStatus, {
        uuid: topologyUuid
      })
    ),
    config
  );
};

const apply = (
  topologyUuid: string,
  milestoneName: string
): Promise<TopologyMilestoneApplied> => {
  return API.post<TopologyMilestoneApplied>(
    apiPrefix(
      interpolateRoute(apiRoutes.applyMilestone, {
        uuid: topologyUuid,
        name: milestoneName
      })
    ),
    null
  );
};

export const milestonesApi = {
  apply,
  getAll,
  getStatus
};
