import React, { memo } from 'react';
import { Redirect } from 'react-router';

import { authUtils } from 'utils';

import { routes } from 'constants/routing';
import { useRouter } from 'hooks/useRouter';
import { LoggedInUser } from 'types';

export const AuthorizedView: React.FC = memo(() => {
  const router = useRouter();
  const { token, error } = router.query as { token: string; error: string };
  const user: LoggedInUser = authUtils.decodeToken(token);

  if (!token || !user || +new Date(user.expires_on) < +new Date()) {
    // todo: display message 'token invalid or expired'
    authUtils.logout();
    return <Redirect to={{ pathname: routes.login.path, state: { error } }} />;
  }

  return (
    <Redirect to={{ pathname: routes.eulaAutorized.path, state: { token } }} />
  );
});
