import { Route } from 'react-router';

import {
  AuthForbiddenRoute,
  PrivateRoute,
  PublicWithEulaRoute
} from 'components';
import { routes } from 'constants/routing';
import {
  AdminStatsView,
  AuthorizedView,
  BuildserverList,
  ContactDetailsView,
  ContactReportsView,
  ContactsView,
  CreateContactView,
  CreateLabWizard,
  CreateTopologyView,
  DashboardView,
  EmailLoginView,
  EulaAuthorizedView,
  EulaNotAuthorizedView,
  LabsView,
  LabView,
  LoginView,
  ManageTemplates,
  QuotasView,
  ResetPasswordView,
  TemplatesView,
  TopologiesView,
  TopologyReportsView,
  TopologyView
} from 'views';
import { KnowledgeBaseSearchView } from 'views/KnowledgeBaseSearch';
import { ManageTagsView } from 'views/TagsView/ManageTagsView';

import { ApstraSizingCalculatorView } from './views/ApstraSizingCalculator/ApstraSizingCalculator';
import { PartnersView } from './views/Partners';

export const appRoutes = [
  {
    path: routes.authorized.path,
    component: AuthorizedView,
    routeComponent: AuthForbiddenRoute
  },
  {
    path: routes.login.path,
    component: LoginView,
    routeComponent: AuthForbiddenRoute
  },
  {
    path: routes.emailLogin.path,
    component: EmailLoginView,
    routeComponent: AuthForbiddenRoute
  },
  {
    path: routes.resetPassword.path,
    component: ResetPasswordView,
    routeComponent: AuthForbiddenRoute
  },
  {
    path: routes.dashboard.path,
    component: DashboardView,
    routeComponent: PrivateRoute
  },
  {
    path: routes.buildservers.path,
    component: BuildserverList,
    routeComponent: PrivateRoute
  },
  {
    path: routes.contacts.path,
    component: ContactsView,
    routeComponent: PrivateRoute
  },
  {
    path: routes.createUserContact.path,
    component: CreateContactView,
    routeComponent: PrivateRoute
  },
  {
    path: routes.contactDetails.path,
    component: ContactDetailsView,
    routeComponent: PrivateRoute
  },
  {
    path: routes.manageTags.path,
    component: ManageTagsView,
    routeComponent: PrivateRoute
  },
  {
    path: routes.createTopology.path,
    component: CreateTopologyView,
    routeComponent: PrivateRoute
  },
  {
    path: routes.createTopologyForUsers.path,
    component: CreateTopologyView,
    routeComponent: PrivateRoute
  },
  {
    path: routes.templates.path,
    component: TemplatesView,
    routeComponent: PrivateRoute
  },
  {
    path: routes.createTemplates.path,
    component: ManageTemplates,
    routeComponent: PrivateRoute
  },
  {
    path: routes.editTemplates.path,
    component: ManageTemplates,
    routeComponent: PrivateRoute
  },
  {
    path: routes.topologies.path,
    component: TopologiesView,
    routeComponent: PrivateRoute
  },
  {
    path: routes.topology.path,
    component: TopologyView,
    routeComponent: PublicWithEulaRoute
  },
  {
    path: routes.eulaAutorized.path,
    component: EulaAuthorizedView,
    routeComponent: Route
  },
  {
    path: routes.eulaNotAuthorized.path,
    component: EulaNotAuthorizedView,
    routeComponent: Route
  },
  {
    path: routes.adminStats.path,
    component: AdminStatsView,
    routeComponent: PrivateRoute
  },
  {
    path: routes.topologyReports.path,
    component: TopologyReportsView,
    routeComponent: PrivateRoute
  },
  {
    path: routes.contactReports.path,
    component: ContactReportsView,
    routeComponent: PrivateRoute
  },
  {
    path: routes.quotas.path,
    component: QuotasView,
    routeComponent: PrivateRoute
  },
  {
    path: routes.chat.path,
    component: KnowledgeBaseSearchView,
    routeComponent: PrivateRoute
  },
  {
    path: routes.apstraSizingCalculator.path,
    component: ApstraSizingCalculatorView,
    routeComponent: PrivateRoute
  },
  {
    path: routes.partners.path,
    component: PartnersView,
    routeComponent: PrivateRoute
  },
  {
    path: routes.createLabs.path,
    component: CreateLabWizard,
    routeComponent: PrivateRoute
  },
  {
    path: routes.labs.path,
    component: LabsView,
    routeComponent: PrivateRoute
  },
  {
    path: routes.lab.path,
    component: LabView,
    routeComponent: PrivateRoute
  }
];
