import React, { useState } from 'react';
import { Confirm, Divider, Header, Icon, Table } from 'semantic-ui-react';

import { CreateQuotaModal } from 'components/Quotas/QuotaModal';
import useQuotaMutations from 'hooks/useQuotaMutations';
import { Quota, QuotaList } from 'types/quota';

import './styles.less';

type Props = {
  readonly type: string;
  readonly quotaType: string;
  readonly quotaList: QuotaList | undefined;
  readonly resourceLabel: string;
  readonly showCurrentValue: boolean;
};

export const QuotaTable: React.FC<Props> = (props: Props) => {
  const { useDeleteQuota } = useQuotaMutations();
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState<boolean>(
    false
  );
  const [currentQuota, setCurrentQuota] = useState<Quota>({} as Quota);

  const quotaList = props.quotaList?.quotas ?? [];

  const quotas: QuotaList = {
    quotas: quotaList.filter(quota => quota.resourceType === props.type)
  };

  const deleteQuota = (quota: Quota) => {
    setCurrentQuota(quota);
    setConfirmDeleteModalOpen(true);
  };

  const handleEdit = (quota: Quota) => {
    setCurrentQuota(quota);
    setEditModalOpen(true);
  };

  return (
    <div className='cl-quota-table'>
      <Header>
        <h2>{props.quotaType}</h2>
      </Header>
      <Divider />
      <Table celled stackable textAlign='center'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell collapsing>#</Table.HeaderCell>
            <Table.HeaderCell collapsing>
              {props.resourceLabel}
            </Table.HeaderCell>
            <Table.HeaderCell collapsing>Limit</Table.HeaderCell>
            {props.showCurrentValue && (
              <Table.HeaderCell collapsing>Current Value</Table.HeaderCell>
            )}
            <Table.HeaderCell collapsing>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {quotas?.quotas.map((quota, index) => (
            <Table.Row key={quota.id}>
              <Table.Cell collapsing>{index + 1}</Table.Cell>
              <Table.Cell collapsing>
                {quota.isDefault ? 'DEFAULT' : quota.resourceName}
              </Table.Cell>
              <Table.Cell collapsing>{quota.quotaLimit}</Table.Cell>
              {props.showCurrentValue && (
                <Table.Cell collapsing>{quota.currentValue}</Table.Cell>
              )}
              <Table.Cell collapsing>
                <Icon name='edit' onClick={() => handleEdit(quota)} />
                <Icon
                  name='trash alternate'
                  onClick={() => deleteQuota(quota)}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <CreateQuotaModal
        onClose={setEditModalOpen}
        onQuotaChange={setCurrentQuota}
        open={editModalOpen}
        selectedQuota={currentQuota}
      />
      <Confirm
        size='mini'
        header='Delete quota'
        content='Are you sure you want to delete this quota?'
        open={confirmDeleteModalOpen}
        onCancel={() => setConfirmDeleteModalOpen(false)}
        onConfirm={() => {
          useDeleteQuota.mutate(currentQuota);
          setConfirmDeleteModalOpen(false);
        }}
        confirmButton='Delete'
      />
    </div>
  );
};
