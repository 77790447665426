import React, { memo } from 'react';

import { StepItem } from 'types';

import { Step } from '../Step/Step';

import './styles.less';

type Props = {
  readonly stepsItems: StepItem[];
  readonly activeSteps: number;
  readonly completedSteps: number;
  readonly onStepClick: (value: number) => void;
};
export const Steps: React.FC<Props> = memo(
  ({ stepsItems, activeSteps, completedSteps, onStepClick }) => {
    return (
      <div className='steps'>
        {stepsItems.map((step, index) => {
          let status = '';
          if (index < activeSteps) {
            status = 'completed';
          }
          if (index > activeSteps) {
            status = 'incomplete';
          }
          if (index === activeSteps) {
            status = 'current';
          }
          return (
            <Step
              key={index}
              stepIndex={index}
              status={status}
              stepItem={step}
              disableStepClick={index > completedSteps}
              onStepClick={onStepClick}
            />
          );
        })}
      </div>
    );
  }
);
