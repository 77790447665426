import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { QuerySummary } from 'interfaces';

export interface QueryState {
  requestQuery: QuerySummary;
}

const initialState: QueryState = {
  requestQuery: { corpusName: '', userQuery: '' }
};

export const chatSlice = createSlice({
  name: 'chatbot',
  initialState,
  reducers: {
    setRequestQuery: (
      state: QueryState,
      action: PayloadAction<QuerySummary>
    ) => {
      state.requestQuery = action.payload;
    }
  }
});

export const { setRequestQuery } = chatSlice.actions;

export default chatSlice.reducer;
