import { orderBy } from 'lodash';
import React, { Fragment, memo, useEffect, useState } from 'react';
import {
  Loader,
  Message,
  Pagination,
  PaginationProps,
  Segment,
  StrictBreadcrumbSectionProps
} from 'semantic-ui-react';

import { authUtils, notifier } from 'utils';
import { paginationUtils } from 'utils/pagination';

import { tagsApi } from 'api/tag';
import { DEFAULT_PAGE_SIZE } from 'constants/pagination';
import { routes } from 'constants/routing';
import { UserRole } from 'enums';
import { SortField, SortOrder } from 'interfaces/queryParams';
import { MainLayout } from 'layouts';
import { PaginationFilters } from 'types';
import { Tag } from 'types/Tags/tags';

import { SearchTags } from './SearchTags';
import { TagActionMenu } from './TagActionMenu';
import { TagsTable } from './TagsTable';

import './styles.less';

const filterTags = (
  tags: Tag[],
  searchText: string,
  pagination: PaginationFilters
): {
  total: number;
  items: Tag[];
} => {
  const items = tags.filter(tag => {
    let valid = true;
    if (searchText) {
      const text = searchText.toLowerCase();
      valid =
        valid &&
        (tag.name.toLowerCase().includes(text) ||
          tag.value.toLowerCase().includes(text));
    }
    return valid;
  });

  const activeItems = paginationUtils.getActiveItems({
    ...pagination,
    items
  });
  return {
    total: items.length,
    items: activeItems
  };
};

export const ManageTagsView: React.FC = memo(() => {
  const [originalTags, setOriginalTags] = useState<Tag[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [refetchData, setRefetch] = useState<boolean>(true);

  const [addTag, setAdd] = useState<boolean>(false);
  const [sortColumn, setColumn] = useState<string>('name');
  const [sortOrder, setOrder] = useState<SortOrder>('ascending');
  const sort: SortField = { sortColumn, sortOrder };

  const [tag, setTag] = useState<Tag>({} as Tag);
  const [selectedTag, updateSelectedTag] = useState<Tag>({} as Tag);
  const [searchText, setSearchText] = useState<string>('');
  const [pagination, setPagination] = useState<PaginationFilters>({
    activePage: 1,
    pageSize: DEFAULT_PAGE_SIZE
  });

  const isAdmin = authUtils.getLoggedInUser().role === UserRole.Admin;
  const infoBannerText = isAdmin
    ? 'Now you can add, edit, and delete tags!'
    : 'Only Admins can create, modify or delete tags.';

  const handleSelect = (t: Tag) => {
    setAdd(false);
    setTag(t);
    updateSelectedTag(t);
  };

  const onSort = (clickedColumn: string) => {
    const { sortColumn: col, sortOrder: order } = sort;

    let newOrder: SortOrder =
      order === 'ascending' ? 'descending' : 'ascending';
    if (col !== clickedColumn) {
      newOrder = 'ascending';
    }
    const direction = newOrder === 'ascending' ? 'asc' : 'desc';

    setOriginalTags(orderBy(originalTags, clickedColumn, direction));
    setColumn(clickedColumn);
    setOrder(newOrder);
  };

  useEffect(() => {
    const getTags = async () => {
      try {
        setRefetch(false);
        setLoading(true);
        const response = await tagsApi.getAll();
        setOriginalTags(response.tags);
        setLoading(false);
      } catch (err) {
        notifier.requestFailed(err);
        setError(true);
      }
    };
    if (refetchData) {
      getTags();
    }
  }, [refetchData]);

  const breadcrumbSections: StrictBreadcrumbSectionProps[] = [
    {
      content: routes.manageTags.label,
      active: true
    }
  ];
  const { total, items } = filterTags(originalTags, searchText, pagination);
  const totalPages = paginationUtils.getTotalPages({
    pageSize: pagination.pageSize,
    totalCount: total
  });
  return (
    <MainLayout breadcrumbSections={breadcrumbSections}>
      {originalTags && !loading && (
        <Fragment>
          <div>
            <div className='access-info'>
              <h2>Manage Tags</h2>
            </div>
            <Segment>
              <Message info icon={'info'} content={infoBannerText} />
              <TagActionMenu
                tag={tag}
                selectedTag={selectedTag}
                addTag={addTag}
                setTag={(value: Tag) => setTag(value)}
                updateSelectedTag={(value: Tag) => updateSelectedTag(value)}
                setRefetch={(value: boolean) => setRefetch(value)}
                setAdd={(value: boolean) => setAdd(value)}
              />
            </Segment>
          </div>
        </Fragment>
      )}
      {error && <h2>{'Tags Not Found'}</h2>}
      {loading && <Loader active />}
      <Fragment>
        <Segment>
          <SearchTags searchText={searchText} setSearchText={setSearchText} />
          <TagsTable
            tags={items}
            selectedTag={selectedTag}
            handleSelect={handleSelect}
            column={sort.sortColumn}
            direction={sort.sortOrder}
            onSort={onSort}
          />
          {totalPages > 0 && (
            <div className='pagination-wrapper'>
              <Pagination
                activePage={pagination.activePage}
                totalPages={totalPages}
                pointing
                secondary
                onPageChange={(
                  event: React.MouseEvent<HTMLAnchorElement>,
                  data: PaginationProps
                ) =>
                  setPagination({
                    ...pagination,
                    activePage: data.activePage as number
                  })
                }
              />
            </div>
          )}
        </Segment>
      </Fragment>
    </MainLayout>
  );
});
