export interface BlueprintTestCaseOutputType {
  apstra_controller_vm: {
    min_vcpu_count: number;
    min_mem_gb: number;
    min_disk_gb: string;
  };
  number_of_workers: {
    number_of_devices: number;
    number_of_vm: number;
  };
  apstra_worker_vms: {
    min_vcpu_count: number;
    min_mem_gb: number;
    min_disk_gb: string;
  };
}

export interface FlowSizingOutputType {
  number_of_managed_devices?: number;
  flow_size?: string;
  server_size?: string;
}

export enum ApstraControllerVMSizing {
  XXSMALL = 0,
  XSMALL = 1,
  SMALL = 2,
  MEDIUM = 3,
  LARGE = 4,
  XLARGE = 5
}

export type Survey = {
  number_of_fabrics?: string;
  design_type?: string;
  managed_devices_count?: string;
  threeStageFabricType?: string;
  clos_single_rack_count?: string;
  clos_dual_rack_count?: string;
  fiveStageFabricType?: string;
  '5_stage_clos_single_rack_count'?: string;
  '5_stage_clos_dual_rack_count'?: string;
  '3_stage_clos_avg_generic_systems_per_rack_count'?: string;
  '5_stage_clos_avg_generic_systems_per_rack_count'?: string;
  '5_stage_pod_count'?: string;
  evpn_rz_count?: string;
  vxlan_vn_count_total?: string;
  vxlan_endpoint_count?: string;
  has_rack_local?: string;
  rack_local_vns_count?: string;
  rack_local_endpoint_count?: string;
  iba_vmware?: string;
  iba_scale?: string;
  is_evpn?: boolean;
  clos_avg_generic_systems_per_rack_count?: number;
  result?: string;
};
