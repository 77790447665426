import React, { memo } from 'react';
import { Label } from 'semantic-ui-react';

import './styles.less';

type Props = {
  readonly deployedStatus: string;
};

export const DeployedLabel: React.FC<Props> = memo(({ deployedStatus }) => {
  const deployedClassname =
    deployedStatus === 'deployed' ? 'deployed-color' : '';
  return (
    <Label
      className={`blueprint-property ${deployedClassname}`}
      content={deployedStatus}
    />
  );
});
