import { isEmpty } from 'lodash';
import React, { memo } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { TopologyStatus } from 'utils';

import { TopologyDetails } from 'types';

type Props = {
  readonly topologies: TopologyDetails[];
  readonly visible: boolean;
};

export const LabDetailsOverlay: React.FC<Props> = memo(
  ({ topologies, visible }) => {
    const topologyInProgress = topologies.some(
      topology => topology.state === TopologyStatus.DEPLOY_IN_PROGRESS
    );
    const topologyErrored = topologies.some(
      topology => topology.state === TopologyStatus.ERROR
    );
    const topologyDown = topologies.some(
      topology => topology.state === TopologyStatus.DOWN
    );
    const topologyShutdown = topologies.some(
      topology => topology.state === TopologyStatus.SHUTDOWN_IN_PROGRESS
    );
    const getTopologyStatusMessage = () => {
      if (topologyInProgress)
        return 'Your topology is being deployed, please come back in 30-40 minutes to start your lab';
      if (topologyErrored)
        return 'Topology deployment failed, please try creating new lab.';
      if (topologyDown)
        return 'Topology either expired or was removed, please try creating new lab.';
      if (topologyShutdown)
        return 'Topology is being deleted, please try creating new lab.';
      if (isEmpty(topologies))
        return 'No topologies available, please try creating new lab.';
      return '';
    };
    return (
      <>
        {visible && (
          <Dimmer
            className='overlay-lab-dimmer'
            active
            data-testid='deploy-lab-overlay'
          >
            {topologyInProgress && (
              <Loader inline='centered' size='large' indeterminate>
                {getTopologyStatusMessage()}
              </Loader>
            )}
            {!topologyInProgress && getTopologyStatusMessage()}
          </Dimmer>
        )}
      </>
    );
  }
);
