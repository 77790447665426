import { isEmpty } from 'lodash';

import { compareDates, groupByProperty } from 'utils';

import { ChartDictValueType, TimeSeries } from 'types';

export const getTotalContacts = (response: TimeSeries[]) => {
  let temp = {} as ChartDictValueType;
  response.forEach(c => {
    if (isEmpty(temp)) {
      temp = {
        timestamp: c.timestamp,
        value: c.value
      };
    } else {
      if (compareDates(temp.timestamp, c.timestamp)) {
        temp = {
          timestamp: c.timestamp,
          value: c.value
        };
      }
    }
  });
  return temp.value ? Math.round(temp.value) : 0;
};

export const getContactsPerPCSeries = (utilization: TimeSeries[]) => {
  return groupByProperty(utilization, 'partner_company');
};

export const getContactsPerDepartmentSeries = (utilization: TimeSeries[]) => {
  return groupByProperty(utilization, 'department');
};
