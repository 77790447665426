import React, { memo, useState } from 'react';
import { Form, Icon } from 'semantic-ui-react';

import { useGetValidRoles } from 'hooks/useGetValidRoles';
import useQuotaMutations from 'hooks/useQuotaMutations';
import { Quota } from 'types/quota';
import { Tag } from 'types/tag';

import { useGetTags } from 'hooks/useGetTags';

type Props = {
  readonly currentQuota: Quota;
  readonly onChange: (quota: Quota) => void;
  readonly onClose: (value: boolean) => void;
};

export const CreateQuotaForm: React.FC<Props> = memo(
  ({ currentQuota, onChange, onClose }) => {
    const handleChange = (field: string, value: string | boolean) => {
      onChange({ ...(currentQuota || {}), [field]: value });
    };
    const availableOptions = [
      { text: 'contact', value: 'contact' },
      { text: 'role', value: 'role' },
      { text: 'department', value: 'department' }
    ];
    const { useUpdateQuota } = useQuotaMutations();
    const [selectedResourceType, setResourceType] = useState(
      currentQuota.resourceType
        ? currentQuota.resourceType
        : availableOptions[0].value
    );
    const fetchedValidRoles = useGetValidRoles().data;
    const roles = fetchedValidRoles ? fetchedValidRoles.roles : [];
    const fetchedValidTags = useGetTags('department').data;
    const tags = fetchedValidTags ? fetchedValidTags.tags : [];
    const saveQuota = (quota: Quota) => {
      if (!quota.resourceType) {
        quota.resourceType = selectedResourceType;
      }
      useUpdateQuota.mutate(quota);
      onChange({} as Quota);
      onClose(false);
    };
    return (
      <div>
        <Form onSubmit={() => saveQuota(currentQuota)}>
          <Form.Select
            required
            label='Choose Resource Type'
            fluid
            placeholder='Select'
            text={selectedResourceType}
            value={selectedResourceType}
            options={availableOptions}
            onChange={(event, data) => {
              setResourceType(data.value as string);
              handleChange('resourceType', data.value as string);
              handleChange('resourceName', '');
            }}
          />
          {selectedResourceType === 'contact' && (
            <Form.Input
              label='Resource Name'
              required
              placeholder='Input Resource Name'
              value={currentQuota.resourceName || ''}
              disabled={currentQuota.isDefault}
              autoFocus
              onChange={event =>
                handleChange('resourceName', event.target.value)
              }
            />
          )}
          {selectedResourceType === 'role' && (
            <Form.Dropdown
              label='Resource Name'
              required
              selection
              placeholder='Input Resource Name'
              value={currentQuota.resourceName || ''}
              disabled={currentQuota.isDefault}
              autoFocus
              options={roles.map((option: string) => {
                return {
                  key: option,
                  text: option,
                  value: option
                };
              })}
              onChange={(event, data) => {
                handleChange('resourceName', data.value as string);
              }}
            />
          )}
          {selectedResourceType === 'department' && (
            <Form.Dropdown
              label='Resource Name'
              required
              selection
              placeholder='Input Resource Name'
              value={currentQuota.resourceName || ''}
              disabled={currentQuota.isDefault}
              autoFocus
              options={tags.map((option: Tag) => {
                return {
                  key: option.value,
                  text: option.value,
                  value: option.value
                };
              })}
              onChange={(event, data) => {
                handleChange('resourceName', data.value as string);
              }}
            />
          )}
          <Form.Input
            label='Quota Limit'
            required
            type='number'
            placeholder='Input Quota Limit'
            value={currentQuota.quotaLimit || ''}
            autoFocus
            onChange={event => handleChange('quotaLimit', event.target.value)}
          />
          <Form.Checkbox
            label='Set Quota as Default'
            checked={currentQuota.isDefault}
            onChange={(event, data) =>
              handleChange('isDefault', data.checked as boolean)
            }
          />
          <div className='actions'>
            <Form.Button primary>
              <Icon className='button' name='add circle' />
              Save Quota
            </Form.Button>
          </div>
        </Form>
      </div>
    );
  }
);
