import { Moment } from 'moment';
import React, { memo } from 'react';
import { DateTimeInput } from 'semantic-ui-calendar-react';
import { Icon } from 'semantic-ui-react';

import './styles.less';

type Props = {
  readonly placeholderText: string;
  readonly setDateTimeValue: (value: any) => void;
  readonly pickerType: string;
  readonly dateTimeValue: string;
  readonly minDate?: string | Moment | Date;
};

export const DatePicker: React.FC<Props> = memo(
  ({
    placeholderText,
    setDateTimeValue,
    pickerType,
    dateTimeValue,
    minDate
  }) => {
    return (
      <DateTimeInput
        placeholder={placeholderText}
        popupPosition='bottom left'
        className='calendar-input'
        name={pickerType}
        minDate={minDate}
        closable
        clearIcon={<Icon name='remove' color='red' />}
        animation={'' as any}
        hideMobileKeyboard
        dateTimeFormat='MM-DD-YYYY HH:mm'
        preserveViewMode={false}
        value={dateTimeValue}
        autoComplete='off'
        onChange={(e: React.SyntheticEvent<HTMLElement>, data: any) =>
          setDateTimeValue(data)
        }
      />
    );
  }
);
