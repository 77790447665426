import { nanoid } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Card,
  Icon,
  Pagination,
  PaginationProps,
  Segment
} from 'semantic-ui-react';

import {
  getStoredBaseQueryResponse,
  paginationUtils,
  storeBaseQueryResponse
} from 'utils';
import { isNullOrUndefined } from 'utils/utilFunctions';

import { getActiveItems } from 'components/TopologyReports/UsageTable/ReportFilterHelpers';
import { QueryPanel, QueryStorage, QuerySummary } from 'interfaces';
import { PaginationFilters } from 'types';

import { setRequestQuery } from 'features/chatbot';

import './styles.less';

type Props = {
  readonly segmentType: string;
  readonly inputQuery: QuerySummary;
  readonly setSegmentType: (type: string) => void;
  readonly setInputQuery: (value: QuerySummary) => void;
};

const filterItems = (
  panels: QueryPanel[],
  pagination: PaginationFilters
): {
  total: number;
  items: QueryPanel[];
} => {
  return {
    total: panels.length,
    items: getActiveItems(pagination, panels)
  };
};

export const PastQueries: React.FC<Props> = memo(
  ({ segmentType, inputQuery, setSegmentType, setInputQuery }) => {
    const dispatch = useDispatch();
    const [panels, setPanel] = useState<any>([]);
    const [favorite, setFavorite] = useState<boolean>(false);
    const [pagination, setPagination] = useState<PaginationFilters>({
      activePage: 1,
      pageSize: 8
    });
    const { total, items } = filterItems(panels, pagination);
    const totalPages = paginationUtils.getTotalPages({
      pageSize: pagination.pageSize,
      totalCount: total
    });
    useEffect(() => {
      const getQueries = (queries: QueryStorage[]) => {
        const queryPanels: QueryPanel[] = queries.map((q: QueryStorage) => {
          return {
            key: nanoid(),
            query: q.query,
            response: q.response,
            corpus: q.corpusName,
            favorite: q.favorite,
            feedback: q.feedback
          };
        });
        setPanel(queryPanels);
      };
      const storedQueries = getStoredBaseQueryResponse();
      if (segmentType === 'favorite') {
        const favoriteQueries = storedQueries.filter(
          (q: QueryStorage) => q.favorite
        );
        getQueries(favoriteQueries);
      } else {
        getQueries(storedQueries);
      }
      setFavorite(false);
    }, [segmentType, favorite, setFavorite]);

    if (isEmpty(items)) {
      const queryType =
        segmentType === 'favorite' ? 'favorites' : 'recent searches';
      return <Segment>You don't have any {queryType} yet.</Segment>;
    }

    const handleFavorite = (panel: QueryPanel) => {
      storeBaseQueryResponse(
        panel.corpus,
        panel.query,
        !panel.favorite,
        panel.response,
        '',
        'neutral',
        true,
        false,
        false
      );
      setFavorite(true);
    };
    return (
      <>
        <Card.Group className='pad-cards'>
          {items.map((panel: QueryPanel) => (
            <Card
              raised
              key={panel.key}
              link={true}
              data-testid='card'
              onClick={() => {
                setSegmentType('response');
                dispatch(
                  setRequestQuery({
                    corpusName: panel.corpus,
                    userQuery: panel.query
                  })
                );
                setInputQuery({ ...inputQuery, userQuery: '' });
              }}
            >
              <Card.Content>
                {!isNullOrUndefined(panel.feedback) &&
                  panel.feedback !== 'neutral' && (
                    <Icon
                      size='small'
                      className='align-right feedback-color'
                      name={
                        panel.feedback === 'positive'
                          ? 'thumbs up'
                          : 'thumbs down'
                      }
                    />
                  )}
                <Icon
                  size='small'
                  className='align-right'
                  name={panel.favorite ? 'star' : 'star outline'}
                  color={panel.favorite ? 'yellow' : 'grey'}
                  onClick={(e: any) => {
                    handleFavorite(panel);
                    e.stopPropagation();
                  }}
                />
                <Card.Header data-testid='card-header'>
                  {panel.query}
                </Card.Header>
              </Card.Content>
              <Card.Content>
                <Card.Meta>{panel.corpus}</Card.Meta>
                <Card.Description>{panel.response.summaryMsg}</Card.Description>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
        <div>
          {totalPages > 0 && (
            <div className='pagination'>
              <Pagination
                activePage={pagination.activePage}
                totalPages={totalPages}
                onPageChange={(
                  event: React.MouseEvent<HTMLAnchorElement>,
                  data: PaginationProps
                ) =>
                  setPagination({
                    ...pagination,
                    activePage: data.activePage as number
                  })
                }
              />
            </div>
          )}
        </div>
      </>
    );
  }
);
