/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */

import React from 'react';
import { Message } from 'semantic-ui-react';

export const DomainsHelp: React.FC = () => (
  <p>
    <b>Domains</b> are used by Cloudlabs for user authentication. If a partner
    user's email domain matches one of the domains in the list, they are
    automatically granted access to Cloudlabs. Otherwise, they must be added
    manually using the <b>Add New User</b> menu.
  </p>
);

export const PartnersViewHelpMessage: React.FC = () => {
  return (
    <Message info>
      <h3>Edit partner companies and their domains</h3>
      <DomainsHelp />
      <p>
        Changes introduced here are not applied until the <b>Apply Changes</b>{' '}
        button is pressed.
      </p>
      <p>
        You can see the changes made in the <b>Changes</b> on the right.
      </p>
      <p>
        Use <b>Undo</b> and <b>Redo</b> to revert changes you don't want.
      </p>
    </Message>
  );
};
