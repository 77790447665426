import { isArray } from 'lodash';
import { toast, ToastContent, ToastOptions } from 'react-toastify';

const requestFailed = (errorResponse: any) => {
  try {
    const errors = isArray(errorResponse.errors)
      ? errorResponse.errors
      : errorResponse.response.data.errors;
    const messages = errors.map((err: any) => err.message);
    messages.forEach((message: string) => toast.error(message));
  } catch (err) {
    toast.error(`Unexpected error: ${err}`);
  }
};

const error = (
  { message }: { message: ToastContent },
  options?: ToastOptions
) => {
  toast.error(message, options);
};

const success = (
  { message }: { message: ToastContent },
  options?: ToastOptions
) => {
  toast.success(message, options);
};

export const notifier = {
  requestFailed,
  success,
  error
};
