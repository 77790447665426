import { map } from 'lodash';
import React, { memo } from 'react';
import { Header, Icon, Table } from 'semantic-ui-react';

import { Template } from 'types';

type Props = {
  readonly templates: Template[];
  readonly onDelete: (template: Template) => void;
  readonly onEdit: (template: Template) => void;
};

export const TemplatesTable: React.FC<Props> = memo(
  ({ templates, onDelete, onEdit }) => {
    return (
      <Table celled striped className='cl-templates-table'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{'Name and description'}</Table.HeaderCell>
            <Table.HeaderCell>{'Apstra version'}</Table.HeaderCell>
            <Table.HeaderCell>{'Approved'}</Table.HeaderCell>
            <Table.HeaderCell>{'Deployed'}</Table.HeaderCell>
            <Table.HeaderCell>{'Default Expiration (hours)'}</Table.HeaderCell>
            <Table.HeaderCell>{'Ptest Details'}</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>{'Actions'}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {map(templates, template => {
            return (
              <Table.Row key={template.id}>
                <Table.Cell>
                  <Header as='h3'>{template.name}</Header>
                  <p>{template.description}</p>
                </Table.Cell>
                <Table.Cell collapsing>{template.aosVersion}</Table.Cell>
                <Table.Cell collapsing>
                  {template.approved ? 'Yes' : 'No'}
                </Table.Cell>
                <Table.Cell collapsing>
                  {template.deployed === 'deployed' ? 'Yes' : 'No'}
                </Table.Cell>
                <Table.Cell collapsing>{template.defaultExpiration}</Table.Cell>
                <Table.Cell>
                  <p>
                    Suite: <code>{template.ptestSuite}</code>
                  </p>
                  <p>
                    Args: <code>"{template.ptestArgs}"</code>
                  </p>
                  <p>
                    Image: <code>"{template.ptestImage}"</code>
                  </p>
                  <p>
                    Working Dir: <code>"{template.workingDirectory}"</code>
                  </p>
                </Table.Cell>
                <Table.Cell collapsing textAlign='center'>
                  <Icon
                    link
                    name='edit outline'
                    onClick={() => onEdit(template)}
                  />
                  <Icon
                    link
                    name='trash alternate'
                    onClick={() => onDelete(template)}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }
);
