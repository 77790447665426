import React, { memo } from 'react';
import { Button, Icon, Segment } from 'semantic-ui-react';

import { RouterLink } from 'components/RouterLink';

import './styles.less';

type Props = {
  readonly name: string;
  readonly description: string;
  readonly link: string;
};

export const LabGuideCard: React.FC<Props> = memo(
  ({ name, description, link }) => (
    <Segment className='cl-lab-guide-card'>
      <h3 className='header'>{name}</h3>
      <div className='description'>{description}</div>
      <div className='flex-1' />
      <Button
        as={RouterLink}
        to={link}
        primary
        className='open-btn'
        size='medium'
      >
        <Icon name='triangle right' className='button' />
        {'Open Guide'}
      </Button>
    </Segment>
  )
);
