import React, { memo } from 'react';
import { Card, Icon } from 'semantic-ui-react';

import { Lab } from 'types';

import { TopologyCards } from './TopologyCards';

import './styles.less';

type Props = {
  readonly lab: Lab;
};

export const LabSidebarDetails: React.FC<Props> = memo(({ lab }) => {
  const [isIdVisible, setIsIdVisible] = React.useState('');
  return (
    <div>
      <div className='content-div'>Topologies</div>
      <div>
        {lab.topologies.length === 0 && (
          <div className='topology-card'>
            No topologies are associated with this lab
          </div>
        )}
        {lab.topologies.map(topology => (
          <div key={topology.uuid} className='content-card'>
            <Card link fluid>
              <Card.Header
                className='content-font'
                onClick={() =>
                  setIsIdVisible(
                    isIdVisible === topology.uuid ? '' : topology.uuid
                  )
                }
              >
                <Icon name='sitemap' />
                {topology.name}
              </Card.Header>
            </Card>
            {isIdVisible === topology.uuid && (
              <TopologyCards topology={topology} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
});
