import Markdown from 'markdown-to-jsx';
import React from 'react';
import {
  Icon,
  Message,
  MessageHeader,
  SemanticCOLORS,
  SemanticICONS
} from 'semantic-ui-react';

import './styles.less';

export type NotificationBannerType = 'info' | 'warning' | 'error';

export type NotificationBannerProps = {
  header: string;
  message: string;
  type: NotificationBannerType;
  onDismiss: () => void;
};

const NotificationBanner: React.FC<NotificationBannerProps> = ({
  header,
  message,
  type,
  onDismiss
}) => {
  const notificationSettings: Record<
    string,
    { icon: SemanticICONS; color: SemanticCOLORS }
  > = {
    info: { icon: 'info circle', color: 'green' },
    warning: { icon: 'exclamation triangle', color: 'yellow' },
    error: { icon: 'times circle', color: 'red' }
  };

  return (
    <Message onDismiss={onDismiss} color={notificationSettings[type].color}>
      <i
        className='close icon'
        role='button'
        aria-label={`close notification ${header}`}
        onClick={onDismiss}
      />
      <MessageHeader>
        <Icon name={notificationSettings[type].icon} />
        {header}
      </MessageHeader>
      <Markdown>{message}</Markdown>
    </Message>
  );
};

export default NotificationBanner;
