/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */

import React from 'react';
import { Button, Icon, List } from 'semantic-ui-react';

import { EditableElement, Validator } from './EditableElement';

export interface EditableListProps {
  listItems: string[];
  addItemLabel: string;
  onItemsChanged: (items: string[]) => void;
  validators?: Validator[];
}

const keyForItem = (item: string, index: number): string => {
  return [item.replace(/^[a-zA-Z0-9]/, '_'), index.toString()].join('_');
};

export const EditableList: React.FC<EditableListProps> = ({
  listItems,
  addItemLabel,
  onItemsChanged,
  validators
}) => {
  const addItem = () => {
    onItemsChanged([...listItems, '']);
  };

  const removeItem = (index: number) => {
    const filtered = listItems.filter((item, i) => i !== index);
    onItemsChanged(filtered);
  };

  const editItem = (index: number, value: string) => {
    const newItems = [...listItems];
    newItems[index] = value;
    onItemsChanged(newItems);
  };

  return (
    <div>
      <List ordered>
        {listItems.map((item, index) => (
          <List.Item key={keyForItem(item, index)}>
            <EditableElement
              text={item}
              validators={validators ?? []}
              onChange={value => editItem(index, value)}
              onDelete={() => removeItem(index)}
            />
          </List.Item>
        ))}
      </List>
      <Button
        icon
        labelPosition={'left'}
        color={'green'}
        onClick={() => {
          addItem();
        }}
      >
        <Icon name={'plus'} />
        {addItemLabel}
      </Button>
    </div>
  );
};
