import { Grid } from 'semantic-ui-react';

import React, { memo } from 'react';

import {
  FreeformLabGuideDoc,
  JuniperLabGuideDoc,
  MultiLabGuideDoc
} from 'utils/urls';
import { FREEFORM, JUNIPER } from 'utils/utilFunctions';

import { LabGuideCard } from 'views/Topology/LabGuideCard/LabGuideCard';

import './styles.less';

const cards = [
  {
    name: 'Lab 1 - Intro to Apstra',
    description:
      'Design and build a new Leaf/Spine network with L2 connections to the servers and an L3 fabric.',
    link: JuniperLabGuideDoc().toString()
  }
];

export const getLab1Guide = (topologyName: string) =>
  topologyName.toLowerCase().includes(FREEFORM.toLowerCase())
    ? FreeformLabGuideDoc().toString()
    : topologyName.toLowerCase().includes(JUNIPER.toLowerCase())
    ? JuniperLabGuideDoc().toString()
    : MultiLabGuideDoc().toString();

type Props = {
  readonly topologyName: string;
};

export const InstructionsGuidedLabs: React.FC<Props> = memo(
  ({ topologyName }) => (
    <div className='cl-instructions-guided-labs'>
      <h2 className='header'>{'Instructions & Guided Labs'}</h2>
      <Grid stretched centered>
        <Grid.Row>
          {cards.map(card => (
            <Grid.Column
              key={card.name}
              computer='8'
              largeScreen='4'
              widescreen='4'
            >
              <LabGuideCard
                name={card.name}
                description={card.description}
                link={getLab1Guide(topologyName)}
              />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </div>
  )
);
