export const routes = {
  buildservers: {
    label: 'Buildservers',
    path: '/buildservers'
  },
  createBuildserver: {
    label: 'Create Buildserver',
    path: `/buildservers/create`
  },
  contactDetails: {
    label: 'Contact Details',
    path: '/contact/:id/tab/:active'
  },
  contacts: {
    label: 'User List',
    path: '/contacts'
  },
  manageTags: {
    label: 'Tags',
    path: `/manage/tags`
  },
  createUserContact: {
    label: 'Add New User',
    path: '/contact/create'
  },
  createTopology: {
    label: 'Create Topology',
    path: `/contact/:id/topology/create`
  },
  createTopologyForUsers: {
    label: 'Create Topology',
    path: `/contact/topology/create`
  },
  applyTopologyMilestone: {
    label: 'Apply Milestone',
    path: `/topology/:id/milestone/:name/apply`
  },
  dashboard: {
    label: 'Dashboard',
    path: '/dashboard'
  },
  login: {
    label: null,
    path: '/login'
  },
  emailLogin: {
    label: null,
    path: '/login/email'
  },
  adminStats: {
    label: 'Admin Stats',
    path: '/reports/stats'
  },
  contactReports: {
    label: 'Contact Reports',
    path: '/reports/contacts'
  },
  topologyReports: {
    label: 'Topology Reports',
    path: '/reports/topologies'
  },
  topology: {
    label: 'Topology',
    path: '/topology/:id'
  },
  topologies: {
    label: 'Topologies',
    path: '/topologies'
  },
  eulaAutorized: {
    label: 'EULA',
    path: '/eula-authorized'
  },
  eulaNotAuthorized: {
    label: 'EULA',
    path: '/eula'
  },
  templates: {
    label: 'Templates',
    path: '/templates'
  },
  editTemplates: {
    label: 'Edit Templates',
    path: `/topology/:id/create`
  },
  createTemplates: {
    label: 'Create Templates',
    path: `/templates/create`
  },
  authorized: {
    label: null,
    path: '/authorized'
  },
  resetPassword: {
    label: null,
    path: '/reset-password/:uuid'
  },
  quotas: {
    label: 'Quotas',
    path: '/quotas'
  },
  chat: {
    label: 'Chat Assistant',
    path: '/chat'
  },
  apstraSizingCalculator: {
    label: 'Apstra calculator',
    path: '/calculator'
  },
  partners: {
    label: 'Partner Companies',
    path: '/partners'
  },
  labs: {
    label: 'Lab',
    path: '/labs'
  },
  lab: {
    label: 'Lab Details',
    path: '/labs/:uuid'
  },
  createLabs: {
    label: 'Create Lab',
    path: '/labs/create'
  }
};

export const defaultRoute = routes.dashboard;

export const routesValues = Object.values(routes);
