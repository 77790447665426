import { AxiosRequestConfig } from 'axios';

import { interpolateRoute } from 'utils';

import { apiPrefix, apiRoutes } from 'constants/api-routes';
import { TopologyCreatedResponse, TopologyListResponse } from 'interfaces';
import {
  CreateTopology,
  ExtendDuration,
  Topology,
  UpdateTopology
} from 'types';

import { API } from './api';

const getAll = (config?: AxiosRequestConfig): Promise<TopologyListResponse> => {
  return API.get<TopologyListResponse>(
    apiPrefix(apiRoutes.topologiesWithCreator),
    config
  );
};

const getOne = (
  uuid: string,
  config?: AxiosRequestConfig
): Promise<Topology | null> => {
  return API.get<Topology>(
    apiPrefix(interpolateRoute(apiRoutes.topologyById, { uuid })),
    config
  );
};

const update = (
  uuid: string,
  data: UpdateTopology,
  config?: AxiosRequestConfig
): Promise<UpdateTopology> => {
  return API.patch<UpdateTopology>(
    apiPrefix(interpolateRoute(apiRoutes.topologyById, { uuid })),
    data,
    config
  );
};

const create = (
  data: CreateTopology,
  config?: AxiosRequestConfig
): Promise<TopologyCreatedResponse> => {
  return API.post<TopologyCreatedResponse>(
    apiPrefix(interpolateRoute(apiRoutes.topologiesWithCreator)),
    data,
    config
  );
};

const remove = (
  uuid: string,
  config?: AxiosRequestConfig
): Promise<Topology | null> => {
  return API.delete<Topology>(
    apiPrefix(interpolateRoute(apiRoutes.topologyById, { uuid })),
    config
  );
};

const extendDuration = (
  uuid: string,
  duration: string,
  config?: AxiosRequestConfig
): Promise<ExtendDuration> => {
  return API.post<ExtendDuration>(
    apiPrefix(interpolateRoute(apiRoutes.topologyExtendDuration, { uuid })),
    { duration },
    config
  );
};

const toggleTopologyAction = (
  uuid: string,
  action: string,
  value: boolean,
  config?: AxiosRequestConfig
) => {
  const url = apiPrefix(
    interpolateRoute(apiRoutes.topologyAction, { uuid, action })
  );
  if (value) {
    return API.patch(url, null, config);
  } else {
    return API.delete(url, config);
  }
};

const sendTopologyDetailsEmail = (uuid: string) => {
  return API.post(
    apiPrefix(interpolateRoute(apiRoutes.topologySendEmailAction, { uuid })),
    {}
  );
};

export const topologiesApi = {
  getAll,
  getOne,
  update,
  create,
  remove,
  extendDuration,
  toggleTopologyAction,
  sendTopologyDetailsEmail
};
