import { filter, isEmpty } from 'lodash';
import React, { memo, useCallback, useMemo, useState } from 'react';

import { permissionsUtils } from 'utils/permissions';

import { NoDataPlaceholder, RequestData } from 'components';
import { apiRoutes } from 'constants/api-routes';
import { permissions } from 'constants/permissions';
import { Template } from 'types';

import { TemplateSelectionTable } from '../TemplateSelectionTable';
import { TemplateFilters, TemplateFiltersForm } from './TemplateFiltersForm';

import './styles.less';

const filterTempaltes = (
  templates: Template[],
  filters: TemplateFilters
): Template[] => {
  if (filters.approved === filters.experimental) {
    return templates;
  }
  if (filters.approved) {
    return filter(templates, { approved: true });
  }
  return filter(templates, { approved: false });
};

type Props = {
  readonly selectedTemplateId: number | null;
  readonly onChange: (templateId: number | null) => void;
};

export const TemplateSelection: React.FC<Props> = memo(
  ({ selectedTemplateId, onChange }) => {
    const [filters, setFilters] = useState<TemplateFilters>({
      approved: true,
      experimental: false
    });
    const params = useMemo(() => ({ limit: 100000 }), []);
    const maySeeExternal = permissionsUtils.checkPermissions(
      permissions.templates.maySeeExternal
    );
    return (
      <RequestData
        url={apiRoutes.templates}
        params={params}
        transformResponse={useCallback(
          (data: { templates: Template[] }) => data.templates,
          []
        )}
      >
        {({ data: templates }) => {
          const filteredTemplates = filterTempaltes(templates, filters);
          return (
            <div className='cl-template-selection'>
              <h4 className='section-number'>1. Select a Template</h4>
              {maySeeExternal && (
                <TemplateFiltersForm
                  filters={filters}
                  onChange={value => {
                    setFilters(value);
                    onChange(null);
                  }}
                />
              )}
              {isEmpty(filteredTemplates) ? (
                <NoDataPlaceholder />
              ) : (
                <TemplateSelectionTable
                  templates={filteredTemplates}
                  selectedTemplateId={selectedTemplateId}
                  onChange={onChange}
                />
              )}
            </div>
          );
        }}
      </RequestData>
    );
  }
);
