import React, { Component } from 'react';
import { Pagination as SemanticPagination } from 'semantic-ui-react';

import './styles.less';

type Props = {
  readonly activePage: number;
  readonly totalPages: number;
  readonly onPaginationChange: (page: number) => void;
};

export class Pagination extends Component<Props> {
  static defaultProps = {
    activePage: 1,
    boundaryRange: 1
  };

  handlePaginationChange = (e: any, { activePage }: any) =>
    this.props.onPaginationChange(activePage);

  render() {
    const { activePage, totalPages } = this.props;
    if (totalPages <= 0) {
      return null;
    }

    return (
      <SemanticPagination
        activePage={activePage}
        totalPages={totalPages}
        onPageChange={this.handlePaginationChange}
      />
    );
  }
}
