/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */
import { Validator } from './Base';

export class NotAllowedChars extends Validator {
  notAllowed: string;

  constructor(notAllowed: string) {
    super();
    this.notAllowed = notAllowed;
  }

  validate(text: string): boolean {
    for (const c of this.notAllowed) {
      if (text.includes(c)) {
        return false;
      }
    }
    return true;
  }

  errorMessage(): string {
    return `These characters are not allowed: ${this.notAllowed}`;
  }
}
