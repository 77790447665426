import React, { Fragment } from 'react';
import { Divider } from 'semantic-ui-react';

import { permissionsUtils } from 'utils';

import { ContactsTable, DefaultColumns } from 'components/ContactsTable';
import { permissions } from 'constants/permissions';

import { ContactActionContainer } from './ContactActionContainer';
import { CreateNewUserLink } from './CreateNewUserLink';
import { SearchForm } from './SearchForm';

import './styles.less';

export const CONTACT_ROLE_QUERY = 'role';

export const PARTNER_ROLE_VALUE = 'partner';
export const CUSTOMERS_ROLE_VALUE = 'customer';
export const ALL_ROLES_VALUE = 'all';
export const SE_ROLE_VALUE = 'se';

export const CONTACT_ROLE_OPTIONS = {
  all: {
    label: 'All',
    value: ALL_ROLES_VALUE,
    permissions: null
  },
  internal: {
    label: 'Internal',
    value: 'internal',
    permissions: permissions.contacts.maySeeInternals
  },
  support: {
    label: 'Support',
    value: 'support',
    permissions: permissions.contacts.maySeeSupport
  },
  customers: {
    label: 'Customers',
    value: CUSTOMERS_ROLE_VALUE,
    permissions: null
  },
  partners: {
    label: 'Partners',
    value: PARTNER_ROLE_VALUE,
    permissions: null
  },
  se: {
    label: 'SE',
    value: SE_ROLE_VALUE,
    permissions: permissions.contacts.mayChangeInternal
  }
};

export const getAvailableOptions = (userRole?: string) => {
  return Object.values(CONTACT_ROLE_OPTIONS).filter(
    ({ permissions: optionPermissions }) =>
      !optionPermissions ||
      permissionsUtils.checkPermissions(optionPermissions, userRole)
  );
};

export const Contacts: React.FC = () => {
  return (
    <div className='cl-contacts'>
      <Fragment>
        <div className='caption'>
          <h2>{'Contacts'}</h2>
          <div>
            <div className='actions'>
              <SearchForm />
              <div className='flex-1' />
              <ContactActionContainer />
              <CreateNewUserLink />
            </div>
          </div>
        </div>
        <Divider />
        <ContactsTable
          displayedColumns={DefaultColumns}
          selectionType={'multi'}
          options={['allowDepartmentUpdate']}
        />
      </Fragment>
    </div>
  );
};
