import React, { memo } from 'react';

import { TopologyExpirationDatePicker } from 'components/TopologyExpirationDatePicker';

type Props = {
  readonly selectedDateTime: string;
  readonly onChange: (region: string) => void;
};

export const TopologyExpiration: React.FC<Props> = memo(
  ({ selectedDateTime, onChange }) => {
    return (
      <div>
        <h4 className='section-number'>
          3. Select Expiration time for your Topology
        </h4>
        <TopologyExpirationDatePicker
          selectedDateTime={selectedDateTime}
          onChange={onChange}
        />
      </div>
    );
  }
);
