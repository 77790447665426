import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { notifier } from 'utils';

import { apiPrefix } from 'constants/api-routes';
import { Quota } from 'types/quota';

// eslint-disable-next-line
export default () => {
  const queryClient = useQueryClient();

  const useUpdateQuota = useMutation(
    (quota: Quota) => {
      return axios
        .put(apiPrefix('/v1.2/quotas/topologies'), quota)
        .then(response => {
          notifier.success({
            message: `Saved Quota of type ${quota.resourceType}. for '${quota.resourceName}'`
          });
        })
        .catch(error => {
          notifier.requestFailed(error);
        });
    },
    { onSettled: () => queryClient.invalidateQueries('Quotas') }
  );

  const useDeleteQuota = useMutation(
    (quota: Quota) => {
      return axios
        .delete(apiPrefix(`/v1.2/quotas/topologies/${quota.id}`))
        .then(response => {
          notifier.success({
            message: `Deleted Quota of type ${quota.resourceType}. for '${quota.resourceName}'`
          });
        })
        .catch(error => {
          notifier.requestFailed(error);
        });
    },
    { onSettled: () => queryClient.invalidateQueries('Quotas') }
  );

  return {
    useDeleteQuota,
    useUpdateQuota
  };
};
