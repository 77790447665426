import React, { Fragment, memo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Button,
  Confirm,
  Divider,
  Loader,
  StrictBreadcrumbSectionProps,
  Tab
} from 'semantic-ui-react';

import { authUtils, contactUtils, interpolateRoute, notifier } from 'utils';

import { contactsApi } from 'api/contacts';
import {
  ContactDetailsModal,
  CreateTopologyLink,
  RequestData,
  Topologies
} from 'components';
import { apiRoutes } from 'constants/api-routes';
import { DEFAULT_PAGE_SIZE } from 'constants/pagination';
import { routes } from 'constants/routing';
import { useRouter } from 'hooks/useRouter';
import { MainLayout } from 'layouts';
import { Contact } from 'types';

import { ContactDetailsCaption } from './Caption';

import { AccountDetailsTab } from './AccountDetailsTab';

import './styles.less';

export const ContactDetailsView: React.FC = memo(() => {
  const router = useRouter();
  const contactId: number = Number((router.match.params as { id: string }).id);
  const active: string = (router.match.params as { active: string }).active;
  const [activeTab, setActiveTab] = useState<string>(active);

  const [localContact, setLocalContact] = useState<Contact | null>(null);
  const [detailsModalOpen, setDetailsModalOpen] = useState<boolean>(false);
  const [confirmDeleteModelOpen, setConfirmDeleteModalOpen] = useState(false);
  const [redirect, setRedirect] = useState<boolean>(false);

  const getPanes = (contact: Contact) => [
    {
      menuItem: {
        id: 'details',
        key: 'details',
        icon: 'users',
        content: 'Contact'
      },
      render: () => (
        <Tab.Pane active={activeTab === 'details'}>
          <AccountDetailsTab user={contact} />
        </Tab.Pane>
      )
    },
    {
      menuItem: {
        id: 'topologies',
        key: 'topologies',
        content: 'Active Topologies'
      },
      render: () => (
        <Tab.Pane active={activeTab === 'topologies'}>
          <Topologies
            filter={contact.email}
            limit={DEFAULT_PAGE_SIZE}
            displayedColumns={[
              'topology name',
              'region',
              'creation time',
              'expiration time',
              'created by',
              'created for',
              'department',
              'tags',
              'status',
              'actions'
            ]}
            selectionType={'none'}
            basicNoDataPlaceholder
          />
        </Tab.Pane>
      )
    }
    /* Disabled for Phase I */
    // {
    //   menuItem: 'Activity Logs',
    //   render: () => (
    //     <Tab.Pane>
    //       <ActivityLogsTable />
    //     </Tab.Pane>
    //   )
    // }
  ];

  const onDelete = async (contact: Contact) => {
    if (!contact) {
      return;
    }
    try {
      await contactsApi.remove(contact.id);
      notifier.success({
        message: `Contact "${contact.name}" deleted`
      });
      setRedirect(true);
    } catch (err) {
      notifier.requestFailed(err);
    }
    setConfirmDeleteModalOpen(false);
  };

  const user = authUtils.getLoggedInUser();

  if (redirect) {
    return <Redirect to={routes.contacts.path} />;
  }
  return (
    <RequestData
      url={interpolateRoute(apiRoutes.contactById, { id: contactId })}
      customLoader
      customError
    >
      {({ data: contact, loading }) => {
        contact = localContact || contact;
        const breadcrumbSections: StrictBreadcrumbSectionProps[] = [
          {
            content: routes.contacts.label,
            href: routes.contacts.path
          },
          {
            content: loading ? (
              <Loader active inline='centered' size='tiny' />
            ) : contact ? (
              contact.name
            ) : null,
            active: true
          }
        ];

        const mayChangeContact =
          contact &&
          (contact.email === user.email ||
            contactUtils.mayChangeByRole(contact.role));

        const panesMap = new Map(
          getPanes(contact).map((object, i) => {
            return [object.menuItem.id, i];
          })
        );

        return (
          <MainLayout
            className='cl-contact-details'
            breadcrumbSections={breadcrumbSections}
          >
            {contact && !loading && (
              <Fragment>
                <ContactDetailsCaption
                  contact={contact}
                  openDetailsModal={() => setDetailsModalOpen(true)}
                  mayChangeContact={mayChangeContact}
                />
                <Divider />
                <div className='content create-topology-btn'>
                  <div className='create-topology-btn'>
                    <Button
                      negative
                      onClick={() => setConfirmDeleteModalOpen(true)}
                    >
                      Delete Contact
                    </Button>
                    <CreateTopologyLink id={contact.id} text='New Topology' />
                  </div>
                  <Tab
                    activeIndex={panesMap.get(activeTab)}
                    panes={getPanes(contact)}
                    onTabChange={(event, data) => {
                      const target = event.target as HTMLButtonElement;
                      setActiveTab(target.id as string);
                    }}
                  />
                </div>
              </Fragment>
            )}
            {!contact && !loading && <h2>{'Contact Not Found'}</h2>}
            {loading && <Loader active />}
            {contact && mayChangeContact && (
              <ContactDetailsModal
                open={detailsModalOpen}
                onClose={(newContact: null | Contact) => {
                  if (newContact) {
                    setLocalContact(newContact);
                  }
                  setDetailsModalOpen(false);
                }}
                contact={contact}
              />
            )}
            <Confirm
              size='mini'
              header='Delete contact'
              content='This action is irreversible. Are you sure you want to delete this contact?'
              open={confirmDeleteModelOpen}
              onCancel={() => setConfirmDeleteModalOpen(false)}
              onConfirm={() => onDelete(contact)}
              confirmButton='Delete'
            />
          </MainLayout>
        );
      }}
    </RequestData>
  );
});
