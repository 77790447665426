import React from 'react';

import { Buildserver } from 'types/buildserver';

interface Props {
  buildserver: Buildserver;
}

export const BuildserverNameColumn: React.FC<Props> = ({ buildserver }) => {
  return <React.Fragment>{buildserver.name}</React.Fragment>;
};
