import React from 'react';

import { ExternalLink } from 'components/ExternalLink/ExternalLink';
import { Nbsp } from 'components/Nbsp/Nbsp';
import { appConfig } from 'constants/config';
import { Buildserver } from 'types/buildserver';

interface Props {
  buildserver: Buildserver;
}

function ec2InstanceLink(buildserver: Buildserver): string {
  return `https://${buildserver.region.name}.console.aws.amazon.com/ec2/v2/home?region=${buildserver.region.name}#InstanceDetails:instanceId=${buildserver.ec2InstanceId}`;
}

function slicerBuildserverLink(buildserver: Buildserver): string {
  return `http://${appConfig.slicerServerPublicDns}:5000/#/resources/${buildserver.name}`;
}

export const BuildserverPublicIpDns: React.FC<Props> = ({ buildserver }) => {
  let ipDns = <React.Fragment>N/A</React.Fragment>;
  if (buildserver.publicIp && buildserver.publicDns) {
    ipDns = (
      <div>
        <div>
          <b>DNS:</b>
          <Nbsp />
          {buildserver.publicDns}
        </div>
        <div>
          <b>IP:</b>
          <Nbsp />
          {buildserver.publicIp}
        </div>
        <div>
          <ExternalLink
            url={ec2InstanceLink(buildserver)}
            openInNewWindow={true}
            text={'Open in EC2 console'}
          />
        </div>
        <div>
          <ExternalLink
            url={slicerBuildserverLink(buildserver)}
            openInNewWindow={true}
            text={'Open in Slicer UI'}
          />
        </div>
      </div>
    );
  }

  return ipDns;
};
