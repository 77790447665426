import React, { memo } from 'react';
import { Container, Popup, Segment } from 'semantic-ui-react';

import { MilestoneCard } from '../LabGuideCard/MilestoneCard';

import './styles.less';

type Props = {
  readonly title: string;
  readonly topologyUuid: string;
  readonly topologyIsUp: boolean;
  readonly topologyIsDown: boolean;
};

export const ActionsCard: React.FC<Props> = memo(
  ({ title, topologyUuid, topologyIsUp, topologyIsDown }) => (
    <Segment className='cl-actions-card' padded={false}>
      <h3 className='header'>{title}</h3>
      <div className='description'>
        <Popup
          trigger={
            <Container>
              Milestones are snapshots of a given point in the lab progression,
              Apply the milestone you need to start where you left off or start
              over at a certain point.
            </Container>
          }
          position='bottom center'
          content='Milestones are snapshots of a given point in the lab progression,
                   and deploys the environment to the step where you would like to
                   go or last left off.  Apply the milestone you are interested in
                   starting from and continue the lab from there.  Milestones
                   apply all of the needed configuration changes to that point,
                   you do not need to apply one Milestone before another, you can
                   just apply the Milestone you would like and previous milestones
                   will be applied to achieve the state you need.'
        />
      </div>
      <div>
        <MilestoneCard
          topologyUuid={topologyUuid}
          isUp={topologyIsUp}
          isInactive={topologyIsDown}
        />
      </div>
    </Segment>
  )
);
