import React, { memo, useState } from 'react';
import { Pagination, PaginationProps, Table } from 'semantic-ui-react';

import { paginationUtils } from 'utils/pagination';
import { convertTime } from 'utils/utilFunctions';

import { LoadingState } from 'components/LoadingState/LoadingState';
import { REPORT_PAGE_SIZE } from 'constants/pagination';
import { DepartmentUsage } from 'interfaces';
import { PaginationFilters } from 'types';

import { filterDepartmentItems } from '../ReportFilterHelpers';

import './styles.less';

type Props = {
  readonly response: DepartmentUsage[];
  readonly searchText: string;
  readonly loading: boolean;
};

export const DepartmentTable: React.FC<Props> = memo(
  ({ response, searchText, loading }) => {
    const [pagination, setPagination] = useState<PaginationFilters>({
      activePage: 1,
      pageSize: REPORT_PAGE_SIZE
    });
    const { total, items } = filterDepartmentItems(
      response,
      searchText,
      pagination
    );
    const totalPages = paginationUtils.getTotalPages({
      pageSize: pagination.pageSize,
      totalCount: total
    });
    if (loading) {
      return <LoadingState />;
    }
    return (
      <>
        <Table stackable padded celled striped collapsing>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Department</Table.HeaderCell>
              <Table.HeaderCell>Number of topologies</Table.HeaderCell>
              <Table.HeaderCell>Billable Time</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items.map((t: DepartmentUsage) => {
              return (
                <Table.Row key={t.id}>
                  <Table.Cell>{t.department}</Table.Cell>
                  <Table.Cell>{t.totalTopologies}</Table.Cell>
                  <Table.Cell>{convertTime(t.activeSeconds)}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        {totalPages > 0 && (
          <div className='pagination'>
            <Pagination
              activePage={pagination.activePage}
              totalPages={totalPages}
              pointing
              secondary
              onPageChange={(
                event: React.MouseEvent<HTMLAnchorElement>,
                data: PaginationProps
              ) =>
                setPagination({
                  ...pagination,
                  activePage: data.activePage as number
                })
              }
            />
          </div>
        )}
      </>
    );
  }
);
