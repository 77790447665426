import React from 'react';
import { Label, Popup, SemanticCOLORS } from 'semantic-ui-react';

import { Buildserver } from 'types/buildserver';

interface Props {
  buildserver: Buildserver;
}

export const BuildserverStateLabel: React.FC<Props> = ({ buildserver }) => {
  let color: SemanticCOLORS = 'grey';

  if (buildserver.state === 'error') {
    color = 'red';
  } else if (buildserver.state === 'running') {
    color = 'green';
  }

  const label = <Label color={color}>{buildserver.state}</Label>;

  if (buildserver.stateMessage) {
    return <Popup content={buildserver.stateMessage} trigger={label} />;
  } else {
    return label;
  }
};
