import axios, { AxiosRequestConfig } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

import { notifier } from 'utils';

import { apiPrefix } from 'constants/api-routes';

import { Tag } from 'types/tag';

export const useGetTags = (
  name?: string,
  resource?: string
): UseQueryResult<{ tags: Tag[] }> => {
  const config: AxiosRequestConfig = {
    params: { name, resource }
  };
  return useQuery<{ tags: Tag[] }>(
    ['DepartmentTags'],
    () => {
      return axios
        .get(apiPrefix('/v1.2/tags'), config)
        .then(response => (response.data ? response.data : []))
        .catch(error => {
          notifier.requestFailed(error);
          return [];
        });
    },
    {
      notifyOnChangeProps: 'tracked'
    }
  );
};
