import React, { memo, useState } from 'react';
import { Menu, MenuItemProps } from 'semantic-ui-react';

import { getTimeRanges } from 'utils';

export type StartEndMixin = {
  start: string;
  end: string;
};

type Props = {
  readonly setDateTime: (value: StartEndMixin) => void;
};

export const TimelinePicker: React.FC<Props> = memo(({ setDateTime }) => {
  const options = ['1w', '3w', '1m', '3m', '6m', '1y'];
  const [selected, setSelectedTime] = useState<string>('1w');

  return (
    <>
      <Menu stackable compact floated='right'>
        {options.map(item => (
          <Menu.Item
            link
            key={item}
            active={selected === item}
            onClick={(
              event: React.MouseEvent<HTMLAnchorElement>,
              data: MenuItemProps
            ) => {
              setDateTime(getTimeRanges(data.children as string));
              setSelectedTime(data.children as string);
            }}
          >
            {item}
          </Menu.Item>
        ))}
      </Menu>
    </>
  );
});
