import React, { memo, useState } from 'react';
import { Button, Grid, Header } from 'semantic-ui-react';

import { CreateQuotaModal } from 'components/Quotas/QuotaModal';
import { QuotaTable } from 'components/Quotas/QuotaTable';
import { useQuotas } from 'hooks/useQuota';
import { MainLayout } from 'layouts';
import { Quota } from 'types/quota';

export const QuotasView: React.FC = memo(() => {
  const { quotas } = useQuotas<Quota>();

  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [currentQuota, setCurrentQuota] = useState<Quota>({} as Quota);

  return (
    <MainLayout>
      <Header>
        <h1>Quotas</h1>
      </Header>
      <CreateQuotaModal
        onClose={setCreateModalOpen}
        open={createModalOpen}
        selectedQuota={currentQuota}
        onQuotaChange={setCurrentQuota}
      />
      <Button primary onClick={() => setCreateModalOpen(true)}>
        Add Quota
      </Button>
      <Grid columns='2'>
        <Grid.Row>
          <Grid.Column>
            <QuotaTable
              type='contact'
              quotaType='Per-contact Quotas'
              quotaList={quotas}
              resourceLabel='Contact Email'
              showCurrentValue={true}
            />
          </Grid.Column>
          <Grid.Column>
            <QuotaTable
              type='role'
              quotaType='Per-role Quotas'
              quotaList={quotas}
              resourceLabel='Role'
              showCurrentValue={false}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <QuotaTable
              type='department'
              quotaType='Per-department Quotas'
              quotaList={quotas}
              resourceLabel='Department'
              showCurrentValue={true}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </MainLayout>
  );
});
