import React, { memo } from 'react';
import { Button } from 'semantic-ui-react';

import './styles.less';

type Props = {
  disabled?: boolean;
  readonly children?: React.ReactNode;
};

export const SubmitButton: React.FC<Props> = memo(({ children, disabled }) => (
  <Button className='cl-submit-button' type='submit' fluid disabled={disabled}>
    {children}
  </Button>
));
