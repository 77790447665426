import { isEmpty } from 'lodash';

import {
  DepartmentUsage,
  InternalContactUsage,
  PartnerCompanyUsage,
  PartnerContactUsage,
  TopContactUsage,
  Topology,
  TopUsage
} from 'interfaces';
import { ChartDictValueType, TimeSeries, TopTopologyUsage } from 'types';

export const groupByProperty = (
  utilization: TimeSeries[],
  property: string
) => {
  if (isEmpty(utilization)) {
    return [];
  }
  const dict = new Map();
  utilization.forEach((u: TimeSeries) => {
    if (!dict.has(u.dimensions[property])) {
      const data = {
        x: u.timestamp,
        y: Math.round(u.value)
      };
      dict.set(u.dimensions[property], [data]);
    } else {
      const data = {
        x: u.timestamp,
        y: Math.round(u.value)
      };
      dict.get(u.dimensions[property]).push(data);
    }
  });
  const utilized: ApexAxisChartSeries = [];
  dict.forEach((v, k) => {
    const serie = {
      name: k,
      type: 'line',
      data: v
    };
    utilized.push(serie);
  });
  return utilized;
};

export const groupTopologiesByRegion = (utilization: TimeSeries[]) => {
  const regionDict = new Map();
  utilization.forEach((u: TimeSeries) => {
    if (!regionDict.has(u.dimensions.Region)) {
      const data = {
        timestamp: u.timestamp,
        value: u.value
      };
      regionDict.set(u.dimensions.Region, [data]);
    } else {
      const data = {
        timestamp: u.timestamp,
        value: u.value
      };
      regionDict.get(u.dimensions.Region).push(data);
    }
  });
  return regionDict;
};

export const groupTopologyValueByTimestamp = (
  regionDict: Map<string, ChartDictValueType[]>
) => {
  const timestampDict = new Map();
  const allValues = Array.from(regionDict.values()).flat();
  allValues.forEach((u: ChartDictValueType) => {
    if (!timestampDict.has(u.timestamp)) {
      timestampDict.set(u.timestamp, u.value);
    } else {
      const sum = timestampDict.get(u.timestamp) + u.value;
      timestampDict.set(u.timestamp, sum);
    }
  });
  return timestampDict;
};

const getDepartmentUsage = (usage: InternalContactUsage[]) => {
  const departmentUsage: DepartmentUsage[] = [];
  usage.forEach((u: InternalContactUsage, i: number) => {
    const dUsage = {
      id: i,
      department: u.department || 'N/A',
      totalTopologies: u.topologies.length,
      activeSeconds: u.totalActiveSeconds
    };
    departmentUsage.push(dUsage);
  });
  return departmentUsage;
};

const getPartnerCompanyUsage = (usage: PartnerContactUsage[]) => {
  const partnerCompanyUsage: PartnerCompanyUsage[] = [];
  usage.forEach((u: PartnerContactUsage, i: number) => {
    const pcUsage = {
      id: i,
      partnerCompany: u.partnerCompany || 'N/A',
      totalTopologies: u.topologies.length,
      activeSeconds: u.totalActiveSeconds
    };
    partnerCompanyUsage.push(pcUsage);
  });
  return partnerCompanyUsage;
};

const getTopTopologies = (usage: TopUsage[], topActive: number) => {
  const topActiveTopologies: TopTopologyUsage[] = [];
  usage.forEach((u: TopUsage) => {
    u.topologies.forEach((t: Topology) => {
      const active = {
        name: t.name,
        owner: t.owner,
        departmentOrCompany: u.department || u.partnerCompany || 'N/A',
        activeSeconds: t.totalActiveSeconds
      };
      topActiveTopologies.push(active);
    });
  });
  topActiveTopologies.sort((a, b) =>
    a.activeSeconds > b.activeSeconds ? 1 : -1
  );
  return topActiveTopologies.reverse().slice(0, topActive);
};

const getTopContacts = (usage: TopUsage[], topActive: number) => {
  const topActiveContacts: TopContactUsage[] = [];
  usage.forEach((u: TopUsage) => {
    u.topologies.forEach((t: Topology) => {
      const active = {
        name: t.owner,
        departmentOrCompany: u.department || u.partnerCompany || 'N/A',
        activeSeconds: t.totalActiveSeconds,
        totalTopologies: 0
      };
      topActiveContacts.push(active);
    });
  });
  topActiveContacts.sort((a, b) =>
    a.activeSeconds > b.activeSeconds ? 1 : -1
  );
  topActiveContacts.reverse();
  const dict = new Map();
  topActiveContacts.forEach((c: TopContactUsage) => {
    if (!dict.has(c.name)) {
      dict.set(c.name, [c]);
    } else {
      dict.get(c.name).push(c);
    }
  });
  const uniqueContacts: TopContactUsage[] = [];
  dict.forEach((v, k) => {
    const topologiesLength = v.length;
    v[0].totalTopologies = topologiesLength;
    uniqueContacts.push(v[0]);
  });
  return uniqueContacts.slice(0, topActive);
};

export const reportUtils = {
  getDepartmentUsage,
  getPartnerCompanyUsage,
  getTopTopologies,
  getTopContacts
};
