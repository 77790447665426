import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';

import { authUtils, interpolateRoute } from 'utils';

import { routes } from 'constants/routing';

type Props = {
  readonly id?: number;
  readonly icon?: boolean;
  readonly text?: string;
};

export const CreateNewUserLink: React.FC<Props> = memo(({ id, icon, text }) => {
  if (!id) {
    id = authUtils.getLoggedInUser().id;
  }

  return (
    <Link to={interpolateRoute(routes.createUserContact.path, { id })}>
      <Button primary>
        {icon && <Icon className='button' name='add user' />}
        {text}
      </Button>
    </Link>
  );
});

CreateNewUserLink.defaultProps = {
  icon: true,
  text: 'Create New User'
};
