import React, { memo } from 'react';
import {
  Button,
  Dropdown,
  Form,
  Header,
  Icon,
  Input,
  Modal
} from 'semantic-ui-react';

import { useBuildserverMutations } from 'hooks/useBuildserverMutations';
import { Region } from 'types';
import { LaunchTemplate } from 'types/buildserver';

interface Props {
  regions: Region[];
  launchTemplates: LaunchTemplate[];

  setOpen(isOpen: boolean): void;
  isOpen(): boolean;
}

export const CreateBuildserverModal: React.FC<Props> = memo(
  ({ regions, launchTemplates, isOpen, setOpen }) => {
    const [bsName, setBsName] = React.useState<string>('');
    const [launchTemplate, setLaunchTemplate] = React.useState<string>();
    const [selectedRegion, setRegion] = React.useState<string>();
    const { useCreateBuildserver } = useBuildserverMutations();

    const submit = () => {
      if (selectedRegion && launchTemplate) {
        useCreateBuildserver.mutate({
          name: bsName,
          awsLaunchTemplateName: launchTemplate,
          region: selectedRegion
        });
        setOpen(false);
      }
    };

    return (
      <Modal
        closeIcon={true}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={isOpen()}
        size='small'
      >
        <Header icon>
          <Icon name='server' />
          Launch new buildserver
        </Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>Name</label>
              <p>
                Optional buildserver name. If not specified - name is generated
                using pattern <code>bs-[region_name]-[uuid]</code>.
              </p>
              <Input
                placeholder='Buildserver Name'
                onChange={(e, data) => setBsName(data.value as string)}
              />
            </Form.Field>
            <Form.Field required>
              <label>Region</label>
              <Dropdown
                placeholder='Select Region'
                fluid
                selection
                options={regions.map((region: Region) => {
                  return {
                    key: region.name,
                    value: region.name,
                    text: `${region.label} (${region.name})`
                  };
                })}
                onChange={(e, data) => setRegion(data.value as string)}
              />
            </Form.Field>
            <Form.Field required>
              <label>Launch template</label>
              <p>
                If dropdown is empty - create launch template using Cloudlabs
                APIs.
              </p>
              <Dropdown
                placeholder='Select launch template'
                fluid
                selection
                options={launchTemplates.map((lt: LaunchTemplate) => {
                  return {
                    key: lt.name,
                    value: lt.name,
                    text: lt.name
                  };
                })}
                onChange={(e, data) => setLaunchTemplate(data.value as string)}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button basic negative onClick={() => setOpen(false)}>
            <Icon name='remove' /> Cancel
          </Button>
          <Button
            type={'submit'}
            positive
            onClick={submit}
            disabled={
              selectedRegion === undefined || launchTemplate === undefined
            }
          >
            <Icon name='rocket' /> Launch
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
);
