import { isEmpty } from 'lodash';
import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'semantic-ui-react';

import { formatDateTime, isDateTimeValid, notifier } from 'utils';

import { reportsApi } from 'api/reports';
import { PeriodFilterSelector } from 'components/PeriodFilterSelector';
import { SendEmailModal } from 'components/SendEmailModal';
import { defaultTopologyUsage, topActiveOptions } from 'constants/reports';
import { useTopologyUsage } from 'hooks/reportHooks/useTopologyUsage';
import { DatesByPage, FiltersByPage } from 'types';

import { setTopActiveMarker } from 'actions/actions';
import { RootState } from 'Reducers/contactReducer';

import { UsageTable } from './UsageTable/UsageTable';

import './styles.less';

export const TopologyReports: React.FC = memo(() => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState<string>('');
  const [emailModalVisible, setEmailModalVisible] = useState<boolean>(false);
  const [recipients, setRecipients] = useState<string[]>([]);
  const [topActive, setTopActive] = useState<number>(5);
  const dates: DatesByPage = useSelector((state: RootState) => state.dates);
  const filtersByPage: FiltersByPage = useSelector(
    (state: RootState) => state.filters
  );
  const topActiveState: number = useSelector(
    (state: RootState) => state.active
  );
  const filters = isEmpty(searchText)
    ? filtersByPage.topologyFilterText
    : searchText;
  const startDate = isDateTimeValid(
    dates.topology.startDate,
    'MM-DD-YYYY HH:mm'
  )
    ? formatDateTime(
        dates.topology.startDate,
        'MM-DD-YYYY HH:mm',
        'YYYY-MM-DD HH:mm'
      )
    : '';
  const endDate = isDateTimeValid(dates.topology.endDate, 'MM-DD-YYYY HH:mm')
    ? formatDateTime(
        dates.topology.endDate,
        'MM-DD-YYYY HH:mm',
        'YYYY-MM-DD HH:mm'
      )
    : '';
  const sendReportEmails = async () => {
    try {
      await reportsApi.sendUsageReportEmail({
        startDate,
        endDate,
        recipients
      });
      notifier.success({
        message: `Reports successfully sent to recipients`
      });
      setRecipients([]);
      setEmailModalVisible(false);
    } catch (err) {
      notifier.requestFailed(err);
    }
  };

  const usage = useTopologyUsage(startDate, endDate);
  const originalResponse = usage.data || defaultTopologyUsage;
  const isInternalResponseEmpty = !isEmpty(originalResponse.internal);
  const isExternalResponseEmpty =
    !isEmpty(originalResponse.partners) && !isEmpty(originalResponse.external);
  const departmentUsage =
    originalResponse && isInternalResponseEmpty
      ? originalResponse.internal
      : [];
  const partnerCompanyUsage =
    originalResponse && isExternalResponseEmpty
      ? [...originalResponse.partners, ...originalResponse.external]
      : [];
  const topActiveTopology =
    originalResponse && isExternalResponseEmpty && isInternalResponseEmpty
      ? [
          ...originalResponse.partners,
          ...originalResponse.external,
          ...originalResponse.internal
        ]
      : [];
  const topActiveContacts =
    originalResponse && isExternalResponseEmpty && isInternalResponseEmpty
      ? [
          ...originalResponse.partners,
          ...originalResponse.external,
          ...originalResponse.internal
        ]
      : [];

  const getTopActive =
    topActive === topActiveOptions[0].value ? topActiveState : topActive;
  return (
    <div className='cl-topology-reports'>
      <h2 className='header'>{'Topology Reports'}</h2>
      <Divider />
      <PeriodFilterSelector
        searchText={searchText}
        setSearchText={setSearchText}
        reportType='topology'
        setEmailModalVisible={setEmailModalVisible}
        topActive={getTopActive}
        setTopActive={(data: number) => {
          dispatch(setTopActiveMarker(data));
          setTopActive(data);
        }}
      />
      <Divider />
      <UsageTable
        loading={usage.isLoading}
        filters={filters}
        topActive={getTopActive}
        departmentUsage={departmentUsage}
        partnerCompanyUsage={partnerCompanyUsage}
        topActiveTopology={topActiveTopology}
        topActiveContacts={topActiveContacts}
      />
      <SendEmailModal
        visible={emailModalVisible}
        setVisible={setEmailModalVisible}
        emails={recipients}
        setEmail={setRecipients}
        onSubmit={sendReportEmails}
      />
    </div>
  );
});
