/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */
import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'semantic-ui-react';

import { RequestData } from 'components';
import { apiRoutes } from 'constants/api-routes';
import {
  RegionCapacity,
  RegionsWithCapacity,
  RegionWithEmbeddedTemplates,
  TopologyFieldNames
} from 'types';

import { setTopologyFieldName } from 'actions/actions';
import { RootState } from 'Reducers/contactReducer';

import { RegionCapacityLabel } from './RegionCapacity';

import './styles.less';

export const RegionSelection: React.FC<{
  selectedRegionName: string | null;
  onChange: (region: string) => void;
}> = memo(({ selectedRegionName, onChange }) => {
  let regionLabel = '';
  const dispatch = useDispatch();
  const fieldValueNames: TopologyFieldNames = useSelector(
    (state: RootState) => state.fieldValues
  );

  const embedTemplatesIntoRegionsCapacities = (
    data: RegionsWithCapacity
  ): RegionWithEmbeddedTemplates[] =>
    data.regions.map((region: RegionCapacity) => ({
      ...region,
      capacities: data.templates.map((template, index) => ({
        template,
        numberOfTopologies: region.numberOfTopologiesForTemplates[index]
      }))
    }));

  return (
    <RequestData
      url={apiRoutes.regionsCapacity}
      transformResponse={useCallback(embedTemplatesIntoRegionsCapacities, [])}
    >
      {({ data: regions }) => (
        <div className='regions'>
          {regions.map((rc: RegionWithEmbeddedTemplates) => (
            <div
              key={rc.name}
              className='region same-row'
              onClick={() => {
                regionLabel = rc.label;
                onChange(rc.name);
                dispatch(
                  setTopologyFieldName({
                    ...fieldValueNames,
                    region: regionLabel
                  })
                );
              }}
            >
              <Checkbox
                id={`deployment-region-${rc.name}`}
                radio
                name='deploymentRegion'
                checked={rc.name === selectedRegionName}
              />
              <div className={'same-column'}>
                <div>{rc.label}</div>
                <RegionCapacityLabel
                  numberOfTopologies={
                    rc.capacities.find(
                      capacity =>
                        fieldValueNames.templateId &&
                        fieldValueNames.templateId.includes(
                          capacity.template.id
                        )
                    )?.numberOfTopologies
                  }
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </RequestData>
  );
});
