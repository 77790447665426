import { configureStore, PreloadedState } from '@reduxjs/toolkit';

import appReducer, { RootState } from './Reducers/contactReducer';

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: appReducer,
    preloadedState
  });
};

export const store = configureStore({
  reducer: appReducer
});
