import { isEmpty } from 'lodash';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Segment } from 'semantic-ui-react';

import { isNullOrUndefined } from 'utils/utilFunctions';

import {
  CloudlabsBanner,
  DeploymentRegionSelectionWizard,
  ProgressStepperNavButtons,
  TagSelectorWizard,
  UserSelectorWizard
} from 'components';
import { ProgressStepper } from 'components/ProgressStepper';
import { CreateTopologyPage } from 'enums/topologyAction';
import { CreateTopology, StepItem, TopologyFieldNames } from 'types';

import { initialFieldName, RootState } from 'Reducers/contactReducer';
import { TemplateSelectionWizard } from './TemplateSelectionWizard';
import { TopologyExpirationWizard } from './TopologyExpirationWizard';

import './styles.less';

type Props = {
  readonly contactId: number;
  readonly topology: CreateTopology;
  readonly selectedTags: string[];
  readonly setSelectedTags: (value: string[]) => void;
  readonly onChange: (value: CreateTopology) => void;
  readonly createTopology: () => void;
};

export const CreateTopologyWizard: React.FC<Props> = ({
  contactId,
  topology,
  selectedTags,
  setSelectedTags,
  onChange,
  createTopology
}) => {
  const contactsState = useSelector((state: RootState) => state.contact);
  const selectedContacts = contactsState.selected;
  const fieldValueNames: TopologyFieldNames = useSelector(
    (state: RootState) => state.fieldValues
  );
  const [activeSteps, setActiveSteps] = useState<number>(0);
  const [completedSteps, setCompletedSteps] = useState<number>(0);
  const [sendEmail, setSendEmail] = useState<boolean>(
    !isNullOrUndefined(topology.emailNotificationsEnabled)
      ? topology.emailNotificationsEnabled
      : true
  );
  const disablePrevButton = activeSteps === 0;
  const stepItems: StepItem[] = [
    {
      key: CreateTopologyPage.user,
      title: 'Step 1:',
      description: 'Select a User'
    },
    {
      key: CreateTopologyPage.template,
      title: 'Step 2:',
      description: 'Select a Template'
    },
    {
      key: CreateTopologyPage.region,
      title: 'Step 3:',
      description: 'Select a Deployment region'
    },
    {
      key: CreateTopologyPage.expiration,
      title: 'Step 4:',
      description: 'Set Expiration for Topology'
    },
    {
      key: CreateTopologyPage.tag,
      title: 'Step 5:',
      description: 'Set Tags and Notifications'
    }
  ];
  const [createTopologySteps, setCreateTopologySteps] = useState<StepItem[]>(
    stepItems
  );

  if (contactId) {
    topology.createdForId = contactId;
    stepItems.shift();
    stepItems.map(
      (step: StepItem, i: number) => (step.title = `Step ${i + 1}`)
    );
  } else if (selectedContacts && selectedContacts.length > 0) {
    const selected = selectedContacts[0];
    if (selected) {
      topology.createdForId = selected.id;
    }
  }
  const mapSteps = useMemo(() => {
    return new Map();
  }, []);
  stepItems.forEach((step: StepItem, i: number) => {
    mapSteps.set(i, step.key);
  });

  const checkIfStepIsCompleted = (stepKey: string) => {
    switch (stepKey) {
      case CreateTopologyPage.user:
        return !isNullOrUndefined(topology.createdForId);
      case CreateTopologyPage.template:
        return !isNullOrUndefined(topology.template);
      case CreateTopologyPage.region:
        return !isNullOrUndefined(topology.region);
      default:
        return true;
    }
  };

  const updatedStepItems = useMemo(() => {
    if (fieldValueNames !== initialFieldName) {
      const createSteps = createTopologySteps;
      if (
        mapSteps.get(activeSteps) === CreateTopologyPage.user ||
        !isEmpty(fieldValueNames.owner)
      ) {
        const index = createSteps.findIndex(
          step => step.key === CreateTopologyPage.user
        );
        if (index > -1)
          createSteps[
            index
          ].description = `Selected user: ${fieldValueNames.owner}`;
      }
      if (
        mapSteps.get(activeSteps) === CreateTopologyPage.template ||
        !isEmpty(fieldValueNames.template)
      ) {
        const index = createSteps.findIndex(
          step => step.key === CreateTopologyPage.template
        );
        createSteps[
          index
        ].description = `Selected template: ${fieldValueNames.template}`;
      }
      if (
        mapSteps.get(activeSteps) === CreateTopologyPage.region ||
        !isEmpty(fieldValueNames.region)
      ) {
        const index = createSteps.findIndex(
          step => step.key === CreateTopologyPage.region
        );
        createSteps[
          index
        ].description = `Selected region: ${fieldValueNames.region}`;
      }
      if (
        mapSteps.get(activeSteps) === CreateTopologyPage.expiration ||
        !isEmpty(fieldValueNames.expirationTime)
      ) {
        const index = createSteps.findIndex(
          step => step.key === CreateTopologyPage.expiration
        );
        createSteps[
          index
        ].description = `Selected expiration date: ${fieldValueNames.expirationTime}`;
      }
      return createSteps;
    }
  }, [createTopologySteps, activeSteps, fieldValueNames, mapSteps]);

  useEffect(() => {
    setCreateTopologySteps(createTopologySteps);
  }, [fieldValueNames, createTopologySteps, updatedStepItems]);

  const handleChange = (field: string, value: any) => {
    onChange({ ...(topology || {}), [field]: value });
  };

  return (
    <Fragment>
      <CloudlabsBanner />
      <div className='caption'>
        <h2>Create New Topology</h2>
      </div>
      <ProgressStepper
        activeSteps={activeSteps}
        completedSteps={completedSteps}
        stepItems={createTopologySteps}
        onStepClick={setActiveSteps}
      />
      <UserSelectorWizard
        stepVisible={mapSteps.get(activeSteps) === CreateTopologyPage.user}
      />
      <TemplateSelectionWizard
        stepVisible={mapSteps.get(activeSteps) === CreateTopologyPage.template}
        selectedTemplateId={topology.template}
        onChange={value => {
          handleChange('template', value);
        }}
      />
      <DeploymentRegionSelectionWizard
        stepVisible={mapSteps.get(activeSteps) === CreateTopologyPage.region}
        selectedRegionName={topology.region}
        onChange={value => {
          handleChange('region', value);
        }}
      />
      <TopologyExpirationWizard
        stepVisible={
          mapSteps.get(activeSteps) === CreateTopologyPage.expiration
        }
        selectedDateTime={topology.expirationTime}
        onChange={(value: string) => {
          handleChange('expirationTime', value);
        }}
      />
      <TagSelectorWizard
        stepVisible={mapSteps.get(activeSteps) === CreateTopologyPage.tag}
        selectedTags={selectedTags}
        sendEmail={topology.emailNotificationsEnabled || sendEmail}
        onEmailChange={(value: boolean) => {
          setSendEmail(value);
          handleChange('emailNotificationsEnabled', value);
        }}
        onTagsChange={setSelectedTags}
      />
      <div className='nav-buttons-segment'>
        <Segment raised>
          <ProgressStepperNavButtons
            activeSteps={activeSteps}
            stepItemsLength={createTopologySteps.length}
            disablePrevButton={disablePrevButton}
            disableNextButton={
              !checkIfStepIsCompleted(mapSteps.get(activeSteps))
            }
            actionButton='Create Topology'
            setActiveSteps={setActiveSteps}
            setCompletedSteps={value => {
              setCompletedSteps(value);
            }}
            action={() => {
              topology.emailNotificationsEnabled = sendEmail;
              createTopology();
            }}
          />
        </Segment>
      </div>
    </Fragment>
  );
};
