import { find } from 'lodash';
import React, { memo } from 'react';

import { VM } from 'types';

import { VMCard } from '../VMCard';

type Props = {
  vm: VM | undefined;
  enabled: boolean;
};

const getAccess = (vm: VM | undefined) => {
  return vm ? find(vm.access, acc => acc.privatePort === 5601) : null;
};

export const AOSFlowUICard: React.FC<Props> = memo(({ vm, enabled }) => (
  <VMCard
    name='ApstraFlow UI'
    description='ApstraFlow Dashboard'
    vm={vm}
    access={getAccess(vm)}
    enabled={enabled}
  />
));
