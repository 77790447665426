import moment from 'moment';
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion } from 'semantic-ui-react';

import { DatePicker } from 'components/PeriodFilterSelector/DatePicker';
import { TopologyFieldNames } from 'types';

import { setTopologyFieldName } from 'actions/actions';
import { RootState } from 'Reducers/contactReducer';

type Props = {
  readonly selectedDateTime: string;
  readonly onChange: (date: string) => void;
};

export const TopologyExpirationDatePicker: React.FC<Props> = memo(
  ({ selectedDateTime, onChange }) => {
    const present = new Date();
    const dispatch = useDispatch();
    const fieldValueNames: TopologyFieldNames = useSelector(
      (state: RootState) => state.fieldValues
    );
    let activeIndex = -1;
    const panels = [
      {
        key: 'expiration',
        title: 'Customize Expiration',
        content: {
          content: (
            <DatePicker
              placeholderText='Expiration Date'
              pickerType='expirationDate'
              minDate={present}
              dateTimeValue={selectedDateTime || fieldValueNames.expirationTime}
              setDateTimeValue={(data: any) => {
                onChange(data.value);
                dispatch(
                  setTopologyFieldName({
                    ...fieldValueNames,
                    expirationTime: data.value
                  })
                );
              }}
            />
          )
        }
      }
    ];
    const incrementTime: number =
      fieldValueNames.templateExpiration &&
      fieldValueNames.templateExpiration !== 0
        ? fieldValueNames.templateExpiration
        : 6;
    const expirationTime = moment.utc().add(incrementTime, 'h');
    return (
      <>
        <b>Topology will expire on: </b> {expirationTime.toString()}
        <Accordion
          active={activeIndex}
          panels={panels}
          onTitleClick={() => (activeIndex = activeIndex === 0 ? -1 : 0)}
        />
      </>
    );
  }
);
