import React from 'react';
import { Icon } from 'semantic-ui-react';

import { Nbsp } from '../Nbsp/Nbsp';

interface Props {
  url: string;
  openInNewWindow: boolean;
  text: string;
}

export const ExternalLink: React.FC<Props> = props => {
  const target: string = props.openInNewWindow ? '_blank' : '';
  return (
    <React.Fragment>
      <a href={props.url} target={target}>
        {props.text}
        <Nbsp />
        <Icon name={'external'} />
      </a>
    </React.Fragment>
  );
};
