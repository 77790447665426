import React, { memo } from 'react';
import { Divider } from 'semantic-ui-react';

import { LabActionContainer, Labs, LabSearch } from 'components/Labs';
import { DEFAULT_PAGE_SIZE } from 'constants/pagination';
import { useRouter } from 'hooks/useRouter';
import { MainLayout } from 'layouts';

export const LabsView: React.FC = memo(() => {
  const router = useRouter();
  const search = new URLSearchParams(router.query);
  const filter = search.get('filter');
  const [filterInput, setFilterInput] = React.useState<string>(filter || '');

  return (
    <MainLayout className='cl-topologies-view'>
      <div className='caption'>
        <h2 className='header'>Labs</h2>
        <div className='actions'>
          <LabSearch filter={filterInput} setFilter={setFilterInput} />
          <div className='flex-1' />
          <LabActionContainer />
        </div>
      </div>
      <Divider />
      <Labs
        filter={filterInput}
        displayedColumns={[
          'lab name',
          'region',
          'creation time',
          'expiration time',
          'created by',
          'created for',
          'status',
          'progress',
          'topology',
          'actions'
        ]}
        selectionType={'multi'}
        limit={DEFAULT_PAGE_SIZE}
      />
    </MainLayout>
  );
});
