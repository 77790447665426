/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */

import isValidDomain from 'is-valid-domain';

import { Validator } from './Base';

export class ValidDomainName extends Validator {
  protected validate(text: string): boolean {
    return isValidDomain(text);
  }

  protected errorMessage(): string {
    return 'Invalid domain name';
  }
}
