import { AxiosRequestConfig } from 'axios';

import { interpolateRoute } from 'utils';

import { apiPrefix, apiRoutes } from 'constants/api-routes';
import { ContactListResponse, TaskCreatedResponse } from 'interfaces';
import { Contact } from 'types';

import { API } from './api';

const getAll = (config?: AxiosRequestConfig): Promise<ContactListResponse> => {
  return API.get<ContactListResponse>(apiPrefix(apiRoutes.contacts), config);
};

const getOne = (
  id: string,
  config?: AxiosRequestConfig
): Promise<Contact | null> => {
  return API.get<Contact>(
    apiPrefix(interpolateRoute(apiRoutes.contactById, { id })),
    config
  );
};

const update = (
  id: number,
  data: Partial<Contact>,
  config?: AxiosRequestConfig
): Promise<Contact | null> => {
  return API.patch<Contact>(
    apiPrefix(interpolateRoute(apiRoutes.contactById, { id })),
    data,
    config
  );
};

const remove = (
  id: number,
  config?: AxiosRequestConfig
): Promise<Contact | null> => {
  return API.delete<Contact>(
    apiPrefix(interpolateRoute(apiRoutes.contactById, { id })),
    config
  );
};

const create = (
  data: Contact,
  config?: AxiosRequestConfig
): Promise<TaskCreatedResponse | null> => {
  return API.post<TaskCreatedResponse>(
    apiPrefix(interpolateRoute(apiRoutes.contacts)),
    data,
    config
  );
};

export const contactsApi = {
  getAll,
  getOne,
  update,
  remove,
  create
};
