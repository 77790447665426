import axios, { AxiosError, AxiosRequestConfig, Method } from 'axios';
import { castArray, isArray } from 'lodash';
import React, {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useState
} from 'react';
import { Loader } from 'semantic-ui-react';

import { notifier } from 'utils';

import { ErrorMessage } from 'components/ErrorMessage';
import { appConfig } from 'constants/config';

import * as qs from 'qs';

export type RequestDataChildrenProps<T> = {
  readonly data: T;
  readonly loading: boolean;
  readonly error: any;
  readonly refetchData: () => void;
};

type Props = {
  readonly url?: string;
  readonly data?: any;
  readonly method?: Method;
  readonly baseURL?: string;
  readonly params?: any;
  readonly headers?: any;
  readonly transformResponse?: (data: any) => void;
  readonly customLoader?: boolean;
  readonly customError?: boolean;
  readonly pollingInterval?: number;
  readonly notify?: boolean;
  readonly children: ({ data }: RequestDataChildrenProps<any>) => ReactElement;
};

export const RequestData: React.FC<Props> = memo(
  ({
    children,
    url,
    data,
    method,
    baseURL,
    transformResponse,
    params,
    headers,
    customLoader,
    customError,
    pollingInterval,
    notify
  }) => {
    const [responseData, setResponseData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<AxiosError | undefined>(undefined);
    const [refetchDataHandle, setRefetchDataHandle] = useState<number | null>(
      null
    );

    const refetchData = useCallback(() => {
      setRefetchDataHandle(+new Date());
    }, []);

    useEffect(() => {
      const source = axios.CancelToken.source();
      let timeoutId: NodeJS.Timeout;

      const transformResponseConfig = [];
      if (axios.defaults.transformResponse) {
        transformResponseConfig.push(
          ...castArray(axios.defaults.transformResponse)
        );
      }
      if (transformResponse) {
        transformResponseConfig.push((response: any) => {
          if (!isArray(response.errors)) {
            return transformResponse(response);
          }
          return response;
        });
      }
      const config: AxiosRequestConfig = {
        url,
        data,
        method,
        baseURL,
        params,
        paramsSerializer: param => {
          return qs.stringify(param, { arrayFormat: 'repeat' });
        },
        headers,
        transformResponse: transformResponseConfig,
        cancelToken: source.token
      };
      const loadData = async () => {
        setLoading(true);
        let isCancelled = false;
        try {
          const response = await axios.request(config);
          setError(undefined);
          setResponseData(response.data);
          setLoading(false);
        } catch (error) {
          if (!axios.isCancel(error)) {
            if (notify) {
              notifier.requestFailed(error);
            }
            setError(error);
            setLoading(false);
          } else {
            isCancelled = true;
          }
        } finally {
          if (pollingInterval && !isCancelled) {
            timeoutId = setTimeout(() => {
              refetchData();
            }, pollingInterval);
          }
        }
      };
      loadData();

      return () => {
        source.cancel();
        clearTimeout(timeoutId);
      };
    }, [
      url,
      data,
      method,
      baseURL,
      params,
      headers,
      transformResponse,
      refetchDataHandle,
      pollingInterval,
      refetchData,
      notify
    ]);

    if (loading && !customLoader) {
      return <Loader active inline='centered' />;
    }

    if (error && !customError) {
      return <ErrorMessage error={error} />;
    }

    return children({ data: responseData, loading, error, refetchData });
  }
);

RequestData.defaultProps = {
  baseURL: appConfig.baseURL,
  notify: true
};
