/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */

import { useEffect, useState } from 'react';

import { storage } from 'utils';

type LocalStorageKey = string;

/**
 * A custom React hook for managing state with local storage persistence.
 *
 * @template S - The type of the state value.
 * @template T - The type of the serialized value stored in local storage.
 * @param {LocalStorageKey} key - The key to use for storing the state in local storage.
 * @param {S} initialValue - The initial value for the state.
 * @param {(value: S) => T} serialize - A function to serialize the state value for storage.
 * @param {(value: T) => S} deserialize - A function to deserialize the stored value from local storage.
 * @returns {{ state: S, setState: React.Dispatch<React.SetStateAction<S>> }} An object containing the current state value and the state updater function.
 */
export function usePersistentState<S, T>(
  key: LocalStorageKey,
  initialValue: S,
  serialize: (value: S) => T,
  deserialize: (value: T) => S
) {
  const storedValue: any = storage.get(key);
  const initialStoredValue = storedValue
    ? deserialize(storedValue)
    : initialValue;
  const [state, setState] = useState<S>(initialStoredValue);

  useEffect(() => {
    if (state !== initialStoredValue) {
      storage.set(key, serialize(state));
    }
  }, [key, state, initialStoredValue, serialize]);

  return { state, setState };
}
