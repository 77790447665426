/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */
import { validateEmail } from 'utils';

import { Validator } from './Base';

export class Email extends Validator {
  emails: string[];
  invalidEmails: string[];

  constructor() {
    super();
    this.emails = [];
    this.invalidEmails = [];
  }

  validate(text: string): boolean {
    if (text === '') {
      return true;
    }
    this.emails = text.trim() === '' ? [] : text.trim().split(' ');
    this.invalidEmails = this.emails.filter(email => !validateEmail(email));
    return this.invalidEmails.length === 0;
  }

  errorMessage(): string {
    let prefix = 'Invalid email:';
    if (this.invalidEmails.length > 1) {
      prefix = 'Invalid emails:';
    }
    return `${prefix} ${this.invalidEmails.join(', ')}`;
  }
}
