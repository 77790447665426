import React, { PureComponent, useState } from 'react';
import { Container, Divider, Grid, Header, List } from 'semantic-ui-react';

import { equalArrays } from 'utils';

import { Partner, usePartners, usePartnersMutations } from 'api/usePartners';
import { Unique } from 'components/ValidatedInput/Validators/Unique';
import { ValidDomainName } from 'components/ValidatedInput/Validators/ValidDomainName';
import { usePersistentState } from 'hooks/usePersistentState';
import { MainLayout } from 'layouts';

import { AddPartnerModal } from './AddPartnerModal';
import { AddPartner } from './Commands/AddPartner';
import { Command, SerializedCommand } from './Commands/Command';
import { DeletePartner } from './Commands/DeletePartner';
import { EditPartner } from './Commands/EditPartner';
import { optimized } from './Commands/optimizer';
import { deserialize, serialize } from './Commands/serialization';
import { PartnersViewHelpMessage } from './HelpMessages';
import { PartnersMenuBar } from './PartnersMenuBar';
import { PartnersTable } from './PartnersTable';

const editedPartners = (
  data: Partner[],
  commands: Command[],
  setCommands: (commands: Command[]) => void,
  search: string,
  undoneCommands: Command[],
  setUndoneCommands: (undoneCommands: Command[]) => void
) => {
  const applyFilter = (
    ps: Partner[] | undefined,
    filter: string
  ): Partner[] => {
    return (
      ps?.filter(
        p =>
          p.name.toLocaleLowerCase().includes(filter.toLowerCase()) ||
          p.domains.some(d =>
            d.toLocaleLowerCase().includes(filter.toLowerCase())
          )
      ) || []
    );
  };
  const applyCommands = (ps: Partner[], cs: Command[]): Partner[] => {
    function deepCopy<T>(arr: T[]): T[] {
      return JSON.parse(JSON.stringify(arr));
    }

    let result = deepCopy<Partner>(ps);
    for (const command of cs) {
      result = command.preview(result);
    }
    return result;
  };

  const partners = applyCommands(applyFilter(data, search), commands);

  const undo = () => {
    setCommands(commands.slice(0, commands.length - 1));
    setUndoneCommands([...undoneCommands, commands[commands.length - 1]]);
  };
  const redo = () => {
    setCommands([...commands, undoneCommands[undoneCommands.length - 1]]);
    setUndoneCommands(undoneCommands.slice(0, undoneCommands.length - 1));
  };

  return {
    partners,
    undo,
    redo
  };
};

export const Partners: React.FC = () => {
  const { data, isLoading, isError } = usePartners([]);
  const { state, setState } = usePersistentState<
    Command[],
    SerializedCommand[]
  >('clPartnerCommands', [], serialize, deserialize);
  const [commands, setCommands] = [state, setState];

  const [undoneCommands, setUndoneCommands] = useState<Command[]>([]);
  const [search, setSearch] = useState('');

  const [showAddPartnerModal, setShowAddPartnerModal] = useState<boolean>(
    false
  );
  const partnerMutations = usePartnersMutations();

  const edited = editedPartners(
    data ?? [],
    commands,
    setCommands,
    search,
    undoneCommands,
    setUndoneCommands
  );

  return (
    <Container>
      <Header as={'h2'}>{'Partner Companies'}</Header>
      <PartnersViewHelpMessage />
      <PartnersMenuBar
        onSearchChanged={(value: string) => setSearch(value)}
        onAddPartner={() => setShowAddPartnerModal(true)}
        onUndo={() => {
          edited.undo();
        }}
        onRedo={() => {
          edited.redo();
        }}
        onApply={() => {
          for (const command of optimized(commands)) {
            const { functionName, args } = command.getApiCall();
            partnerMutations[functionName].mutate(...args);
          }
          setCommands([]);
          setUndoneCommands([]);
        }}
        onClear={() => {
          setCommands([]);
          setUndoneCommands([]);
        }}
        undoDisabled={commands.length === 0}
        redoDisabled={undoneCommands.length === 0}
      />
      <Divider />
      <Grid>
        <Grid.Column width={12}>
          <Grid.Row>
            <PartnersTable
              partners={edited.partners}
              isLoading={isLoading}
              isError={isError}
              onPartnerEdit={(partner, newName, newDomains) => {
                if (
                  (newName !== undefined && partner.name !== newName) ||
                  (newDomains !== undefined &&
                    !equalArrays(partner.domains, newDomains))
                ) {
                  setCommands([
                    ...commands,
                    new EditPartner(partner, newName, newDomains)
                  ]);
                }
              }}
              onPartnerDelete={partner => {
                setCommands([...commands, new DeletePartner(partner)]);
              }}
            />
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={4}>
          <Grid.Row>
            <Header>Changes</Header>
            <List ordered>
              {commands.map((command, index) => (
                <List.Item key={index}>{command.asString()}</List.Item>
              ))}
            </List>
          </Grid.Row>
        </Grid.Column>
      </Grid>
      <AddPartnerModal
        open={showAddPartnerModal}
        setOpen={setShowAddPartnerModal}
        onPartnerAdded={(name, domains) => {
          setCommands([...commands, new AddPartner(name, domains)]);
        }}
        nameValidators={[
          new Unique(
            (data?.map(p => p.name) || []).concat(
              commands
                .filter(c => c instanceof AddPartner)
                .map(c => (c as AddPartner).partner)
            )
          )
        ]}
        domainValidators={[new ValidDomainName()]}
      />
    </Container>
  );
};

export class PartnersView extends PureComponent {
  render() {
    return (
      <MainLayout>
        <Partners />
      </MainLayout>
    );
  }
}
