import axios from 'axios';
import httpStatus from 'http-status';

import { authTokenStorageKey, authUtils } from 'utils';

import { apiRoutes } from 'constants/api-routes';
import { routes } from 'constants/routing';

axios.interceptors.request.use(
  config => {
    const token = authUtils.getToken(authTokenStorageKey);
    if (token) {
      config.headers.Authorization = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => response,
  error => {
    const shouldLogout =
      error &&
      error.response &&
      error.response.status === httpStatus.UNAUTHORIZED &&
      !error.config.url.endsWith(apiRoutes.loginEmail);

    if (shouldLogout) {
      authUtils.logout();
      window.location.href = routes.login.path;
    }

    return Promise.reject(error);
  }
);
