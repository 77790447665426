import { Pagination, SortField } from 'interfaces/queryParams';

export const paginationUtils = {
  getActiveItems: ({
    activePage,
    pageSize,
    items
  }: {
    activePage: number;
    pageSize: number;
    items: any[];
  }) => {
    if (pageSize === null) {
      return items;
    } else {
      const start = (activePage - 1) * pageSize;
      const end = start + pageSize;
      return items.slice(start, end);
    }
  },
  getTotalPages: ({
    totalCount,
    pageSize
  }: {
    totalCount: number;
    pageSize: number;
  }) => {
    return totalCount ? Math.ceil(totalCount / pageSize) : 0;
  }
};

export const constructQuery = (
  pagination: Pagination,
  filter: string,
  sort: SortField | undefined = undefined
): string => {
  const params = [];
  params.push(`limit=${pagination.limit}`);
  params.push(`offset=${pagination.offset - 1}`);
  params.push(`filter=${encodeURIComponent(filter)}`);
  if (sort !== undefined) {
    params.push(`sort=${sort.sortColumn}`);
    if (sort.sortOrder) {
      params.push(`order=${sort.sortOrder === 'ascending' ? 'asc' : 'desc'}`);
    }
  }

  return params.join('&');
};
