import React, { memo, useState } from 'react';
import { Pagination, PaginationProps, Table } from 'semantic-ui-react';

import { paginationUtils } from 'utils/pagination';
import { convertTime } from 'utils/utilFunctions';

import { LoadingState } from 'components/LoadingState/LoadingState';
import { REPORT_PAGE_SIZE } from 'constants/pagination';
import { PaginationFilters, TopTopologyUsage } from 'types';

import { filterTopTopologyItems } from '../ReportFilterHelpers';

type Props = {
  readonly response: TopTopologyUsage[];
  readonly searchText: string;
  readonly loading: boolean;
};

export const ActiveTopologiesTable: React.FC<Props> = memo(
  ({ response, searchText, loading }) => {
    const [pagination, setPagination] = useState<PaginationFilters>({
      activePage: 1,
      pageSize: REPORT_PAGE_SIZE
    });
    const { total, items } = filterTopTopologyItems(
      response,
      searchText,
      pagination
    );
    const totalPages = paginationUtils.getTotalPages({
      pageSize: pagination.pageSize,
      totalCount: total
    });
    if (loading) {
      return <LoadingState />;
    }
    return (
      <>
        <Table stackable padded celled striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Topology name</Table.HeaderCell>
              <Table.HeaderCell>Owner</Table.HeaderCell>
              <Table.HeaderCell>Department/Partner company</Table.HeaderCell>
              <Table.HeaderCell>Billable Time</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items.map((t: TopTopologyUsage) => {
              return (
                <Table.Row key={t.name}>
                  <Table.Cell>{t.name}</Table.Cell>
                  <Table.Cell>{t.owner}</Table.Cell>
                  <Table.Cell>{t.departmentOrCompany}</Table.Cell>
                  <Table.Cell>{convertTime(t.activeSeconds)}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        {totalPages > 1 && (
          <div className='pagination'>
            <Pagination
              activePage={pagination.activePage}
              totalPages={totalPages}
              pointing
              secondary
              onPageChange={(
                event: React.MouseEvent<HTMLAnchorElement>,
                data: PaginationProps
              ) =>
                setPagination({
                  ...pagination,
                  activePage: data.activePage as number
                })
              }
            />
          </div>
        )}
      </>
    );
  }
);
