import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Icon, Message, SemanticICONS } from 'semantic-ui-react';

import {
  authUtils,
  contactUtils,
  notifier,
  permissionsUtils,
  validateEmail
} from 'utils';
import { JUNIPER } from 'utils/utilFunctions';

import { tagsApi } from 'api/tag';
import { usePartners } from 'api/usePartners';
import { CUSTOMERS_ROLE_VALUE } from 'components/Contacts';
import { permissions } from 'constants/permissions';
import { UserRole } from 'enums';
import { Contact } from 'types';

import { setDepartments } from 'actions/actions';

type Props = {
  readonly contact: Contact;
  readonly submitBtnText: string;
  readonly submitBtnIcon: SemanticICONS;
  readonly passwordOptional?: boolean;
  readonly onSubmit: () => void;
  readonly onChange: (contact: Contact) => void;
};

interface PartnerCompanyDropdownProps {
  defaultValue: string;
  onChange: (value: string) => void;
  enabled: boolean;
}

const PartnerCompanyDropdown: React.FC<PartnerCompanyDropdownProps> = ({
  defaultValue,
  onChange,
  enabled
}) => {
  const partners = usePartners([]);
  return (
    <>
      <Form.Select
        label='Partner group'
        data-testid='create-partner-group'
        required
        disabled={!enabled}
        loading={partners.isLoading}
        fluid
        search
        error={partners.isError}
        options={
          partners.data?.map(partner => ({
            key: partner.uuid,
            value: partner.name,
            text: partner.name
          })) || []
        }
        placeholder='Partner group'
        value={defaultValue}
        onChange={(event, data) => onChange(data.value as string)}
      />
      <Message size='small'>
        <Icon name='info' /> If your partner company is not found in this
        dropdown, you can request that it be added by sending email to&nbsp;
        <b>
          <a href='mailto:apstra-cloudlabs@juniper.net'>
            apstra-cloudlabs@juniper.net
          </a>
        </b>
      </Message>
    </>
  );
};

export const ContactDetailsForm: React.FC<Props> = memo(
  ({ contact, submitBtnText, submitBtnIcon, onChange, onSubmit }) => {
    const dispatch = useDispatch();
    const [departments, setDepartmentsResponse] = React.useState<string[]>([]);
    const [localTag, setDepartmentTag] = useState<string>();
    const [isValid, isEmailValid] = useState(false);
    const isCreation = !contact || !contact.id;
    contact = contact || {};

    const handleChange = (field: string, value: string) => {
      onChange({ ...(contact || {}), [field]: value });
    };
    const handleAdminChange = (value: boolean) => {
      onChange({
        ...(contact || {}),

        role: value ? UserRole.PartnerAdmin : UserRole.Partner
      });
    };
    const maySetPartnerCompany = permissionsUtils.checkPermissions(
      permissions.contacts.maySetPartnerCompany
    );

    const findRole = (role: string) => {
      if (role === UserRole.Internal) return UserRole.Internal;
      if (role === UserRole.Support) return UserRole.Support;
      if (role === UserRole.Partner) return UserRole.Partner;
      if (role === UserRole.SE) return UserRole.SE;
      return UserRole.Customer;
    };

    const onFormSubmit = () => {
      if (!contact.role) {
        contact.role = findRole(selectedUserType.toLowerCase());
      }
      if (isPartner) {
        if (!contact.partnerCompany) {
          contact.partnerCompany = authUtils.getLoggedInUser().partner_company;
        }
        if (!contact.company) {
          contact.company = contact.partnerCompany;
        }
      }
      if (isInternal || isSupport || isSE) {
        if (!contact.company) {
          contact.company = JUNIPER;
        }
        if (!contact.department) {
          contact.department = localTag as string;
        }
      }
      isEmailValid(!validateEmail(contact.email));
      if (validateEmail(contact.email)) {
        onSubmit();
      }
    };
    const getOptions = (userRole?: string) => {
      return Object.values(contactUtils.ROLE_OPTIONS).filter(
        ({ permissions: optionPermissions }) =>
          !optionPermissions ||
          permissionsUtils.checkPermissions(optionPermissions, userRole)
      );
    };
    const availableOptions = getOptions(authUtils.getLoggedInUser().role).map(
      option => {
        return {
          key: option.value,
          value: option.value,
          text: option.label
        };
      }
    );

    const getDepartmentOptions = departments.map(option => {
      return {
        key: option,
        value: option,
        text: option
      };
    });

    useEffect(() => {
      const getDepartments = async () => {
        try {
          const res = await tagsApi.getAllDepartments();
          setDepartmentsResponse(res.departments);
          dispatch(setDepartments(res.departments));
        } catch (err) {
          notifier.requestFailed(err);
        }
      };
      getDepartments();
    }, [dispatch, setDepartmentsResponse]);

    const [selectedUserType, setUserType] = useState<string>(
      !contact.role
        ? contactUtils.formatRole(availableOptions[0].value as UserRole)
        : contactUtils.formatRole(contact.role)
    );
    const isPartner =
      (isCreation && selectedUserType.toLowerCase() === UserRole.Partner) ||
      contactUtils.isPartnerOrPartnerAdmin(contact);
    const isInternal =
      (isCreation && selectedUserType.toLowerCase() === UserRole.Internal) ||
      contactUtils.isInternal(contact);
    const isSupport =
      (isCreation && selectedUserType.toLowerCase() === UserRole.Support) ||
      contactUtils.isSupport(contact);
    const isSE =
      (isCreation && selectedUserType.toLowerCase() === UserRole.SE) ||
      contactUtils.isSupport(contact);
    const isCustomer =
      (isCreation && selectedUserType.toLowerCase() === CUSTOMERS_ROLE_VALUE) ||
      contactUtils.isCustomer(contact);
    const onRoleChange = (userType: string) => {
      setUserType(contactUtils.formatRole(userType as UserRole));
      setDepartmentTag(getDepartmentOptions[0].value);
    };
    const mayChangePartnerAdmin =
      isCreation && authUtils.getLoggedInUser().role === UserRole.PartnerAdmin
        ? permissionsUtils.checkPermissions(
            permissions.contacts.mayChangePartnerAdmin
          )
        : permissionsUtils.checkPermissions(
            permissions.contacts.mayChangePartner
          );
    return (
      <Form onSubmit={onFormSubmit}>
        {isCreation && (
          <Form.Select
            data-testid='select-role'
            required
            label='Choose Contact Type'
            fluid
            placeholder='Select'
            text={selectedUserType}
            value={selectedUserType}
            options={availableOptions}
            onChange={(event, data) => {
              contact = { name: contact.name, email: contact.email } as Contact;
              handleChange('role', data.value as string);
              onRoleChange(data.value as string);
            }}
          />
        )}
        <Form.Input
          label='Name'
          data-testid='create-user-name'
          required
          placeholder='Name'
          value={contact.name || ''}
          autoFocus
          onChange={event => handleChange('name', event.target.value)}
        />
        {isPartner && maySetPartnerCompany && (
          <PartnerCompanyDropdown
            defaultValue={contact.partnerCompany || ''}
            enabled={isCreation}
            onChange={value => handleChange('partnerCompany', value)}
          />
        )}
        {(contactUtils.isInternalContact(
          contactUtils.roleFromString(selectedUserType)
        ) ||
          contactUtils.isInternalContact(contact.role)) && (
          <Form.Select
            label='Department'
            data-testid='create-department'
            required
            fluid
            options={getDepartmentOptions}
            placeholder='Select Department'
            value={contact.department || localTag}
            onChange={(event, data) =>
              handleChange('department', data.value as string)
            }
          />
        )}
        {isCustomer && (
          <Form.Input
            label='Company Name'
            data-testid='create-company-name'
            required
            placeholder='Company Name'
            value={contact.company || ''}
            onChange={event => handleChange('company', event.target.value)}
          />
        )}
        <Form.Input
          label='Email'
          data-testid='create-email'
          required
          disabled={!isCreation}
          placeholder='Email'
          type='email'
          error={isValid}
          value={contact.email || ''}
          onChange={event => handleChange('email', event.target.value)}
        />
        {isPartner && mayChangePartnerAdmin && (
          <Form.Checkbox
            label='Admin'
            data-testid='create-partner'
            checked={contact.role === UserRole.PartnerAdmin || false}
            onChange={(event, data) =>
              handleAdminChange(data.checked as boolean)
            }
          />
        )}
        <div className='actions'>
          <Form.Button primary>
            <Icon
              className='button'
              data-testid='create-btn-icon'
              name={submitBtnIcon}
            />
            {submitBtnText}
          </Form.Button>
        </div>
        {isValid && (
          <Message
            data-testid='create-message-error'
            negative
            content='Please enter a valid email'
          />
        )}
      </Form>
    );
  }
);
