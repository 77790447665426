import { Usage } from 'types';

export const topActiveOptions = [
  {
    key: '5',
    text: '5',
    value: 5
  },
  {
    key: '10',
    text: '10',
    value: 10
  },
  {
    key: '50',
    text: '50',
    value: 50
  },
  {
    key: '100',
    text: '100',
    value: 100
  },
  {
    key: '500',
    text: '500',
    value: 500
  }
];

export const activityTimeOptions = [
  {
    key: 'seconds',
    text: 'Seconds',
    value: 'seconds'
  },
  {
    key: 'minutes',
    text: 'Minutes',
    value: 'minutes'
  },
  {
    key: 'hours',
    text: 'Hours',
    value: 'hours'
  },
  {
    key: 'days',
    text: 'Days',
    value: 'days'
  },
  {
    key: 'weeks',
    text: 'Weeks',
    value: 'weeks'
  },
  {
    key: 'months',
    text: 'Months',
    value: 'months'
  },
  {
    key: 'years',
    text: 'Years',
    value: 'years'
  }
];

export const defaultTopologyUsage: Usage = {
  internal: [],
  partners: [],
  external: [],
  totalActiveSeconds: 0
};
