export enum TopologyAction {
  create = 'create',
  delete = 'delete',
  extend_duration = 'extend_duration',
  send_topology_expiration_email = 'send_topology_expiration_email',
  external_router_config = 'external_router_config',
  apply_milestone = 'apply_milestone',
  no_action_selected = 'NONE'
}

export enum CreateTopologyPage {
  user = 'user',
  template = 'template',
  region = 'region',
  tag = 'tag',
  expiration = 'expiration'
}
