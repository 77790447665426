import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

import { apiPrefix } from '../constants/api-routes';
import { Region } from '../types';
import { notifier } from '../utils';

export const useRegions = (): UseQueryResult<Region[]> => {
  return useQuery<Region[]>('regions', () => {
    return axios
      .get(apiPrefix('/v1.0/regions'))
      .then(response => (response.data ? response.data.regions : []))
      .catch(error => {
        notifier.requestFailed(error);
        return [];
      });
  });
};
