import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { apiPrefix } from '../constants/api-routes';
import { Buildserver, CreateBuildserver } from '../types/buildserver';
import { notifier } from '../utils';

export const useBuildserverMutations = () => {
  const queryClient = useQueryClient();

  const useCreateBuildserver = useMutation(
    (buildserver: CreateBuildserver) => {
      return axios
        .post(apiPrefix('/v1.1/buildservers'), buildserver)
        .then(response => {
          notifier.success({
            message: `Scheduled creation of buildserver '${response.data.name}' in region ${response.data.region.label}.`
          });
        })
        .catch(error => {
          notifier.requestFailed(error);
        });
    },
    { onSettled: () => queryClient.invalidateQueries('buildservers') }
  );

  const useDeleteBuildserver = useMutation(
    (buildserver: Buildserver) => {
      return axios
        .delete(apiPrefix(`/v1.1/buildservers/${buildserver.id}`), {
          params: { force: true }
        })
        .then(response => {
          notifier.success({
            message: `Scheduled termination of buildserver '${buildserver.name}'.`
          });
        })
        .catch(error => {
          notifier.requestFailed(error);
        });
    },
    { onSettled: () => queryClient.invalidateQueries('buildservers') }
  );

  return {
    useCreateBuildserver,
    useDeleteBuildserver
  };
};
