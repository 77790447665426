import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbSectionProps,
  Header,
  Icon,
  SemanticShorthandItem,
  StrictBreadcrumbSectionProps
} from 'semantic-ui-react';

import './styles.less';

const defaultBreadcrumbSection: StrictBreadcrumbSectionProps = {
  content: <Icon name='home' />,
  href: '/'
};

type Props = {
  readonly breadcrumbSections: StrictBreadcrumbSectionProps[];
};

export const MainHeader: React.FC<Props> = memo(({ breadcrumbSections }) => {
  const sections: BreadcrumbSectionProps[] = [
    defaultBreadcrumbSection,
    ...(Array.isArray(breadcrumbSections) ? breadcrumbSections : [])
  ].map((section, index) => {
    if (section.href) {
      section.as = () => (
        <Link className='section' to={String(section.href)}>
          {section.content}
        </Link>
      );
    }
    return { ...section, key: index };
  });

  return (
    <Header className='cl-main-header' block>
      <div className='left-content'>
        <Breadcrumb
          icon='caret right'
          sections={sections as SemanticShorthandItem<BreadcrumbSectionProps>[]}
        />
      </div>
    </Header>
  );
});
