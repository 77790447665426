import { find } from 'lodash';
import React, { Fragment, memo } from 'react';
import { Label, Table } from 'semantic-ui-react';

import { VM } from 'types';

import './styles.less';

type Props = {
  readonly vms: ReadonlyArray<VM>;
};

export const VMsTable: React.FC<Props> = memo(({ vms }) => {
  return (
    <Table celled striped className='cl-vms-table'>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{'Name'}</Table.HeaderCell>
          <Table.HeaderCell>{'Private IP'}</Table.HeaderCell>
          <Table.HeaderCell>{'Credentials'}</Table.HeaderCell>
          <Table.HeaderCell>{'Role'}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {vms.map((vm: VM) => {
          const access = find(vm.access, { protocol: 'ssh' });

          return (
            <Table.Row key={vm.name}>
              <Table.Cell collapsing>{vm.name}</Table.Cell>
              <Table.Cell collapsing>{access && access.privateIp}</Table.Cell>
              <Table.Cell collapsing singleLine={false}>
                {access && (
                  <Fragment>
                    <Label size='tiny' color='blue'>
                      {access.username}
                    </Label>
                    <Label size='tiny' color='blue'>
                      {access.password}
                    </Label>
                  </Fragment>
                )}
              </Table.Cell>
              <Table.Cell collapsing>
                <Label size='tiny' color='grey'>
                  {vm.role}
                </Label>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
});
