import cx, { Value } from 'classnames';
import React, { memo } from 'react';

import './styles.less';

type Props = {
  readonly title: string;
  readonly className?: Value;
  readonly children?: React.ReactNode;
};

export const AuthFormContainer: React.FC<Props> = memo(
  ({ children, title, className }) => (
    <div className={cx('cl-auth-form-container', className)}>
      <h2 className='form-title'>{title}</h2>
      <div className='form-content'>{children}</div>
    </div>
  )
);
