import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';

import { authUtils, interpolateRoute } from 'utils';

import { routes } from 'constants/routing';

type Props = {
  readonly id?: number;
  readonly icon?: boolean;
  readonly text?: string;
};

export const CreateTopologyLink: React.FC<Props> = memo(
  ({ id, icon, text }) => {
    if (!id) {
      id = authUtils.getLoggedInUser().id;
    }

    return (
      <Link to={interpolateRoute(routes.createTopology.path, { id })}>
        <Button primary>
          {icon && <Icon className='button' name='add circle' />}
          {text}
        </Button>
      </Link>
    );
  }
);

CreateTopologyLink.defaultProps = {
  icon: true,
  text: 'Create New Topology'
};
