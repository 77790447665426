/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */
import { useMutation, useQuery, useQueryClient } from 'react-query';

import axios from 'axios';

import { interpolateRoute, notifier } from 'utils';

import { apiPrefix, apiRoutes } from 'constants/api-routes';

import qs from 'qs';

export interface CreatePartner {
  name: string;
  domains: string[];
}

export interface Partner extends CreatePartner {
  uuid: string;
  createdAt: string;
}

const getPartners = async (names: string[]): Promise<Partner[]> => {
  const response = await axios.get(apiPrefix(apiRoutes.partnersList), {
    params: { name: names },
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
  });
  return response.data.partners;
};

export const createPartner = async (
  partner: CreatePartner
): Promise<Partner> => {
  const response = await axios.post(
    apiPrefix(apiRoutes.partnersCreate),
    partner
  );
  notifier.success({
    message: `Partner '${response.data.name}' created.`
  });
  return response.data;
};

export const deletePartner = async (partner: Partner) => {
  await axios.delete(
    apiPrefix(
      interpolateRoute(apiRoutes.partnersDelete, { uuid: partner.uuid })
    )
  );
  notifier.success({
    message: `Partner '${partner.name}' deleted.`
  });
};

interface UpdatePartnerParams {
  partner: Partner;
  newName: string | undefined;
  newDomains: string[] | undefined;
}

export const updatePartner = async (params: UpdatePartnerParams) => {
  const payload: { name?: string; domains?: string[] } = {};
  const messages: string[] = [];

  if (params.newName !== undefined) {
    payload.name = params.newName;
    messages.push(
      `Update name for partner '${params.partner.name}' to '${params.newName}'`
    );
  }

  if (params.newDomains !== undefined) {
    payload.domains = params.newDomains;
    messages.push(
      `Update domains for partner '${
        params.partner.name
      }' to ${params.newDomains.join(', ')}`
    );
  }
  const response = await axios.patch(
    apiPrefix(
      interpolateRoute(apiRoutes.partnersUpdate, { uuid: params.partner.uuid })
    ),
    payload
  );

  notifier.success({
    message: messages.join(', ')
  });
  return response.data;
};

export const usePartners = (names: string[]) => {
  return useQuery(['partners', names], () => getPartners([]));
};

export interface IIndexable<T = any> {
  [key: string]: T;
}

export const usePartnersMutations = (): IIndexable => {
  const queryClient = useQueryClient();

  const useCreatePartner = useMutation(createPartner, {
    onSettled: () => queryClient.invalidateQueries('partners'),
    onError: error => {
      notifier.requestFailed(error);
    }
  });

  const useDeletePartner = useMutation(deletePartner, {
    onSettled: () => queryClient.invalidateQueries('partners'),
    onError: error => {
      notifier.requestFailed(error);
    }
  });

  const useUpdatePartner = useMutation(updatePartner, {
    onSettled: () => queryClient.invalidateQueries('partners'),
    onError: error => {
      notifier.requestFailed(error);
    }
  });

  return {
    useCreatePartner,
    useUpdatePartner,
    useDeletePartner
  };
};
