/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */

import { Partner } from 'api/usePartners';

import { Command, FunctionWithArgs, SerializedCommand } from './Command';

const NEW_PARTNER_UUID: string = '<UUID to be generated by backend>';

class AddPartner implements Command {
  partner: string;
  domains: string[];

  constructor(partner: string, domains: string[]) {
    this.partner = partner;
    this.domains = domains;
  }

  preview(partners: Partner[]): Partner[] {
    return [
      ...partners,
      {
        uuid: NEW_PARTNER_UUID,
        name: this.partner,
        domains: this.domains,
        createdAt: new Date().toISOString()
      }
    ];
  }

  getApiCall(): FunctionWithArgs {
    return {
      functionName: 'useCreatePartner',
      args: [{ name: this.partner, domains: this.domains }]
    };
  }

  asString(): string {
    const domains = this.domains.length > 1 ? 'domains' : 'domain';

    return (
      'Create new partner "' +
      this.partner +
      `", with ${domains} ` +
      this.domains.map(d => `"${d}"`).join(', ')
    );
  }

  serialize(): SerializedCommand {
    return {
      type: 'AddPartner',
      data: {
        partner: this.partner,
        domains: this.domains
      }
    };
  }
}

export default AddPartner;
export { AddPartner, NEW_PARTNER_UUID };
