import { find, isEmpty } from 'lodash';
import React, { memo } from 'react';
import { Card, Icon } from 'semantic-ui-react';

import { isTopologyInactive, isTopologyUp } from 'utils';

import { AOSUICard } from 'views/Topology/AOSUICard';
import { MilestoneActionsCard } from 'views/Topology/MilestoneActionsCard';
import { PredefinedActionsCard } from 'views/Topology/PredefinedActionsCard';
import { TelemetryStreamingCard } from 'views/Topology/TelemetryStreamingCard';
import { VMsTable } from 'views/Topology/VMsTable';

import { NoDataPlaceholder } from 'components/NoDataPlaceholder';
import { TopologyDetails } from 'types';

import './styles.less';

type Props = {
  readonly topology: TopologyDetails;
};

export const TopologyCards: React.FC<Props> = memo(({ topology }) => {
  const [isIdVisible, setIsIdVisible] = React.useState('');
  const supportedActionsAvailable =
    topology.templateData &&
    topology.templateData.params &&
    topology.templateData.params.hasOwnProperty('supportedActions');
  const supportedActions: string[] = supportedActionsAvailable
    ? (topology.templateData?.params?.supportedActions as string[])
    : [];
  const vms = topology.vms;
  const aosVM = find(vms, { role: 'aos' });
  const telemetryStreamingVM = find(vms, { role: 'bastion' });
  const name = !isEmpty(topology) ? topology.name.toLowerCase() : '';
  const topologyIsDown = !isEmpty(topology)
    ? isTopologyInactive(topology)
    : true;
  const topologyIsUp = !isEmpty(topology) ? isTopologyUp(topology) : false;
  return (
    <div className='topology-card'>
      <div className='content-div'>Connections</div>
      <Card
        link
        className='content-card'
        fluid
        onClick={() => setIsIdVisible(isIdVisible === 'aosUI' ? '' : 'aosUI')}
      >
        <Card.Description className='content-font'>
          <Icon name='grid layout' />
          Apstra UI
        </Card.Description>
        {isIdVisible === 'aosUI' && <AOSUICard vm={aosVM} enabled={true} />}
      </Card>
      <Card
        link
        className='content-card'
        fluid
        onClick={() =>
          setIsIdVisible(
            isIdVisible === 'telemetryStreaming' ? '' : 'telemetryStreaming'
          )
        }
      >
        <Card.Description className='content-font'>
          <Icon name='feed' />
          Telemetry Streaming
        </Card.Description>
        {isIdVisible === 'telemetryStreaming' && (
          <TelemetryStreamingCard vm={telemetryStreamingVM} enabled={true} />
        )}
      </Card>
      <div className='content-div'>Topology Details</div>
      <Card className='content-card' fluid>
        {isEmpty(vms) ? <NoDataPlaceholder /> : <VMsTable vms={vms} />}
      </Card>
      <div className='content-div'>Topology Actions</div>
      {name.includes('customer_lab') && !name.includes('freeform') && (
        <Card
          link
          className='content-card'
          fluid
          onClick={() =>
            setIsIdVisible(isIdVisible === 'milestone' ? '' : 'milestone')
          }
        >
          <Card.Description className='content-font'>
            <Icon name='map pin' />
            Revert Milestone
          </Card.Description>
          {isIdVisible === 'milestone' && (
            <MilestoneActionsCard
              topologyUuid={topology.uuid}
              isUp={topologyIsUp}
              topologyIsDown={topologyIsDown}
            />
          )}
        </Card>
      )}
      <Card
        link
        className='content-card'
        fluid
        onClick={() =>
          setIsIdVisible(isIdVisible === 'interface' ? '' : 'interface')
        }
      >
        <Card.Description className='content-font'>
          <Icon name='server' />
          Settings and Switches
        </Card.Description>
        {isIdVisible === 'interface' && (
          <PredefinedActionsCard
            topology={topology}
            refetchData={() => {
              window.location.reload();
            }}
            supportedActions={supportedActions}
          />
        )}
      </Card>
    </div>
  );
});
