import React from 'react';
import { Progress } from 'semantic-ui-react';

import { Buildserver } from 'types/buildserver';

interface Props {
  buildserver: Buildserver;
}

export const BuildserverUtilization: React.FC<Props> = ({ buildserver }) => {
  if (buildserver.ram.total > 0) {
    return (
      <Progress
        percent={Math.floor(
          (buildserver.ram.used / buildserver.ram.total) * 100
        )}
        progress
        success={buildserver.ram.used / buildserver.ram.total < 0.7}
        error={buildserver.ram.used / buildserver.ram.total >= 0.7}
      />
    );
  } else {
    return <React.Fragment>N/A</React.Fragment>;
  }
};
