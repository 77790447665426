/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */

import { AddPartner } from './AddPartner';
import { Command } from './Command';
import { DeletePartner } from './DeletePartner';
import { EditPartner } from './EditPartner';

/**
 * Replaces the old value with the new value if both are defined.
 *
 * @param oldValue - The old value.
 * @param newValue - The new value.
 * @returns The new value if both the new value and the old value are defined,
 *          otherwise returns undefined.
 */
function replaceWithNewIfDefined<T>(
  oldValue: T | undefined,
  newValue: T | undefined
): T | undefined {
  let result: T | undefined;
  if (newValue === undefined && oldValue === undefined) {
    result = undefined;
  } else if (newValue === undefined && oldValue !== undefined) {
    result = oldValue;
  } else if (newValue !== undefined && oldValue === undefined) {
    result = newValue;
  } else if (newValue !== oldValue) {
    result = newValue;
  }
  return result;
}

/**
 * Optimizes the given array of commands.
 *
 * The optimization is done by removing commands that are redundant.
 *
 * There are 4 cases total:
 *
 * 1. AddPartner + N x [EditPartner]
 *    This can be optimized to a single AddPartner command with parameters of the
 *    last EditPartner command.
 * 2. N x [EditPartner]
 *    This can be optimized to a single EditPartner command with parameters
 *    aggregated from all EditPartner commands.
 * 3. AddPartner + N x [EditPartner] + DeletePartner
 *    This is a no-op and can be optimized to an empty array.
 * 4. N x [EditPartner] + DeletePartner
 *    This can be optimized to a single DeletePartner command.
 *
 * @param {Command[]} commands - Array of commands to be optimized.
 */
export const optimized = (commands: Command[]): Command[] => {
  const partnerCommands = new Map<string, Command>();

  // maps original partner name to new partner name to track all name changes
  const partnerNameChanges = new Map<string, string>();

  for (const command of commands) {
    if (command instanceof AddPartner) {
      partnerCommands.set(command.partner, command);
    } else if (command instanceof EditPartner) {
      const originalPartnerName =
        partnerNameChanges.get(command.partner.name) ?? command.partner.name;
      if (partnerCommands.has(originalPartnerName)) {
        const existing = partnerCommands.get(originalPartnerName);
        if (existing instanceof AddPartner) {
          const name: string =
            command.newName !== undefined &&
            command.newName !== existing.partner
              ? command.newName
              : existing.partner;
          const domains: string[] =
            command.newDomains !== undefined &&
            command.newDomains !== existing.domains
              ? command.newDomains
              : existing.domains;
          partnerCommands.set(existing.partner, new AddPartner(name, domains));
        } else if (existing instanceof EditPartner) {
          const name = replaceWithNewIfDefined(
            existing.newName,
            command.newName
          );
          const domains = replaceWithNewIfDefined(
            existing.newDomains,
            command.newDomains
          );

          partnerCommands.set(
            originalPartnerName,
            new EditPartner(existing.partner, name, domains)
          );

          if (name !== undefined) {
            partnerNameChanges.set(name, existing.partner.name);
          }
        }
      } else {
        partnerCommands.set(originalPartnerName, command);
        if (command.newName !== undefined) {
          partnerNameChanges.set(command.newName, originalPartnerName);
        }
      }
    } else if (command instanceof DeletePartner) {
      if (partnerCommands.has(command.deletedPartner.name)) {
        const existing = partnerCommands.get(command.deletedPartner.name);
        if (existing instanceof AddPartner) {
          partnerCommands.delete(existing.partner);
        } else if (existing instanceof EditPartner) {
          partnerCommands.set(
            existing.partner.name,
            new DeletePartner(existing.partner)
          );
        }
      } else {
        partnerCommands.set(command.deletedPartner.name, command);
      }
    }
  }

  return Array.from(partnerCommands.values());
};
