import { ApexOptions } from 'apexcharts';

import {
  commonMultiSeriesProps,
  commonProps,
  toolbarProps
} from './ChartOptions';

export const topologyPerRegionOptions = (loading: boolean) => {
  const props = JSON.parse(JSON.stringify(commonProps(loading)));
  const modified = {
    chart: {
      id: 'Topologies_per_region',
      type: 'bar',
      toolbar: toolbarProps('Region'),
      zoom: {
        enabled: true
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
        dataLabels: {
          position: 'top'
        }
      }
    },
    dataLabels: {
      enabled: true,
      position: 'top'
    },
    title: {
      text: 'Topologies per region',
      align: 'center'
    },
    xaxis: {
      tickPlacement: 'on',
      title: {
        text: 'Regions',
        style: {
          color: 'gray'
        }
      }
    },
    yaxis: {
      title: {
        text: 'Number of Topologies',
        style: {
          color: 'gray'
        }
      }
    }
  };
  return Object.assign(props, modified);
};

export const ramUtilizationOptions = (loading: boolean) => {
  const props = JSON.parse(JSON.stringify(commonProps(loading)));
  const modified = {
    chart: {
      id: 'Region_Utilization',
      type: 'bar',
      toolbar: toolbarProps('Region'),
      zoom: {
        enabled: true
      }
    },
    dataLabels: {
      enabled: true,
      position: 'top',
      labels: {
        formatter: (val: string | number | number[], opts?: any) => {
          return val + '%';
        }
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
        dataLabels: {
          position: 'top'
        }
      }
    },
    title: {
      text: 'Region Utilization',
      align: 'center'
    },
    xaxis: {
      title: {
        text: 'Regions',
        style: {
          color: 'gray'
        }
      },
      tickPlacement: 'on'
    },
    yaxis: {
      title: {
        text: 'RAM utilization %',
        style: {
          color: 'gray'
        }
      },
      labels: {
        show: true,
        formatter: (val: number, opts?: any) => {
          return val + '%';
        }
      }
    }
  };
  return Object.assign(props, modified);
};

export const activeFailingAndActivelyDeployedTopologyOptions = (
  loading: boolean
) => {
  const props = JSON.parse(JSON.stringify(commonMultiSeriesProps(loading)));
  const modified = {
    chart: {
      id: 'active_failing_and_actively_deployed_topologies',
      type: 'line',
      toolbar: toolbarProps('Date'),
      zoom: {
        enabled: true
      }
    },
    title: {
      text: 'Active, Failing, and Actively Deployed Topologies',
      align: 'center'
    },
    yaxis: {
      title: {
        text: 'Number of Topologies',
        style: {
          color: 'gray'
        }
      }
    }
  };
  return Object.assign(props, modified);
};

export const activeTopologyPerRegionsOptions = (
  loading: boolean
): ApexOptions => {
  const props = JSON.parse(JSON.stringify(commonMultiSeriesProps(loading)));
  const modified = {
    chart: {
      id: 'Active_topologies_per_region',
      type: 'line',
      stacked: false,
      toolbar: toolbarProps('Date'),
      zoom: {
        enabled: true
      }
    },
    title: {
      text: 'Active Topologies per region',
      align: 'center'
    },
    yaxis: {
      title: {
        text: 'Number of Active Topologies',
        style: {
          color: 'gray'
        }
      }
    }
  };
  return Object.assign(props, modified);
};
