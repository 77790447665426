import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

import { notifier } from 'utils';

import { apiPrefix } from 'constants/api-routes';

export const useGetValidRoles = (): UseQueryResult<{ roles: string[] }> => {
  return useQuery<{ roles: string[] }>(
    ['QuotaValidRoles'],
    () => {
      return axios
        .get(apiPrefix('/v1.0/contacts/roles'))
        .then(response => (response.data ? response.data : []))
        .catch(error => {
          notifier.requestFailed(error);
          return [];
        });
    },
    {
      notifyOnChangeProps: 'tracked'
    }
  );
};
