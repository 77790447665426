import { appConfig } from 'constants/config';

export const apiPrefix = (url: string) => `${appConfig.baseURL}${url}`;

export const apiRoutes = {
  loginEmail: '/v1.0/login/email',
  loginGoogle: '/v1.0/login/google',
  loginJuniper: '/v1.0/login/okta',
  resetPassword: '/v1.0/contacts/reset-password/:uuid',
  resetPasswordEmail: '/v1.0/contacts/start-anon-reset-password/:email',
  buildservers: '/v1.1/buildservers',
  buildserverById: '/v1.1/buildservers/:id',
  contactById: '/v1.0/contacts/:id',
  contacts: '/v1.0/contacts',
  departments: '/v1.2/contacts/departments',
  tags: '/v1.2/tags',
  tagsById: '/v1.2/tags/:id',
  templates: '/v1.0/templates',
  templateById: '/v1.0/templates/:id',
  manageTemplateImage: '/v1.1/templates/:templateName/image/:imageName',
  topologies: '/v1.0/topologies',
  topologiesWithCreator: '/v1.2/topologies',
  topologyById: '/v1.0/topologies/:uuid',
  topologyWithCreatorById: '/v1.2/topologies/:uuid',
  topologyTagUpdate: '/v1.0/topologies/:uuid/tags',
  topologySendEmailAction: '/v1.0/topologies/:uuid/send_email',
  topologyExtendDuration: '/v1.0/topologies/:uuid/extendDuration',
  topologyAction: '/v1.0/topologies/:uuid/:action',
  milestones: '/v1.0/topologies/:uuid/milestones',
  milestoneStatus: '/v1.0/topologies/:uuid/milestoneStatus',
  applyMilestone: '/v1.0/topologies/:uuid/milestones/:name/apply',
  regions: '/v1.0/regions',
  regionsCapacity: '/v1.0/regions_capacity',
  newContactReport: '/v1.2/reports/created_contacts',
  newContactEmailReport: '/v1.2/reports/created_contacts_email',
  timeseriesData: '/v1.2/reports/timeseries',
  quotas: '/v1.2/quotas/topologies',
  usageReport: '/v1.2/reports/usage',
  usageReportEmail: '/v1.2/reports/usage_email',
  chat: '/v1.2/chat',
  knowledgeBases: '/v1.2/chat/knowledge_bases',
  tasks: '/v1.2/tasks/:taskId',
  feedbackForChat: '/v1.2/chat/feedback/:uuid',
  partnersList: '/v1.2/partners',
  partnersCreate: '/v1.2/partners',
  partnersDelete: '/v1.2/partners/:uuid',
  partnersUpdate: '/v1.2/partners/:uuid',
  lab: '/v1.3/labs',
  labTemplates: '/v1.3/labtemplates',
  labById: '/v1.3/labs/:uuid'
};
