import { isEmpty } from 'lodash';
import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader, StrictBreadcrumbSectionProps } from 'semantic-ui-react';

import {
  authUtils,
  convertTimeToISO,
  interpolateRoute,
  notifier,
  toggleTokenStorageKey
} from 'utils';

import { topologiesApi } from 'api/topology';
import { RequestData } from 'components';
import { apiRoutes } from 'constants/api-routes';
import { routes } from 'constants/routing';
import { useRouter } from 'hooks/useRouter';
import { MainLayout } from 'layouts';
import { CreateTopology, ToggleObject } from 'types';

import { setTopologyFieldName } from 'actions/actions';
import { setSelectedContacts } from 'features/contacts';
import { initialFieldName, RootState } from 'Reducers/contactReducer';

import { CreateTopologySingleView } from './CreateTopologySingleView';
import { CreateTopologyWizard } from './CreateTopologyWizard';

import './styles.less';

export const CreateTopologyView: React.FC = memo(() => {
  const router = useRouter();
  const dispatch = useDispatch();
  const contactId: number = Number((router.match.params as { id: number }).id);
  const toggleReduxValue: ToggleObject = useSelector(
    (state: RootState) => state.toggleValue
  );
  const toggleValue = toggleReduxValue.default
    ? JSON.parse(authUtils.getToken(toggleTokenStorageKey))
    : JSON.parse(toggleReduxValue.value);
  const id = contactId ? contactId : authUtils.getLoggedInUser().id;
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [topology, setNewTopology] = useState<CreateTopology>(
    {} as CreateTopology
  );
  const createTopology = async () => {
    const tags =
      selectedTags.length >= 1
        ? selectedTags.map(tag => {
            const selected = tag.split(':');
            return {
              name: selected[0],
              value: selected[1]
            };
          })
        : [];
    try {
      const { topologyUuid } = await topologiesApi.create({
        createdForId: topology.createdForId,
        template: topology.template,
        region: topology.region,
        emailNotificationsEnabled: topology.emailNotificationsEnabled,
        expirationTime: !isEmpty(topology.expirationTime)
          ? convertTimeToISO(
              topology.expirationTime,
              'MM-DD-YYYY HH:mm'
            ).replace('Z', '')
          : '',
        tags
      });
      notifier.success({
        message: 'Topology scheduled for creation'
      });
      setNewTopology({} as any);
      dispatch(setSelectedContacts([]));
      dispatch(setTopologyFieldName(initialFieldName));
      router.push(interpolateRoute(routes.topology.path, { id: topologyUuid }));
    } catch (err) {
      notifier.requestFailed(err);
    }
  };
  return (
    <RequestData
      url={interpolateRoute(apiRoutes.contactById, { id })}
      customLoader
      customError
    >
      {({ data: contact, loading, error }) => {
        const breadcrumbSections: StrictBreadcrumbSectionProps[] = [
          {
            content: routes.contacts.label,
            href: routes.contacts.path
          },
          {
            content: loading ? (
              <Loader active inline='centered' size='tiny' />
            ) : contact ? (
              contact.name
            ) : (
              '<Not Found>'
            ),
            href: contact
              ? interpolateRoute(routes.contactDetails.path, {
                  id: contact.id,
                  active: 'details'
                })
              : undefined
          },
          {
            content: routes.createTopology.label,
            active: true
          }
        ];
        const dataReady = contact && !loading;
        return (
          <MainLayout
            breadcrumbSections={breadcrumbSections}
            className='cl-create-topology-view'
          >
            {dataReady && !toggleValue && (
              <CreateTopologyWizard
                contactId={contactId}
                topology={topology}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                onChange={(value: CreateTopology) => setNewTopology(value)}
                createTopology={createTopology}
              />
            )}
            {dataReady && toggleValue && (
              <CreateTopologySingleView
                contact={contact}
                topology={topology}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                onChange={(value: CreateTopology) => setNewTopology(value)}
                createTopology={createTopology}
              />
            )}
            {error && <h2>{'Contact Not Found'}</h2>}
            {loading && <Loader active />}
          </MainLayout>
        );
      }}
    </RequestData>
  );
});
