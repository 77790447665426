import axios from 'axios';
import React, { memo, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { QueryFunction, useQuery } from 'react-query';
import { Button, Icon, Label, Message, Modal } from 'semantic-ui-react';

import { apiPrefix } from 'constants/api-routes';
import { TopologyAction } from 'enums/topologyAction';

import './styles.less';

type Props = {
  readonly topologyUuid: string;
  readonly topologyAction: TopologyAction;
  readonly isOpen: boolean;
  readonly onClose: () => void;
};

type TopologyTextProps = {
  readonly topologyUuid: string;
  readonly topologyAction: TopologyAction;
};

interface LogRecord {
  timestamp: Date;
  message: string;
  ingestionTimestamp: Date;
}

interface Logs {
  logs: LogRecord[];
}

interface CopyLogsButtonProps {
  logs: LogRecord[];
  isLoading: boolean;
  isError: boolean;
}

interface LogRowProps {
  readonly index: number;
  readonly log: LogRecord;
}

interface QueryParam {
  topologyUuid: string;
  topologyAction: TopologyAction;
}

const getLogs: QueryFunction<Logs> = async ({ queryKey }) => {
  const { topologyUuid, topologyAction } = queryKey[1] as QueryParam;

  return axios
    .get(
      apiPrefix(
        `/v1.2/topologies/${topologyUuid}/logs?action=${topologyAction}`
      )
    )
    .then(response => response.data);
};

const LogRow: React.FC<LogRowProps> = memo(({ log, index }) => (
  <div className={'log-row'}>
    {log.message.split('\n').map(line => (
      <pre key={index} className={'log-row'}>
        {line}
      </pre>
    ))}
  </div>
));

function useLogs(props: TopologyTextProps) {
  const { isLoading, isError, data, error } = useQuery<Logs>(
    ['topologyLogs', props],
    getLogs,
    {
      keepPreviousData: true,
      initialData: {
        logs: []
      }
    }
  );
  return { isLoading, isError, data, error };
}

const LogText: React.FC<TopologyTextProps> = memo(
  ({ topologyUuid, topologyAction }) => {
    const { isError, data, error, isLoading } = useLogs({
      topologyUuid,
      topologyAction
    });

    if (isError) {
      return <div>{`Failed to fetch logs: ${error}`}</div>;
    }

    if (!isLoading && data) {
      if (data.logs.length > 0) {
        return (
          <div>
            {data.logs.map((log, index) => (
              <LogRow key={index} index={index} log={log} />
            ))}
          </div>
        );
      } else {
        return (
          <Message warning>
            <Message.Header>No logs available</Message.Header>
            <Message.Content>
              Be patient, it may take several minutes before logs are dumped to
              storage.
            </Message.Content>
          </Message>
        );
      }
    }
    return <div>Fetching logs...</div>;
  }
);

const CopyLogsToClipboard: React.FC<CopyLogsButtonProps> = memo(
  ({ logs, isLoading, isError }) => {
    const [copied, setCopied] = React.useState(false);

    useEffect(() => {
      if (copied) {
        setTimeout(() => {
          setCopied(false);
        }, 3000);
      }
    }, [copied]);

    return (
      <div>
        <CopyToClipboard
          text={logs.map(log => log.message).join('\n')}
          onCopy={() => setCopied(true)}
        >
          <Button
            disabled={isLoading || isError || logs.length === 0}
            icon
            labelPosition={'right'}
          >
            Copy
            {copied ? (
              <Icon name={'checkmark'} color={'green'} />
            ) : (
              <Icon name='copy' />
            )}
          </Button>
        </CopyToClipboard>
        {copied && (
          <Label pointing={'left'} color={'green'}>
            Copied!
          </Label>
        )}
      </div>
    );
  }
);

const CopyLogsToClipboardButton: React.FC<TopologyTextProps> = memo(
  ({ topologyUuid, topologyAction }) => {
    const { isLoading, isError, data } = useLogs({
      topologyUuid,
      topologyAction
    });

    return (
      <CopyLogsToClipboard
        logs={data ? data.logs : []}
        isLoading={isLoading}
        isError={isError}
      />
    );
  }
);

export const LogsModal: React.FC<Props> = memo(
  ({ topologyUuid, topologyAction, isOpen, onClose }) => {
    if (!isOpen) {
      return null;
    }

    return (
      <Modal closeIcon={true} open={isOpen} onClose={onClose}>
        <Modal.Header>
          Topology {topologyUuid} {topologyAction} logs
        </Modal.Header>
        <Modal.Content scrolling={true}>
          <Modal.Description>
            <LogText
              topologyUuid={topologyUuid}
              topologyAction={topologyAction}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <CopyLogsToClipboardButton
            topologyUuid={topologyUuid}
            topologyAction={topologyAction}
          />
          <Button icon labelPosition={'right'} color='green' onClick={onClose}>
            Close
            <Icon name={'close'} />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
);
