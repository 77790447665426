import React, { memo, useState } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';

import { authUtils, contactUtils, notifier } from 'utils';

import { topologiesApi } from 'api/topology';
import { ExtendDuration } from 'components/ExtendDuration';
import { LoggedInUser, Topology } from 'types';

const options: Partial<DropdownItemProps>[] = [
  { text: '1 hour', value: '1h' },
  { text: '3 hours', value: '3h' },
  { text: '6 hours', value: '6h' },
  { text: '12 hours', value: '12h' },
  { text: '1 day', value: '1d' },
  { text: '2 days', value: '2d' }
];

const inAdOptions: Partial<DropdownItemProps>[] = [
  { text: '1 hour', value: '1h' },
  { text: '3 hours', value: '3h' },
  { text: '6 hours', value: '6h' },
  { text: '12 hours', value: '12h' },
  { text: '1 day', value: '1d' },
  { text: '2 days', value: '2d' },
  { text: '1 week', value: '1w' },
  { text: '2 weeks', value: '2w' }
];

type Props = {
  // trigger can either be text with icon or just icon
  // or just plain text.
  readonly trigger: React.ReactNode;
  readonly topology: Topology;
  readonly onSuccess: () => void;
  readonly enabled: boolean;
  readonly showHeader: boolean;
  // based on the trigger type we will pass
  // loading view as props
  readonly loadingTrigger: React.ReactNode;
  readonly item?: boolean;
  readonly icon?: string | null;
  readonly direction?: 'left' | 'right';
  readonly scrolling?: boolean;
};

export const filterOptionsByRole = (
  user: LoggedInUser
): Partial<DropdownItemProps>[] => {
  if (contactUtils.isInternalUser(user)) {
    return inAdOptions;
  }
  return options;
};

export const ExtendTopologyDuration: React.FC<Props> = memo(
  ({
    topology,
    trigger,
    loadingTrigger,
    onSuccess,
    enabled,
    item,
    showHeader,
    icon,
    direction,
    scrolling
  }) => {
    const [loading, setLoading] = useState(false);
    const user = authUtils.getLoggedInUser();
    const durationOptions = filterOptionsByRole(user);
    const extendDuration = async (duration: string) => {
      setLoading(true);
      try {
        await topologiesApi.extendDuration(topology.uuid, duration);
        notifier.success({
          message: `Duration successfully extended for ${topology.name}`
        });
        onSuccess();
      } catch (err) {
        notifier.requestFailed(err);
      } finally {
        setLoading(false);
      }
    };

    if (loading) {
      return <>{loadingTrigger}</>;
    }

    return (
      <ExtendDuration
        trigger={trigger}
        options={durationOptions}
        onChange={extendDuration}
        enabled={enabled}
        item={item}
        showHeader={showHeader}
        icon={icon}
        direction={direction}
        scrolling={scrolling}
      />
    );
  }
);
