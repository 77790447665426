import axios from 'axios';
import { useState } from 'react';
import { QueryFunction, useQuery } from 'react-query';
import {
  NumberParam,
  QueryParamConfig,
  QueryParamConfigMap,
  StringParam,
  useQueryParams,
  withDefault
} from 'use-query-params';

import { constructQuery } from 'utils/pagination';

import { apiPrefix } from 'constants/api-routes';
import {
  Pagination,
  QueryParam,
  SortField,
  SortOrder
} from 'interfaces/queryParams';
import { Buildserver } from 'types/buildserver';

interface BuildserverListState {
  offset: number;
  total: number;
  buildservers: Buildserver[];
}

export const getBuildservers: QueryFunction<BuildserverListState> = async ({
  queryKey
}) => {
  const { pagination, filter, sort } = queryKey[1] as QueryParam;

  const query = constructQuery(pagination, filter, sort);

  return axios
    .get(apiPrefix(`/v1.1/buildservers?${query}`))
    .then(response => response.data);
};

interface Q extends QueryParamConfigMap {
  filter: QueryParamConfig<string, string>;
  offset: QueryParamConfig<number, number>;
  limit: QueryParamConfig<number, number>;
}

export const useBuildservers = <ItemType>() => {
  const [queryParams, setQueryParams] = useQueryParams<Q>({
    filter: withDefault(StringParam, ''),
    offset: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 10),
    sortColumn: withDefault(StringParam, 'id'),
    sortOrder: withDefault(StringParam, 'ascending')
  });
  const { filter, offset, limit, sortColumn, sortOrder } = queryParams;
  const pagination: Pagination = { offset, limit };
  const sort: SortField = { sortColumn, sortOrder };
  const [selected, setSelected] = useState<ItemType[]>([]);

  const setFilter = (newFilter: string) => {
    setQueryParams({ filter: newFilter }, 'replaceIn');
  };

  const setPagination = (newPagination: Pagination) => {
    setQueryParams(
      {
        ...newPagination
      },
      'replaceIn'
    );
  };

  const setSort = (sortField: SortField) => {
    setQueryParams({ ...sortField }, 'replaceIn');
  };

  const onSort = (clickedColumn: string) => {
    const { sortColumn: col, sortOrder: order } = sort;

    let newOrder: SortOrder =
      order === 'ascending' ? 'descending' : 'ascending';
    if (col !== clickedColumn) {
      newOrder = 'ascending';
    }

    setPagination({ ...pagination, offset: 1 });
    setSort({ sortColumn: clickedColumn, sortOrder: newOrder });
  };

  const { isLoading, isError, data, error } = useQuery<BuildserverListState>(
    ['buildservers', { pagination, filter, sort }],
    getBuildservers,
    {
      keepPreviousData: true,
      refetchInterval: 5000,
      initialData: {
        offset: 0,
        total: 0,
        buildservers: []
      }
    }
  );

  const onSubmitFilter = (value: string) => {
    if (value !== filter) {
      setFilter(value);
      setPagination({ ...pagination, offset: 1 });
    }
  };

  const onChangeLimit = (newLimit: number) => {
    if (newLimit !== pagination.limit) {
      setPagination({ limit: newLimit, offset: 1 });
    }
  };

  const onChangePage = (page: number) => {
    if (page !== pagination.offset) {
      setPagination({ ...pagination, offset: page });
    }
  };

  const totalCount = data ? data.total : 0;
  const buildservers = data ? data.buildservers : [];
  const currentPage = data ? data.offset : 1;

  return {
    onSubmitFilter,
    onChangeLimit,
    onChangePage,
    onSort,
    filter,
    pagination,
    sort,
    isLoading,
    isError,
    error,
    totalPages: Math.ceil(totalCount / pagination.limit),
    totalCount,
    currentPage,
    buildservers,
    selected,
    setSelected
  };
};
