import React, { memo } from 'react';
import { Form, Icon, SemanticICONS } from 'semantic-ui-react';

import { Contact } from 'types';

type Props = {
  readonly contact: Contact;
  readonly submitBtnText: string;
  readonly submitBtnIcon: SemanticICONS;
  readonly onSubmit: () => void;
  readonly onChange: (contact: Contact) => void;
};

export const CustomerContactDetailsForm: React.FC<Props> = memo(
  ({ contact, submitBtnText, submitBtnIcon, onChange, onSubmit }) => {
    const isCreation = !contact || !contact.id;
    contact = contact || {};

    const handleChange = (field: string, value: string | boolean) => {
      onChange({ ...(contact || {}), [field]: value });
    };

    return (
      <Form onSubmit={onSubmit}>
        <Form.Input
          label='Name'
          required
          placeholder='Name'
          value={contact.name || ''}
          autoFocus
          onChange={event => handleChange('name', event.target.value)}
        />

        <Form.Input
          label='Company Name'
          required
          placeholder='Company Name'
          value={contact.company || ''}
          onChange={event => handleChange('company', event.target.value)}
        />

        <Form.Input
          label='Email'
          required
          disabled={!isCreation}
          placeholder='Email'
          type='email'
          value={contact.email || ''}
          onChange={event => handleChange('email', event.target.value)}
        />

        <div className='actions'>
          <Form.Button primary>
            <Icon className='button' name={submitBtnIcon} />
            {submitBtnText}
          </Form.Button>
        </div>
      </Form>
    );
  }
);
