import React, { useEffect } from 'react';

import { getBases, notifier, storeBases } from 'utils';

import { chatbotApi } from 'api/chatbot';
import { KnowledgeSearchResults } from 'components/KnowledgeSearchResults';
import { MainLayout } from 'layouts';

export const KnowledgeBaseSearchView: React.FC = () => {
  const kbBases = getBases();
  useEffect(() => {
    const getKnowledgeBases = async () => {
      try {
        const resp = await chatbotApi.getAllKnowledgeBases();
        storeBases(resp.knowledgeBases);
      } catch (err) {
        notifier.requestFailed(err);
      }
    };
    getKnowledgeBases();
  }, [kbBases]);
  return (
    <MainLayout>
      <KnowledgeSearchResults />
    </MainLayout>
  );
};
