import React, { memo } from 'react';

import { RegionSelection } from 'views/CreateTopology/RegionSelector/RegionSelection';

type Props = {
  readonly selectedRegionName: string | null;
  readonly onChange: (regionName: string) => void;
};

export const DeploymentRegionSelection: React.FC<Props> = memo(
  ({ selectedRegionName, onChange }) => (
    <div className='cl-deployment-region-selection'>
      <h4 className='section-number'>2. Select a Deployment Region</h4>
      <RegionSelection
        selectedRegionName={selectedRegionName}
        onChange={onChange}
      />
    </div>
  )
);
