import React, { memo } from 'react';
import { Redirect } from 'react-router';

import { authUtils, setNotAuthorizedEulaAccepted } from 'utils';

import { Eula } from 'components';
import { routes } from 'constants/routing';
import { useRouter } from 'hooks/useRouter';

export const EulaNotAuthorizedView: React.FC = memo(() => {
  const router = useRouter();
  const returnUrl = router.location.state
    ? (router.location.state as { returnUrl: string }).returnUrl
    : null;

  if (!returnUrl) {
    return <Redirect to={routes.login.path} />;
  }

  if (authUtils.loggedIn()) {
    return <Redirect to={returnUrl} />;
  }

  const onAccept = async () => {
    setNotAuthorizedEulaAccepted();
    router.replace(returnUrl);
  };

  return <Eula onAccept={onAccept} />;
});
