import React, { memo, useState } from 'react';
import { Button, Checkbox, Form } from 'semantic-ui-react';

import './styles.less';

type Props = {
  readonly onAccept: () => void;
  readonly onCancel?: () => void;
};

export const EulaAcceptForm: React.FC<Props> = memo(
  ({ onAccept, onCancel }) => {
    const [acceptChecked, setAcceptChecked] = useState<boolean>(false);
    return (
      <Form className='cl-eula-accept-form'>
        <Checkbox
          required
          label={'I have Read the End User License Agreement'}
          name='role'
          checked={acceptChecked}
          onChange={() => setAcceptChecked(!acceptChecked)}
        />
        <div className='actions'>
          {onCancel && <Button onClick={onCancel}>{'Cancel'}</Button>}
          <Button primary disabled={!acceptChecked} onClick={onAccept}>
            {'Accept'}
          </Button>
        </div>
      </Form>
    );
  }
);
