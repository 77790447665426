import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route } from 'react-router';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import { defaultRoute } from 'constants/routing';

import { appRoutes } from './app-routes';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const App: React.FC = () => (
  <Router>
    <QueryClientProvider client={queryClient}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Switch>
          {appRoutes.map(route => {
            const RouteComponent = route.routeComponent;
            return (
              <RouteComponent
                key={route.path}
                path={route.path}
                component={route.component}
                exact={true}
              />
            );
          })}
          <Redirect to={defaultRoute.path} />
        </Switch>
      </QueryParamProvider>
    </QueryClientProvider>
  </Router>
);

export default App;
