import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Icon, Menu, Popup } from 'semantic-ui-react';

import { authUtils, isNullOrUndefined, toggleTokenStorageKey } from 'utils';

import { ToggleObject } from 'types';

import { toggleUI } from 'actions/actions';
import { RootState } from 'Reducers/contactReducer';

import './styles.less';

export const SupportMenu: React.FC = memo(() => {
  const dispatch = useDispatch();
  const toggleReduxValue: ToggleObject = useSelector(
    (state: RootState) => state.toggleValue
  );
  const toggleSessionValue = authUtils.getToken(toggleTokenStorageKey);
  const compareLocalAndReduxState =
    !toggleReduxValue.default && JSON.parse(toggleReduxValue.value)
      ? toggleReduxValue.value
      : 'false';

  const [value, setValue] = useState(
    !isNullOrUndefined(toggleSessionValue)
      ? toggleSessionValue
      : compareLocalAndReduxState
  );
  const parseToggleValue = JSON.parse(value);
  return (
    <div>
      <div className='support-wrapper'>
        <Menu.Item>
          <div>
            <Icon name='adjust' />
            <Popup
              header='Comfortable with the old UI?'
              content={'Simple click on the toggle button!'}
              trigger={<span>Classic view</span>}
              style={{ margin: '0 -50px 0 100px', float: 'right' }}
              offset={[100, 20]}
              flowing
              position='top left'
            />
            <Checkbox
              className='checkbox-toggle'
              toggle
              checked={parseToggleValue}
              onChange={() => {
                const newValue = JSON.stringify(!parseToggleValue);
                setValue(newValue);
                dispatch(toggleUI({ default: false, value: newValue }));
                authUtils.setToken(toggleTokenStorageKey, newValue);
              }}
            />
          </div>
        </Menu.Item>
      </div>
    </div>
  );
});
