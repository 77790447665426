/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */
import isValidDomain from 'is-valid-domain';
import React, { memo } from 'react';

import { Partner } from 'api/usePartners';

import { Validator } from './EditableElement';
import { EditableList } from './EditableList';

interface PartnerDomainsProps {
  partner: Partner;
  onPartnerDomainsChanged: (partner: Partner, domains: string[]) => void;
}

export const PartnerDomainsList: React.FC<PartnerDomainsProps> = memo(
  ({ partner, onPartnerDomainsChanged }) => {
    const duplicate: Validator = (newValue: string, originalValue?: string) => {
      if (newValue !== originalValue && partner.domains.includes(newValue)) {
        return new Error(`Domain '${newValue}' already exists`);
      }
      return null;
    };
    const validDomain: Validator = (newValue: string) => {
      if (isValidDomain(newValue)) {
        return null;
      }
      return new Error(`'${newValue}' is not a valid domain`);
    };

    return (
      <EditableList
        listItems={partner.domains}
        addItemLabel={'Add domain'}
        onItemsChanged={items => {
          onPartnerDomainsChanged(partner, items);
        }}
        validators={[duplicate, validDomain]}
      />
    );
  }
);
