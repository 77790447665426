import { AxiosRequestConfig } from 'axios';

import { interpolateRoute } from 'utils';

import { apiPrefix, apiRoutes } from 'constants/api-routes';
import {
  ContactUsageReportRequest,
  ContactUsageReportResponse,
  UsageReportRequest,
  UsageReportResponse
} from 'interfaces';

import { API } from './api';

const sendUsageReportEmail = (
  data: UsageReportRequest,
  config?: AxiosRequestConfig
): Promise<UsageReportResponse> => {
  return API.post<UsageReportResponse>(
    apiPrefix(interpolateRoute(apiRoutes.usageReportEmail)),
    data,
    config
  );
};

const sendCreatedContactsReport = (
  data: ContactUsageReportRequest,
  config?: AxiosRequestConfig
): Promise<ContactUsageReportResponse> => {
  return API.post<ContactUsageReportResponse>(
    apiPrefix(interpolateRoute(apiRoutes.newContactEmailReport)),
    data,
    config
  );
};

export const reportsApi = {
  sendUsageReportEmail,
  sendCreatedContactsReport
};
