import React, { memo, useState } from 'react';
import { Form, Input, InputProps } from 'semantic-ui-react';

type Props = {
  readonly inputProps: InputProps;
  readonly label?: string;
};

export const PasswordField: React.FC<Props> = memo(({ inputProps, label }) => {
  const [type, setType] = useState('password');

  const toggleInputType = () => {
    setType(type === 'password' ? 'text' : 'password');
  };
  const iconName = type === 'password' ? 'eye slash' : 'eye';

  return (
    <Form.Field className='cl-password-field'>
      <label>{label}</label>
      <Input
        autoComplete='on'
        icon={{
          name: iconName,
          link: true,
          onClick: toggleInputType
        }}
        placeholder='Password'
        type={type}
        {...inputProps}
      />
    </Form.Field>
  );
});

PasswordField.defaultProps = {
  label: 'Password:'
};
