import { ApstraControllerVMSizing, Survey } from 'types';

const getPossibleSizing = (size: number) => {
  switch (size) {
    case ApstraControllerVMSizing.XXSMALL:
      return {
        possibleVcpuValue: 4,
        possibleMemValue: 16,
        possibleDiskValue: 80
      };
    case ApstraControllerVMSizing.XSMALL:
      return {
        possibleVcpuValue: 4,
        possibleMemValue: 16,
        possibleDiskValue: 80
      };
    case ApstraControllerVMSizing.SMALL:
      return {
        possibleVcpuValue: 8,
        possibleMemValue: 32,
        possibleDiskValue: 160
      };
    case ApstraControllerVMSizing.MEDIUM:
      return {
        possibleVcpuValue: 12,
        possibleMemValue: 64,
        possibleDiskValue: 320
      };
    case ApstraControllerVMSizing.LARGE:
      return {
        possibleVcpuValue: 16,
        possibleMemValue: 128,
        possibleDiskValue: 320
      };
    default:
      return {
        possibleVcpuValue: 16,
        possibleMemValue: 128,
        possibleDiskValue: 320
      };
  }
};

type FabricType =
  | 'threeStageFabric'
  | 'fiveStageFabric'
  | 'freeformFabric'
  | 'other';

const getVMSizingFactor = (fabricType: FabricType): number => {
  switch (fabricType) {
    case 'threeStageFabric':
      return 30;
    case 'fiveStageFabric':
      return 50;
    case 'freeformFabric':
      return 40;
    default:
      return 25;
  }
};

const getWorkerVMPossibleSizing = (numberOfVms: number) => {
  if (numberOfVms < 4) {
    return {
      possibleVcpuValue: 4,
      possibleMemValue: 24,
      possibleDiskValue: 80
    };
  } else {
    return {
      possibleVcpuValue: 6,
      possibleMemValue: 32,
      possibleDiskValue: 160
    };
  }
};

const calculateDevicesPerController = (managedDevices: string) => {
  return {
    number_of_workers: {
      number_of_devices: Number(managedDevices),
      number_of_vm: Math.ceil(Number(managedDevices) / 25)
    }
  };
};

const getApstraControllerVMSizing = (survey: Survey, sizingFactor: number) => {
  const getVMSizing =
    Math.round(Number(survey.managed_devices_count)) / sizingFactor;
  const controllerVMSizingAdjusted =
    survey.design_type === 'threeStageFabric'
      ? Math.ceil(getVMSizing)
      : Math.floor(getVMSizing);
  return {
    apstra_controller_vm: {
      min_vcpu_count: getPossibleSizing(controllerVMSizingAdjusted)
        ?.possibleVcpuValue,
      min_mem_gb: getPossibleSizing(controllerVMSizingAdjusted)
        ?.possibleMemValue,
      min_disk_gb: getPossibleSizing(controllerVMSizingAdjusted)
        ?.possibleDiskValue
    }
  };
};

const getApstraWorkerVMSizing = (survey: Survey) => {
  const controllerVMSizing = Math.ceil(
    Number(survey.managed_devices_count) / 25
  );
  return {
    apstra_worker_vms: {
      min_vcpu_count: getWorkerVMPossibleSizing(controllerVMSizing)
        ?.possibleVcpuValue,
      min_mem_gb: getWorkerVMPossibleSizing(controllerVMSizing)
        ?.possibleMemValue,
      min_disk_gb: getWorkerVMPossibleSizing(controllerVMSizing)
        ?.possibleDiskValue
    }
  };
};

const getDevicesByFabricDesignType = (survey: Survey) => {
  if (!survey.managed_devices_count) return {};
  switch (survey.design_type) {
    case 'threeStageFabric':
      return calculateDevicesPerController(survey.managed_devices_count);
    case 'fiveStageFabric':
      return calculateDevicesPerController(survey.managed_devices_count);
    case 'freeformFabric':
      return calculateDevicesPerController(survey.managed_devices_count);
    default:
      return {};
  }
};

export const getMatchesFromSurvey = (survey: Survey) => {
  if (
    survey.managed_devices_count &&
    survey.design_type &&
    Number(survey.managed_devices_count) <= 350
  ) {
    const getBPOutput = {
      ...getApstraControllerVMSizing(
        survey,
        getVMSizingFactor(survey.design_type as FabricType)
      ),
      ...getDevicesByFabricDesignType(survey),
      ...getApstraWorkerVMSizing(survey)
    };
    return getBPOutput;
  } else {
    return;
  }
};

const getFlowSize = (managedDevices: number) => {
  if (managedDevices <= 25) {
    return 'Small, Up to 5,000 Flows Per Second';
  } else if (managedDevices <= 50) {
    return 'Medium, Up to 10,000 Flows Per Second';
  } else if (managedDevices <= 75) {
    return 'Large, Up to 15,000 Flows Per Second';
  } else {
    return 'XLarge, >15,000 Flows Per Second';
  }
};

const getServerSize = (managedDevices: number) => {
  if (managedDevices <= 25) {
    return '8 vCPU, 32 GB RAM, 4 TB disk';
  } else if (managedDevices <= 50) {
    return '16 vCPU, 64 GB RAM, 4 TB disk';
  } else if (managedDevices <= 75) {
    return '24 vCPU, 64 GB RAM, 4 TB disk';
  } else {
    return 'Please contact Apstra sizing team for custom sizing';
  }
};

export const getFlowSizingFromSurvey = (survey: Survey) => {
  return {
    number_of_managed_devices: Number(survey.managed_devices_count),
    flow_size: getFlowSize(Number(survey.managed_devices_count)),
    server_size: getServerSize(Number(survey.managed_devices_count))
  };
};
