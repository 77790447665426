import { isEmpty } from 'lodash';

import {
  compareDates,
  groupByProperty,
  groupTopologiesByRegion,
  groupTopologyValueByTimestamp
} from 'utils';
import { isNullOrUndefined } from 'utils/utilFunctions';

import { GroupBSByRegion, ReportChart, TimeSeries } from 'types';

export const getTotalTopologies = (utilization: ReportChart[]) => {
  if (isEmpty(utilization)) {
    return 0;
  }
  const sum = utilization.reduce((accumulator, object) => {
    return accumulator + object.y;
  }, 0);
  return sum;
};

export const getTopologyPerRegionSeries = (utilization: TimeSeries[]) => {
  const utilized: ReportChart[] = [];
  if (isEmpty(utilization)) {
    return [];
  }
  const regionDict = new Map();
  utilization.forEach((u: TimeSeries) => {
    if (!regionDict.has(u.dimensions.Region)) {
      const data = {
        timestamp: u.timestamp,
        value: u.value
      };
      regionDict.set(u.dimensions.Region, data);
    } else {
      const values = regionDict.get(u.dimensions.Region);
      if (compareDates(values.timestamp, u.timestamp)) {
        const data = {
          timestamp: u.timestamp,
          value: u.value
        };
        regionDict.set(u.dimensions.Region, data);
      }
    }
  });
  regionDict.forEach((v, k) => {
    const data = {
      x: k,
      y: Math.round(v.value)
    };
    utilized.push(data);
  });
  return utilized;
};

export const getRamUtilizationSeries = (utilization: TimeSeries[]) => {
  const utilized: ReportChart[] = [];
  if (isEmpty(utilization)) {
    return [];
  }
  const regionDict = new Map();
  utilization.forEach((u: TimeSeries) => {
    if (!regionDict.has(u.dimensions.InstanceRegion)) {
      const data = {
        buildServer: u.dimensions.InstanceName,
        timestamp: u.timestamp,
        value: u.value
      };
      regionDict.set(u.dimensions.InstanceRegion, [data]);
    } else {
      const buildServers = regionDict.get(u.dimensions.InstanceRegion);
      const obj = buildServers.find(
        (b: GroupBSByRegion) => b.buildServer === u.dimensions.InstanceName
      );
      const index = !isNullOrUndefined(obj) ? buildServers.indexOf(obj) : -1;
      if (index === -1) {
        const data = {
          buildServer: u.dimensions.InstanceName,
          timestamp: u.timestamp,
          value: u.value
        };
        buildServers.push(data);
      } else {
        if (compareDates(obj.timestamp, u.timestamp)) {
          buildServers[index] = {
            buildServer: u.dimensions.InstanceName,
            timestamp: u.timestamp,
            value: u.value
          };
        }
      }
    }
  });
  regionDict.forEach((v, k) => {
    const sum = v.reduce((accumulator: number, object: GroupBSByRegion) => {
      return accumulator + object.value;
    }, 0);
    const data = {
      x: k,
      y: (sum / v.length).toFixed(2)
    };
    utilized.push(data);
  });
  return utilized;
};

const processActiveTopologies = (activeTopologies: TimeSeries[]) => {
  const regionDict = groupTopologiesByRegion(activeTopologies);
  const timestampDict = groupTopologyValueByTimestamp(regionDict);
  const activeTopologiesData: ReportChart[] = [];
  timestampDict.forEach((v, k) => {
    const data = {
      x: k,
      y: Math.round(v)
    };
    activeTopologiesData.push(data);
  });

  return activeTopologiesData;
};

const processActivelyDeployedOrFailingTopologies = (
  topologies: TimeSeries[]
) => {
  const topologiesData: ReportChart[] = [];
  topologies.forEach(topology => {
    const data = {
      x: topology.timestamp,
      y: Math.round(topology.value)
    };
    topologiesData.push(data);
  });

  return topologiesData;
};

const getUtilized = (
  activeTopologiesData: ReportChart[],
  failingTopologiesData: ReportChart[],
  activelyDeployedTopologiesData: ReportChart[]
) => {
  const utilized: ApexAxisChartSeries = [];
  const seriesForActiveTopologies = {
    name: 'Active Topologies',
    type: 'line',
    data: activeTopologiesData
  };
  utilized.push(seriesForActiveTopologies);

  const seriesForFailingTopologies = {
    name: 'Failed Topologies',
    type: 'line',
    data: failingTopologiesData
  };
  utilized.push(seriesForFailingTopologies);

  const seriesForActivelyDeployedTopologies = {
    name: 'Actively Deployed Topologies',
    type: 'line',
    data: activelyDeployedTopologiesData
  };
  utilized.push(seriesForActivelyDeployedTopologies);

  return utilized;
};

export const getActiveFailingAndActivelyDeployedTopologySeries = (
  activeTopologies: TimeSeries[],
  failingTopologies: TimeSeries[],
  activelyDeployedTopologies: TimeSeries[]
) => {
  if (
    isEmpty(activeTopologies) ||
    isEmpty(failingTopologies) ||
    isEmpty(activelyDeployedTopologies)
  ) {
    return [];
  }
  const activeTopologiesData = processActiveTopologies(activeTopologies);
  const failingTopologiesData = processActivelyDeployedOrFailingTopologies(
    failingTopologies
  );
  const activelyDeployedTopologiesData = processActivelyDeployedOrFailingTopologies(
    activelyDeployedTopologies
  );

  const utilized = getUtilized(
    activeTopologiesData,
    failingTopologiesData,
    activelyDeployedTopologiesData
  );

  return utilized;
};

export const getActiveTopologyPerRegionSeries = (utilization: TimeSeries[]) => {
  return groupByProperty(utilization, 'Region');
};
