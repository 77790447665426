import { isEmpty } from 'lodash';
import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Divider } from 'semantic-ui-react';

import { convertTimeToISO, isDateTimeValid, notifier } from 'utils';

import { reportsApi } from 'api/reports';
import { PeriodFilterSelector } from 'components/PeriodFilterSelector';
import { SendEmailModal } from 'components/SendEmailModal';
import { DatesByPage, FiltersByPage, ResponseCount } from 'types';

import { RootState } from 'Reducers/contactReducer';

import { ContactUsageTable } from './ContactUsageTable/ContactUsageTable';

import './styles.less';

export const UserReport: React.FC = memo(() => {
  const [searchText, setSearchText] = useState<string>('');
  const [dbCount, setCount] = useState<ResponseCount>({
    contacts: 0,
    topologies: 0
  });
  const [emailModalVisible, setEmailModalVisible] = useState<boolean>(false);
  const [recipients, setRecipients] = useState<string[]>([]);
  const dates: DatesByPage = useSelector((state: RootState) => state.dates);
  const filtersByPage: FiltersByPage = useSelector(
    (state: RootState) => state.filters
  );
  const filters = isEmpty(searchText)
    ? filtersByPage.contactFilterText
    : searchText;
  const startDate = isDateTimeValid(dates.contact.startDate, 'MM-DD-YYYY HH:mm')
    ? convertTimeToISO(dates.contact.startDate, 'MM-DD-YYYY HH:mm')
    : '';
  const endDate = isDateTimeValid(dates.contact.endDate, 'MM-DD-YYYY HH:mm')
    ? convertTimeToISO(dates.contact.endDate, 'MM-DD-YYYY HH:mm')
    : '';

  const sendReportEmails = async () => {
    try {
      await reportsApi.sendCreatedContactsReport({
        startDate,
        endDate,
        recipients
      });
      notifier.success({
        message: `Reports successfully sent to recipients`
      });
      setEmailModalVisible(false);
      setRecipients([]);
    } catch (err) {
      notifier.requestFailed(err);
    }
  };

  return (
    <div>
      <h2 className='caption'>Contact Reports</h2>
      <Divider />
      <PeriodFilterSelector
        searchText={searchText}
        setSearchText={setSearchText}
        reportType='contact'
        setEmailModalVisible={setEmailModalVisible}
      />
      <div className='align-right'>
        {'Number of Topologies: '}
        {dbCount.topologies} {'Number of Contacts: '}
        {dbCount.contacts}
      </div>
      <Divider />
      <ContactUsageTable
        startDate={startDate}
        endDate={endDate}
        searchText={filters}
        setDBCount={setCount}
      />
      <SendEmailModal
        visible={emailModalVisible}
        setVisible={setEmailModalVisible}
        emails={recipients}
        setEmail={setRecipients}
        onSubmit={sendReportEmails}
      />
    </div>
  );
});
