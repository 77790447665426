import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dropdown,
  DropdownProps,
  Icon,
  Message,
  Modal
} from 'semantic-ui-react';

import { contactUtils, notifier } from 'utils';

import { contactsApi } from 'api/contacts';
import { tagsApi } from 'api/tag';
import { Contact } from 'types';

import { setDepartments } from 'actions/actions';
import { fetchContacts } from 'features/contacts';
import { RootState } from 'Reducers/contactReducer';
import { Nbsp } from '../Nbsp/Nbsp';

export function ChooseDepartmentModal(props: {
  contacts: Contact[];
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const multiContactsSelected = props.contacts && props.contacts.length > 1;
  const [department, setDepartment] = useState<string>('');
  const getLocalTag = () => {
    if (multiContactsSelected) {
      setDepartment(availableOptions[0].value);
    } else if (props.contacts && props.contacts.length === 1) {
      if (contactUtils.formatField(props.contacts[0].department) === '-') {
        setDepartment(availableOptions[0].value);
      } else {
        setDepartment(props.contacts[0].department);
      }
    }
  };
  const allDepartments = useSelector((state: RootState) => state.department);
  const dispatch = useDispatch();
  const contactsState = useSelector((state: RootState) => state.contact);

  const availableOptions = allDepartments.map((option: string) => {
    return {
      key: option,
      value: option,
      text: option
    };
  });

  useEffect(() => {
    const getDepartments = async () => {
      try {
        const res = await tagsApi.getAllDepartments();
        setDepartment(
          props.contacts && props.contacts.length === 1
            ? props.contacts[0].department
            : res.departments && res.departments[0]
        );
        dispatch(setDepartments(res.departments));
      } catch (err) {
        notifier.requestFailed(err);
      }
    };
    getDepartments();
  }, [dispatch, props.contacts]);

  const updateDepartment = async () => {
    if (props.contacts === undefined) {
      return;
    }
    props.contacts.forEach(async (contact: Contact) => {
      try {
        await contactsApi.update(contact.id, {
          department
        });
        props.setOpen(false);

        dispatch(
          fetchContacts({
            page: contactsState.currentPage,
            limit: contactsState.limit,
            filter: contactsState.filter.text,
            role: contactsState.filter.role
          })
        );
        notifier.success({
          message: `${department} Added To Contact "${contact.name}"`
        });
      } catch (err) {
        notifier.requestFailed(err);
      }
    });
  };
  const contactName: string =
    props.contacts && props.contacts.length === 1 ? props.contacts[0].name : '';

  return (
    <Modal
      open={props.open}
      closeIcon={true}
      closeOnDimmerClick={false}
      size='tiny'
      onClose={() => {
        getLocalTag();
        props.setOpen(false);
      }}
    >
      <Modal.Header>Manage Department for {contactName}</Modal.Header>
      <Modal.Content>
        <span>
          Please select a value to Add or Edit the user's Department. <Nbsp />
        </span>
        <div className='modal-content'>
          <Modal.Description>
            <div>
              <Dropdown
                placeholder='Select Department'
                fluid
                selection
                text={department}
                value={department}
                options={availableOptions}
                onChange={(event, data: DropdownProps) => {
                  setDepartment(data.value as string);
                }}
              />
            </div>
          </Modal.Description>
        </div>
        {multiContactsSelected && (
          <Message
            warning
            content='Note: Multiple Contacts selected. Please choose departments carefully.'
          />
        )}
        <Message
          className='modal-content'
          warning
          content='Note: If a Department has already been assigned to the user, submitting the form will override the Department.'
        />
      </Modal.Content>
      <Modal.Actions>
        <div className='flex-1'>
          <Button className='' color='red' onClick={() => props.setOpen(false)}>
            <Icon name='remove' /> Cancel
          </Button>
          <Button color='green' onClick={() => updateDepartment()}>
            <Icon name='checkmark' /> Submit
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
}
