import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Pagination, PaginationProps, Segment } from 'semantic-ui-react';

import { paginationUtils } from 'utils/pagination';

import { getActiveItems } from 'components/TopologyReports/UsageTable/ReportFilterHelpers';
import { assets } from 'constants/assets';
import {
  ImageAssets,
  LabTemplate,
  PaginationFilters,
  StrictUnion,
  Template,
  TopologyFieldNames
} from 'types';
import { TemplateCard, TemplateCardButtons, TemplatePreviewModal } from 'views';

import { setTopologyFieldName } from 'actions/actions';
import { RootState } from 'Reducers/contactReducer';
import { TemplateSearchWizard } from './TemplateSearchWizard';

import './styles.less';

type TemplateProps = StrictUnion<Template | LabTemplate>;

type Props = {
  readonly paneDescription: string;
  readonly templates: TemplateProps[];
  readonly selectedTemplateId: number | string | null;
  readonly onChange: (
    templateId: number | string,
    templateName: string
  ) => void;
};

const filterTemplateItems = (
  isTopologyTemplate: boolean,
  templates: TemplateProps[],
  searchText: string,
  pagination: PaginationFilters
): {
  total: number;
  items: TemplateProps[];
} => {
  const items = templates.filter((t: TemplateProps) => {
    let valid = true;
    if (searchText) {
      const text = searchText.toLowerCase();
      valid =
        valid &&
        (t.label.toLowerCase().includes(text) ||
          (isTopologyTemplate &&
            ((t as Template).name.toLowerCase().includes(text) ||
              (t as Template).aosVersion.toLowerCase().includes(text))));
    }
    return valid;
  });

  return {
    total: items.length,
    items: getActiveItems(pagination, items)
  };
};

export const TemplateSelectionCardWizard: React.FC<Props> = memo(
  ({ paneDescription, templates, selectedTemplateId, onChange }) => {
    const isTopologyTemplate = paneDescription !== 'lab';
    const [searchText, setSearchText] = useState<string>('');
    const [pagination, setPagination] = useState<PaginationFilters>({
      activePage: 1,
      pageSize: 8
    });
    const [previewTemplateImage, setPreviewTemplateImage] = useState<
      ImageAssets
    >(assets.images.noImageAvailable);
    const [
      previewTemplateDescription,
      setPreviewTemplateDescription
    ] = useState<string>('');
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    const dispatch = useDispatch();
    const fieldValueNames: TopologyFieldNames = useSelector(
      (state: RootState) => state.fieldValues
    );
    const { total, items } = filterTemplateItems(
      isTopologyTemplate,
      templates,
      searchText,
      pagination
    );
    const totalPages = paginationUtils.getTotalPages({
      pageSize: pagination.pageSize,
      totalCount: total
    });

    const onTemplateChange = (template: Template) => {
      onChange(template.id, template.label ? template.label : template.name);
      const templateName = template.label ? template.label : template.name;
      dispatch(
        setTopologyFieldName({
          ...fieldValueNames,
          template: templateName,
          templateId: [template.id],
          templateExpiration: template.defaultExpiration
            ? template.defaultExpiration
            : 0
        })
      );
    };
    const onLabTemplateChange = (template: LabTemplate) => {
      onChange(template.uuid, template.label);
      const templateName = template.label;
      dispatch(
        setTopologyFieldName({
          ...fieldValueNames,
          template: templateName,
          templateId: template.topologyTemplates
        })
      );
    };
    return (
      <div className='cl-template-selection-card'>
        <Segment basic className='template-search'>
          {isTopologyTemplate && (
            <div className='description'>{paneDescription}</div>
          )}
          <TemplateSearchWizard
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </Segment>
        <Card.Group doubling stackable centered itemsPerRow={4}>
          {items.map(template => (
            <Card
              key={
                isTopologyTemplate
                  ? (template as Template).id
                  : (template as LabTemplate).uuid
              }
              raised
              fluid
              className={
                (isTopologyTemplate &&
                  (template as Template).id === selectedTemplateId) ||
                (!isTopologyTemplate &&
                  (template as LabTemplate).uuid === selectedTemplateId)
                  ? 'focussed-card'
                  : ''
              }
              onClick={() =>
                isTopologyTemplate
                  ? onTemplateChange(template as Template)
                  : onLabTemplateChange(template as LabTemplate)
              }
            >
              <TemplateCard
                template={template}
                imageAsURL=''
                showTemplateImage={isTopologyTemplate}
                showLongDescription={!isTopologyTemplate}
                showShortDescription={true}
                showDescription={isTopologyTemplate}
                showDeployedLabel={isTopologyTemplate}
                showAosVersion={isTopologyTemplate}
                displayAnimation={isTopologyTemplate ? 'dimmer' : 'slide'}
              />
              {isTopologyTemplate && (
                <TemplateCardButtons
                  template={template as Template}
                  onChange={onChange}
                  setPreviewTemplateImage={setPreviewTemplateImage}
                  setPreviewTemplateDescription={setPreviewTemplateDescription}
                  setModalVisible={setModalVisible}
                />
              )}
            </Card>
          ))}
        </Card.Group>
        <div className='template-search'>
          {totalPages > 0 && (
            <div className='pagination'>
              <Pagination
                activePage={pagination.activePage}
                totalPages={totalPages}
                onPageChange={(
                  event: React.MouseEvent<HTMLAnchorElement>,
                  data: PaginationProps
                ) =>
                  setPagination({
                    ...pagination,
                    activePage: data.activePage as number
                  })
                }
              />
            </div>
          )}
        </div>
        {isTopologyTemplate && (
          <TemplatePreviewModal
            templateLongDescription={previewTemplateDescription}
            templateImage={previewTemplateImage}
            visible={modalVisible}
            setVisible={setModalVisible}
          />
        )}
      </div>
    );
  }
);
