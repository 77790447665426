import React, { memo } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  readonly to: string;
  readonly [key: string]: any;
};

const isExternal = (url: string) => /^(https?|ssh):\/\//.test(url);

export const RouterLink: React.FC<Props> = memo(
  ({ to, children, ...restProps }) => {
    return isExternal(to) ? (
      <a href={to} {...restProps} target='_blank' rel='noopener noreferrer'>
        {children}
      </a>
    ) : (
      <Link {...restProps} to={to}>
        {children}
      </Link>
    );
  }
);
