import axios from 'axios';
import { isEmpty } from 'lodash';
import { useQuery, UseQueryResult } from 'react-query';

import { notifier } from 'utils';

import { apiPrefix, apiRoutes } from 'constants/api-routes';
import { Usage } from 'types';

export const useTopologyUsage = (
  startDate: string,
  endDate: string
): UseQueryResult<Usage> => {
  return useQuery<Usage>(
    ['TopologyUsage', startDate, endDate],
    () => {
      return axios
        .get(
          apiPrefix(
            `${apiRoutes.usageReport}?startDate=${startDate}&endDate=${endDate}`
          )
        )
        .then(response => (response.data ? response.data.usage : []))
        .catch(error => {
          notifier.requestFailed(error);
          return [];
        });
    },
    {
      enabled: !isEmpty(startDate) && !isEmpty(endDate),
      notifyOnChangeProps: 'tracked'
    }
  );
};
