import React, { Fragment, memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Message, Segment } from 'semantic-ui-react';

import { notifier } from 'utils';

import { contactsApi } from 'api/contacts';
import { MainLayout } from 'layouts';
import { Contact } from 'types';

import { ContactDetailsForm } from 'components';

import { ContactsState, fetchContacts } from 'features/contacts';
import { RootState } from 'Reducers/contactReducer';

import './styles.less';

export const CreateContactView: React.FC = memo(() => {
  const contactsState: ContactsState = useSelector(
    (state: RootState) => state.contact
  );
  const [contact, setContact] = useState<Contact>({} as Contact);
  const dispatch = useDispatch();
  const onSubmit = async () => {
    let data: any;
    data = {
      name: contact.name,
      partnerCompany: contact.partnerCompany,
      company: contact.company,
      email: contact.email,
      password: contact.password,
      role: contact.role,
      department: contact.department
    } as Contact;
    try {
      await contactsApi.create(data);
      notifier.success({
        message: `Contact "${contact.name}" successfully created`
      });
      setContact(null as any);
      dispatch(
        fetchContacts({
          page: contactsState.currentPage,
          filter: contactsState.filter.text,
          limit: contactsState.limit,
          role: contactsState.filter.role
        })
      );
    } catch (err) {
      notifier.requestFailed(err);
    }
  };

  return (
    <MainLayout className='cl-create-contact'>
      <div className='caption'>
        <h2 className='header'>{'Create User'}</h2>
      </div>
      <Fragment>
        <Message size='large'>
          {
            <span>
              <Icon name='info' /> Register a new account to Juniper Apstra
              CloudLabs™
            </span>
          }
        </Message>
        <Segment className='create-contact-form-container'>
          <ContactDetailsForm
            contact={contact}
            submitBtnText='Create'
            submitBtnIcon='add circle'
            onSubmit={onSubmit}
            onChange={(value: Contact) => setContact(value)}
          />
        </Segment>
      </Fragment>
    </MainLayout>
  );
});
