import $ from 'jquery';
import React, { useEffect } from 'react';
import { Segment } from 'semantic-ui-react';

import './styles.less';

function toggleFeedbackContainer() {
  $('.feedback-helpful-container').toggle(500);
}

(window as any).ATL_JQ_PAGE_PROPS = {
  /* Feedback */
  '2e0165df': {
    triggerFunction(showCollectorDialog: any) {
      $('#feedback-trigger').on('click', e => {
        e.preventDefault();
        showCollectorDialog();
        toggleFeedbackContainer();
      });
    }
  },
  /* Bug */
  d2883a66: {
    triggerFunction(showCollectorDialog: any) {
      $('#bug-trigger').click(e => {
        e.preventDefault();
        showCollectorDialog();
        toggleFeedbackContainer();
      });
    }
  },
  /* Feature */
  '284f8da0': {
    triggerFunction(showCollectorDialog: any) {
      $('#feature-trigger').click(e => {
        e.preventDefault();
        showCollectorDialog();
        toggleFeedbackContainer();
      });
    }
  }
};

export const Feedback: React.FC = () => {
  function loadScript(url: string) {
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('src', url);
    document.body.append(script);
  }

  function buildJiraUrl(collectorId: string) {
    return (
      'https://apstrktr.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/6i46lu/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=' +
      collectorId
    );
  }

  useEffect(() => {
    function loadJiraScripts() {
      loadScript(
        'https://apstrktr.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/1abpwv/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=2e0165df'
      );
      loadScript(buildJiraUrl('d2883a66'));
      loadScript(buildJiraUrl('284f8da0'));
    }
    loadJiraScripts();
  }, []);

  return (
    <>
      <div
        id='feedback-link'
        onMouseOver={() => toggleFeedbackContainer()}
        className='feedback-link feedback-link-right'
      >
        Feedback
      </div>

      <div
        className='feedback-helpful-container'
        onMouseLeave={() => $('.feedback-helpful-container').hide(500)}
      >
        <Segment>
          <p className='feedback-title'>
            What feedback would you like to give?
          </p>
          <div className='feedback-trigger' id='feedback-trigger'>
            Leave us a comment
          </div>
          <div className='feedback-trigger' id='bug-trigger'>
            Report a bug
          </div>
          <div className='feedback-trigger' id='feature-trigger'>
            Request a feature
          </div>
        </Segment>
      </div>
    </>
  );
};
