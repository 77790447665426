import React, { memo } from 'react';
import { Button } from 'semantic-ui-react';

import './styles.less';

type Props = {
  readonly activeSteps: number;
  readonly stepItemsLength: number;
  readonly disablePrevButton: boolean;
  readonly disableNextButton: boolean;
  readonly setActiveSteps: (step: number) => void;
};

export const LabGuidePageNavigator: React.FC<Props> = memo(
  ({
    activeSteps,
    stepItemsLength,
    disablePrevButton,
    disableNextButton,
    setActiveSteps
  }) => {
    const prev = () => {
      let step = activeSteps;
      if (step > 0) {
        step = step - 1;
        setActiveSteps(step);
      }
    };

    const next = () => {
      let step = activeSteps;
      if (step < stepItemsLength + 1) {
        step = step + 1;
        setActiveSteps(step);
      }
    };
    return (
      <div className='lab-nav-buttons'>
        <Button disabled={disablePrevButton} onClick={() => prev()}>
          Previous
        </Button>
        <Button
          className='align-right'
          data-testid='next-button'
          disabled={disableNextButton}
          onClick={() => next()}
        >
          Next
        </Button>
      </div>
    );
  }
);
