import moment from 'moment';

export const formatUTCDate = (
  utcDate: string,
  format: string = 'MM-DD-YYYY HH:mm'
) => {
  return utcDate ? moment.utc(utcDate).format(format) : null;
};

export const getUTCFromNow = (utcDate: string) => {
  return utcDate ? moment.utc(utcDate).fromNow() : null;
};

export const getUTCDate = (date: string | Date = new Date()) => {
  return moment.utc(date);
};

export const isDateTimeValid = (date: string, format: string) => {
  return moment(date, format, true).isValid();
};

export const formatDateTime = (
  date: string,
  initialFormat: string,
  newFormat: string
) => {
  return moment(date, initialFormat, true).format(newFormat);
};

export const convertTimeToISO = (date: string, format: string) => {
  return moment(date, format, true).toISOString();
};

function getDates(time: number) {
  const now = new Date();
  const start = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - time
  );
  return { start: start.toISOString(), end: now.toISOString() };
}

export const getTimeRanges = (date: string) => {
  switch (date) {
    case '1w':
      return getDates(7);
    case '3w':
      return getDates(21);
    case '1m':
      return getDates(30);
    case '3m':
      return getDates(90);
    case '6m':
      return getDates(180);
    case '1y':
      return getDates(365);
    default:
      return getDates(7);
  }
};

export const compareDates = (oldDate: string, newDate: string) => {
  const date1 = new Date(oldDate);
  const date2 = new Date(newDate);
  return date1 < date2;
};
