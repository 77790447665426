import React, { memo } from 'react';

import { ApstraLoginForm } from './ApstraLoginForm';
import { JuniperLoginForm } from './JuniperLoginForm';

import './styles.less';

export const InternalLoginForm: React.FC = memo(() => {
  return (
    <div>
      <ApstraLoginForm />
      <JuniperLoginForm />
    </div>
  );
});
