import { isNull, isUndefined } from 'lodash';

import humanizeDuration from 'humanize-duration';

export const isNullOrUndefined = (value: any) => {
  return isNull(value) || isUndefined(value);
};

export const JUNIPER = 'Juniper';
export const FREEFORM = 'Freeform';

export const convertTime = (time: number) => {
  const timeMs = time * 1000;
  return humanizeDuration(timeMs, { largest: 2, round: true });
};
