import React, { memo, useEffect, useState } from 'react';
import {
  Divider,
  Dropdown,
  DropdownItemProps,
  Form,
  Icon,
  Input,
  InputOnChangeData,
  Label
} from 'semantic-ui-react';

import { notifier } from 'utils';

import { Topologies } from 'components';
import { MainLayout } from 'layouts';

import { tagsApi } from 'api/tag';

import { DEFAULT_PAGE_SIZE } from 'constants/pagination';
import { Tag } from 'types/Tags/tags';

import { TopologyActionContainer } from './TopologyActionContainer';

import './styles.less';

export const TopologiesView: React.FC = memo(() => {
  const [filters, setFilters] = useState({ text: '' });
  const [tags, setTags] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>('');

  const [originalTags, setOriginalTagsResponse] = useState<string[]>([]);
  const [allTags, setAllTags] = useState<string[]>([]);

  useEffect(() => {
    const getDepartments = async () => {
      try {
        const res = await tagsApi.getAll();
        const newTags = res.tags.map((tag: Tag) => {
          return `${tag.name}:${tag.value}`;
        });
        setOriginalTagsResponse(newTags);
        setAllTags(newTags);
      } catch (err) {
        notifier.requestFailed(err);
      }
    };
    getDepartments();
  }, []);

  const filterTag = (value: string) => {
    if (!tags.includes(value)) {
      setTags([...tags, value]);
    }
  };

  const removeTagFilter = (value: string) => {
    if (tags.includes(value)) {
      setTags(tags.filter(tag => tag !== value));
    }
  };

  const onFilterTagsInput = (value: string) => {
    if (value.length > 0) {
      const text = value.toLowerCase();
      const searchItems = allTags.filter(tag => {
        let valid = true;
        valid = valid && tag.toLowerCase().includes(text);
        return valid;
      });
      setAllTags(searchItems);
    } else {
      setAllTags(originalTags);
    }
  };

  return (
    <MainLayout className='cl-topologies-view'>
      <div className='caption'>
        <h2 className='header'>{'Topologies'}</h2>
      </div>
      <div className='actions'>
        <Form className='search-form'>
          <Input
            className='search-input'
            placeholder='Search topologies'
            value={filters.text || ''}
            autoFocus
            onChange={e =>
              setFilters({ ...filters, text: e.target.value as string })
            }
            action={
              <Dropdown button icon='filter'>
                <Dropdown.Menu>
                  <Input
                    icon='search'
                    iconPosition='left'
                    placeholder='Search Tags'
                    className='search'
                    value={searchText}
                    onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                      e.stopPropagation();
                    }}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                      data: InputOnChangeData
                    ) => {
                      setSearchText(data.value);
                      onFilterTagsInput(data.value);
                    }}
                  />
                  <Dropdown.Menu scrolling>
                    <Dropdown.Header icon='tags' content='Filter by tag' />
                    {allTags.map(option => (
                      <Dropdown.Item
                        key={option}
                        text={option}
                        value={option}
                        active={tags.includes(option)}
                        onClick={(
                          event: React.MouseEvent<HTMLDivElement>,
                          data: DropdownItemProps
                        ) => {
                          event.stopPropagation();
                          filterTag(data.value as string);
                        }}
                      />
                    ))}
                  </Dropdown.Menu>
                </Dropdown.Menu>
              </Dropdown>
            }
          />
        </Form>
        <div className='flex-1' />
        <TopologyActionContainer />
      </div>
      <br />
      {tags.map(tag => (
        <Label key={tag}>
          {tag}
          <Icon name='delete' onClick={() => removeTagFilter(tag)} />
        </Label>
      ))}
      <br />
      <Divider />
      <Topologies
        filter={filters.text}
        limit={DEFAULT_PAGE_SIZE}
        displayedColumns={[
          'topology name',
          'region',
          'creation time',
          'expiration time',
          'created by',
          'created for',
          'department',
          'tags',
          'status',
          'actions'
        ]}
        selectionType={'multi'}
        tag={tags}
      />
    </MainLayout>
  );
});
export { Topologies };
