import React, { Fragment, memo } from 'react';
import { Icon } from 'semantic-ui-react';

import { RouterLink } from 'components/RouterLink';
import { MenuItem } from 'types';

type Props = {
  readonly item: MenuItem;
  readonly toggleExpanded: (item: MenuItem) => void;
};

export const MenuItemLink: React.FC<Props> = memo(
  ({ item, toggleExpanded }) => (
    <Fragment>
      {item.link && (
        <RouterLink to={item.link} className='ui item-label'>
          {item.icon && <Icon name={item.icon} />}
          {item.label}
        </RouterLink>
      )}
      {!item.link && (
        <span className='ui item-label' onClick={() => toggleExpanded(item)}>
          {item.icon && <Icon name={item.icon} />}
          {item.label}
        </span>
      )}
    </Fragment>
  )
);
