import { find, map, union } from 'lodash';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';

import { RequestData } from 'components';
import { apiRoutes } from 'constants/api-routes';
import { Contact } from 'types';

import './styles.less';

const SEARCH_LIMIT = 5;

type Props = {
  readonly contact: Contact;
  readonly selectedContact: number;
  readonly onChange: (contactId: string) => void;
};

export const CreateTopologyContactSelector: React.FC<Props> = memo(
  ({ contact, selectedContact, onChange }) => {
    const [searchQuery, setSearchQuery] = useState<string | undefined>('');
    const transformResponse = useCallback(
      (data: { contacts: Contact[] }) => data.contacts,
      []
    );
    const params = useMemo(
      () => ({
        filter: searchQuery ? searchQuery : undefined,
        limit: SEARCH_LIMIT
      }),
      [searchQuery]
    );

    return (
      <RequestData
        url={apiRoutes.contacts}
        params={params}
        transformResponse={transformResponse}
        customLoader
      >
        {({ data }) => {
          const contacts = find(data, { id: contact.id })
            ? data
            : union(data, [contact]);
          const options = map(contacts, (c: Contact) => ({
            key: c.id,
            text: c.name,
            value: c.id
          }));
          return (
            <Dropdown
              className='cl-create-topology-contact-selector'
              inline
              search
              value={selectedContact}
              selectOnBlur={false}
              selectOnNavigation={false}
              options={options}
              onChange={(e, { value }) => onChange(value as string)}
              onSearchChange={(e, { searchQuery: value }) =>
                setSearchQuery(value)
              }
            />
          );
        }}
      </RequestData>
    );
  }
);
