/*
 * Copyright 2024-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */
import React from 'react';

import { Topology } from 'types';

interface ContactNameProps {
  readonly topology: Topology;
  readonly contactProperty: 'createdFor' | 'createdBy';
}

const ContactDetail: React.FC<{
  detail: string | undefined;
  className: string;
}> = ({ detail, className }) => (
  <div className={className}>{detail || '-'}</div>
);

export const ContactName: React.FC<ContactNameProps> = ({
  topology,
  contactProperty
}) => {
  const contact = topology[contactProperty];

  return (
    <>
      <ContactDetail detail={contact?.name} className={''} />
      <ContactDetail
        detail={`Company: ${contact?.company}`}
        className={'text-small'}
      />
      {['partner', 'partner_admin'].includes(contact?.role || '') && (
        <ContactDetail
          detail={`Partner company: ${contact?.partnerCompany}`}
          className={'text-small'}
        />
      )}
    </>
  );
};
