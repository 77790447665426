import React, { memo } from 'react';

import { notifier } from 'utils';

import { authApi } from 'api/auth';
import { AosLogo } from 'components';
import { routes } from 'constants/routing';
import { useRouter } from 'hooks/useRouter';

import { ResetPasswordForm } from './ResetPasswordForm/ResetPasswordForm';

import './styles.less';

export const ResetPasswordView: React.FC = memo(() => {
  const router = useRouter();
  const uuid: string = (router.match.params as { uuid: string }).uuid;

  const onSubmit = async (password: string) => {
    try {
      await authApi.resetPassword(uuid, password);
      router.push(routes.login.path);
      notifier.success({ message: 'Password reset successfully' });
    } catch (err) {
      notifier.requestFailed(err);
    }
  };

  return (
    <div className='cl-reset-password-view'>
      <div className='wrapper'>
        <AosLogo nowrap />
        <ResetPasswordForm onSubmit={onSubmit} />
      </div>
    </div>
  );
});
