/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */
import React, { useState } from 'react';
import { Button, Form, Icon, Message, Modal } from 'semantic-ui-react';

import { TagInput, ValidatedInput } from 'components';
import { Validator } from 'components/ValidatedInput/Validators/Base';
import { Length } from 'components/ValidatedInput/Validators/Length';

import { DomainsHelp } from './HelpMessages';

interface AddPartnerModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onPartnerAdded: (name: string, domains: string[]) => void;
  nameValidators: Validator[];
  domainValidators: Validator[];
}

export const AddPartnerModal: React.FC<AddPartnerModalProps> = ({
  open,
  setOpen,
  onPartnerAdded,
  nameValidators,
  domainValidators
}) => {
  const [name, setName] = useState('');
  const [domains, setDomains] = useState<string[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const hasErrors = errors.length > 0;

  return (
    <Modal open={open} aria-label='Add new partner company'>
      <Modal.Header>Add new partner company</Modal.Header>
      <Modal.Content>
        <Form error={hasErrors}>
          <Form.Field required>
            <label>Partner company name</label>
            <ValidatedInput
              validators={[...nameValidators, new Length(1, 200)]}
              placeholder={'Partner company name'}
              onChange={value => {
                setName(value.trim());
              }}
              onErrors={(errs: string[]) => setErrors(errs)}
            />
          </Form.Field>
          <Form.Field required>
            <label>Email Domains</label>
            <Message info>
              <DomainsHelp />
              <p>It is required that at least one domain is added.</p>
              <p>
                Use <b>Space</b> or <b>Enter</b> to add new domain name.
              </p>
              <p>
                Use <b>Backspace</b> to delete existing domain name.
              </p>
            </Message>
            <TagInput
              placeholder={'Email Domains'}
              validators={domainValidators}
              onChange={(tags: string[]) => setDomains(tags)}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          type='submit'
          disabled={hasErrors || name === '' || domains.length === 0}
          icon
          labelPosition='left'
          positive
          onClick={() => {
            onPartnerAdded(name, domains);
            setOpen(false);
          }}
        >
          <Icon name='plus' />
          Create
        </Button>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
      </Modal.Actions>
    </Modal>
  );
};
