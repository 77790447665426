import cx, { Value } from 'classnames';
import React, { memo } from 'react';
import { ToastContainer } from 'react-toastify';
import { Sidebar, StrictBreadcrumbSectionProps } from 'semantic-ui-react';

import { Feedback, MainHeader, MainSidebar } from 'components';
import { InfoSidebar } from 'components/InfoSidebar';
import { routesValues } from 'constants/routing';
import { useRouter } from 'hooks/useRouter';

import './styles.less';

type Props = {
  readonly breadcrumbSections?: StrictBreadcrumbSectionProps[];
  readonly className?: Value;
  readonly bottomPadding?: 'big' | 'normal';
  readonly children?: React.ReactNode;
  readonly sidebarChilden?: React.ReactNode;
  readonly visible?: boolean;
  readonly onVisibleChange?: (visible: boolean) => void;
};

const defaultProps: Partial<Props> = {
  bottomPadding: 'big'
};

export const MainLayout: React.FC<Props> = memo(
  ({
    className,
    children,
    breadcrumbSections,
    bottomPadding,
    visible = false,
    sidebarChilden,
    onVisibleChange
  }) => {
    const router = useRouter();
    const route = routesValues.find(r => r.path === router.match.path);
    const breadcrumbLabel = route ? route.label : null;

    breadcrumbSections = breadcrumbSections
      ? breadcrumbSections
      : breadcrumbLabel
      ? [{ content: breadcrumbLabel, active: true }]
      : [];
    const paddingClassName = 'bottom-padding-' + bottomPadding;
    return (
      <>
        <ToastContainer
          position='top-right'
          autoClose={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          theme='light'
        />
        <Sidebar.Pushable className='cl-main-layout'>
          <MainSidebar />
          <div className='cl-page-outer'>
            <div id='content' className='cl-page-inner'>
              <MainHeader breadcrumbSections={breadcrumbSections} />
              <Sidebar.Pusher className='cl-page-content'>
                <div
                  className={cx('cl-view-content', className, paddingClassName)}
                >
                  {children}
                </div>
                <Feedback />
              </Sidebar.Pusher>
            </div>
          </div>
          <InfoSidebar
            sidebarChilden={sidebarChilden}
            visible={visible}
            onVisibleChange={view => {
              if (onVisibleChange) {
                onVisibleChange(view);
              }
            }}
          />
        </Sidebar.Pushable>
      </>
    );
  }
);

MainLayout.defaultProps = defaultProps;
