/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */

import React, { memo } from 'react';
import { Icon, Label } from 'semantic-ui-react';

export interface TagProps {
  label: string;
  onRemove: () => void;
}

export const Tag: React.FC<TagProps> = memo(({ label, onRemove }) => {
  return (
    <Label>
      {label}
      <Icon aria-label={'delete ' + label} name={'delete'} onClick={onRemove} />
    </Label>
  );
});
