import { isEmpty } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { Checkbox, Divider, Dropdown } from 'semantic-ui-react';

import { notifier, tagOptions, tagUtils } from 'utils';

import { tagsApi } from 'api/tag';
import { Tag } from 'types/Tags/tags';

import './styles.less';

type Props = {
  readonly stepVisible: boolean;
  readonly selectedTags: string[];
  readonly sendEmail: boolean;
  readonly onEmailChange: (selected: boolean) => void;
  readonly onTagsChange: (tags: string[]) => void;
};

export const TagSelectorWizard: React.FC<Props> = memo(
  ({ stepVisible, selectedTags, sendEmail, onTagsChange, onEmailChange }) => {
    const [existingTags, setExistingTags] = useState<Tag[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
      const getTags = async () => {
        try {
          setLoading(true);
          const response = await tagsApi.getAll();
          const filterResponse = response.tags.filter(tag =>
            tagUtils.nonDepartmentTag(tag)
          );
          setExistingTags(filterResponse);
          setLoading(false);
        } catch (err) {
          notifier.requestFailed(err);
        }
      };
      getTags();
    }, []);

    const availableOptions: tagOptions[] = existingTags
      ? existingTags.map(option => {
          return {
            key: option.id,
            value: `${option.name}:${option.value}`,
            text: `${option.name}:${option.value}`
          };
        })
      : [];

    const placeholderText = isEmpty(availableOptions)
      ? 'No tags available'
      : 'Select Tags';
    return (
      <div className='cl-tag-selection' hidden={!stepVisible}>
        <h2 className='section-number'>Set Tags and Notifications</h2>
        <div className='caption'>
          Select a Tag <span className='caption-hint'>(Optional)</span>
        </div>
        <Dropdown
          className='tag'
          placeholder={placeholderText}
          selection
          multiple
          search
          loading={loading}
          value={selectedTags}
          options={availableOptions}
          onChange={(event, data) => {
            onTagsChange(data.value as string[]);
          }}
        />
        <Divider />
        <div className='caption'>
          Send Email <span className='caption-hint'>(Optional)</span>
        </div>
        <Checkbox
          label='Send an Email to Contact After Creation'
          checked={sendEmail}
          onChange={(e, { checked }) => {
            onEmailChange(checked as boolean);
          }}
        />
      </div>
    );
  }
);
