import { isEmpty } from 'lodash';
import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dropdown,
  DropdownOnSearchChangeData,
  DropdownProps,
  Grid
} from 'semantic-ui-react';

import {
  getBases,
  getStoredBaseQueryResponse,
  notifier,
  storeBaseQueryResponse
} from 'utils';

import { chatbotApi } from 'api/chatbot';
import { KnowledgeBase, QueryStorage, QuerySummary } from 'interfaces';

import { setRequestQuery } from 'features/chatbot';
import { RootState } from 'Reducers/contactReducer';

import './styles.less';

type Props = {
  readonly loading: boolean;
  readonly setLoading: (value: boolean) => void;
  readonly inputQuery: QuerySummary;
  readonly setInputQuery: (value: QuerySummary) => void;
};

export const SearchBar: React.FC<Props> = memo(
  ({ loading, inputQuery, setLoading, setInputQuery }) => {
    const dispatch = useDispatch();
    const bases = getBases();
    const requestQuery = useSelector(
      (state: RootState) => state.chat.requestQuery
    );
    const baseOptions = bases.map((base: KnowledgeBase) => {
      return {
        key: base.name,
        value: base.name,
        text: base.name
      };
    });
    const defaultValue = baseOptions.find(
      (option: any) => option.value === 'Apstra 4.2'
    );
    const initialState = {
      corpusName: '',
      userQuery: ''
    };
    const [inputError, setInputError] = useState<boolean>(false);

    const storedQueryResults: QueryStorage[] = getStoredBaseQueryResponse(
      inputQuery.userQuery,
      inputQuery.corpusName
    );

    const getSelectionOptions = () => {
      if (!isEmpty(storedQueryResults)) {
        const options = storedQueryResults.map((q: QueryStorage) => {
          return {
            key: q.query,
            text: q.query,
            value: q.query
          };
        });
        return options.reverse();
      } else return [];
    };
    const handleKeyDown = async (req: QuerySummary) => {
      if (req === initialState) return;
      if (isEmpty(req.corpusName)) req.corpusName = defaultValue.value;
      try {
        setLoading(true);
        const resp = await chatbotApi.getSummaryFromQuery(req);
        const response = await chatbotApi.getAllTasks(resp.taskUuid);
        const replaceResponse = !isEmpty(storedQueryResults);
        storeBaseQueryResponse(
          req.corpusName,
          req.userQuery,
          false,
          response.results,
          resp.taskUuid,
          'neutral',
          false,
          replaceResponse,
          false
        );
        dispatch(setRequestQuery(req));
        setLoading(false);
      } catch (e) {
        notifier.requestFailed(e);
      }
    };

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={3} className='kb-search'>
            <Dropdown
              basic
              button
              text={
                inputQuery.corpusName
                  ? inputQuery.corpusName
                  : requestQuery.corpusName
                  ? requestQuery.corpusName
                  : defaultValue
                  ? defaultValue.text
                  : 'Apstra 4.2'
              }
              direction='right'
              options={baseOptions}
              value={
                inputQuery.corpusName
                  ? inputQuery.corpusName
                  : requestQuery.corpusName
                  ? requestQuery.corpusName
                  : defaultValue
                  ? defaultValue.value
                  : 'Apstra 4.2'
              }
              onChange={(
                event: React.SyntheticEvent<HTMLElement, Event>,
                data: DropdownProps
              ) =>
                setInputQuery({
                  userQuery: '',
                  corpusName: data.value as string
                })
              }
            />
          </Grid.Column>
          <Grid.Column width={13} className='query-search'>
            <Dropdown
              icon='search'
              placeholder='Search...'
              loading={loading}
              disabled={loading}
              error={inputError}
              search
              fluid
              selection
              selectOnBlur={false}
              value={inputQuery.userQuery || ''}
              noResultsMessage='No previous searches found'
              options={getSelectionOptions()}
              onSearchChange={(
                event: React.SyntheticEvent<HTMLElement>,
                data: DropdownOnSearchChangeData
              ) => {
                setInputQuery({
                  ...inputQuery,
                  userQuery: data.searchQuery as string
                });
              }}
              onChange={(
                event: React.SyntheticEvent<HTMLElement, Event>,
                data: DropdownProps
              ) => {
                setInputError(false);
                setInputQuery({
                  ...inputQuery,
                  userQuery: data.value as string
                });
                handleKeyDown({
                  ...inputQuery,
                  userQuery: data.value as string
                });
              }}
              onKeyDown={(evt: any) => {
                if (evt.key === 'Enter') {
                  if (isEmpty(inputQuery.userQuery)) {
                    setInputError(true);
                  }
                  handleKeyDown(inputQuery);
                }
                if (evt.key === 'Backspace' || evt.key === 'Delete') {
                  setInputQuery({
                    ...inputQuery,
                    userQuery: ''
                  });
                }
                return;
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
);
