import React, { memo } from 'react';

import './styles.less';

export const EulaContent: React.FC = memo(() => {
  return (
    <div className='cl-eula-content'>
      <p className='header'>{'End User License Agreement'}</p>
      <p>
        IMPORTANT: This End User License Agreement (“Agreement”) is a legal
        agreement between you (either as an individual or as a legal entity that
        you represent as an authorized employee or agent”) (“Licensee”) and
        Apstra, Inc. (“Apstra”) that governs your use of one or more of the
        Apstra’s software and its related documentation (“Software”).
      </p>
      <p>
        PLEASE READ ALL THE TERMS AND CONDITIONS OF THIS AGREEMENT CAREFULLY
        BEFORE DOWNLOADING OR INSTALLING THE SOFTWARE. BY DOWNLOADING THE
        SOFTWARE OR CLICKING ON THE BUTTON ASSENTING TO THIS AGREEMENT, YOU ARE
        INDICATING THAT YOU HAVE READ, UNDERSTOOD AND ACCEPT THIS AGREEMENT, AND
        THAT YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS STATED BELOW AS
        OR ON BEHALF OF LICENSEE. IF YOU DO NOT AGREE WITH THE TERMS OF THIS
        AGREEMENT, DO NOT DOWNLOAD, INSTALL, COPY, OR OTHERWISE USE THE
        SOFTWARE, AND PROMPTLY RETURN THE UNUSED SOFTWARE TO THE POINT OF
        PURCHASE.
      </p>
      <ol>
        <li>
          <strong>Software License.</strong>
          <br />
          (a) License Grant. Subject to Licensee’s compliance with the terms and
          conditions of this Agreement and payment of the applicable license
          fee, Apstra grants to the Licensee a nonexclusive, nontransferable,
          non-sublicensable, limited license to access and use the Software, in
          object code form only, solely for Licensee’s internal business
          purposes in accordance with this Agreement. This Agreement allows for
          use of the Software only on the total number of workstations or
          computers for which Licensee has purchased licenses and acquired a
          license key and during the term set forth on any order form. Subject
          to the limitations of this Agreement, Licensee may only use the
          Software on a central processing unit (CPU), workstation or portable
          computer that is owned or controlled by Licensee. (b) Evaluation
          License. If Licensee is licensing the Software for evaluation
          purposes, Licensee’s use of the Software is only permitted in a
          non-production environment and for the period limited by the License
          Key. Notwithstanding any other provision in this EULA, an Evaluation
          License of the Software is provided “AS-IS” without indemnification,
          support or warranty of any kind, expressed or implied.
          <br />
          (c) Third Party Technologies. The Software may include or be bundled
          with other software programs licensed under different terms and/or
          licensed by a vendor other than Apstra. Use of any software programs
          accompanied by a separate license agreement is governed by that
          separate license agreement and not the terms of this Agreement, except
          that Sections 7(c) and 8 shall apply to such third party software. Any
          third party software that may be provided with the Software is
          included for use at Licensee’s option. Please review the notices that
          accompany the Software for the separate license notices and
          requirements for the third party software. Apstra is not responsible
          for any third party’s software and shall have no liability for
          Licensee’s use of third party software. (d) Professional Services. If
          Apstra provides any professional services to Licensee (whether at no
          charge or for an additional fee) to install, integrate or customize
          the Software, then any inventions, technology, developments or other
          work product developed during or resulting from such services (“Work
          Product”) shall be considered part of the Software licensed to
          Licensee under this Agreement. Apstra warrants that all such services
          ordered and paid for by Licensee will be performed in a professional
          manner consistent with generally accepted industry standards. If there
          is a breach of the foregoing warranty, Licensee’s sole and exclusive
          remedy, and Apstra’s sole and exclusive liability, will be to
          reperform the services at no additional charge to Licensee to the
          extent such services are capable of being reperformed and to the
          extent necessary to cure such breach or, at Apstra’s sole option and
          discretion, Apstra will refund a prorate portion of the fees paid for
          the affected services.
          <br />
          <br />
        </li>
        <li>
          <strong>Restrictions.</strong>
          <br />
          Apstra reserves all rights not expressly granted to Licensee under
          this Agreement. Licensee may not rent, loan, lease, sublease, assign
          or otherwise transfer of dispose of the Software or its documentation.
          Licensee may make a limited number of backup copies of the Software
          based on the number of licensed workstations. The Software contains
          copyrighted material, trade secrets and other proprietary material.
          Licensee must reproduce on all such copies of the Software the
          copyright notices and any other proprietary legends that were on the
          original copy of the Software. Licensee may make one (1) hard copy of
          the documentation for each user of the Software. Licensee shall not,
          without the prior written permission of Apstra, permit anyone else to
          disassemble, decompile, or reverse engineer the Software in order to
          obtain the source code, which is a trade secret of Apstra and/or its
          licensors. Licensee may not modify, alter, or prepare derivative works
          based upon the Software (including documentation) in whole or in part.
          <br />
          <br />
        </li>
        <li>
          <strong>Ownership.</strong>
          <br />
          No title to or ownership in the Software is transferred to Licensee.
          Licensee acknowledges and agrees that Apstra and its licensors own and
          retain all rights, title and interest in the Software, including any
          Work Product incorporated in the Software, and ownership of all
          intellectual property rights therein, and including any derivative
          works, modifications, adaptations or copies thereof. The Software is
          the propriety product of Apstra and its licensors and is protected by
          United States copyright laws and international provisions. Licensee
          owns the media on which the Software is recorded, if any. Licensee
          agrees not to attempt in any way to obliterate, remove or destroy the
          trade secret or copyright notice in any copies of the Software. Any
          feedback, suggestions or ideas relating to the Software or Work
          Product provided by Licensee to Apstra (collectively “Feedback”) shall
          be owned by Apstra and Licensee hereby assigns any ownership interest
          in such Feedback to Apstra and acknowledges that such Feedback may be
          used by Apstra without obligation to Licensee.
          <br />
          <br />
        </li>
        <li>
          <strong>Technical Support.</strong>
          <br />
          Licensee must register with Apstra in order to be eligible for
          technical support via telephone or electronic mail. During any period
          for which Licensee shall pay for Support, Licensee will have access to
          technical support for the Software for the technical contact
          designated by Licensee, subject to Licensee’s compliance with Apstra’s
          instructions for accessing such support and Apstra’s standard
          verification procedures. Licensee may extend technical support on an
          annual fee basis in accordance with Apstra’s then-current pricing and
          Support and Maintenance Terms; provided, however, Apstra is under no
          obligation to make technical support available or otherwise provide
          Licensee with updates or error corrections of the Software
          (collectively “Releases”). If Apstra, in its sole option, supplies
          Releases to Licensee, the Releases will be considered part of
          Software, and subject to the terms of this Agreement.
          <br />
          <br />
        </li>
        <li>
          <strong>Termination.</strong>
          <br />
          (a) Term. This Agreement will be deemed to commence on the date that
          the Software is made available to Licensee and shall remain in effect
          during the term for which Licensee licenses the Software. (b)
          Termination. Licensee may terminate this Agreement at any time by
          ceasing to use the Software and destroying all copies of the Software
          and documentation. This Agreement will terminate immediately without
          notice from Apstra if Licensee fails to comply with any term or
          provision of this Agreement.
          <br />
          (c) Termination for Breach. Apstra may terminate this Agreement in its
          entirety effective immediately upon written notice to Licensee if: (a)
          Licensee breaches any provision of this Agreement and does not cure
          the breach within thirty (30) days after receiving written notice
          thereof from Apstra; (b) Licensee fails to pay any portion of the fees
          under an applicable order within thirty (30) days after receiving
          written notice from Apstra that payment is past due; or (c) Licensee
          commits a material breach that is not capable of being cured. (d)
          Effect of Termination. Upon termination or expiration of this
          Agreement, Licensee must immediately cease using the Software and its
          related documentation and any Confidential Information. Within five
          (5) business days of the termination of this Agreement, Licensee will
          remove all copies of the Software from Licensee’s systems and
          locations, in whole or in part, including all permitted archival and
          back-up copies and destroys the same. Sections 2, 3, 5(d), 6, 7(c) and
          8 through 12 shall survive the termination of this Agreement.
          <br />
          <br />
        </li>
        <li>
          <strong>Confidentiality.</strong>
          <br />
          (a) Obligations. For purposes of this Agreement, “Confidential
          Information” means: (i) business and technical information and any
          source code or binary code, which Apstra discloses to Licensee related
          to the Software; (ii) Licensee’s feedback based on Software; and (iii)
          the terms, conditions, and existence of this Agreement. Licensee may
          not disclose or use Confidential Information, except for the purpose
          of performing Licensee’s obligations specified in this Agreement.
          Licensee will protect the Confidential Information with the same
          degree of care, but not less than a reasonable degree of care, as
          Licensee uses to protect Licensee’s own Confidential Information.
          Licensee’s obligations regarding Confidential Information will expire
          no less than five (5) years from the date of receipt of the
          Confidential Information. (b) Exceptions. Notwithstanding any
          provisions contained in this Agreement concerning nondisclosure and
          non-use of the Confidential Information, the nondisclosure obligations
          of Section 6(a) will not apply to any portion of Confidential
          Information that Licensee can demonstrate in writing is: (i) now, or
          hereafter through no act or failure to act on the part of Licensee
          becomes, generally known to the general public; (ii) known to Licensee
          at the time of receiving the Confidential Information without an
          obligation of confidentiality; (iii) hereafter rightfully furnished to
          Licensee by a third party without restriction on disclosure; or (iv)
          independently developed by Licensee without any use of the
          Confidential Information. (c) Additional Restrictions. Licensee must
          restrict access to Confidential Information to Licensee’s employees or
          contractors with a need for this access to perform their employment or
          contractual obligations and who have agreed in writing to be bound by
          a confidentiality obligation, which incorporates the protections and
          restrictions substantially as set forth in this Agreement.
          <br />
          <br />
        </li>
        <li>
          <strong>Limited Warranty and Disclaimer.</strong>
          <br />
          (a) Limited Warranty. Apstra warrants to Licensee that, for a period
          of ninety (90) days following delivery of the Software to Licensee
          (the “Warranty Period”), the Software will perform in all material
          respects in accordance with its published documentation. Licensee
          shall report any warranty claim to Apstra during such ninety-day
          period.
          <br />
          (b) Exclusive Remedy. As Licensee’s sole and exclusive remedy under
          this warranty, Apstra will, at its option and expense: (i) promptly
          replace or correct any defective Software or media; or (ii) furnish
          Licensee with a reasonable procedure to circumvent the defect; or
          (iii), if Apstra is unable to do either of the foregoing, refund to
          Licensee the license fees paid by Licensee for the defective Software,
          provided Licensee returns such defective Software to Apstra within the
          warranty period. These limited warranties shall not apply if (a) the
          Software is licensed under an evaluation license; (b) the Software is
          used in conjunction with hardware or software other than those
          specified in the documentation; or (c) Licensee is not using the most
          current version of the Software. No warranties whatsoever are made by
          Apstra to any third party. <br />
          <br />
          (c) Disclaimer. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAWS,
          APSTRA AND APSTRA’S LICENSORS EXPRESSLY DISCLAIM ALL OTHER WARRANTIES,
          EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NONINFRINGEMENT. APSTRA AND ITS LICENSORS DO NOT WARRANT THAT THE
          FUNCTIONS CONTAINED IN THE SOFTWARE WILL MEET LICENSEE’S REQUIREMENTS,
          OR THAT THE OPERATION OF THE SOFTWARE WILL BE UNINTERRUPTED OR
          ERROR-FREE, OR THAT DEFECTS IN THE SOFTWARE WILL BE CORRECTED.
          FURTHERMORE, NEITHER APSTRA NOR ITS LICENSORS WARRANT OR MAKE ANY
          REPRESENTATIONS REGARDING THE PERFORMANCE OR THE RESULTS OF THE USE OF
          THE SOFTWARE OR ITS DOCUMENTATION IN TERMS OF THEIR CORRECTNESS,
          ACCURACY, RELIABILITY, OR OTHERWISE. NO ORAL OR WRITTEN INFORMATION OR
          ADVICE GIVEN BY APSTRA OR ITS AUTHORIZED REPRESENTATIVE SHALL CREATE A
          WARRANTY OR IN ANY WAY INCREASE THE SCOPE OF THIS WARRANTY. Some
          jurisdictions do not allow certain disclaimers and limitations of
          warranties, so portions of the above limitations may not apply to
          Licensee.
          <br />
          <br />
        </li>
        <li>
          <strong>Limitation of Liability.</strong>
          <br />
          IN NO EVENT SHALL APSTRA OR ITS LICENSORS OR THEIR RESPECTIVE
          DIRECTORS, OFFICERS, EMPLOYEES OR AGENTS BE LIABLE TO LICENSEE FOR ANY
          INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES ARISING OUT OF
          OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THE SOFTWARE OR
          SERVICES PROVIDED HEREUNDER (INCLUDING WITHOUT LIMITATION DAMAGES FOR
          LOSS OF PROFITS, BUSINESS, DATA, GOODWILL, ANTICIPATED SAVINGS,
          BUSINESS INTERRUPTION, LOSS OF BUSINESS INFORMATION AND THE LIKE),
          EVEN IF APSTRA OR ITS AUTHORIZED REPRESENTATIVE HAS BEEN ADVISED OF
          THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL APSTRA’S TOTAL
          LIABILITY UNDER THIS AGREEMENT, FROM ALL CAUSES OF ACTION AND UNDER
          ALL THEORIES OF LIABILITY, EXCEED THE PAYMENTS ACTUALLY RECEIVED BY
          APSTRA FOR SOFTWARE LICENSED TO LICENSEE DURING THE TWELVE (12) MONTHS
          PRIOR TO THE DATE OF THE EVENT GIVING RISE TO ANY LIABILITY.
          <br />
          <br />
        </li>
        <li>
          <strong>Indemnification.</strong>
          <br />
          (a) By Apstra. Apstra will defend and protect Licensee from a claim
          against Licensee by a third party that the Software furnished and used
          within the scope of this Agreement infringes a third party copyright
          or patent (a “Claim”) and for all costs and damages, including
          reasonable attorney’s fees and expenses finally awarded by a court of
          competent jurisdiction or agreed to in a settlement of such a Claim,
          provided that: (i) Licensee notifies Apstra promptly in writing of the
          Claim; (ii) Apstra is provided sole control of the defense of the
          Claim and all related settlement negotiations and (iii) Licensee
          provides Apstra with assistance, information and authority necessary
          to perform the above. Apstra shall have no obligation to Licensee
          under this Section 9(a) for any claim of infringement to the extent
          based in whole or in part on: (a) alterations or modifications of the
          Software not made by Apstra or (b) combinations of the Software with
          products or materials not provided by Apstra where the infringement
          would not
          <br />
          have occurred but for Licensee’s combination. In the event that Apstra
          anticipates that the Software may become subject to a Claim or the
          Software does become subject to a Claim, Apstra shall at its option
          and expense (a) modify the Software to be noninfringing; (b) obtain
          for Licensee a license to continue using the Software; or (c)
          substitute the Software with other substantially similar product. In
          the event the foregoing are not commercially practicable, Apstra may
          terminate the license for the infringing Software and provide Licensee
          with a pro-rated refund for any remaining amount of a then-current
          term. The foregoing Section 9(a) states the entire liability of Apstra
          and the sole and exclusive remedy of Licensee with respect to any
          claim of intellectual property infringement. (b) By Licensee. Licensee
          shall defend, indemnify, and hold Apstra, its officers, directors and
          employees, harmless from and against any and all claims, damages,
          losses, costs or other expenses (including reasonable attorneys’ fees)
          that arise directly or indirectly out of Licensee’s willful misconduct
          or unauthorized use of the Software, provided that: (i) Apstra
          notifies Licensee promptly in writing of the Claim; (ii) Licensee is
          provided sole control of the defense of the Claim and all related
          settlement negotiations and (iii) Apstra provides Licensee with
          assistance, information and authority necessary to perform the above.
          <br />
          <br />
        </li>
        <li>
          <strong>Export.</strong>
          <br />
          Software and technical data delivered under this Agreement are subject
          to U.S. export control laws and may be subject to export or import
          regulations in other countries. Licensee agrees to comply strictly
          with all such laws and regulations and acknowledge that Licensee has
          the responsibility to obtain such licenses to export, re-export or
          import as may be required after delivery to Licensee.
          <br />
          <br />
        </li>
        <li>
          <strong>Government End Users.</strong>
          <br />
          If the Software is being acquired by or on behalf of the U.S.
          Government or by a U.S. Government prime contractor or subcontractor
          (at any tier), then the Government’s rights in the Software shall be
          only as set forth in this Agreement; this is in accordance with 48
          C.F.R. 227.7202-4 (for Department of Defense (DOD) acquisitions) and
          with 48 C.F.R. 2.101 and 12.212 (for non-DOD acquisitions). Supplier
          is Apstra Inc., 200 Middlefield Rd, Suite 110, Menlo Park, CA 94025.
          <br />
          <br />
        </li>
        <li>
          <strong>Miscellaneous.</strong>
          <br />
          (a) Severability. If any provision of this Agreement is invalid or
          unenforceable under applicable law, then it shall be, to that extent,
          deemed omitted and the remaining provisions will continue in full
          force and effect. (b) Governing Law and Venue. The validity and
          performance of this Agreement shall be governed by California law
          (without reference to choice of law principles), and applicable
          federal law. Any action, suit or proceeding relating to this Agreement
          shall be brought in the appropriate federal or state court location in
          Santa Clara County, California, and Licensee hereby consent to such
          jurisdiction. The United Nations Convention on Contracts for the
          International
          <br />
          Sale of Goods shall not apply. (c) Construction. This Agreement is
          deemed entered into in California, and shall be construed as to its
          fair meaning and not strictly for or against either party. (d)
          Equitable Relief. Licensee agrees that Apstra could be irreparably
          harmed by the unauthorized use or disclosure of any of the Software,
          and that money damages may not be an adequate remedy. Accordingly,
          Licensee agrees that Apstra may seek equitable relief, including
          injunctive relief, for breaches of confidentiality or unauthorized use
          of the Software. (e) Assignment. This Agreement will be binding upon
          and inure to the benefit of the parties hereto and their respective
          successors and assigns; provided, however, that Licensee shall not
          assign any of Licensee’s rights, obligations, or privileges (by
          operation of law or otherwise) hereunder without the prior written
          consent of Apstra. Any attempted assignment in violation of this
          section will be void and of no effect. (f) Waiver. No term or
          provision hereof shall be deemed waived and no breach consented to or
          excused, unless such waiver, consent or excuse shall be in writing and
          signed by the party claimed to have waived or consented. Should either
          party consent, waive, or excuse a breach by the other party, such
          shall not constitute consent to, waiver of, or excuse of any other
          different or subsequent breach whether or not of the same kind as the
          original breach. (g) Attorneys’ Fees. In the event of any legal action
          or proceeding relating to this Agreement, the prevailing party shall
          be entitled to recover its attorneys’ fees in addition to any other
          relief granted. (h) Entire Agreement; Modification. This Agreement
          sets forth the entire understanding and agreement between Licensee and
          Apstra and supersedes all prior or contemporaneous agreements
          regarding its subject matter. Any different or additional terms
          preprinted on any Licensee purchase order or similar document are
          hereby rejected, notwithstanding any terms set forth therein to the
          contrary. This Agreement may be amended only in a writing signed by
          both parties.
        </li>
      </ol>
    </div>
  );
});
