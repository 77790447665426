import React, { memo, useState } from 'react';
import Chart from 'react-apexcharts';
import { Card, Divider, Icon } from 'semantic-ui-react';

import { getTimeRanges } from 'utils';

import { MetricDataName } from 'enums';
import { useTimeSeriesData } from 'hooks/reportHooks/useTimeSeriesData';

import {
  contactPerDepartmentOptions,
  contactPerPartnerCompanyOptions
} from '../ChartOptions';
import {
  StartEndMixin,
  TimelinePicker
} from '../TimelinePicker/TimelinePicker';
import {
  getContactsPerDepartmentSeries,
  getContactsPerPCSeries,
  getTotalContacts
} from './ContactChartHelpers';

import './styles.less';

export const ContactCharts: React.FC = memo(() => {
  const [dateTime, setDateTime] = useState(getTimeRanges('1w'));

  // Adding comments for readability: original responses by api
  const originalNumberOfContacts = useTimeSeriesData(
    MetricDataName.TotalNumberOfContacts,
    dateTime.start,
    dateTime.end
  ).data;
  const originalContactsByDepartment = useTimeSeriesData(
    MetricDataName.NumberOfContactsPerDepartment,
    dateTime.start,
    dateTime.end
  );
  const originalContactsByPartnerCompany = useTimeSeriesData(
    MetricDataName.NumberOfContactsPerPartnerCompany,
    dateTime.start,
    dateTime.end
  );
  const numberOfContactsByPartnerCompany =
    originalContactsByPartnerCompany.data;
  const numberOfContactsByDepartment = originalContactsByDepartment.data;

  // process data for charts
  const totalNumberOfContacts = getTotalContacts(
    originalNumberOfContacts && originalNumberOfContacts.timeseries
      ? originalNumberOfContacts.timeseries
      : []
  );
  const contactsPerDepartment = getContactsPerDepartmentSeries(
    numberOfContactsByDepartment && numberOfContactsByDepartment.timeseries
      ? numberOfContactsByDepartment.timeseries
      : []
  );
  const contactsPerPartnerCompany = getContactsPerPCSeries(
    numberOfContactsByPartnerCompany &&
      numberOfContactsByPartnerCompany.timeseries
      ? numberOfContactsByPartnerCompany.timeseries
      : []
  );

  return (
    <div className='graph-padding'>
      <h2 className='caption'>Contacts</h2>
      <TimelinePicker
        setDateTime={(value: StartEndMixin) => setDateTime(value)}
      />
      <Divider />
      <Card
        raised
        header={
          <h3>
            <Icon name='user' />
            Total number of contacts {totalNumberOfContacts}
          </h3>
        }
      />
      <div className='charts-div'>
        <Chart
          options={contactPerDepartmentOptions(
            originalContactsByDepartment.isLoading
          )}
          series={contactsPerDepartment}
          type='line'
          width='700'
          height='300'
        />
        <Chart
          options={contactPerPartnerCompanyOptions(
            originalContactsByPartnerCompany.isLoading
          )}
          series={contactsPerPartnerCompany}
          type='line'
          width='700'
          height='300'
        />
      </div>
    </div>
  );
});
