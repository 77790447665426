import { find } from 'lodash';
import React, { memo } from 'react';

import { VM } from 'types';

import { VMCard } from '../VMCard';

type Props = {
  vm: VM | undefined;
  enabled: boolean;
};

const getAccess = (vm: VM | undefined) => {
  return vm
    ? find(
        vm.access,
        acc => acc.protocol === 'http' || acc.protocol === 'https'
      )
    : null;
};

export const AOSUICard: React.FC<Props> = memo(({ vm, enabled }) => (
  <VMCard
    name='Apstra UI'
    description='Administration interface of the Apstra Server, you can see how to operate an existing topology or build your own'
    vm={vm}
    access={getAccess(vm)}
    enabled={enabled}
  />
));
