/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */

import React, { memo, useCallback, useEffect, useState } from 'react';
import { Checkbox, Radio, Table as SemanticTable } from 'semantic-ui-react';

interface TableProps {
  header: string[];
  rows?: JSX.Element[][];
  selectableRows: 'radio' | 'checkbox' | 'disabled';
  onSelected: (selectedRows: number[]) => void;
  isLoading: boolean;
  isError: boolean;
}

export const Table: React.FC<TableProps> = memo(
  ({ header, rows, selectableRows, onSelected, isLoading, isError }) => {
    const [selectedRows, setSelectedRows] = useState<number[]>([]);

    useEffect(() => {
      if (onSelected) {
        onSelected(selectedRows);
      }
    }, [selectedRows, onSelected]);

    const handleSelect = useCallback(
      (index: number) => {
        if (selectableRows === 'radio') {
          setSelectedRows([index]);
        } else if (selectableRows === 'checkbox') {
          if (selectedRows.includes(index)) {
            setSelectedRows(selectedRows.filter(row => row !== index));
          } else {
            setSelectedRows([...selectedRows, index]);
          }
        }
      },
      [selectableRows, selectedRows]
    );

    if (isError) {
      return <div>Something went wrong</div>;
    }

    return (
      <SemanticTable celled>
        <SemanticTable.Header>
          <SemanticTable.Row>
            {selectableRows !== 'disabled' && <th>Select</th>}
            {header.map((item, index) => (
              <SemanticTable.HeaderCell key={index}>
                {item}
              </SemanticTable.HeaderCell>
            ))}
          </SemanticTable.Row>
        </SemanticTable.Header>
        <SemanticTable.Body>
          {!isLoading &&
            rows?.map((rowItems, rowIndex) => (
              <SemanticTable.Row key={rowIndex}>
                {selectableRows === 'radio' && (
                  <SemanticTable.Cell key={rowIndex}>
                    <Radio
                      checked={selectedRows.includes(rowIndex)}
                      onChange={() => handleSelect(rowIndex)}
                    />
                  </SemanticTable.Cell>
                )}
                {selectableRows === 'checkbox' && (
                  <SemanticTable.Cell key={rowIndex}>
                    <Checkbox
                      checked={selectedRows.includes(rowIndex)}
                      onChange={() => handleSelect(rowIndex)}
                    />
                  </SemanticTable.Cell>
                )}
                {rowItems.map((cell, cellIndex) => (
                  <SemanticTable.Cell key={cellIndex}>
                    {cell}
                  </SemanticTable.Cell>
                ))}
              </SemanticTable.Row>
            ))}
        </SemanticTable.Body>
      </SemanticTable>
    );
  }
);
