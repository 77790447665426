import { find, map } from 'lodash';
import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox, Table } from 'semantic-ui-react';
import { authUtils, contactUtils } from 'utils';

import { SelectionType } from 'components/ContactsTable/SelectionType';
import { CustomTableHeaderRow } from 'components/CustomTableHeaderRow';
import { Pagination } from 'components/TablePagination';
import { Template, Topology } from 'types';

import { setTopologies } from 'actions/actions';
import { TopologyRow } from './TopologyRow';

import './styles.less';

type Props = {
  readonly topologies: Topology[];
  readonly templates: Template[];
  readonly displayedColumns: string[];
  readonly selectionType: SelectionType;
  readonly onDelete: (topology: Topology) => void;
  readonly onExtendDuration: () => void;
  readonly activePage: number;
  readonly totalPages: number;
  readonly setActivePage: React.Dispatch<React.SetStateAction<number>>;
};

export const TopologiesTable: React.FC<Props> = memo(
  ({
    topologies,
    templates,
    displayedColumns,
    selectionType,
    onDelete,
    onExtendDuration,
    activePage,
    totalPages,
    setActivePage
  }) => {
    const [selectedTopologies, updateSelectedTopologies] = useState<Topology[]>(
      []
    );
    const dispatch = useDispatch();
    const isTopologySelected = (uuid: string) => {
      return (
        selectedTopologies &&
        selectedTopologies.filter(topology => topology.uuid === uuid)
      );
    };
    const handleSelect = (topology: Topology) => {
      const tempTopologies =
        isTopologySelected(topology.uuid).length !== 0
          ? selectedTopologies.filter(
              selected => selected.uuid !== topology.uuid
            )
          : [...selectedTopologies, topology];
      updateSelectedTopologies(tempTopologies);
      dispatch(setTopologies(tempTopologies));
    };
    const handleSelectAll = () => {
      const tempTopologies =
        selectedTopologies.length === topologies.length ||
        selectedTopologies.length > 0
          ? []
          : topologies;
      updateSelectedTopologies(tempTopologies);
      dispatch(setTopologies(tempTopologies));
    };
    const allOrAnySelected = () =>
      (selectedTopologies.length === topologies.length &&
        topologies.length !== 0) ||
      selectedTopologies.length > 0;
    const isAdminOrInternal = contactUtils.isAdminOrInternal(
      authUtils.getLoggedInUser()
    );
    const header = (
      <Table.Header>
        <CustomTableHeaderRow
          selectionType={selectionType}
          indeterminate={allOrAnySelected()}
          onChange={() => handleSelectAll()}
          displayedColumns={displayedColumns}
        />
      </Table.Header>
    );
    const footer = (
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan='12'>
            <Pagination
              activePage={activePage}
              totalPages={totalPages}
              onPaginationChange={page => {
                setActivePage(page);
              }}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    );
    return (
      <Table celled striped unstackable className='cl-topologies-table'>
        {header}

        <Table.Body>
          {map(topologies, topology => {
            const template = find(templates, { id: topology.template });

            return (
              <Table.Row
                key={topology.uuid}
                onClick={() => handleSelect(topology)}
                data-testid={`row-${topology.uuid}`}
              >
                {isAdminOrInternal && selectionType !== 'none' && (
                  <Table.Cell collapsing>
                    <Checkbox
                      checked={isTopologySelected(topology.uuid).length !== 0}
                      onChange={() => handleSelect(topology)}
                      data-testid={`checkbox-${topology.uuid}`}
                    />
                  </Table.Cell>
                )}
                <TopologyRow
                  data-testid={`row-${topology.uuid}`}
                  key={topology.uuid}
                  topology={topology}
                  template={template}
                  displayedColumns={displayedColumns}
                  onDelete={onDelete}
                  onExtendDuration={onExtendDuration}
                />
              </Table.Row>
            );
          })}
        </Table.Body>

        {footer}
      </Table>
    );
  }
);
