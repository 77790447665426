import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Confirm, Dropdown, Icon } from 'semantic-ui-react';

import { authUtils, contactUtils, interpolateRoute, notifier } from 'utils';

import { contactsApi } from 'api/contacts';
import { ChooseDepartmentModal } from 'components/ContactsTable';
import { routes } from 'constants/routing';
import { Contact } from 'types';

import { ContactsState, fetchContacts } from 'features/contacts';
import { RootState } from 'Reducers/contactReducer';

import './styles.less';

export const ContactActionContainer: React.FC = () => {
  const contactsState: ContactsState = useSelector(
    (state: RootState) => state.contact
  );
  const selectedContacts = contactsState.selected;

  const dispatch = useDispatch();
  const dropdownDisable = selectedContacts.length === 0;
  const multiContactsDisable = selectedContacts && selectedContacts.length > 1;
  const id =
    selectedContacts && selectedContacts.length === 1
      ? selectedContacts[0].id
      : authUtils.getLoggedInUser().id;
  const [confirmDeleteModelOpen, setConfirmDeleteModelOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const onDelete = () => {
    if (!selectedContacts) {
      return;
    }
    selectedContacts.forEach(async (contact: Contact) => {
      try {
        await contactsApi.remove(contact.id);
        notifier.success({
          message: `Contact "${contact.name}" deleted`
        });
        dispatch(
          fetchContacts({
            page: contactsState.currentPage,
            filter: contactsState.filter.text,
            limit: contactsState.limit,
            role: contactsState.filter.role
          })
        );
      } catch (err) {
        notifier.requestFailed(err);
      }
      setConfirmDeleteModelOpen(false);
    });
  };
  const user = authUtils.getLoggedInUser();
  const departmentVisible = selectedContacts.some(c => c.department !== '');

  return (
    <div>
      <Dropdown
        text='Actions'
        button
        labeled
        basic
        className='icon'
        direction='right'
      >
        <Dropdown.Menu className='right'>
          <Dropdown.Menu scrolling>
            <Dropdown.Item
              disabled={dropdownDisable}
              onClick={() => setConfirmDeleteModelOpen(true)}
            >
              <Icon name='delete' /> Delete
            </Dropdown.Item>
            {contactUtils.isInternalContact(user.role) && departmentVisible && (
              <Dropdown.Item
                disabled={dropdownDisable}
                onClick={() => {
                  setConfirmModalOpen(true);
                }}
              >
                <Icon name='tag' /> Manage Departments
              </Dropdown.Item>
            )}
            <Dropdown.Item
              disabled={multiContactsDisable || dropdownDisable}
              onClick={() =>
                (window.location.href = interpolateRoute(
                  routes.contactDetails.path,
                  { id, active: 'topologies' }
                ))
              }
            >
              <Icon name='settings' /> Manage Existing Topologies
            </Dropdown.Item>
            <Dropdown.Item
              disabled={multiContactsDisable || dropdownDisable}
              onClick={() =>
                (window.location.href = interpolateRoute(
                  routes.createTopology.path,
                  { id }
                ))
              }
            >
              <Icon name='plus circle' /> Create Topology
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown.Menu>
      </Dropdown>
      <Confirm
        size='mini'
        header='Delete contact'
        content={`This action is irreversible. Are you sure you want to delete contacts ${selectedContacts
          .map(c => c.name)
          .join(', ')}?`}
        open={confirmDeleteModelOpen}
        onCancel={() => setConfirmDeleteModelOpen(false)}
        onConfirm={() => onDelete()}
        confirmButton='Delete'
      />
      {confirmModalOpen && (
        <ChooseDepartmentModal
          open={confirmModalOpen}
          setOpen={setConfirmModalOpen}
          contacts={selectedContacts}
        />
      )}
    </div>
  );
};
