/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */
import axios from 'axios';
import {
  getFlowSizingFromSurvey,
  getMatchesFromSurvey
} from 'utils/SizingCalculator';
import { isNullOrUndefined } from 'utils/utilFunctions';

import { apiPrefix } from 'constants/api-routes';
import {
  BlueprintTestCaseOutputType,
  FlowSizingOutputType,
  Survey
} from 'types';

import { Model } from 'survey-core';

// TypeScript 4.2.x doesn't like the following types, and fails to compile with the following error:
//
//    Line 0:  Parsing error: Cannot read property 'map' of undefined
//
// Leave type definitions for future reference.

// type Answer = string | number | boolean;
// type Question = string;
// type Title = string;
// type SurveyQuestion = [Question, Title, Answer];
// type SurveyQuestions = SurveyQuestion[][];

type SurveyQuestions = string[][];

interface SurveyCompletePayload {
  survey: SurveyQuestions[];
  projectName: string;
  projectDescription: string;
  testing: boolean;
  to: string[];
  cc: string[];
}

interface SurveyLoggingPayload {
  userSessionId: string;
  surveyStatus: string;
  survey?: Model;
  sizingResponse?: BlueprintTestCaseOutputType;
  sizingTeamContacted?: boolean;
  sizingTeamContactedEmails?: string[];
}

export enum SurveyStatus {
  Started = 'Started',
  Pending = 'Pending',
  Completed = 'Completed'
}

export const getSurveyCompletePayload = (
  survey: Model,
  to: string[],
  cc: string[]
): SurveyCompletePayload => {
  const projectName = survey.getQuestionByName('project_name').value;
  const projectDescription = survey.getQuestionByName('project_description')
    .value;
  const testing = survey.getQuestionByName('testing').value;
  const surveyQuestions: SurveyQuestions[] = [];
  const plainData = survey
    .getQuestionByName('all_fabrics')
    .getPlainData({ includeEmpty: false });
  if (plainData && plainData.data) {
    for (const panel of plainData.data) {
      if (panel.data) {
        const panelData: SurveyQuestions = [];

        for (const q of panel.data) {
          panelData.push([
            q.name as string,
            q.title,
            q.displayValue ? q.displayValue : q.value
          ]);
        }
        surveyQuestions.push(panelData);
      }
    }
  }

  return {
    survey: surveyQuestions,
    projectName,
    projectDescription,
    testing,
    to,
    cc
  };
};

export const getCompletedSurveyObj = (survey: Model): Survey[] => {
  const surveyQuestions: Survey[] = [];
  const plainData = survey
    .getQuestionByName('all_fabrics')
    .getPlainData({ includeEmpty: false });
  if (plainData && plainData.data) {
    for (const panel of plainData.data) {
      if (panel.data) {
        const panelData: Survey = {};

        for (const q of panel.data) {
          Object.assign(panelData, {
            [q.name]: (q.value ? q.value : q.displayValue).toString()
          });
        }
        surveyQuestions.push(panelData);
      }
    }
  }
  return surveyQuestions;
};

export const matchSurvey = (
  parsedSurvey: Survey[]
): BlueprintTestCaseOutputType[] => {
  let filteredSurvey: any[] = [];
  if (parsedSurvey.length && parsedSurvey.length < 2) {
    const survey = parsedSurvey[0];
    if (survey.number_of_fabrics && survey.number_of_fabrics <= '2') {
      const matches = getMatchesFromSurvey(survey);
      if (!isNullOrUndefined(matches)) {
        filteredSurvey = [matches];
      }
    }
  }
  return filteredSurvey;
};

export const matchFlowSizingSurvey = (
  parsedSurvey: Survey[]
): FlowSizingOutputType => {
  // only pick the first survey
  if (parsedSurvey.length) {
    return getFlowSizingFromSurvey(parsedSurvey[0]);
  }
  return {};
};

const sizingCalculatorAnalyticsApi = (data: SurveyLoggingPayload) => {
  return axios.post(apiPrefix('/v1.3/sizingCalculator/analytics'), data);
};

export const surveyComplete = (
  userSessionId: string,
  survey: Model,
  sizingTeamContacted: boolean,
  to: string[],
  cc: string[],
  surveyMatches: BlueprintTestCaseOutputType,
  onBeforeSend: () => void,
  onSuccess: () => void,
  onError: (reason: Error) => void
) => {
  onBeforeSend();
  axios
    .post(
      apiPrefix('/v1.2/survey/complete'),
      getSurveyCompletePayload(survey, to, cc)
    )
    .then(() => {
      sizingCalculatorAnalyticsApi({
        userSessionId,
        survey: survey.data,
        sizingResponse: surveyMatches,
        sizingTeamContacted,
        sizingTeamContactedEmails: [...to, ...cc],
        surveyStatus: SurveyStatus.Completed
      });
    })
    .then(() => {
      onSuccess();
    })
    .catch(reason => {
      onError(reason);
    });
};

export const surveyLogged = (
  userSessionId: string,
  survey: Model,
  sizingTeamContacted: boolean,
  surveyMatches: BlueprintTestCaseOutputType,
  onBeforeSend: () => void,
  onSuccess: () => void,
  onError: (reason: Error) => void
) => {
  onBeforeSend();
  sizingCalculatorAnalyticsApi({
    userSessionId,
    survey: survey.data,
    sizingResponse: surveyMatches,
    sizingTeamContacted,
    surveyStatus: SurveyStatus.Completed
  })
    .then(() => {
      onSuccess();
    })
    .catch(reason => {
      onError(reason);
    });
};

export const surveyLogging = (
  userSessionId: string,
  surveyStarted: boolean,
  surveyFilled: boolean,
  survey?: Model,
  surveyMatches?: BlueprintTestCaseOutputType
) => {
  if (surveyStarted) {
    sizingCalculatorAnalyticsApi({
      userSessionId,
      surveyStatus: SurveyStatus.Started
    });
  } else if (surveyFilled) {
    if (surveyMatches) {
      sizingCalculatorAnalyticsApi({
        userSessionId,
        survey,
        sizingResponse: surveyMatches,
        surveyStatus: SurveyStatus.Pending
      });
    } else {
      sizingCalculatorAnalyticsApi({
        userSessionId,
        survey,
        surveyStatus: SurveyStatus.Pending
      });
    }
  }
};
