import React, { memo } from 'react';
import { Redirect, RouteProps } from 'react-router';
import { Route } from 'react-router-dom';

import { authUtils, permissionsUtils } from 'utils';

import { defaultRoute, routes } from 'constants/routing';

export const PrivateRoute: React.FC<RouteProps> = memo(props => {
  if (!authUtils.loggedIn()) {
    return <Redirect to={routes.login.path} />;
  }
  if (!permissionsUtils.checkRoutePermissions(props.path as string)) {
    return <Redirect to={defaultRoute.path} />;
  }

  return <Route {...props} />;
});
