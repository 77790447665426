import { isEmpty } from 'lodash';
import React, { Fragment, memo, useCallback, useMemo, useState } from 'react';
import { Button, Confirm, Icon } from 'semantic-ui-react';

import { interpolateRoute, notifier } from 'utils';

import { templatesApi } from 'api/templates';
import { NoDataPlaceholder, RequestData } from 'components';
import { apiRoutes } from 'constants/api-routes';
import { routes } from 'constants/routing';
import { MainLayout } from 'layouts';
import { Template } from 'types';

import { TemplatesTable } from './TemplatesTable';

import './styles.less';

export const TemplatesView: React.FC = memo(() => {
  const [confirmDeleteModelOpen, setConfirmDeleteModelOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
    null
  );
  const params = useMemo(() => ({ limit: 100000 }), []);

  const onDelete = async ({ refetchData }: { refetchData: () => void }) => {
    if (!selectedTemplate) {
      return;
    }
    try {
      await templatesApi.remove(selectedTemplate.id);
      notifier.success({
        message: `Template deleted`
      });
      await refetchData();
    } catch (err) {
      notifier.requestFailed(err);
    }
    setConfirmDeleteModelOpen(false);
  };

  return (
    <MainLayout className='cl-templates-view'>
      <div className='caption'>
        <h2 className='header'>{'Templates'}</h2>
      </div>
      <RequestData
        url={apiRoutes.templates}
        params={params}
        transformResponse={useCallback(
          (data: { templates: Template[] }) => data.templates,
          []
        )}
      >
        {({ data: templates, refetchData }) => {
          return (
            <Fragment>
              <div className='actions'>
                <Button
                  primary
                  onClick={() =>
                    (window.location.href = interpolateRoute(
                      routes.createTemplates.path
                    ))
                  }
                >
                  <Icon className='button' name='add circle' />
                  Create New Template
                </Button>
              </div>

              {isEmpty(templates) ? (
                <NoDataPlaceholder />
              ) : (
                <TemplatesTable
                  templates={templates}
                  onDelete={template => {
                    setSelectedTemplate(template);
                    setConfirmDeleteModelOpen(true);
                  }}
                  onEdit={template => {
                    setSelectedTemplate(template);
                    window.location.href = interpolateRoute(
                      routes.editTemplates.path,
                      { id: template.id }
                    );
                  }}
                />
              )}
              <Confirm
                size='mini'
                content='Are you sure to delete template?'
                open={confirmDeleteModelOpen}
                onCancel={() => setConfirmDeleteModelOpen(false)}
                onConfirm={() => onDelete({ refetchData })}
                confirmButton='Delete'
              />
            </Fragment>
          );
        }}
      </RequestData>
    </MainLayout>
  );
});
