import { debounce } from 'lodash';
import React, { useState } from 'react';
import { Input, InputOnChangeData, Popup } from 'semantic-ui-react';

const regex = new RegExp('^[a-zA-Z0-9- ]+$');

interface FilterProps {
  filter: string;
  loading?: boolean;

  onSubmitFilter(value: string): void;
}

interface FilterState {
  filter: string;
  filterValid: boolean;
}

export const TableFilter: React.FC<FilterProps> = ({
  loading,
  onSubmitFilter,
  filter
}) => {
  const [state, setState] = useState<FilterState>({
    filter,
    filterValid: true
  });

  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    { value }: InputOnChangeData
  ) => {
    f(value);
  };

  const f = debounce((value: string) => {
    if (value !== '' && !regex.test(value)) {
      setState({ filter: value, filterValid: false });
    } else {
      setState({ filter: value, filterValid: true });
      onSubmitFilter(value);
    }
  }, 500);

  return (
    <Popup
      trigger={
        <Input
          loading={loading}
          icon={'search'}
          onChange={handleOnChange}
          error={!state.filterValid}
          placeholder={'Search...'}
        />
      }
      content={
        !state.filterValid
          ? 'Filter must only contain alphanumeric characters'
          : ''
      }
      on={'click'}
      open={!state.filterValid}
      position={'right center'}
    />
  );
};
