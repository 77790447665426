import React, { memo } from 'react';

import { ManageExistingTopologiesLink, Topologies } from 'components';
import { CreateTopologyLink } from 'components/CreateTopologyLink';

import './styles.less';

export const RecentTopologies: React.FC = memo(() => {
  return (
    <div className='cl-recent-topologies'>
      <div className='caption'>
        <h2>Topologies</h2>
        <div className='sub-header'>Most recent topologies</div>
      </div>
      <Topologies
        limit={5}
        displayedColumns={[
          'topology name',
          'region',
          'creation time',
          'expiration time',
          'created by',
          'created for',
          'department',
          'tags',
          'status',
          'actions'
        ]}
        selectionType={'none'}
      />
      <div className='actions'>
        <CreateTopologyLink />
        <ManageExistingTopologiesLink />
      </div>
    </div>
  );
});
