import cx from 'classnames';
import React, { memo } from 'react';
import { Checkbox, Icon, Segment, SemanticICONS } from 'semantic-ui-react';

import './styles.less';

export type Action = {
  readonly icon?: SemanticICONS;
  readonly iconSrc?: string;
  readonly name: string;
  readonly description: string;
  readonly disabled?: boolean;
  readonly toggleDisabled?: boolean;
  readonly loading?: boolean;
  readonly value?: boolean;
  readonly onToggle?: (value: boolean) => void;
};

type Props = {
  readonly action: Action;
  readonly children?: React.ReactNode;
};

export const PredefinedActionCard: React.FC<Props> = memo(
  ({ action, children }) => (
    <Segment className='cl-predefined-action-card' padded={false}>
      <h3 className='header'>
        {action.iconSrc && (
          <img className={'custom-icon'} src={action.iconSrc} alt='icon' />
        )}
        {action.icon && <Icon name={action.icon} />}
        {action.name}
      </h3>
      <div
        key={action.name}
        className={cx('card-content', { disabled: action.disabled })}
      >
        <div className='action-content'>
          <Checkbox
            disabled={
              action.loading || action.disabled || action.toggleDisabled
            }
            toggle
            className='action-toggle'
            checked={action.value}
            onChange={() => action.onToggle && action.onToggle(!action.value)}
          />
          <div className='action-description'>{action.description}</div>
        </div>
        {children && <div className='card-children'>{children}</div>}
      </div>
    </Segment>
  )
);
