import React, { memo } from 'react';
import { Form } from 'semantic-ui-react';

import { ApstraLogo, AuthFormContainer, SubmitButton } from 'components';
import { apiPrefix, apiRoutes } from 'constants/api-routes';

import { Feature } from '../../../components/FeatureFlags';

import './styles.less';

export const ApstraLoginForm: React.FC = memo(() => {
  const login = () => (window.location.href = apiPrefix(apiRoutes.loginGoogle));
  return (
    <Feature name={'ApstraGoogleAuth'}>
      <AuthFormContainer
        title='Apstra Internal Login'
        className='cl-internal-login-form'
      >
        <Form onSubmit={login}>
          <ApstraLogo />
          <SubmitButton>{'SIGN IN WITH GOOGLE'}</SubmitButton>
        </Form>
      </AuthFormContainer>
    </Feature>
  );
});
