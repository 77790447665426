import React, { Fragment, useState } from 'react';
import { Message } from 'semantic-ui-react';

import './styles.less';

export const CloudlabsBanner = () => {
  const [messageVisible, setMessageVisible] = useState<boolean>(false);

  const region = 'Oregon';
  const shortDate = 'Friday the 18th';
  const longDate = 'Friday, November 18th';

  return (
    <Fragment>
      {messageVisible && (
        <Message warning onDismiss={() => setMessageVisible(false)}>
          <Message.Header>{region} Region Maintenance</Message.Header>
          <Message.Content>
            <p className='message-body'>
              On <b>{longDate}</b> the {region} region will be down for
              maintenance. All existing topologies will need to be destroyed. If
              possible please avoid deploying topologies to this region until
              services are restored on <b>{shortDate}</b>. If you have any
              questions or concerns please send an email to&nbsp;
              <b>
                <a href='mailto:apstra-cloudlabs@juniper.net'>
                  apstra-cloudlabs@juniper.net
                </a>
              </b>
              . Thank you.
            </p>
          </Message.Content>
        </Message>
      )}
    </Fragment>
  );
};
