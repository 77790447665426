/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */

import { Validator } from './Base';

export class NumberOfEmails extends Validator {
  protected min?: number;
  protected max?: number;
  protected equal?: number;
  protected actual: number;

  constructor(min?: number, max?: number, equal?: number) {
    super();
    if (min && max && min > max) {
      throw new Error('Min cannot be greater than max');
    }
    if (min && equal) {
      throw new Error('Min and equal cannot be defined at the same time');
    }
    if (max && equal) {
      throw new Error('Max and equal cannot be defined at the same time');
    }

    this.min = min;
    this.max = max;
    this.equal = equal;
    this.actual = 0;
  }

  validate(text: string): boolean {
    const emails = text.trim() === '' ? [] : text.trim().split(' ');
    this.actual = emails.length;

    if (this.equal !== undefined) {
      // ignore min and max if equal is defined
      return this.actual === this.equal;
    } else if (this.min === undefined && this.max === undefined) {
      throw new Error('Either min or max should be defined');
    } else if (this.min === undefined && this.max !== undefined) {
      return this.actual <= this.max;
    } else if (this.max === undefined && this.min !== undefined) {
      return this.actual >= this.min;
    } else if (this.min !== undefined && this.max !== undefined) {
      return this.actual >= this.min && this.actual <= this.max;
    }
    return false;
  }
  errorMessage(): string {
    if (this.equal !== undefined) {
      return `Must have exactly ${this.equal} emails, got ${this.actual}`;
    }
    if (this.min !== undefined && this.max !== undefined) {
      return `Must have between ${this.min} and ${this.max} emails, got ${this.actual}`;
    }
    if (this.min !== undefined && this.max === undefined) {
      return `Must have at least ${this.min} emails, got ${this.actual}`;
    }
    if (this.min === undefined && this.max !== undefined) {
      return `Must have maximum of ${this.max} emails, got ${this.actual}`;
    }
    return 'Unexpected error';
  }
}
