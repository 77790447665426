import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';

import { authUtils, interpolateRoute } from 'utils';

import { routes } from 'constants/routing';

type Props = {
  readonly id?: number;
  readonly icon?: boolean;
  readonly text?: string;
};

export const ManageExistingTopologiesLink: React.FC<Props> = memo(
  ({ id, icon, text }) => {
    if (!id) {
      id = authUtils.getLoggedInUser().id;
    }

    return (
      <Link
        to={interpolateRoute(routes.contactDetails.path, {
          id,
          active: 'topologies'
        })}
      >
        <Button primary inverted>
          {icon && <Icon className='button' name='info circle' />}
          {text}
        </Button>
      </Link>
    );
  }
);

ManageExistingTopologiesLink.defaultProps = {
  icon: true,
  text: 'Manage Existing Topologies'
};
