import { commonContactProps, toolbarProps } from './ChartOptions';

export const contactPerDepartmentOptions = (loading: boolean) => {
  const props = JSON.parse(JSON.stringify(commonContactProps(loading)));
  const modified = {
    chart: {
      id: 'Number_of_Contacts_by_Department',
      type: 'line',
      stacked: false,
      toolbar: toolbarProps('Date'),
      zoom: {
        enabled: true
      }
    },
    title: {
      text: 'Number of contacts by Department',
      align: 'center'
    }
  };
  return Object.assign(props, modified);
};

export const contactPerPartnerCompanyOptions = (loading: boolean) => {
  const props = JSON.parse(JSON.stringify(commonContactProps(loading)));
  const modified = {
    chart: {
      id: 'Number_of_Contacts_by_Partner_Company',
      type: 'line',
      stacked: false,
      toolbar: toolbarProps('Date'),
      zoom: {
        enabled: true
      }
    },
    title: {
      text: 'Number of contacts by Partner Company',
      align: 'center'
    }
  };
  return Object.assign(props, modified);
};
