import { interpolateRoute } from 'utils';

import { API } from 'api/api';
import { apiPrefix, apiRoutes } from 'constants/api-routes';

const loginEmail = async (credentials: { email: string; password: string }) => {
  return await API.post<{ token: string }>(
    apiPrefix(apiRoutes.loginEmail),
    credentials
  );
};

const resetPassword = async (uuid: string, password: string) => {
  return await API.patch(
    apiPrefix(interpolateRoute(apiRoutes.resetPassword, { uuid })),
    { password }
  );
};

const sendResetPasswordEmail = async (email: string) => {
  return await API.patch(
    apiPrefix(interpolateRoute(apiRoutes.resetPasswordEmail, { email })),
    {}
  );
};

export const authApi = { loginEmail, resetPassword, sendResetPasswordEmail };
