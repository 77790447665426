import axios from 'axios';
import { useState } from 'react';
import { QueryFunction, useQuery } from 'react-query';

import { apiPrefix } from 'constants/api-routes';

import { QuotaList } from 'types/quota';

export const getQuotas: QueryFunction<QuotaList> = async () => {
  return axios
    .get(apiPrefix('/v1.2/quotas/topologies'))
    .then(response => response.data);
};

export const useQuotas = <ItemType>() => {
  const [selected, setSelected] = useState<ItemType[]>([]);
  const { isLoading, isError, data: quotas, error } = useQuery<QuotaList>(
    'Quotas',
    getQuotas,
    {
      keepPreviousData: true,
      refetchInterval: 5000,
      initialData: {
        quotas: []
      }
    }
  );

  return {
    isLoading,
    isError,
    error,
    quotas,
    selected,
    setSelected
  };
};
