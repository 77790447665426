import React, { memo } from 'react';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';

export const LoadingState: React.FC = memo(() => (
  <Segment>
    <Dimmer active inverted>
      <Loader />
    </Dimmer>
  </Segment>
));
