import React, { memo, useMemo } from 'react';
import { Redirect } from 'react-router';
import { Loader } from 'semantic-ui-react';

import {
  authTokenStorageKey,
  authUtils,
  interpolateRoute,
  notifier
} from 'utils';

import { contactsApi } from 'api/contacts';
import { Eula, RequestData, RequestDataChildrenProps } from 'components';
import { apiRoutes } from 'constants/api-routes';
import { defaultRoute, routes } from 'constants/routing';
import { UserRole } from 'enums';
import { useRouter } from 'hooks/useRouter';
import { Contact, LoggedInUser } from 'types';

const mayLoginWithoutEula = [
  UserRole.Admin,
  UserRole.Internal,
  UserRole.Support
];

export const EulaAuthorizedView: React.FC = memo(() => {
  const router = useRouter();
  const token = router.location.state
    ? (router.location.state as { token: string }).token
    : null;
  const headers = useMemo(() => ({ Authorization: `Bearer ${token}` }), [
    token
  ]);

  if (authUtils.loggedIn()) {
    return <Redirect to={defaultRoute.path} />;
  }
  const user: LoggedInUser = token ? authUtils.decodeToken(token) : null;

  if (!token || !user || +new Date(user.expires_on) < +new Date()) {
    return <Redirect to={routes.login.path} />;
  }

  if (mayLoginWithoutEula.includes(user.role)) {
    authUtils.setToken(authTokenStorageKey, token);
    return <Redirect to={defaultRoute.path} />;
  }

  const onAccept = async () => {
    try {
      await contactsApi.update(user.id, { eulaAccepted: true }, { headers });
      authUtils.setToken(authTokenStorageKey, token);
      router.push(defaultRoute.path);
    } catch (err) {
      notifier.requestFailed(err);
    }
  };

  const onCancel = () => router.push(routes.login.path);

  return (
    <RequestData
      url={interpolateRoute(apiRoutes.contactById, { id: user.id })}
      headers={headers}
      customLoader
    >
      {({ data: contact, loading }: RequestDataChildrenProps<Contact>) => {
        if (loading) {
          return <Loader active />;
        }
        if (contact.eulaAccepted) {
          authUtils.setToken(authTokenStorageKey, token);
          return <Redirect to={defaultRoute.path} />;
        }
        return <Eula onAccept={onAccept} onCancel={onCancel} />;
      }}
    </RequestData>
  );
});
