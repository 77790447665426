import { isEmpty } from 'lodash';
import React, { memo, useState } from 'react';
import { Pagination, PaginationProps, Table } from 'semantic-ui-react';

import { paginationUtils } from 'utils/pagination';

import { LoadingState } from 'components/LoadingState/LoadingState';
import { DEFAULT_PAGE_SIZE } from 'constants/pagination';
import { useCreateContacts } from 'hooks/reportHooks/useCreateContacts';
import { ContactUsage, PaginationFilters, ResponseCount } from 'types';

import { filterContacts } from './FilterContacts';

type Props = {
  readonly startDate: string;
  readonly endDate: string;
  readonly searchText: string;
  readonly setDBCount: (value: ResponseCount) => void;
};

export const ContactUsageTable: React.FC<Props> = memo(
  ({ startDate, endDate, searchText, setDBCount }) => {
    const createdContacts = useCreateContacts(startDate, endDate);
    const getTotalTopologies = (data: ContactUsage[]) => {
      let sum = 0;
      data.forEach(c => {
        sum += c.totalTopologies;
      });
      return sum;
    };
    const originalResponse = () => {
      const response = createdContacts.data || [];
      const topologyTotal = !isEmpty(response)
        ? getTotalTopologies(response)
        : 0;
      setDBCount({ contacts: response.length, topologies: topologyTotal });
      return response;
    };
    const [pagination, setPagination] = useState<PaginationFilters>({
      activePage: 1,
      pageSize: DEFAULT_PAGE_SIZE
    });
    const { total, items } = filterContacts(
      originalResponse(),
      searchText,
      pagination
    );
    const totalPages = paginationUtils.getTotalPages({
      pageSize: pagination.pageSize,
      totalCount: total
    });
    if (createdContacts.isLoading) {
      return <LoadingState />;
    }
    return (
      <div>
        <Table stackable celled striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Company</Table.HeaderCell>
              <Table.HeaderCell>Created by</Table.HeaderCell>
              <Table.HeaderCell>Event time</Table.HeaderCell>
              <Table.HeaderCell>Number of topologies</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items.map((contact: ContactUsage) => {
              return (
                <Table.Row key={contact.id}>
                  <Table.Cell>{contact.name}</Table.Cell>
                  <Table.Cell>{contact.email}</Table.Cell>
                  <Table.Cell>{contact.company}</Table.Cell>
                  <Table.Cell>{contact.createdBy}</Table.Cell>
                  <Table.Cell>
                    {new Date(contact.eventTime).toTimeString()}
                  </Table.Cell>
                  <Table.Cell>{contact.totalTopologies}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        {totalPages > 0 && (
          <div className='pagination-wrapper'>
            <Pagination
              activePage={pagination.activePage}
              totalPages={totalPages}
              pointing
              secondary
              onPageChange={(
                event: React.MouseEvent<HTMLAnchorElement>,
                data: PaginationProps
              ) =>
                setPagination({
                  ...pagination,
                  activePage: data.activePage as number
                })
              }
            />
          </div>
        )}
      </div>
    );
  }
);
