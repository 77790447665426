import cx from 'classnames';
import React, { memo } from 'react';

import { assets } from 'constants/assets';

import './styles.less';

type Props = {
  readonly nowrap?: boolean;
};

const { apstraLogo } = assets.images;

export const AosLogo: React.FC<Props> = memo(({ nowrap }) => (
  <div className={cx('cl-aos-logo', { nowrap })}>
    <img src={apstraLogo.src} alt={apstraLogo.alt} />
    <span>Juniper Apstra CloudLabs</span>
  </div>
));
