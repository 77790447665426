import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, Icon } from 'semantic-ui-react';
import {
  authUtils,
  contactUtils,
  interpolateRoute,
  notifier,
  onSendEmail
} from 'utils';
import { reloadData } from 'utils/urls';

import { topologiesApi } from 'api/topology';
import { filterOptionsByRole } from 'components/ExtendTopologyDuration/ExtendTopologyDuration';
import { routes } from 'constants/routing';
import { LoggedInUser, Topology } from 'types';
import { TagSelectionModal } from 'views/TagSelectionModal/TagSelectionModal';

import { RootState } from 'Reducers/contactReducer';
import { ConfirmTopologyDelete } from './ConfirmTopologyDelete';

import './styles.less';

export const TopologyActionContainer: React.FC = () => {
  const topologies: Topology[] = useSelector(
    (state: RootState) => state.topology
  );
  const user: LoggedInUser = authUtils.getLoggedInUser();
  const dropdownDisable = topologies && topologies.length > 0;
  const multiDisable = topologies && topologies.length > 1;
  const id =
    topologies && topologies.length === 1
      ? topologies[0].createdFor.id
      : user.id;

  const [confirmDeleteModelOpen, setConfirmDeleteModelOpen] = useState(false);
  const [confirmTagModelOpen, setConfirmTagModelOpen] = useState(false);

  const onDelete = () => {
    if (!topologies) {
      return;
    }
    topologies.forEach(async (topology: Topology) => {
      try {
        await topologiesApi.remove(topology.uuid);
        notifier.success({
          message: `Topology "${topology.name}" scheduled for deletion`
        });
        reloadData();
      } catch (err) {
        notifier.requestFailed(err);
      }
      setConfirmDeleteModelOpen(false);
    });
  };

  const extendDuration = async (duration: string) => {
    if (!topologies) {
      return;
    }
    topologies.forEach(async (topology: Topology) => {
      try {
        await topologiesApi.extendDuration(topology.uuid, duration);
        notifier.success({
          message: `Duration successfully extended for ${topology.name}`
        });
        reloadData();
      } catch (err) {
        notifier.requestFailed(err);
      }
    });
  };

  const selectedTopology =
    topologies.length >= 1 ? topologies[0] : ({} as Topology);
  return (
    <div>
      <Dropdown
        text='Topology Actions'
        button
        labeled
        basic
        floating
        className='icon link item extend-dropdown'
        direction='left'
      >
        <Dropdown.Menu>
          <Dropdown.Menu scrolling>
            {contactUtils.isAdminOrInternal(user) && (
              <Dropdown.Item
                disabled={!dropdownDisable}
                onClick={() =>
                  topologies.forEach((topology: Topology) =>
                    onSendEmail(topology)
                  )
                }
              >
                <Icon name='mail' /> Send Topology Details to Email
              </Dropdown.Item>
            )}
            <Dropdown.Item
              disabled={!dropdownDisable}
              onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                e.stopPropagation();
              }}
            >
              <Dropdown
                item
                trigger={
                  <span>
                    <Icon name='stopwatch' />
                    Extend Duration
                  </span>
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Header icon='stopwatch' content='Select Duration' />
                  <Dropdown.Divider />
                  {filterOptionsByRole(user).map((option, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => extendDuration(option.value as string)}
                      {...option}
                    />
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Dropdown.Item>
            <Dropdown.Item
              disabled={!dropdownDisable}
              onClick={() => setConfirmDeleteModelOpen(true)}
            >
              <Icon name='delete' /> Delete
            </Dropdown.Item>
            <Dropdown.Item
              disabled={!dropdownDisable || multiDisable}
              onClick={() => setConfirmTagModelOpen(true)}
            >
              <Icon name='tags' /> Manage Tags
            </Dropdown.Item>
            <Dropdown.Item
              disabled={multiDisable}
              onClick={() =>
                (window.location.href = interpolateRoute(
                  routes.contactDetails.path,
                  { id, active: 'topologies' }
                ))
              }
            >
              <Icon name='settings' /> Manage Existing Topologies
            </Dropdown.Item>
            <Dropdown.Item
              disabled={multiDisable}
              onClick={() =>
                (window.location.href = interpolateRoute(
                  routes.createTopology.path,
                  { id }
                ))
              }
            >
              <Icon name='plus circle' /> Create Topology
            </Dropdown.Item>
            <Dropdown.Item
              disabled={multiDisable}
              onClick={() =>
                (window.location.href = interpolateRoute(
                  routes.createTopologyForUsers.path
                ))
              }
            >
              <Icon name='plus circle' /> Create Topology For Another User
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown.Menu>
      </Dropdown>
      {selectedTopology && (
        <TagSelectionModal
          open={confirmTagModelOpen}
          onClose={setConfirmTagModelOpen}
          topology={selectedTopology}
        />
      )}
      <ConfirmTopologyDelete
        onDelete={onDelete}
        selectedTopologies={topologies}
        setOpen={setConfirmDeleteModelOpen}
        open={confirmDeleteModelOpen}
      />
    </div>
  );
};
