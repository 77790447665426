import { isEmpty } from 'lodash';
import React, { Fragment, memo, useCallback, useMemo, useState } from 'react';
import { Loader } from 'semantic-ui-react';

import { notifier } from 'utils';
import { paginationUtils } from 'utils/pagination';

import { topologiesApi } from 'api/topology';
import { NoDataPlaceholder } from 'components';
import { SelectionType } from 'components/ContactsTable/SelectionType';
import { ErrorMessage } from 'components/ErrorMessage';
import { RequestData } from 'components/RequestData';
import { TopologiesTable } from 'components/TopologiesTable';
import { apiRoutes } from 'constants/api-routes';
import { DEFAULT_PAGE_SIZE } from 'constants/pagination';
import { Template, Topology } from 'types';
import { ConfirmTopologyDelete } from 'views/Topologies/ConfirmTopologyDelete';

type Props = {
  readonly filter?: string | null;
  readonly offset?: number;
  readonly limit: number;
  readonly displayedColumns: string[];
  readonly selectionType: SelectionType;
  readonly owner?: number[];
  readonly createdForId?: number[];
  readonly tag?: string[];
  readonly basicNoDataPlaceholder?: boolean;
};

export const Topologies: React.FC<Props> = memo(
  ({
    filter,
    limit,
    displayedColumns,
    selectionType,
    tag,
    basicNoDataPlaceholder,
    owner,
    createdForId
  }) => {
    const [activePage, setActivePage] = useState<number>(1);

    const params = useMemo(
      () => ({
        filter,
        offset: activePage - 1,
        limit,
        tag,
        paginated: true,
        owner,
        createdForId
      }),
      [filter, activePage, limit, tag, owner, createdForId]
    );
    const templateParams = useMemo(() => ({ limit: 100 }), []);

    const [confirmDeleteModelOpen, setConfirmDeleteModelOpen] = useState(false);

    const [selectedTopology, setSelectedTopology] = useState<Topology | null>(
      null
    );

    const onDelete = async ({ refetchData }: { refetchData: () => void }) => {
      if (!selectedTopology) {
        return;
      }
      try {
        await topologiesApi.remove(selectedTopology.uuid);
        notifier.success({
          message: `Topology ${selectedTopology.name} scheduled for deletion`
        });
        await refetchData();
      } catch (err) {
        notifier.requestFailed(err);
      }
      setConfirmDeleteModelOpen(false);
    };

    return (
      <RequestData
        url={apiRoutes.templates}
        params={templateParams}
        transformResponse={useCallback(
          (data: { templates: Template[] }) => data.templates,
          []
        )}
        customLoader
        customError
        notify={false}
      >
        {({ data: templates, loading: templatesLoading }) => {
          return (
            <RequestData
              url={apiRoutes.topologiesWithCreator}
              params={params}
              customLoader
              customError
              notify={false}
            >
              {({
                data: resp,
                loading: topologiesLoading,
                refetchData,
                error: topologiesError
              }) => {
                if (
                  (topologiesLoading || templatesLoading) &&
                  ((resp && !resp.topologies) || !templates)
                ) {
                  return (
                    <Loader
                      data-testid='loader'
                      active
                      inline='centered'
                      className='padded'
                    />
                  );
                }
                if (topologiesError) {
                  return <ErrorMessage error={topologiesError} />;
                }
                if (isEmpty(resp)) {
                  return <NoDataPlaceholder basic={basicNoDataPlaceholder} />;
                }
                const totalPages = paginationUtils.getTotalPages({
                  pageSize: limit,
                  totalCount: resp.total
                });

                return (
                  <Fragment>
                    <TopologiesTable
                      topologies={resp.topologies}
                      templates={templates}
                      displayedColumns={displayedColumns}
                      selectionType={selectionType}
                      onExtendDuration={refetchData}
                      onDelete={topology => {
                        setSelectedTopology(topology);
                        setConfirmDeleteModelOpen(true);
                      }}
                      setActivePage={setActivePage}
                      activePage={activePage}
                      totalPages={totalPages}
                    />
                    <ConfirmTopologyDelete
                      selectedTopologies={
                        selectedTopology ? [selectedTopology] : []
                      }
                      setOpen={setConfirmDeleteModelOpen}
                      open={confirmDeleteModelOpen}
                      onDelete={() => onDelete({ refetchData })}
                    />
                  </Fragment>
                );
              }}
            </RequestData>
          );
        }}
      </RequestData>
    );
  }
);

Topologies.defaultProps = {
  limit: DEFAULT_PAGE_SIZE
};
