import { notifier } from 'utils';

import { contactsApi } from 'api/contacts';
import { topologiesApi } from 'api/topology';
import { Topology, VM, VMAccess } from 'types';

const getAccessLink = (access: VMAccess, vm: VM): string => {
  const { protocol, username, password, host, port } = access;
  if (
    protocol !== 'ssh' &&
    (vm.role === 'aos' || vm.role === 'flowData' || vm.role === 'bastion')
  ) {
    if (access.url) return `${protocol}://${access.url}`;
    else return `${protocol}://${host}:${port}`;
  }
  return `${protocol}://${username}:${password}@${host}:${port}`;
};

export const topologyUtils = {
  getAccessLink
};

export const onSendEmail = async (topology: Topology) => {
  let email = 'owner';

  if (topology.createdFor) {
    email = topology.createdFor.email;
  } else if (topology.owner) {
    const owner = await contactsApi.getOne(topology.owner.toString());
    if (owner) {
      email = owner.email;
    }
  }

  try {
    await topologiesApi.sendTopologyDetailsEmail(topology.uuid);
    notifier.success({
      message: `Email notification with topology was sent to ${email}`
    });
  } catch (err) {
    notifier.requestFailed(err);
  }
};

export enum TopologyStatus {
  DEPLOY_IN_PROGRESS = 'deployInProgress',
  PASSWORD_CHANGE = 'passwordChange',
  BASTION_CONFIGURATION = 'bastionConfiguration',
  SHUTDOWN_IN_PROGRESS = 'shutdownInProgress',
  UP = 'up',
  DOWN = 'down',
  ERROR = 'error',
  UNKNOWN = 'unknown'
}

export const isTopologyInactive = (topology: Topology) => {
  return (
    topology.state === TopologyStatus.DOWN ||
    topology.state === TopologyStatus.ERROR ||
    topology.state === TopologyStatus.UNKNOWN
  );
};

export const isTopologyUp = (topology: Topology) => {
  return topology.state === TopologyStatus.UP;
};

const topologyActive = (topology: Topology) => {
  return [TopologyStatus.UP, TopologyStatus.DEPLOY_IN_PROGRESS].includes(
    topology.state as TopologyStatus
  );
};

const topologyInactive = (topology: Topology) => {
  return [
    TopologyStatus.DOWN,
    TopologyStatus.SHUTDOWN_IN_PROGRESS,
    TopologyStatus.UNKNOWN
  ].includes(topology.state as TopologyStatus);
};

const topologyError = (topology: Topology) => {
  return topology.state === TopologyStatus.ERROR;
};

export const topologyStates = {
  topologyActive,
  topologyInactive,
  topologyError
};

export const topologyName = (topology: Topology): string => {
  if (topology.label) {
    return `${topology.label} (${topology.name})`;
  } else {
    return topology.name;
  }
};
