import React, { memo, useState } from 'react';
import { Button, Divider, Modal, Segment } from 'semantic-ui-react';

import { Lab } from 'types';

import './styles.less';

type Props = {
  readonly lab: Lab;
  readonly onDelete: () => void;
  readonly onRestart: (step: number) => void;
};

export const LabCompletionActions: React.FC<Props> = memo(
  ({ lab, onDelete, onRestart }) => {
    const [showDeleteLab, setShowDeleteLab] = useState<boolean>(false);
    return (
      <Segment textAlign='center' raised padded='very'>
        <h1>Congratulations!</h1>
        <p>
          You have successfully completed <b>{lab.label}</b>
        </p>
        <Button onClick={() => onRestart(0)}>Restart Lab</Button>
        <Button onClick={() => setShowDeleteLab(true)}>Delete Lab</Button>
        <Modal
          open={showDeleteLab}
          centered={true}
          onClose={() => setShowDeleteLab(false)}
          closeIcon={true}
          confirmButton='Delete'
          dimmer='blurring'
        >
          <Modal.Header>Delete Lab</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <p>
                This action is irreversible. This will also delete the
                topologies associated with the lab. Are you sure you want to
                delete lab {lab.label}?
              </p>
            </Modal.Description>
            <Divider />
            <div className='actions align-right'>
              <Button onClick={() => setShowDeleteLab(false)}>Cancel</Button>
              <Button primary onClick={onDelete}>
                Delete
              </Button>
            </div>
          </Modal.Content>
        </Modal>
      </Segment>
    );
  }
);
