import React, { memo } from 'react';
import { Button } from 'semantic-ui-react';

import './styles.less';

type Props = {
  readonly activeSteps: number;
  readonly stepItemsLength: number;
  readonly disablePrevButton: boolean;
  readonly disableNextButton: boolean;
  readonly actionButton: string;
  readonly setActiveSteps: (step: number) => void;
  readonly setCompletedSteps: (step: number) => void;
  readonly action: () => void;
};
export const ProgressStepperNavButtons: React.FC<Props> = memo(
  ({
    activeSteps,
    stepItemsLength,
    disablePrevButton,
    disableNextButton,
    actionButton,
    setActiveSteps,
    setCompletedSteps,
    action
  }) => {
    const nextButtonText =
      activeSteps > stepItemsLength - 2 ? actionButton : 'Next';
    const prev = () => {
      let step = activeSteps;
      if (step > 0) {
        step = step - 1;
        setActiveSteps(step);
      }
    };

    const next = () => {
      let step = activeSteps;
      if (step < stepItemsLength + 1) {
        step = step + 1;
        setActiveSteps(step);
        setCompletedSteps(step);
      }
      if (activeSteps === stepItemsLength - 1) {
        action();
      }
    };
    const actionButtonStyleId =
      activeSteps > stepItemsLength - 2 ? 'is-submit' : '';
    return (
      <div className='nav-buttons'>
        <Button disabled={disablePrevButton} onClick={() => prev()}>
          Previous
        </Button>
        <Button
          id={actionButtonStyleId}
          disabled={disableNextButton}
          onClick={() => next()}
        >
          {nextButtonText}
        </Button>
      </div>
    );
  }
);
