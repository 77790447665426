import React, { memo } from 'react';

import { ContactCharts } from './ContactCharts';
import { TopologyCharts } from './TopologyCharts';

export const ReportCharts: React.FC = memo(() => {
  return (
    <div>
      <div>
        <ContactCharts />
      </div>
      <div>
        <TopologyCharts />
      </div>
    </div>
  );
});
