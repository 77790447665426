/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */
import React, { memo, useEffect } from 'react';
import { Input, Label } from 'semantic-ui-react';

import { Validator } from './Validators/Base';

interface Props {
  readonly label?: string;
  readonly placeholder: string;
  readonly icon?: string;
  readonly defaultValue?: string;
  validators: Validator[];
  disabled?: boolean;
  onChange?: (value: string) => void;
  onErrors?: (errors: string[]) => void;
  onBlur?: (value: string) => void;
}

export const ValidatedInput: React.FC<Props> = memo(
  ({
    label,
    disabled,
    validators,
    icon,
    placeholder,
    defaultValue,
    onChange,
    onErrors,
    onBlur
  }) => {
    const [errors, setErrors] = React.useState<string[]>([]);

    useEffect(() => {
      if (onErrors) {
        onErrors(errors);
      }
    }, [errors, onErrors]);

    return (
      <>
        <Input
          icon={icon}
          label={label && { tag: false, content: label }}
          labelPosition={'left'}
          placeholder={placeholder}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            const errs = validators
              .map(v => v.error(e.target.value))
              .filter(err => err !== '');
            setErrors(errs);
            if (onBlur) {
              onBlur(e.target.value);
            }
          }}
          defaultValue={defaultValue}
          error={errors.length > 0}
          disabled={disabled}
          onChange={e => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
        />
        {errors.length > 0 && (
          <Label basic color={'red'} pointing={'above'}>
            {errors.join('; ')}
          </Label>
        )}
      </>
    );
  }
);
