import React, { memo } from 'react';

import { TopologyReports } from 'components';
import { MainLayout } from 'layouts';

import './styles.less';

export const TopologyReportsView: React.FC = memo(() => {
  return (
    <MainLayout className='cl-topology-reports-view'>
      <TopologyReports />
    </MainLayout>
  );
});
