import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { Icon, Segment } from 'semantic-ui-react';

import { setRequestQuery } from 'features/chatbot';

import './styles.less';

type Props = {
  readonly setSegmentType: (type: string) => void;
};
export const ChatActivity: React.FC<Props> = memo(({ setSegmentType }) => {
  const dispatch = useDispatch();
  const handleSegment = (type: string) => {
    dispatch(setRequestQuery({ corpusName: '', userQuery: '' }));
    setSegmentType(type);
  };

  return (
    <Segment raised padded className='chat-options-cursor'>
      <h3
        data-testid='recent'
        id='recent'
        className='caption'
        onClick={() => handleSegment('recent')}
      >
        <Icon name='search' />
        Recent Searches
      </h3>
      <h3
        data-testid='favorite'
        className='caption'
        onClick={() => handleSegment('favorite')}
      >
        <Icon name='star outline' />
        Favorites
      </h3>
      <h3 className='caption' onClick={() => handleSegment('firstLoad')}>
        <Icon name='comment outline' />
        About Cloudlabs AI
      </h3>
    </Segment>
  );
});
