import React, { PureComponent } from 'react';

import { UserReport } from 'components';
import { MainLayout } from 'layouts';

export class ContactReportsView extends PureComponent {
  render() {
    return (
      <MainLayout>
        <UserReport />
      </MainLayout>
    );
  }
}
