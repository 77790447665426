import { TopologyAlterationAction } from 'enums';
import { VM } from 'types/vm';

import { VMAccess } from '../vm-access';
import { Topology } from './topology';
import { TopologyAlteration } from './topology-alteration';

export type TopologyDetails = Topology & {
  readonly statusMessage: string;
  readonly emailNotificationsEnabled: boolean;
  readonly alterations: {
    [key in TopologyAlterationAction]: TopologyAlteration;
  };
  readonly vms: VM[];
};

export const getApstraVm = (topology: TopologyDetails): VM | undefined => {
  return topology.vms.find(vm => vm.role === 'aos');
};

export const getFlowVm = (topology: TopologyDetails): VM | undefined => {
  return topology.vms.find(vm => vm.role === 'flowData');
};

export const getApstraVmWebAccess = (
  topology: TopologyDetails
): [VM | undefined, VMAccess | undefined] => {
  const apstraVm = getApstraVm(topology);
  return [
    apstraVm,
    apstraVm?.access.find(
      access => access.protocol === 'http' || access.protocol === 'https'
    )
  ];
};

export const getFlowVmWebAccess = (
  topology: TopologyDetails
): [VM | undefined, VMAccess | undefined] => {
  const flowVm = getFlowVm(topology);
  return [
    flowVm,
    flowVm?.access.find(
      access => access.protocol === 'http' || access.protocol === 'https'
    )
  ];
};
