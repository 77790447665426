/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */
import React from 'react';

import { Partner } from 'api/usePartners';

import { EditableElement } from './EditableElement';
import { PartnerDomainsList } from './PartnerDomainsList';
import { Table } from './Table';

interface PartnersTableProps {
  partners: Partner[];
  onPartnerEdit: (
    partner: Partner,
    newName: string | undefined,
    newDomains: string[] | undefined
  ) => void;
  onPartnerDelete: (partner: Partner) => void;
  isLoading: boolean;
  isError: boolean;
}

export const PartnersTable: React.FC<PartnersTableProps> = ({
  partners,
  onPartnerEdit,
  onPartnerDelete,
  isError,
  isLoading
}) => {
  function noop(...args: any[]): void {
    // do nothing
  }

  const duplicateName = (
    newValue: string,
    originalValue?: string
  ): Error | null => {
    if (
      partners.filter(p => p.name === newValue && newValue !== originalValue)
        .length > 0
    ) {
      return new Error('Partner name must be unique');
    }
    return null;
  };
  const nameLength = (value: string): Error | null => {
    if (value.length < 1 || value.length > 200) {
      return new Error('Partner name must be between 1 and 200 characters');
    }
    return null;
  };

  return (
    <Table
      header={['Partner', 'Domains']}
      rows={partners.map((p, index) => {
        return [
          <EditableElement
            text={p.name}
            validators={[duplicateName, nameLength]}
            onChange={value => onPartnerEdit(p, value, undefined)}
            onDelete={() => onPartnerDelete(p)}
            key={`${p.name}_${index}_name`}
          />,
          <PartnerDomainsList
            partner={p}
            onPartnerDomainsChanged={(partner, domains) => {
              onPartnerEdit(partner, undefined, domains);
            }}
            key={`${p.name}_${index}`}
          />
        ];
      })}
      selectableRows={'disabled'}
      onSelected={noop}
      isLoading={isLoading}
      isError={isError}
    />
  );
};
