import { routes } from 'constants/routing';
import { UserRole } from 'enums';

const contactsPermissions = {
  mayChangeAdmin: [UserRole.Admin],
  mayChangeSupport: [UserRole.Admin],
  mayChangeInternal: [
    UserRole.Admin,
    UserRole.Internal,
    UserRole.Support,
    UserRole.SE
  ],
  mayChangePartnerAdmin: [
    UserRole.Admin,
    UserRole.Internal,
    UserRole.Support,
    UserRole.SE
  ],
  mayChangePartner: [
    UserRole.Admin,
    UserRole.Internal,
    UserRole.PartnerAdmin,
    UserRole.Support,
    UserRole.SE
  ],
  mayChangeCustomer: [
    UserRole.Admin,
    UserRole.Internal,
    UserRole.PartnerAdmin,
    UserRole.Partner,
    UserRole.Support,
    UserRole.SE
  ],
  mayApprove: [
    UserRole.Admin,
    UserRole.Internal,
    UserRole.Support,
    UserRole.PartnerAdmin,
    UserRole.SE
  ],
  mayChangeQuotas: [UserRole.Admin],
  maySetPartnerCompany: [
    UserRole.Admin,
    UserRole.Internal,
    UserRole.Support,
    UserRole.SE
  ],
  maySeePendingApproval: [
    UserRole.Admin,
    UserRole.Internal,
    UserRole.Support,
    UserRole.SE
  ],
  maySeeInternals: [
    UserRole.Admin,
    UserRole.Internal,
    UserRole.Support,
    UserRole.SE
  ],
  maySeeSupport: [UserRole.Admin],
  maySeePartners: [
    UserRole.Admin,
    UserRole.Internal,
    UserRole.PartnerAdmin,
    UserRole.Support,
    UserRole.SE
  ],
  mayConvertInternals: [
    UserRole.Admin,
    UserRole.Internal,
    UserRole.Support,
    UserRole.SE
  ]
};

const buildserverPermissions = {
  canViewBuildservers: [UserRole.Admin],
  canCreateBuildservers: [UserRole.Admin]
};

const templatesPermissions = {
  maySeeExternal: [
    UserRole.Admin,
    UserRole.Internal,
    UserRole.Support,
    UserRole.SE
  ],
  mayChange: [UserRole.Admin]
};

const calculatorPermissions = {
  maySee: [UserRole.Admin, UserRole.SE, UserRole.Internal, UserRole.Support]
};

const chatPermissions = {
  maySee: [UserRole.Admin, UserRole.SE, UserRole.Support, UserRole.Internal]
};

const partnerManagementViewPermissions = {
  maySee: [UserRole.Admin, UserRole.Support]
};

const routesPermissions = [
  {
    path: routes.createUserContact.path,
    roles: contactsPermissions.mayChangeCustomer
  },
  {
    path: routes.templates.path,
    roles: templatesPermissions.mayChange
  },
  {
    path: routes.buildservers.path,
    roles: buildserverPermissions.canViewBuildservers
  },
  {
    path: routes.adminStats.path,
    roles: contactsPermissions.mayChangeAdmin
  },
  {
    path: routes.contactReports.path,
    roles: contactsPermissions.mayChangeAdmin
  },
  {
    path: routes.topologyReports.path,
    roles: contactsPermissions.mayChangeAdmin
  },
  {
    path: routes.quotas.path,
    roles: contactsPermissions.mayChangeQuotas
  },
  {
    path: routes.manageTags.path,
    roles: contactsPermissions.mayChangeInternal
  },
  {
    path: routes.apstraSizingCalculator.path,
    roles: calculatorPermissions.maySee
  },
  {
    path: routes.chat.path,
    roles: chatPermissions.maySee
  },
  {
    path: routes.partners.path,
    roles: partnerManagementViewPermissions.maySee
  },
  {
    path: routes.labs.path,
    roles: [UserRole.Admin, UserRole.SE]
  },
  {
    path: routes.createLabs.path,
    roles: [UserRole.Admin, UserRole.SE]
  },
  {
    path: routes.lab.path,
    roles: [UserRole.Admin, UserRole.SE]
  }
];

export const permissions = {
  buildservers: buildserverPermissions,
  contacts: contactsPermissions,
  templates: templatesPermissions,
  routes: routesPermissions
};
