import axios, { AxiosRequestConfig } from 'axios';

async function getRequest<T>(
  url: string,
  config?: AxiosRequestConfig
): Promise<T> {
  const response = await axios.get(url, config);
  return response.data;
}

async function postRequest<T>(
  url: string,
  data: object | null,
  config?: AxiosRequestConfig
): Promise<T> {
  const response = await axios.post(url, data, config);
  return response.data;
}

async function putRequest<T>(
  url: string,
  data: object | null,
  config?: AxiosRequestConfig
): Promise<T> {
  const response = await axios.put(url, data, config);
  return response.data;
}

async function patchRequest<T>(
  url: string,
  data: object | null,
  config?: AxiosRequestConfig
): Promise<T> {
  const response = await axios.patch(url, data, config);
  return response.data;
}

async function deleteRequest<T>(
  url: string,
  config?: AxiosRequestConfig
): Promise<T> {
  const response = await axios.delete(url, config);
  return response.data;
}

export const API = {
  get: getRequest,
  post: postRequest,
  put: putRequest,
  patch: patchRequest,
  delete: deleteRequest
};
