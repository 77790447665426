import { AxiosRequestConfig } from 'axios';

import { interpolateRoute } from 'utils';

import { apiPrefix, apiRoutes } from 'constants/api-routes';
import {
  FeedbackResponse,
  FeedbackSchema,
  KnowledgeBasesResponse,
  QuerySummary,
  SummaryQueryResponse,
  TaskCreatedResponse
} from 'interfaces';

import { API } from './api';

const getAllKnowledgeBases = (
  config?: AxiosRequestConfig
): Promise<KnowledgeBasesResponse> => {
  return API.get<KnowledgeBasesResponse>(
    apiPrefix(apiRoutes.knowledgeBases),
    config
  );
};

const getAllTasks = (
  taskId: string,
  config?: AxiosRequestConfig
): Promise<SummaryQueryResponse> => {
  return API.get<SummaryQueryResponse>(
    apiPrefix(interpolateRoute(apiRoutes.tasks, { taskId })),
    config
  );
};

const getSummaryFromQuery = (
  data: QuerySummary,
  config?: AxiosRequestConfig
): Promise<TaskCreatedResponse> => {
  return API.post<TaskCreatedResponse>(
    apiPrefix(interpolateRoute(apiRoutes.chat)),
    data,
    config
  );
};

const postFeedback = (
  uuid: string,
  data: FeedbackSchema,
  config?: AxiosRequestConfig
): Promise<FeedbackResponse> => {
  return API.post<FeedbackResponse>(
    apiPrefix(interpolateRoute(apiRoutes.feedbackForChat, { uuid })),
    data,
    config
  );
};

export const chatbotApi = {
  getAllKnowledgeBases,
  getAllTasks,
  getSummaryFromQuery,
  postFeedback
};
