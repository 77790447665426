import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownItemProps, Input } from 'semantic-ui-react';

import { CONTACT_ROLE_QUERY, getAvailableOptions } from 'components/Contacts';
import { useRouter } from 'hooks/useRouter';

import {
  ContactsFilters,
  ContactsState,
  fetchContacts,
  setCurrentPage,
  setFilter
} from 'features/contacts';
import { RootState } from 'Reducers/contactReducer';

import './styles.less';

export const SearchForm: React.FC = memo(() => {
  const router = useRouter();
  const availableOptions = getAvailableOptions();

  const dispatch = useDispatch();
  const contactsState: ContactsState = useSelector(
    (state: RootState) => state.contact
  );
  const filters = contactsState.filter;

  const updateFilters = (newFilters: ContactsFilters) => {
    if (newFilters.role && filters.role !== newFilters.role) {
      const search = new URLSearchParams(router.query);
      search.set(CONTACT_ROLE_QUERY, newFilters.role);
      router.push({ search: search.toString() });
    }
    dispatch(setFilter(newFilters));
  };
  const dispatchQuery = () => {
    dispatch(setCurrentPage(1));
    dispatch(fetchContacts({}));
  };

  return (
    <Input
      type='search'
      placeholder='Search contacts. Hit Enter to search'
      className='search-input'
      value={filters.text}
      action={
        <Dropdown icon='filter' button className='icon'>
          <Dropdown.Menu>
            <Dropdown.Header icon='users' content='Filter by Role' />
            <Dropdown.Menu scrolling>
              {availableOptions.map(option => (
                <Dropdown.Item
                  key={option.value}
                  text={option.label as string}
                  value={option.value}
                  onClick={(
                    event: React.MouseEvent<HTMLDivElement>,
                    data: DropdownItemProps
                  ) => {
                    updateFilters({
                      ...filters,
                      role: data.value as string
                    });
                    dispatchQuery();
                  }}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown.Menu>
        </Dropdown>
      }
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        updateFilters({
          ...filters,
          text: e.target.value as string
        })
      }
      onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          dispatchQuery();
        }
      }}
    />
  );
});
