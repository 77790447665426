import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

import { apiPrefix } from '../constants/api-routes';
import { LaunchTemplate } from '../types/buildserver';
import { notifier } from '../utils';

export const useLaunchTemplates = (): UseQueryResult<LaunchTemplate[]> => {
  return useQuery<LaunchTemplate[]>('launchTemplates', () => {
    return axios
      .get(apiPrefix('/v1.1/launch_templates'))
      .then(response => (response.data ? response.data.launchTemplates : []))
      .catch(error => {
        notifier.requestFailed(error);
        return [];
      });
  });
};
