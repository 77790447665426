import React, { memo } from 'react';
import { Redirect, RouteProps } from 'react-router';
import { Route } from 'react-router-dom';

import { authUtils } from 'utils';

import { defaultRoute } from 'constants/routing';

export const AuthForbiddenRoute: React.FC<RouteProps> = memo(props => {
  if (authUtils.loggedIn()) {
    return <Redirect to={defaultRoute.path} />;
  }

  authUtils.logout();
  return <Route {...props} />;
});
