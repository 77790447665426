import React, { memo } from 'react';
import { Button, Image, Modal } from 'semantic-ui-react';

import { ImageAssets } from 'types';

import './styles.less';

type Props = {
  readonly templateLongDescription: string;
  readonly templateImage: ImageAssets;
  readonly visible: boolean;
  readonly setVisible: (value: boolean) => void;
};

export const TemplatePreviewModal: React.FC<Props> = memo(
  ({ templateLongDescription, templateImage, visible, setVisible }) => {
    return (
      <Modal open={visible} closeIcon={true} onClose={() => setVisible(false)}>
        <Modal.Header>Template Details</Modal.Header>
        <Modal.Content>
          <Modal.Description className='preview-image'>
            <Image src={templateImage.src} bordered />
            <div>{templateLongDescription}</div>
          </Modal.Description>
          <div className='actions align-right'>
            <Button primary onClick={() => setVisible(false)}>
              Close
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
);
