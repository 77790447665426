import React, { memo } from 'react';
import { Card, Icon } from 'semantic-ui-react';

import { authUtils, contactUtils, interpolateRoute } from 'utils';

import { routes } from 'constants/routing';

import './styles.less';

type Props = {
  readonly id?: number;
};

export const QuickLinks: React.FC<Props> = memo(({ id }) => {
  const user = authUtils.getLoggedInUser();
  return (
    <Card.Group itemsPerRow={5} className='card-wrapper'>
      {contactUtils.isAdminOrSE(user.role) && (
        <Card
          raised
          className='card-wrapper'
          onClick={() =>
            (window.location.href = interpolateRoute(routes.createLabs.path))
          }
        >
          <Card.Content textAlign='center'>
            <Icon name='lab' size='big' />
          </Card.Content>
          <Card.Description textAlign='center'>New Lab</Card.Description>
        </Card>
      )}
      <Card
        raised
        className='card-wrapper'
        onClick={() =>
          (window.location.href = interpolateRoute(routes.createTopology.path, {
            id
          }))
        }
      >
        <Card.Content textAlign='center'>
          <Icon name='sitemap' size='big' />
        </Card.Content>
        <Card.Description textAlign='center'>New Topology</Card.Description>
      </Card>
      <Card
        raised
        className='card-wrapper'
        onClick={() =>
          (window.location.href = interpolateRoute(
            routes.createUserContact.path,
            {
              id
            }
          ))
        }
      >
        <Card.Content textAlign='center'>
          <Icon name='user outline' size='big' />
        </Card.Content>
        <Card.Description textAlign='center'>New User</Card.Description>
      </Card>
      <Card
        raised
        className='card-wrapper'
        onClick={() =>
          (window.location.href = interpolateRoute(routes.chat.path))
        }
      >
        <Card.Content textAlign='center'>
          <Icon name='comment outline' size='big' />
        </Card.Content>
        <Card.Description textAlign='center'>Chat Assistant</Card.Description>
      </Card>
      <Card
        raised
        className='card-wrapper'
        onClick={() =>
          (window.location.href = interpolateRoute(
            routes.apstraSizingCalculator.path
          ))
        }
      >
        <Card.Content textAlign='center'>
          <Icon name='calculator' size='big' />
        </Card.Content>
        <Card.Description textAlign='center'>
          Sizing Calculator
        </Card.Description>
      </Card>
    </Card.Group>
  );
});
