import React, { memo } from 'react';
import {
  Checkbox,
  Header,
  Icon,
  Menu,
  Pagination,
  PaginationProps,
  Table
} from 'semantic-ui-react';

import { MainLayout } from 'layouts';

import { TableFilter } from 'components';
import { BuildserverRow } from 'components/Buildservers';
import { BuildserverActions } from 'components/Buildservers/BuildserverActions';
import { TablePageSizeSelect } from 'components/TableFilter/TablePageSizeSelect';
import { useBuildservers } from 'hooks/useBuildserver';
import { Buildserver } from 'types/buildserver';

import './styles.less';

interface BuildserverTableProps {
  buildservers: Buildserver[];

  totalCount: number;

  totalPages: number;
  currentPage: number;
  limit: number;

  isLoading: boolean;

  column?: string;
  direction?: 'ascending' | 'descending';

  selected: Buildserver[];

  onChangePage(page: number): void;
  onChangeLimit(limit: number): void;
  setSelected(buildservers: Buildserver[]): void;
  handleSort(clickedColumn: string): void;
}

export const BuildserverTable: React.FC<BuildserverTableProps> = ({
  buildservers,
  totalCount,
  totalPages,
  currentPage,
  onChangeLimit,
  column,
  direction,
  handleSort,
  isLoading,
  limit,
  onChangePage,
  selected,
  setSelected
}) => {
  const buildserverRows = isLoading ? (
    <Icon loading />
  ) : (
    buildservers.map((buildserver, index) => (
      <BuildserverRow
        key={index}
        buildserver={buildserver}
        buildserverCount={buildservers.length}
        selected={selected}
        setSelected={setSelected}
      />
    ))
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLAnchorElement>,
    { activePage }: PaginationProps
  ) => {
    onChangePage(activePage as number);
  };

  const onAllSelected = () => {
    if (selected.length < buildservers.length) {
      setSelected(buildservers);
    } else {
      setSelected([]);
    }
  };

  return (
    <React.Fragment>
      <Table celled selectable sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell collapsing>
              <Checkbox
                onChange={() => onAllSelected()}
                indeterminate={
                  selected.length > 0 && selected.length < buildservers.length
                }
                checked={
                  buildservers.length > 0 &&
                  selected.length === buildservers.length
                }
                disabled={buildservers.length === 0}
              />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'name' ? direction : undefined}
              onClick={() => handleSort('name')}
            >
              Name
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'region' ? direction : undefined}
              onClick={() => handleSort('region')}
            >
              Region
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'state' ? direction : undefined}
              onClick={() => handleSort('state')}
            >
              State
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'public_dns' ? direction : undefined}
              onClick={() => handleSort('public_dns')}
            >
              Public DNS/IP
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'ram_utilization' ? direction : undefined}
              onClick={() => handleSort('ram_utilization')}
            >
              Utilization
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{buildserverRows}</Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan='8'>
              <Pagination
                totalPages={totalPages}
                activePage={currentPage}
                onPageChange={handleChangePage}
              />
              <div className={'align-right'}>
                <TablePageSizeSelect
                  limit={limit}
                  onChangeLimit={onChangeLimit}
                />
                Total: {totalCount}.
              </div>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </React.Fragment>
  );
};

export const BuildserverList: React.FC = memo(() => {
  const {
    isLoading,
    filter,
    pagination,
    sort,
    selected,
    setSelected,
    totalCount,
    totalPages,
    buildservers,
    onSubmitFilter,
    onChangePage,
    onChangeLimit,
    onSort
  } = useBuildservers<Buildserver>();

  return (
    <MainLayout className='cl-buildservers-view'>
      <Header>Buildservers</Header>
      <Menu text>
        <Menu.Item>
          <TableFilter
            filter={filter}
            onSubmitFilter={onSubmitFilter}
            loading={isLoading}
          />
        </Menu.Item>
        <Menu.Item position={'right'}>
          <BuildserverActions selected={selected} />
        </Menu.Item>
      </Menu>
      <BuildserverTable
        isLoading={isLoading}
        buildservers={buildservers}
        totalCount={totalCount}
        totalPages={totalPages}
        onChangeLimit={onChangeLimit}
        onChangePage={onChangePage}
        handleSort={onSort}
        currentPage={pagination.offset}
        limit={pagination.limit}
        selected={selected}
        setSelected={setSelected}
        column={sort.sortColumn}
        direction={sort.sortOrder}
      />
    </MainLayout>
  );
});
