import { isEmpty } from 'lodash';
import React, { memo, useState } from 'react';
import { Form, Icon, Modal } from 'semantic-ui-react';

import { validateEmail } from 'utils';

import './styles.less';

type Props = {
  readonly visible: boolean;
  readonly setVisible: (value: boolean) => void;
  readonly emails: string[];
  readonly setEmail: (value: string[]) => void;
  readonly onSubmit: () => void;
};

export const SendEmailModal: React.FC<Props> = memo(
  ({ visible, setVisible, emails, setEmail, onSubmit }) => {
    const [inputValue, setInputValue] = useState<string>('');
    const [error, setError] = useState<string>('');

    const isInList = (email: string) => {
      return emails.includes(email);
    };
    const isEmail = (email: string) => {
      return validateEmail(email);
    };
    const isValid = (email: string) => {
      let errorMsg = '';
      if (isInList(email)) {
        errorMsg = `${email} has already been added.`;
      }
      if (!isEmail(email)) {
        errorMsg = `${email} is not a valid email address.`;
      }

      if (errorMsg) {
        setError(errorMsg);
        return false;
      }
      setError('');
      return true;
    };

    const handleKeyDown = (evt: any) => {
      if (['Enter', 'Tab', ','].includes(evt.key)) {
        evt.preventDefault();
        const value = inputValue.trim();

        if (value && isValid(value)) {
          setEmail([...emails, inputValue]);
          setInputValue('');
        }
      }
    };
    const handleChange = (value: string) => {
      setInputValue(value);
    };
    const handleDelete = (item: string) => {
      setEmail(emails.filter(i => i !== item));
    };
    return (
      <Modal open={visible} closeIcon={true} onClose={() => setVisible(false)}>
        <Modal.Header>Share This Report</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form onSubmit={onSubmit}>
              {emails.map(item => (
                <div className='email-id' key={item}>
                  {item}
                  <button
                    type='button'
                    className='button'
                    onClick={() => handleDelete(item)}
                  >
                    &times;
                  </button>
                </div>
              ))}
              <Form.Input
                label='Email'
                required={isEmpty(emails)}
                multiple
                placeholder='Type email addresses and press Enter or Tab or comma'
                error={!isEmpty(error)}
                type='email'
                value={inputValue}
                onChange={event => handleChange(event.target.value)}
                onKeyDown={handleKeyDown}
              />
              {error && <p className='error'>{error}</p>}

              <div className='actions'>
                <Form.Button secondary onClick={() => setVisible(false)}>
                  <Icon className='button' name='cancel' />
                  Cancel
                </Form.Button>
                <Form.Button primary>
                  <Icon className='button' name='send' />
                  Send Email
                </Form.Button>
              </div>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
);
