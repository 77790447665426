import React, { memo, useState } from 'react';
import { Form } from 'semantic-ui-react';

import { AuthFormContainer, PasswordField, SubmitButton } from 'components';

type Props = {
  onSubmit: (password: string) => void;
};

export const ResetPasswordForm: React.FC<Props> = memo(({ onSubmit }) => {
  const [credentials, setCredentials] = useState<{
    password: string;
    confirmPassword: string;
  }>({
    password: '',
    confirmPassword: ''
  });

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(credentials.password);
  };

  return (
    <AuthFormContainer
      title='Reset Password Form'
      className='cl-reset-password-form'
    >
      <Form onSubmit={submit}>
        <PasswordField
          inputProps={{
            value: credentials.password,
            required: true,
            onChange: e =>
              setCredentials({ ...credentials, password: e.target.value })
          }}
        />
        <PasswordField
          label='Confirm Password:'
          inputProps={{
            value: credentials.confirmPassword,
            required: true,
            onChange: e =>
              setCredentials({
                ...credentials,
                confirmPassword: e.target.value
              })
          }}
        />
        <div className='flex-1' />
        <SubmitButton
          disabled={
            !credentials.password ||
            credentials.password !== credentials.confirmPassword
          }
        >
          {'RESET PASSWORD'}
        </SubmitButton>
      </Form>
    </AuthFormContainer>
  );
});
