import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Card, Segment } from 'semantic-ui-react';

import {
  getStoredBaseQueryResponse,
  notifier,
  storeBaseQueryResponse
} from 'utils';

import { chatbotApi } from 'api/chatbot';
import { BotWelcome, ChatButtonContainer } from 'components';
import { Nbsp } from 'components/Nbsp/Nbsp';
import { FeedbackSchema, QueryStorage, QuerySummary } from 'interfaces';

import { RootState } from 'Reducers/contactReducer';

import { BaseReferenceLinks } from '../KnowledgeSearchResults/BaseReferenceLinks';
import { PastQueries } from './PastQueries';

type Props = {
  readonly segmentType: string;
  readonly summary: string;
  readonly linkData: any;
  readonly inputQuery: QuerySummary;
  readonly setSegmentType: (type: string) => void;
  readonly setInputQuery: (value: QuerySummary) => void;
};
export const Queries: React.FC<Props> = ({
  segmentType,
  summary,
  linkData,
  inputQuery,
  setSegmentType,
  setInputQuery
}) => {
  const requestQuery = useSelector(
    (state: RootState) => state.chat.requestQuery
  );
  const [summaryResponse, setResponse] = React.useState<QueryStorage>({
    corpusName: '',
    favorite: false,
    query: '',
    taskUuid: '',
    response: {
      confidenceScores: [],
      summaryMsg: '',
      summaryRef: [],
      documentRef: {}
    },
    feedback: 'neutral'
  });
  const [favorited, setFavorite] = React.useState(false);
  const [feedback, setFeedback] = React.useState<string>('neutral');
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  useMemo(
    () => {
      const retrieveData = () => {
        const response = getStoredBaseQueryResponse(
          requestQuery.userQuery,
          requestQuery.corpusName
        )[0];
        setResponse(response);
        if (response && response.favorite) setFavorite(response.favorite);
        if (response && response.feedback) setFeedback(response.feedback);
      };
      if (segmentType === 'response' && !isEmpty(requestQuery.userQuery)) {
        setLoading(true);
        setFeedback('neutral');
        retrieveData();
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [requestQuery.userQuery, requestQuery.corpusName, segmentType, favorited]
  );
  const handleFavorite = () => {
    setFavorite(!summaryResponse.favorite);
    storeBaseQueryResponse(
      summaryResponse.corpusName,
      summaryResponse.query,
      !summaryResponse.favorite,
      summaryResponse.response,
      '',
      'neutral',
      true,
      false,
      false
    );
  };
  const handleFeedback = async (value: string, feedbackMessage: string) => {
    setFeedback(value);
    const data: FeedbackSchema = {
      queryBody: {
        corpusName: summaryResponse.corpusName,
        userQuery: summaryResponse.query
      },
      responseBody: summaryResponse.response,
      feedback: value,
      feedbackMessage
    };
    try {
      await chatbotApi.postFeedback(summaryResponse.taskUuid, data);
      storeBaseQueryResponse(
        summaryResponse.corpusName,
        summaryResponse.query,
        summaryResponse.favorite,
        summaryResponse.response,
        '',
        value,
        false,
        false,
        true
      );
      setModalVisible(false);
    } catch (err) {
      notifier.requestFailed(err);
    }
  };
  if (loading) {
    return (
      <Segment loading>
        <img
          src='https://react.semantic-ui.com/images/wireframe/paragraph.png'
          alt=''
        />
      </Segment>
    );
  }
  return (
    <>
      {segmentType === 'firstLoad' && <BotWelcome />}
      {segmentType === 'response' && (
        <div className='pad-cards'>
          <Segment raised padded>
            {!isEmpty(summary) && <h3>{requestQuery.userQuery}</h3>}
            {!isEmpty(summary) && (
              <ChatButtonContainer
                summaryResponse={summaryResponse}
                feedback={
                  ['positive', 'negative'].includes(summaryResponse.feedback)
                    ? summaryResponse.feedback
                    : feedback
                }
                modalVisible={modalVisible}
                setFeedback={setFeedback}
                handleFavorite={handleFavorite}
                handleFeedback={handleFeedback}
                setModalVisible={setModalVisible}
              />
            )}
            <Nbsp />
            <span dangerouslySetInnerHTML={{ __html: summary }} />
          </Segment>
          <h3>Here are some resources related to your query that can help.</h3>
          {
            <Card.Group itemsPerRow={4}>
              {linkData.map((data: any) => (
                <BaseReferenceLinks
                  key={data.url}
                  url={data.url}
                  title={data.title}
                />
              ))}
            </Card.Group>
          }
        </div>
      )}
      {['recent', 'favorite'].includes(segmentType) && (
        <PastQueries
          segmentType={segmentType}
          inputQuery={inputQuery}
          setSegmentType={setSegmentType}
          setInputQuery={setInputQuery}
        />
      )}
    </>
  );
};
