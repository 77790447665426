export const assets = {
  images: {
    aosLogo: {
      src: '/assets/images/aos-logo.png',
      alt: 'AOS'
    },
    apstraLogo: {
      src: '/assets/images/new-apstra-logo.svg',
      alt: 'Apstra'
    },
    juniperLogo: {
      src: '/assets/images/juniper-logo.png',
      alt: 'Juniper'
    },
    routerIcon: {
      src: '/assets/images/router-icon.png',
      alt: 'Router'
    },
    noImageAvailable: {
      src: '/assets/images/no-image-available.png',
      alt: 'No image available'
    }
  }
};
