import cx from 'classnames';
import { isEmpty } from 'lodash';
import React, { Fragment, memo, useCallback, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Divider, Menu, Sidebar } from 'semantic-ui-react';

import { authUtils, permissionsUtils } from 'utils';

import { AosLogo } from 'components/AosLogo';
import { UserName } from 'components/MainHeader/UserName';
import { SupportMenu } from 'components/SupportMenu';
import { routes } from 'constants/routing';
import { useRouter } from 'hooks/useRouter';
import { LoggedInUser, MenuItem } from 'types';

import { menuItems as initialMenuItems } from './menu-items';
import { MenuItemLink } from './MenuItemLink';

import './styles.less';

const SUB_ITEM_HEIGHT = 43;
const SUB_ITEM_HEIGHT_OV = 50;
const SUB_MENU_PADDING = 20;

const filterMenuItems = (user: LoggedInUser): MenuItem[] => {
  if (!user) {
    return [];
  }
  return initialMenuItems
    .map(item => {
      if (!item.children) {
        return item;
      }
      const children = item.children.filter(
        child =>
          !child.link ||
          permissionsUtils.checkRoutePermissions(child.link, user.role)
      );
      return {
        ...item,
        children: children.length ? children : undefined
      };
    })
    .filter(
      item =>
        permissionsUtils.checkRoutePermissions(item.link, user.role) &&
        (item.link || !isEmpty(item.children))
    );
};

export const MainSidebar: React.FC = memo(() => {
  const pathname = useRouter().location.pathname;
  const user = authUtils.getLoggedInUser();
  const menuItems = filterMenuItems(user);

  const isSelected = useCallback(
    (item: MenuItem) =>
      item.link === pathname ||
      (item.children && item.children.find(child => child.link === pathname)),
    [pathname]
  );

  const pathMatchItem = menuItems.find(
    item => isSelected(item) && item.children
  );
  const [expandedItem, setExpandedItem] = useState<string | null>(
    pathMatchItem ? pathMatchItem.label : null
  );

  const toggleExpanded = (item: MenuItem) => {
    if (!item.children || !item.children.length) {
      return;
    }
    setExpandedItem(isExpanded(item) ? null : item.label);
  };

  const handleOverflow = (label: string) => {
    return label === 'Lab Guides' ? SUB_ITEM_HEIGHT_OV : SUB_ITEM_HEIGHT;
  };

  const getSubMenuStyle = (item: MenuItem) => {
    return {
      maxHeight:
        expandedItem === item.label && item.children
          ? handleOverflow(item.label) * item.children.length + SUB_MENU_PADDING
          : 0,
      ...(!isExpanded(item) && { padding: 0 })
    };
  };

  const isExpanded = (item: MenuItem) => item.label === expandedItem;

  if (!user) {
    return <Redirect to={routes.login.path} />;
  }

  return (
    <Sidebar
      className='cl-main-sidebar'
      as={Menu}
      animation='overlay'
      inverted
      vertical
      visible
      width='wide'
    >
      <AosLogo nowrap />
      {menuItems.map(item => (
        <Menu.Item
          key={item.label}
          className={cx({
            expanded: isExpanded(item),
            highlight: isSelected(item) && !isExpanded(item)
          })}
        >
          <MenuItemLink item={item} toggleExpanded={toggleExpanded} />
          {item.children && (
            <Fragment>
              <Divider className='sub-menu-divider' />
              <Menu.Menu className='sub-menu' style={getSubMenuStyle(item)}>
                {item.children.map(subItem => (
                  <Menu.Item
                    key={subItem.label}
                    className={cx({ highlight: isSelected(subItem) })}
                  >
                    <MenuItemLink
                      item={subItem}
                      toggleExpanded={toggleExpanded}
                    />
                  </Menu.Item>
                ))}
              </Menu.Menu>
            </Fragment>
          )}
        </Menu.Item>
      ))}
      <Divider className='sub-menu-divider' />
      <SupportMenu />
      <UserName />
    </Sidebar>
  );
});
